import PlanActions from 'client/routes/Admin/Plans/actions'
import PartTemplateActions from 'client/routes/Admin/PartTemplates/actions'
import PartActions from 'client/routes/Admin/Plans/Parts/actions'
import PortfolioAdminActions from 'client/routes/Admin/Portfolios/actions'
import EstimateActions from 'client/routes/Admin/Plans/Estimates/actions'
import NotificationActions from 'client/components/Notifications/actions'
import AuthActions from 'client/routes/Auth/actions'
import SystemTextActions from 'client/routes/Admin/SystemTexts/actions'
import CustomerActions from 'client/routes/Customer/actions'
import UserActions from 'client/routes/Admin/Users/actions'
import HomeActions from 'client/routes/Home/actions'
import PortfolioActions from 'client/routes/Portfolios/actions'
import ProjectActions from 'client/routes/Admin/Plans/Projects/actions'
import FaqActions from 'client/routes/Admin/Faq/actions'
import { ActionTypes } from 'client/constants'

const setRenderedComponent = (subtype) => {
  return { type: ActionTypes.SET_RENDERED_COMPONENT, subtype }
}

export const setBreadcrumb = (value) => {
    return dispatch => {
        dispatch({ type: ActionTypes.SET_BREADCRUMB, payload: value })
    }
}

const clearAutosave = () => {
  return { type: ActionTypes.CLEAR_AUTOSAVE }
}

const forceUpdateAutosave = () => {
  const rand = Date.now()
  return { type: ActionTypes.FORCE_UPDATE_AUTOSAVE, payload: rand }
}

const invalidateData = () => {
  return { type: ActionTypes.INVALIDATE_DATA }
}

const Actions = Object.assign({},
  AuthActions,
  CustomerActions,
  PlanActions,
  PartTemplateActions,
  ProjectActions,
  PartActions,
  PortfolioAdminActions,
  EstimateActions,
  NotificationActions,
  SystemTextActions,
  UserActions,
  HomeActions,
  PortfolioActions,
  FaqActions,
  { setRenderedComponent,
    setBreadcrumb,
    clearAutosave,
    forceUpdateAutosave,
    invalidateData,
  }
)

export default Actions
