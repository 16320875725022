import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Router, Route, IndexRoute, DefaultRoute, browserHistory } from 'react-router'
import { Provider } from 'react-redux'
import { includes } from 'lodash'
import actions from 'client/actions'

import App from 'client/components/App'
import HomeWrapper from 'client/routes/Home'
import Marketing from 'client/routes/Home/Marketing'
import Login from 'client/routes/Auth/Login'
import NotFound from 'client/components/NotFound'
import Unauthorized from 'client/routes/Auth/Unauthorized'
import Logout from 'client/routes/Auth/Logout'

import CustomerRoutes from 'client/routes/Customer/routes'
import AdminRoutes from 'client/routes/Admin/routes'
import PortfolioRoutes from 'client/routes/Portfolios/routes'
import PlansRoutes from 'client/routes/Plans/routes.jsx';
import { ThemeProvider } from '@material-ui/styles';
import { createTheme } from '@material-ui/core/styles';
import Auth from "client/Auth/Auth";
import ga from 'react-ga'
import { GOOGLE_ANALYTICS_TRACKING } from 'client/constants'

const auth = new Auth();

ga.initialize(GOOGLE_ANALYTICS_TRACKING)

function onRouterUpdate (store) {
  return () => {
    const path = store.getState().routing.locationBeforeTransitions.pathname
    if (!includes(path, 'admin')) {
      ga.pageview(path)
    }
    store.dispatch(actions.clearNotifications())

    if (auth.isAuthenticated() && !localStorage.getItem('token')) {
      const nextUrl = window.location.query && window.location.query.next;
      store.dispatch(actions.login(localStorage.getItem('access_token'), localStorage.getItem('id_token'), nextUrl));
    }
  }
}

const handleAuthentication = (nextState) => {
  if (/access_token|id_token|error/.test(nextState.location.hash)) {
    auth.handleAuthentication();
    return true;
  }
  return false;
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#2e4c5f',
    },
    secondary: {
        main: '#FFF',
    }
  },
  overrides: {
    MuiList: {
      root: {
        '& li a': {
            color: '#FFF',
        },
        '& li.active a': {
            color: '#A6B8C5',
        },
        '& li.active .MuiSvgIcon-root': {
            color: '#A6B8C5',
        }
      },
    },
    MuiPaper: {
        root: {
            backgroundColor: '#E8E8E8'
        }
    }
  },
});

const Root = ({ store, history }) => {
  return (
    <ThemeProvider theme={theme}>
        <Provider store={ store }>
        <Router history={ history } onUpdate={ onRouterUpdate(store) }>
            <Route path="/" component={ App }>
            <Route component={ HomeWrapper } title="Driftsplaner - Plan1" >
                <IndexRoute component={ Marketing } />
                <Route path="/login"
                    component={() => <Login auth={auth} />}
                />
                <Route path="/authorize"
                    component={() => auth.login()}/>
            </Route>
            { PortfolioRoutes }
            { CustomerRoutes }
            { AdminRoutes }
            { PlansRoutes }
            </Route>

            <Route path="/auth0/callback"
                component={(props) => {
                    const isAuthenticating = handleAuthentication(props, store)
                    return <HomeWrapper isAuthenticating={isAuthenticating} />
                }}
            />
            <Route path="/no-access" component={ Unauthorized } title="Ingen adgang" />
            <Route path="/logout" component={ Logout } title="Log ud" />
            <Route path="*" component={ NotFound } />
        </Router>
        </Provider>
    </ThemeProvider>
  )
}
Root.propTypes = {
  store: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
}

export default Root
