import { push } from 'react-router-redux'
import { notifyDanger, clearNotifications, notifySuccess } from 'client/components/Notifications/actions'
import api, { ApiError } from 'client/lib/apiHandler'
import { ActionTypes } from 'client/constants'

const submitGenerator = generator => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({ type: ActionTypes.SUBMIT_GENERATOR })

      return api.post('/marketing/generator', generator)
      .then(() => {
        dispatch({ type: ActionTypes.RECEIVE_GENERATOR })
        return resolve()
      })
      .catch(error => {
        if (error instanceof ApiError) {
          dispatch(notifyDanger('Der skete en fejl'))
        }
        dispatch({ type: ActionTypes.HIDE_SPINNER })
        return reject()
      })

    })
  }
}

const submitContact = contact => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({ type: ActionTypes.SUBMIT_CONTACT })

      return api.post('/marketing/contact', contact)
      .then(() => {
        dispatch({ type: ActionTypes.RECEIVE_CONTACT })
        return resolve()
      })
      .catch(error => {
        if (error instanceof ApiError) {
          dispatch(notifyDanger('Der skete en fejl'))
        }
        dispatch({ type: ActionTypes.HIDE_SPINNER })
        return reject()
      })

    })
  }
}

export default {
  submitGenerator,
  submitContact,
}
