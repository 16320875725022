import React, { Component } from 'react'
import { connect } from 'react-redux'
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from 'client/components/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { Field, actions as formActions } from 'react-redux-form'
import {
  InputField,
  TextField,
  SelectField,
} from 'client/components/Field'
import actions from 'client/actions'

class FaqDialog extends Component {

  constructor (props) {
    super(props)
    this.handleSave = this.handleSave.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
  }

  handleSave () {
    const {dispatch, saveFaq, current, handleDialog} = this.props;
    dispatch(saveFaq(current));
    handleDialog();
  }

  handleDelete () {
    const {dispatch, deleteFaq, current, handleDialog} = this.props;
    dispatch(deleteFaq(current));
    handleDialog();
  }

  render () {
    const { form_model, establishmentYears } = this.props;
    const form = this.props.form.$form
    return (
        <Dialog maxWidth="lg" fullWidth onClose={this.props.handleDialog} open={this.props.isDialogOpen}>
            <form>
            <DialogTitle onClose={this.props.handleDialog}>
                Rediger spørgsmål
            </DialogTitle>
            <DialogContent dividers>
              <TextField
                model={ `${form_model}.question` }
                label={ 'Spørgsmål' }
                className="form-control"
                rows="8"
                cols="40"
                valid={ form.validity.text }
                />
              <TextField
                model={ `${form_model}.answer` }
                label={ 'Svar' }
                className="form-control"
                rows="8"
                cols="40"
                valid={ form.validity.text }
                />
            </DialogContent>
            <DialogActions>
                <button type="button" onClick={ this.handleDelete } className="btn btn-danger">
                    Slet
                </button>
                <button type="button" onClick={ this.handleSave } className="btn btn-primary">
                    Gem
                </button>
            </DialogActions>
            </form>
        </Dialog>
    )
  }
}


const mapStateToProps = state => {
  return {
    current: state.data.faq.current,
    form_model: 'data.faq.current',
    form: state.data.faq.form,
  }
}

const mapDispatchToProps = dispatch => ({
  dispatch,
  saveFaq: actions.saveFaq,
  deleteFaq: actions.deleteFaq,
  fetchFaqs: actions.fetchFaqs
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FaqDialog)