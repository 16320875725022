import React, { Component } from 'react'
import { connect } from 'react-redux'
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from 'client/components/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { Field, actions as formActions } from 'react-redux-form'
import {
  InputField,
  TextField,
  SelectField,
} from 'client/components/Field'
import { parseIntOrNull } from 'common/lib/validator'
import actions from 'client/actions'

class ProjectDialog extends Component {

  constructor (props) {
    super(props)
    this.handleSave = this.handleSave.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
  }

  handleSave () {
    const {dispatch, saveProject, current, handleDialog} = this.props;
    dispatch(saveProject(current));
    handleDialog();
  }

  handleDelete () {
    const {dispatch, deleteProject, current, handleDialog} = this.props;
    dispatch(deleteProject(current));
    handleDialog();
  }

  render () {
    const { form_model, establishmentYears } = this.props;
    const form = this.props.form.$form
    return (
        <Dialog onClose={this.props.handleDialog} open={this.props.isDialogOpen}>
            <form>
            <DialogTitle onClose={this.props.handleDialog}>
                Rediger projekt
            </DialogTitle>
            <DialogContent dividers>
              <TextField
                model={ `${form_model}.text` }
                label={ 'Beskrivelse' }
                className="form-control"
                rows="8"
                cols="40"
                valid={ form.validity.text }
                />

              <div className="row">
                <div className="col-xs-12">
                  <Field model={ `${form_model}.prioritized` }>
                    <label className="checkbox-inline">
                      <input type="checkbox" value /> Skal prioriteres
                    </label>
                  </Field>
                </div>
              </div>

              <div className="row">
                <div className="col-xs-6">
                    <SelectField
                        model={ `${form_model}.year` }
                        label="Udførelses år"
                        className="form-control"
                        valid={ form.validity.year }
                        >
                        <option value="">Udførelses år (årstal)</option>
                        { establishmentYears.map(y => <option key={ y } value={ y }>{ y }</option>) }
                    </SelectField>
                </div>
                <div className="col-xs-6">
                  <InputField
                    model={ `${form_model}.amount` }
                    label="Omkostninger ekskl. moms"
                    type="number"
                    parser={ parseIntOrNull }
                    className="form-control"
                    placeholder="Eks. 8000"
                    valid={ form.validity.amount }
                    />
                </div>
              </div>

              <TextField
                model={ `${form_model}.internal_note` }
                label="Interne noter til udregning (Vises ikke i driftsplan)"
                className="form-control"
                rows="4"
                cols="40"
                valid={ form.validity.internal_note }
                />
            </DialogContent>
            <DialogActions>
                <button type="button" onClick={ this.handleDelete } className="btn btn-danger">
                    Slet
                </button>
                <button type="button" onClick={ this.handleSave } className="btn btn-primary">
                    Gem
                </button>
            </DialogActions>
            </form>
        </Dialog>
    )
  }
}


const mapStateToProps = state => {
  return {
    current: state.data.project.current,
    plan: state.data.customer.plan,
    form_model: 'data.project.current',
    form: state.data.project.form,
  }
}

const mapDispatchToProps = dispatch => ({
  dispatch,
  saveProject: actions.saveProject,
  deleteProject: actions.deleteProject,
  fetchProjects: actions.fetchProjects
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectDialog)