import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Link } from 'react-router'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import map from 'lodash/map'
import { Condition, ConditionLegend } from 'client/components/Condition'
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {styles} from 'common/lib/styles';
import { withStyles } from '@material-ui/core/styles';
import PartItem from 'client/components/PartItem';
import actions from 'client/actions'

class PartsList extends Component {

  static propTypes = {
    parts: PropTypes.array.isRequired,
    params: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  }

  constructor (props) {
    super(props)
    this.partLinkHandler = this.partLinkHandler.bind(this)
  }

  headers = [
    'Tilstand', 'BIPS', 'Bygningsdel',
    'Antal', 'Rest Levetid',
    'Eftersyn', 'Vedligehold', 'Noter',
  ]

  componentDidMount() {
      const { dispatch, setBreadcrumb } = this.props
      dispatch(setBreadcrumb('Bygningsdele'));
  }

  partLinkHandler (customer_id, id) {
    const { dispatch, push } = this.props
    return ev => {
      dispatch(push(`/c/${customer_id}/parts/${id}`))
    }
  }

  render () {
    const { classes, parts, params, projects } = this.props
    const partItems = map(parts, (part, idx) => {
      return (
        <PartItem
            linkHandler={ this.partLinkHandler(params.customer_id, part.id) }
            key={ idx }
            part={ part }
            md={6}
            prioritized={!!projects.find(p => p.prioritized == true && p.part_id == part.id)}
          />
      )
    })

    return (
        <div>
            <Grid>
                <Typography className={classes.header} variant="h5">Bygningsdelskort</Typography>
                <Typography paragraph>Vælg det enkelte bygningsdelskort og se stamdata,<br/>
                    specifikation, billeder,  samt anvisninger for<br/>
                    vedligehold og eftersyn.
                </Typography>
            </Grid>
            <Grid container>
                { partItems }
            </Grid>
        </div>
    //   <div>
    //     <section id="masthead" className="wrapper">
    //       <div className="container">
    //         <div className="row">
    //           <div className="col-md-7">
    //             <h1><i className="fa fa-bars"></i> { parts.length } Bygningsdel{ parts.length > 1 ? 'e' : '' }</h1>
    //             <p className="text-muted">Drifts- og vedligeholdelsesplan: <span className="plan">{ plan.name }</span></p>
    //           </div>
    //           <PrintButton component="parts" customer_id={ customer_id } />
    //         </div>
    //       </div>
    //     </section>

    //     <section>
    //       <div className="container">
    //         <hr />
    //         <div className="lead text-center part-switch-links">
    //           {
    //             queryView === 'item'
    //               ? <span><i className="fa fa-image"></i> Vis som billedeoversigt</span>
    //               : (
    //                 <a href={ `${url}?view=item` } onClick={ this.switchViewHandler(`${url}?view=item`) }>
    //                   <i className="fa fa-image"></i> Vis som billedeoversigt
    //                 </a>
    //               )
    //           }
    //           <span className="text-muted"> | </span>
    //           {
    //             queryView === 'list'
    //               ? <span><i className="fa fa-list"></i> Vis som liste</span>
    //               : (
    //                 <a href={ `${url}?view=list` } onClick={ this.switchViewHandler(`${url}?view=list`) }>
    //                   <i className="fa fa-list"></i> Vis som liste
    //                 </a>
    //               )
    //           }
    //         </div>
    //         <hr />
    //         { view }
    //       </div>
    //     </section>
    //   </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    plan: state.data.customer.plan,
    parts: state.data.customer.parts,
    projects: state.data.customer.projects,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    setBreadcrumb: actions.setBreadcrumb,
    push,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PartsList))
