import { ActionTypes } from 'client/constants'

const systemTextsReducer = (state = { }, action) => {
  switch (action.type) {
    case ActionTypes.SYSTEM_TEXT_CHANGE: {
      const { label, content } = action.payload
      return {
        ...state,
        [label]: content,
      }
    }
    case ActionTypes.SYSTEM_TEXT_RECEIVE:
      return {
        ...action.payload,
      }
    default:
      return state
  }
}

export default systemTextsReducer
