import React from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { actions } from 'react-redux-form'
import DatePickerVendor from 'react-datepicker'
import get from 'lodash/get'

// TODO we need to add an explicit dependency on moment
import moment from 'moment'

import DatePickerCss from 'react-datepicker/dist/react-datepicker.css'

const dispatchChange = (dispatch, model, format) => {
  return ev => {
    const data = ev
      ? format
        ? ev.format(format)
        : ev.toISOString()
      : ''
    dispatch(actions.change(model, data))
  }
}

const DatePicker = ({
  model,
  dispatch,
  displayFormat,
  dataFormat = false,
  ...props
}) => {
  // use lodash `get` to get value from state
  // this is a bit dirty i feel, but it's how
  // react-redux-form does it internally, so ...
  const modelValue = get(props, model)

  const selected = modelValue
    ? dataFormat
      ? moment(modelValue, dataFormat)
      : moment(modelValue)
    : null

  return (
    <DatePickerVendor
      { ...props }
      onChange={ dispatchChange(dispatch, model, dataFormat) }
      selected={ selected }
      dateFormat={ displayFormat }
      />
  )
}
DatePicker.propTypes = {
  model: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  displayFormat: PropTypes.string.isRequired,
  dataFormat: PropTypes.string,
}

export default connect(s => s)(DatePicker)
