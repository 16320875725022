import React, { Component } from 'react'
import { connect } from 'react-redux'
import logo from 'assets/images/logo.png'
import cslogo from 'assets/images/cobblestone-logo.png'
import classnames from 'classnames'
import { merge, forEach, map, isEmpty } from 'lodash'
import actions from 'client/actions'

const getInitialContactState = _ => ({
  name: '',
  phone: '',
  details: '',
})

class Footer extends Component {

  constructor (props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.state = {
      contact: getInitialContactState(),
      errors: {},
    }
  }

  handleChange (field) {
    return ev => {
      const value = ev.target.value
      const state = merge(this.state, {
        contact: {
          [field]: value,
        },
      })
      this.setState(state)
    }
  }

  handleSubmit () {
    const { dispatch, submitContact } = this.props
    this.setState({ errors: {} })
    const contact = this.state.contact
    let errors = {}
    const required = 'Dette felt er påkrævet'
    forEach(contact, (field, key) => {
      if (field == '' || !field) {
        errors[key] = { required }
      }
    })
    this.setState({ errors })
    if (isEmpty(errors)) {
      dispatch(submitContact(contact))
      .then(_ => {
        this.setState({ showSuccess: true, contact: getInitialContactState() })
        setTimeout(() => this.setState({ showSuccess: false }), 5000)
      })
      .catch(e => {
      })
    }
  }

  renderErrors (field) {
    const errors = this.state.errors[field]
    return map(errors, error => <span className="help-block">{ error }</span>)
  }

  render () {
    const { isHome, isWorking } = this.props
    const { contact, showSuccess } = this.state

    return (
      <section id="footer" className={ classnames('page-break-before hidden-print', { 'footer-home': isHome }) }>
        <div className="container">
          {
            isHome
              ? (
                <div className="row">
                  <div className="col-md-4">
                    <img src={ logo } />
                    <br/><br/>
                    <p className="small text-muted">
                      <strong>PLAN 1</strong> Cobblestone Architects A/S<br/>
                      Gammel Køge Landevej 57, 3. sal <br/>
                      2500 Valby <br/>
                      Kontor: +45 7022 7715 <br/>
                      Besøg vores <a href="http://www.plan1.dk" target="_blank">hjemmeside</a>.
                    </p><br />
                    <img src={ cslogo } /><br /><br />
                    <p className="small text-muted">
                    Plan1 er en del af et koncernfællesskab med Cobblestone A/S<br />
                    </p>
                    <br />
                  </div>
                  <div className="col-md-4 contact">
                    <h4 className="">Lad os kontakte dig</h4>
                    <div className={ classnames('form-group', { 'has-error': !isEmpty(this.state.errors['name']) }) }>
                      <input
                        className="form-control input-sm"
                        onChange={ this.handleChange('name') }
                        required
                        value={ contact.name }
                        placeholder="Indtast dit navn"
                      />
                      { this.renderErrors('name') }
                    </div>
                    <div className={ classnames('form-group text-muted', { 'has-error': !isEmpty(this.state.errors['phone']) }) }>
                      <input
                        type="phone"
                        className="form-control input-sm"
                        onChange={ this.handleChange('phone') }
                        required
                        value={ contact.phone }
                        placeholder="Indtast dit telefon nummer"
                      />
                      { this.renderErrors('phone') }
                    </div>
                    <div className={ classnames('form-group text-muted', { 'has-error': !isEmpty(this.state.errors['details']) }) }>
                      <textarea
                        className="form-control input-sm"
                        onChange={ this.handleChange('details') }
                        required
                        value={ contact.details }
                        placeholder="Hvad skal vi tale om? Skriv en besked her"
                      />
                      { this.renderErrors('details') }
                    </div>
                    <button type="button" className="btn btn-success" onClick={ this.handleSubmit } disabled={ isWorking }>
                      <i className={ classnames('fa fa-spinner fa-spin fa-fw', { hidden: !isWorking }) }></i>
                      <i className={ classnames('fa fa-check', { hidden: !showSuccess }) }></i>
                      Send
                    </button>
                  </div>
                  <div className="col-md-4 version">
                    <p className="small text-muted pull-right hidden-print">
                      <strong>Online Driftsplaner - version 1.2</strong><br />
                    </p>
                  </div>
                </div>
              )
              : (
                <div className="row">
                  <div className="col-md-4">
                    <img src={ logo } />
                  </div>
                  <div className="col-md-4 contact">
                    <p className="small text-muted text-center">
                      <strong>PLAN 1</strong> Cobblestone Architects A/S <br/>
                      Gammel Køge Landevej 57, 3. sal <br/>
                      2500 Valby <br/>
                      Kontor: +45 7022 7715 <br/>
                      Besøg vores <a href="http://www.plan1.dk" target="_blank">hjemmeside</a>.
                    </p>
                  </div>
                  <div className="col-md-4 version">
                    <p className="small text-muted pull-right hidden-print">
                      <strong>Online Driftsplaner - version 1.2</strong><br />

                    </p>
                  </div>
                </div>
              )
          }
        </div>
      </section>
    )

  }
}

const mapStateToProps = state => {
  return {
    isWorking: state.ui.isWorking,
  }
}

const mapDispatchToProps = dispatch => ({
  dispatch,
  submitContact: actions.submitContact,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Footer)
