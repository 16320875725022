import { combineReducers } from 'redux'
import { ActionTypes } from 'client/constants'
import { modelReducer, formReducer, modeled, actionTypes as formActions } from 'react-redux-form'
import startsWith from 'lodash/startsWith'
import moment from 'moment'
import { findIndex, remove } from 'lodash'

const listReducer = (state = {
    content: [],
    is_valid: false,
}, action) => {
    switch (action.type) {
        case ActionTypes.RECEIVE_REQUEST:
            if (action.subtype === 'plans') {
                return {
                    content: action.payload,
                    is_valid: true,
                }
            }
            return state
        case ActionTypes.SEND_REQUEST:
            if (action.subtype === 'plan') {
                if (action.request === 'create' || action.request === 'update') {
                    return {
                        content: [],
                        is_valid: false,
                    }
                }
            }
            return state
        default:
            return state
    }
}

const initialCurrentState = {
    published: false,
    archived: false,
    case_no: null,
    internal_contact_id: null,
    purchased_inspection: false,
    price_index: null,
    price_index_date: null,
    start_year: null,
    overridden_updated_at: null,
    name: null,
    address1: null,
    address2: null,
    address3: null,
    zip_code: null,
    s3_image_id: null,
    city: null,
    contact_name: null,
    contact_email: null,
    contact_phone: null,
    contact_address: null,
    email_notifications: null,
    password: null,
    residential_area: null,
    commercial_area: null,
    residential_units: null,
    commercial_units: null,
    erected: null,
    bbr_no: null,
    cadastral_no: null,
    conclusion: null,
    about: null,
    recommendations: null,
    files: [],
    energy_report_number: null,
    energy_report_date: null,
    energy_label: null,
    energy_label_potential: null,
    energy_label_text: null,
}
const currentReducer = (state = initialCurrentState, action) => {
    switch (action.type) {
        case ActionTypes.RECEIVE_REQUEST:
            if (action.subtype === 'plan') {
                if (action.request === 'create') {
                    return {
                        ...state,
                        id: action.payload.id,
                        internal_contact_id: action.payload.internal_contact_id,
                        created_at: action.payload.created_at,
                        updated_at: action.payload.updated_at,
                    }
                } else if (action.request === 'fetch' || action.request === "update") {
                    return {
                        ...state,
                        ...action.payload,
                    }
                }
            } else if (action.subtype === 'plan-image') {
                if (action.request === 'fetch') {
                    const { s3_image_id, updated_at } = action.payload
                    return {
                        ...state,
                        s3_image_id,
                        updated_at,
                    }
                } else if (action.request === 'delete') {
                    const { updated_at } = action.payload
                    return {
                        ...state,
                        s3_image_id: null,
                        updated_at,
                    }
                }
            } else if (action.subtype === 'plan-file') {
                if (action.request === 'create') {
                    const files = state.files.concat([action.payload])
                    return {
                        ...state,
                        files,
                    }
                } else if (action.request === 'delete') {
                    const files = state.files
                    remove(files, {
                        id: action.payload,
                    })
                    return {
                        ...state,
                    }
                } else if (action.request === 'update') {
                    const files = state.files
                    const file = findIndex(files, { 'id': action.payload.id })
                    files[file].category = action.payload.category
                    return {
                        ...state,
                    }
                }
            }
            return state
        case ActionTypes.PUBLISH_PLAN:
            return {
                ...state,
                published: action.payload,
            }
        case ActionTypes.INVALIDATE_DATA:
            return initialCurrentState
        default:
            return state
    }
}
const modelPlan = modeled(currentReducer, 'data.plan.current')

const hasFetchedReducer = (state = false, action) => {
    switch (action.type) {
        case ActionTypes.RECEIVE_REQUEST:
            // Don't render plan children components unless plan has fetched, or if new plan, internal contacts have fetched
            if (action.subtype === 'plan' || action.subtype === 'internal_contacts') {
                return true
            }
            return state
        case ActionTypes.INVALIDATE_DATA:
            return false
        default:
            return state
    }
}

const autoSaveReducer = (state = {
    should_save: false,
    last_saved: NaN,
    is_saving: false,
    rand: 1,
}, action) => {
    switch (action.type) {
        case ActionTypes.SEND_REQUEST:
            if (action.subtype === 'plan') {
                if (action.request === 'create' || action.request === 'update') {
                    return {
                        ...state,
                        is_saving: true,
                    }
                }
            }
            return state
        case ActionTypes.RECEIVE_REQUEST:
            if (action.subtype === 'plan' || action.subtype === 'plan-image' || action.subtype === 'plan-file') {
                if (action.request === 'fetch') {
                    const last_saved = moment(action.payload.updated_at).unix()
                    return {
                        ...state,
                        should_save: false,
                        last_saved,
                        is_saving: false,
                    }
                }
                const now = moment().unix()
                return {
                    ...state,
                    should_save: false,
                    last_saved: now,
                    is_saving: false,
                }
            } else if (action.subtype === 'error') {
                return {
                    ...state,
                    should_save: false,
                    is_saving: false,
                }
            }
            return state
        case ActionTypes.PUBLISH_PLAN: {
            const now = moment().unix()
            return {
                ...state,
                should_save: false,
                last_saved: now,
                is_saving: false,
            }
        }
        case formActions.CHANGE:
            if (!state.should_save && startsWith(action.model, 'data.plan.current')) {
                return {
                    ...state,
                    should_save: true,
                }
            }
            return state
        case ActionTypes.CLEAR_AUTOSAVE:
            return {
                ...state,
                should_save: false,
                last_saved: NaN,
                is_saving: false,
            }
        case ActionTypes.FORCE_UPDATE_AUTOSAVE: {
            const rand = action.payload
            return {
                ...state,
                rand,
            }
        }
        default:
            return state
    }
}

const formDataReducer = (state = {
    internal_contacts: [],
    assets: [],
}, action) => {
    switch (action.type) {
        case ActionTypes.SET_ASSETS:
            return {
                ...state,
                assets: action.assets,
            }
        case ActionTypes.RECEIVE_REQUEST:
            if (action.subtype === 'internal_contacts') {
                return {
                    ...state,
                    internal_contacts: action.payload,
                }
            }
            return state
        case ActionTypes.INVALIDATE_DATA:
            return {
                internal_contacts: [],
            }
        default:
            return state
    }
}


export default combineReducers({
    list: listReducer,
    current: modelPlan,
    auto_save: autoSaveReducer,
    form: formReducer('data.plan.current'),
    form_data: formDataReducer,
    hasFetched: hasFetchedReducer,
})
