import fetch from 'isomorphic-fetch'
import { push } from 'react-router-redux'
import { actions as FormActions } from 'react-redux-form'
import { ActionTypes, API_URI } from 'client/constants'
import { notifyDanger, notifySuccess, clearNotifications } from 'client/components/Notifications/actions'
import validate, { ValidationError } from 'common/lib/validator'
import { PartSchema } from 'common/schemas'
import api, { ApiError } from 'client/lib/apiHandler'

const sendRequest = (subtype, request) => {
  return {
    type: ActionTypes.SEND_REQUEST,
    subtype,
    request,
  }
}

const receiveRequest = (subtype, request, payload) => {
  return {
    type: ActionTypes.RECEIVE_REQUEST,
    subtype,
    request,
    payload,
  }
}

const receivePublishPart = (publish) => {
  return {
    type: ActionTypes.PUBLISH_PART,
    payload: publish,
  }
}

export const fetchParts = (plan_id) => {

  return dispatch => {
    dispatch(sendRequest('parts', 'fetch'))

    api.get(`/parts/by-plan/${plan_id}`)
    .then(({ body }) => {
      dispatch(receiveRequest('parts', 'fetch', body))
    })
    .catch(error => {
      dispatch({ type: ActionTypes.RECEIVE_REQUEST, subtype: 'error' })
    })
  }
}

export const fetchPart = (plan_id, part_id) => {

  return dispatch => {
    dispatch(sendRequest('part', 'fetch'))

    Promise.all([
      api.get(`/parts/${part_id}`),
      api.get('/bips'),
    ])
    .then(([part, bips]) => {
      dispatch(receiveRequest('part', 'fetch', part.body))
      dispatch(receiveRequest('bips', 'fetch', bips.body))
    })
    .catch(error => {
      if (error instanceof ApiError) {
        dispatch(push(`/admin/plans/edit/${plan_id}/parts`))
      }
      dispatch({ type: ActionTypes.RECEIVE_REQUEST, subtype: 'error' })
    })
  }
}

export const savePart = (operation, part) => {
  // operation is always 'update', but we don't need to differentiate

  return dispatch => {

    validate(PartSchema, part, false, { ignoreErrors: true })
    .then(result => {
      dispatch(sendRequest('part', operation))

      dispatch(FormActions.setValidity('data.part.current', true))
      dispatch(FormActions.setErrors('data.part.current', result.errors))

      return api.put(`/parts/${part.id}`, result.data)
    })
    .then(({ body }) => {
      dispatch(receiveRequest('part', operation, body))
      dispatch(clearNotifications('danger'))
    })
    .catch(error => {
      if (error instanceof ApiError) {
        dispatch(notifyDanger('Der skete en fejl', 'Error'))
      }
      dispatch({ type: ActionTypes.RECEIVE_REQUEST, subtype: 'error' })
    })
  }
}

export const createPart = (template_id, plan_id) => {
  const body = { template_id, plan_id }

  return (dispatch, getState) => {
    dispatch(sendRequest('part', 'create'))

    api.post('/parts', body)
    .then(({ body }) => {
      dispatch(receiveRequest('part', 'create', body))
      const path = getState().routing.locationBeforeTransitions.pathname
      const editPath = path.replace('new', `edit/${body.id}`)
      dispatch(push(editPath))
    })
    .catch(error => {
      if (error instanceof ApiError) {
        dispatch(notifyDanger('Der skete en fejl', 'Error'))
      }
      dispatch({ type: ActionTypes.RECEIVE_REQUEST, subtype: 'error' })
    })
  }
}

const savePartUpload = (id, s3_image_id) => {
  const body = { id, s3_image_id }

  return dispatch => {
    dispatch(sendRequest('part-image', 'create'))
    api.post(`/parts/${id}/image`, body)
    .then(({ body }) => {
      dispatch(receiveRequest('part-image', 'create', body))
    })
    .catch(error => {
      if (error instanceof ApiError) {
        dispatch(notifyDanger('Der skete en fejl', 'Error'))
      }
      dispatch({ type: ActionTypes.RECEIVE_REQUEST, subtype: 'error' })
    })
  }
}

const deletePartUpload = (part_id, id) => {

  return dispatch => {
    api.del(`/parts/${part_id}/image/${id}`)
    .then(res => {
      dispatch(receiveRequest('part-image', 'delete', id))
    })
    .catch(error => {
      if (error instanceof ApiError) {
        dispatch(notifyDanger('Der skete en fejl', 'Error'))
      }
      dispatch({ type: ActionTypes.RECEIVE_REQUEST, subtype: 'error' })
    })
  }
}

const publishPart = (part_id, part) => {

  return dispatch => {
    validate(PartSchema, part, 'publish')
    .then(result => {
      dispatch(FormActions.setValidity('data.part.current', true))

      const body = { publish: true }
      return api.put(`/parts/${part_id}/publish/`, body)
    })
    .then(res => {
      dispatch(receivePublishPart(true))
      dispatch(notifySuccess('Bygningsdelen er udgivet'))
    })
    .catch(error => {
      if (error instanceof ValidationError) {
        dispatch(FormActions.setValidity('data.part.current', true))
        dispatch(FormActions.setErrors('data.part.current', error.errors))
        dispatch(notifyDanger('Bygningsdel er ikke komplet', 'Error'))
      } else if (error instanceof ApiError) {
        dispatch(notifyDanger('Der skete en fejl', 'Error'))
      }
      dispatch({ type: ActionTypes.RECEIVE_REQUEST, subtype: 'error' })
    })
  }
}

const unpublishPart = (part_id, plan) => {

  return dispatch => {

    if (plan.published) {
      dispatch(notifyDanger('Kan ikke sætte part til kladde da planen er udgivet'))
    } else {
      const body = { publish: false }
      api.put(`/parts/${part_id}/publish/`, body)
      .then(res => {
        dispatch(receivePublishPart(false))
        dispatch(notifySuccess('Bygningsdelen er sat som kladde'))
      })
      .catch(error => {
        if (error instanceof ApiError) {
          dispatch(notifyDanger('Der skete en fejl', 'Error'))
        }
        dispatch({ type: ActionTypes.RECEIVE_REQUEST, subtype: 'error' })
      })
    }
  }
}

const deletePart = (plan_id, part_id) => {

  return dispatch => {

    const body = { id: part_id }
    api.del(`/parts/${part_id}`, body)
    .then(res => {
      dispatch(sendRequest('part', 'delete'))
      dispatch(push(`/admin/plans/edit/${plan_id}/parts`))
    })
    .catch(error => {
      if (error instanceof ApiError) {
        dispatch(notifyDanger('Der skete en fejl', 'Error'))
      }
      dispatch({ type: ActionTypes.RECEIVE_REQUEST, subtype: 'error' })
    })
  }
}

export default {
  fetchParts,
  fetchPart,
  savePart,
  createPart,
  savePartUpload,
  deletePartUpload,
  publishPart,
  unpublishPart,
  deletePart,
}
