
import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { map, uniqBy, merge, forEach, reduce } from 'lodash'
import classNames from 'classnames'

export default class CustomerInformation extends Component {

  static propTypes = {
    plan: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    saveCustomerData: PropTypes.func.isRequired,
    toggleView: PropTypes.func.isRequired,
  }

  constructor (props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.state = {
      isEditing: false,
      plan: {},
      hasError: false,
      errors: {
        name: false,
        address1: false,
        zip_code: false,
        city: false,
        contact_name: false,
        contact_email: false,
        contact_phone: false,
        email_notifications: false,
        password: false,
      },
    }
  }

  componentWillMount () {
    const {
      name, address1, address2, address3,
      zip_code, city, contact_name, contact_email,
      contact_phone, email_notifications, password,
    } = this.props.plan
    const localPlan = {
      name, address1, address2, address3,
      zip_code, city, contact_name, contact_email,
      contact_phone, email_notifications, password,
    }
    const state = merge(this.state, {
      plan: localPlan,
    })
    this.setState(state)
  }

  handleChange (field) {
    return ev => {
      const value = ev.target.value
      const state = merge(this.state, {
        plan: {
          [field]: value,
        },
      })
      this.setState(state)
    }
  }

  handleSave () {
    const { dispatch, saveCustomerData, toggleView, plan: { id } } = this.props
    const state = this.state

    let hasError = false
    forEach(state.errors, (error, field) => {
      state.plan[field] === '' // eslint-disable-line no-unused-expressions
       ? state.errors[field] = hasError = true
       : state.errors[field] = false
    })
    if (state.plan.password.length < 4) {
      state.errors.password = hasError = true
    }
    if (!hasError) {
      const plan = this.state.plan
      plan.id = id
      dispatch(saveCustomerData(plan))
      toggleView()
    }
    this.setState(merge(this.state, {
      hasError,
      errors: state.errors,
    }))
  }

  render () {
    const { plan } = this.state

    const helpBlock = this.state.hasError
      ? (
        <div className="form-group" className={ classNames('form-group', { 'has-error': this.state.hasError }) }>
          <div className="help-block">Markeret felter skal udfyldes</div>
        </div>
      )
      : null

    return (
      <div className="customer-data">
        <div className="row">
          <div className="col-sm-6">
            <p className="text-center"><strong><i className="fa fa-map-marker"></i> Stamdata</strong></p>
            <div className="form-group" className={ classNames('form-group', { 'has-error': this.state.errors.name }) }>
              <label className="control-label">Foreningsnavn</label>
              <input
                type="text"
                className="form-control input-sm"
                value={ plan.name }
                onChange={ this.handleChange('name') }
                />
            </div>
            <div className="form-group" className={ classNames('form-group', { 'has-error': this.state.errors.address1 }) }>
              <label className="control-label">Addresse 1</label>
              <input
                type="text"
                className="form-control input-sm"
                value={ plan.address1 }
                onChange={ this.handleChange('address1') }
                />
            </div>
            <div className="form-group" className={ classNames('form-group', { 'has-error': this.state.errors.address2 }) }>
              <label className="control-label">Addresse 2</label>
              <input
                type="text"
                className="form-control input-sm"
                value={ plan.address2 }
                onChange={ this.handleChange('address2') }
                />
            </div>
            <div className="form-group" className={ classNames('form-group', { 'has-error': this.state.errors.address3 }) }>
              <label className="control-label">Addresse 3</label>
              <input
                type="text"
                className="form-control input-sm"
                value={ plan.address3 }
                onChange={ this.handleChange('address3') }
                />
            </div>
            <div className="form-group" className={ classNames('form-group', { 'has-error': this.state.errors.zip_code }) }>
              <label className="control-label">Postnummer</label>
              <input
                type="text"
                className="form-control input-sm"
                value={ plan.zip_code }
                onChange={ this.handleChange('zip_code') }
                />
            </div>
            <div className="form-group" className={ classNames('form-group', { 'has-error': this.state.errors.city }) }>
              <label className="control-label">By</label>
              <input
                type="text"
                className="form-control input-sm"
                value={ plan.city }
                onChange={ this.handleChange('city') }
                />
            </div>
          </div>
          <div className="col-sm-6">
            <p className="text-center"><strong><i className="fa fa-user"></i> Kontaktperson</strong></p>
            <div className="form-group" className={ classNames('form-group', { 'has-error': this.state.errors.contact_name }) }>
              <label className="control-label">Navn</label>
              <input
                type="text"
                className="form-control input-sm"
                value={ plan.contact_name }
                onChange={ this.handleChange('contact_name') }
                />
            </div>
            <div className="form-group" className={ classNames('form-group', { 'has-error': this.state.errors.contact_email }) }>
              <label className="control-label">Email</label>
              <input
                type="text"
                className="form-control input-sm"
                value={ plan.contact_email }
                onChange={ this.handleChange('contact_email') }
                />
            </div>
            <div className="form-group" className={ classNames('form-group', { 'has-error': this.state.errors.contact_phone }) }>
              <label className="control-label">Telefon</label>
              <input
                type="text"
                className="form-control input-sm"
                value={ plan.contact_phone }
                onChange={ this.handleChange('contact_phone') }
                />
            </div>
            <div className="form-group" className={ classNames('form-group', { 'has-error': this.state.errors.email_notifications }) }>
              <label className="control-label">Email notifikationer</label>
              <input
                type="text"
                className="form-control input-sm"
                value={ plan.email_notifications }
                onChange={ this.handleChange('email_notifications') }
                />
            </div>
            <p><strong><i className="fa fa-lock"></i> Login info</strong></p>
            <div className="form-group" className={ classNames('form-group', { 'has-error': this.state.errors.password }) }>
              <label className="control-label">Adgangskode</label>
              <input
                type="text"
                className="form-control input-sm"
                value={ plan.password }
                onChange={ this.handleChange('password') }
                />
              <div className="help-block" className={ classNames('help-block', { hidden: !this.state.errors.password }) }>Adgangskode skal være mindst 4 karaktere</div>
            </div>
          </div>
          <hr />
          <div className="col-sm-12 text-center">
            { helpBlock }
            <button onClick={ this.handleSave } className="btn btn-success" role="button">Gem</button>
            <button onClick={ this.props.toggleView } className="btn btn-primary" role="button">Luk</button>
          </div>
        </div>
      </div>
    )
  }

}