export const UserSchema = {
  base: {
    properties: {
      id: {
        type: 'integer',
        allowNull: false,
      },
      name: {
        type: 'string',
        required: true,
      },
      email: {
        type: 'email',
        required: true,
      },
      phone: {
        type: 'string',
      },
      password: {
        label: 'Adgangskode',
        type: 'string',
        required: true,
      },
      deleted: {
        type: 'boolean',
      },
      role: {
        type: 'string',
        required: true,
      },
      is_revoked: {
        type: 'boolean',
      },
    },
  },
  extensions: {
    update: {
      properties: {
        id: {
          required: true,
        },
        email: {
          required: true,
        },
        password: {
          strip: true,
          required: false,
        },
        is_revoked: {
          strip: true,
        },
      },
    },
  },
}

export const UserImageSchema = {
  base: {
    properties: {
      id: {
        type: 'integer',
        allowNull: false,
        required: true,
      },
      s3_image_id: {
        type: 'string',
        required: true,
      },
    },
  },
}
