export const ProjectSchema = {
  base: {
    properties: {
      id: {
        type: 'integer',
        allowNull: false,
      },
      part_id: {
        type: 'integer',
        allowNull: false,
      },
      text: {
        type: 'string',
      },
      internal_note: {
        type: 'string',
      },
      amount: {
        type: 'integer',
        minValue: 0,
        allowEmpty: '',
        allowNull: true,
      },
      year: {
        type: 'integer',
        allowEmpty: '',
        allowNull: true,
      },
      prioritized: {
        type: 'boolean',
        default: false
      },
    },
  },
  extensions: {
    update: {
      properties: {
        id: {
          required: true,
        },
      },
    },
  },
}