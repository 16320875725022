import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import includes from 'lodash/includes'

import actions from './actions'

const alertTypes = ['success', 'info', 'warning', 'danger']
const baseClassName = 'alert alert-dismissible'

const Notification = ({ type, title, message, duration, hide }) => {
  const classType = includes(alertTypes, type) ? type : 'info'
  const className = `${baseClassName} alert-${classType}`
  const headline = title ? <strong>{ title }:</strong> : null

  if (duration) {
    setTimeout(hide, duration)
  }

  return (
    <div>
      <div className={ className }>
        <button type="button" className="close" title="Dismiss" onClick={ hide }>&times;</button>
        { headline } { message }
      </div>
    </div>
  )
}
Notification.displayName = 'Notification'
Notification.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string,
  duration: PropTypes.number,
  hide: PropTypes.func.isRequired,
}

class Notifications extends Component {

  static propTypes = {
    notifications: PropTypes.array.isRequired,
    dispatch: PropTypes.func.isRequired,
    notifyHide: PropTypes.func.isRequired,
  }

  constructor (props) {
    super(props)
    this.dismiss = this.dismiss.bind(this)
  }

  dismiss (idx) {
    return ev => {
      const { dispatch, notifyHide } = this.props
      dispatch(notifyHide(idx))
    }
  }

  render () {
    const { notifications } = this.props

    const visible = notifications.map((notification, idx) => {
      return <Notification { ...notification } key={ idx } hide={ this.dismiss(idx) } />
    })

    return (
      <div className="alert-notifier-container">
          { visible }
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    notifications: state.ui.notifications,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    notifyHide: actions.notifyHide,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Notifications)
