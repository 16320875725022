import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Link } from 'react-router'
import { connect } from 'react-redux'
import numeral from 'common/lib/numeral'
import PrintButton from 'client/components/PrintButton'
import DocumentTitle from 'react-document-title'

class Property extends Component {

  static propTypes = {
    plan: PropTypes.object.isRequired,
    texts: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
    route: PropTypes.object.isRequired,
  }

  render () {
    const { plan, texts, params: { customer_id }, route } = this.props
    const { internal_contact = {} } = plan

    return (
      <div>
        <DocumentTitle title={ route.title } />
        <section id="headerDock" className="wrapper">
          <div id="stickyNav_container">
            <div id="stickyNav">
              <div id="masthead">
                <div className="container">
                  <div className="row">
                    <div className="col-md-7 headerInfo">
                      <h1><i className="fa fa-building-o"></i>  Ejendommen</h1>
                      <p className="text-muted">Drifts- og vedligeholdelsesplan: <span className="plan">{ plan.name }</span></p>
                    </div>
                    <PrintButton component="property" customer_id={ customer_id } />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>



        <section>
          <div className="container">
            <div className="row">
              <div className="col-md-3" id="leftCol" style={ { marginTop: '30px' } }>
                <div className="well">
                  <img src={ `https://ik.imagekit.io/aw3ddey5g/${plan.s3_image_id}?tr=w-400` } alt="" className="img-responsive" />
                  <div className="text-center">
                    <h3 className="text-center">Stamdata</h3>
                  </div>
                  <div className="row text-center">
                    <div className="col-md-12">
                      <h4 className="text-muted">Areal</h4>
                      <h3>{ numeral(plan.total_area).format('0,0') } m<sup>2</sup></h3>
                    </div>
                    <div className="col-md-12">
                      <h4 className="text-muted">Opført</h4>
                      <h3>{ plan.erected }</h3>
                    </div>
                  </div>
                  <div className="row text-center">
                    <div className="col-md-12">
                      <h4 className="text-muted">Ejendomsnr.</h4>
                      <h3>{ plan.bbr_no }</h3>
                    </div>
                    <div className="col-md-12">
                      <h4 className="text-muted">Matrikel nr.</h4>
                      <h3>{ plan.cadastral_no }</h3>
                    </div>
                  </div>
                  <hr />
                </div>
              </div>

              <div className="col-md-9">

                <h2 id="sec5" className="text-center">Om ejendommen</h2>
                <hr />
                <p>
                  { plan.about
                    ? plan.about.split('\n').map((line, idx) => {
                      return (
                        <span key={ idx }>
                          { line }
                          <br />
                        </span>
                      )
                    })
                    : null
                  }
                </p>
                <hr />

                { plan.recommendations
                  ? <div>
                      <h2 id="sec3" className="text-center">Anbefalinger </h2>
                      <hr />
                    </div>
                  : null
                }
                <p>
                  { plan.recommendations
                    ? plan.recommendations.split('\n').map((line, idx) => {
                      return (
                        <span key={ idx }>
                          { line }
                          <br />
                        </span>
                      )
                    })
                    : null
                  }
                </p>


                <h2 id="sec6" className="text-center">Grundlag for drifts- og vedligeholdelsesplanen:</h2>
                <hr />
                <p>
                  { texts.foundation
                    ? texts.foundation.split('\n').map((line, idx) => {
                      return (
                        <span key={ idx }>
                          { line }
                          <br />
                        </span>
                      )
                    })
                    : null
                  }
                </p>

                <h2 id="sec7" className="text-center">Vejledning</h2>
                <hr />
                <p>
                  { texts.terms
                    ? texts.terms.split('\n').map((line, idx) => {
                      return (
                        <span key={ idx }>
                          { line }
                          <br />
                        </span>
                      )
                    })
                    : null
                  }
                </p>

                <hr />
              </div>
            </div>
          </div>

        </section>


      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    plan: state.data.customer.plan,
    texts: state.data.customer.systemTexts,
  }
}

export default connect(
  mapStateToProps
)(Property)
