import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import actions from 'client/actions'
import DocumentTitle from 'react-document-title'

class UsersWrapper extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    fetchUsers: PropTypes.func.isRequired,
    isWorking: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
    route: PropTypes.object.isRequired,
  }

  componentDidMount () {
    const { dispatch, fetchUsers } = this.props
    dispatch(fetchUsers())
  }

  render () {
    const { isWorking, children, route } = this.props
    return (
      <DocumentTitle title={ route.title }>
        { isWorking ? null : children }
      </DocumentTitle>
    )
  }
}

const mapStateToProps = state => ({
  isWorking: state.ui.isWorking,
})

const mapDispatchToProps = dispatch => ({
  dispatch,
  fetchUsers: actions.fetchUsers,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersWrapper)
