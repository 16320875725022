import { actions as FormActions } from 'react-redux-form'
import { ActionTypes, API_URI } from 'client/constants'
import { notifyDanger, notifySuccess, clearNotifications } from 'client/components/Notifications/actions'
import validate, { ValidationError } from 'common/lib/validator'
import api, { ApiError } from 'client/lib/apiHandler'
import { FaqSchema } from 'common/schemas'

const sendRequest = (subtype, request) => {
  return {
    type: ActionTypes.SEND_REQUEST,
    subtype,
    request,
  }
}

const receiveRequest = (subtype, request, payload) => {
  return {
    type: ActionTypes.RECEIVE_REQUEST,
    subtype,
    request,
    payload,
  }
}

export const fetchFaqs = () => {
  return (dispatch) => {
    dispatch(sendRequest('faqs', 'fetch'))
    api.get('/faq')
    .then(({ body }) => {
      dispatch(receiveRequest('faqs', 'fetch', body))
    })
    .catch(error => {
      dispatch({ type: ActionTypes.RECEIVE_REQUEST, subtype: 'error' })
    })
  }
}

export const fetchFaq = (faq) => {

  return dispatch => {
      dispatch(receiveRequest('faq', 'fetch', faq))
  }
}


export const createFaq = () => {
  return dispatch => {

    const body = {}
    api.post(`/faq`, body)
    .then(res => {
      dispatch(receiveRequest('faq', 'create', res.body))
    })
    .catch(error => {
      if (error instanceof ApiError) {
        dispatch(notifyDanger('Der skete en fejl', 'Error'))
      }
      dispatch({ type: ActionTypes.RECEIVE_REQUEST, subtype: 'error' })
    })
  }
}

export const saveFaq = (faq) => {

  return dispatch => {

    validate(FaqSchema, faq, false, { ignoreErrors: true })
    .then(result => {
      dispatch(sendRequest('faq', 'update'))

      dispatch(FormActions.setValidity('data.faq.current', true))
      dispatch(FormActions.setErrors('data.faq.current', result.errors))

      return api.put(`/faq/${faq.id}`, result.data)
    })
    .then(({ body }) => {
      dispatch(receiveRequest('faq', 'update', body))
      dispatch(clearNotifications('danger'))
    })
    .catch(error => {
      if (error instanceof ApiError) {
        dispatch(notifyDanger('Der skete en fejl', 'Error'))
      }
      dispatch({ type: ActionTypes.RECEIVE_REQUEST, subtype: 'error' })
    })
  }
}

export const deleteFaq = (faq) => {

  return dispatch => {

    const body = { id: faq.id }
    api.del(`/faq/${faq.id}`, body)
    .then(res => {
      dispatch(receiveRequest('faq', 'delete', faq))
    })
    .catch(error => {
      if (error instanceof ApiError) {
        dispatch(notifyDanger('Der skete en fejl', 'Error'))
      }
      dispatch({ type: ActionTypes.RECEIVE_REQUEST, subtype: 'error' })
    })
  }
}

export default {
  fetchFaqs,
  fetchFaq,
  saveFaq,
  createFaq,
  deleteFaq,
}
