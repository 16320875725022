import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import {styles} from 'common/lib/styles';
import Badge from '@material-ui/core/Badge';
import ErrorIcon from '@material-ui/icons/Error';
import Tooltip from '@material-ui/core/Tooltip';
import { Condition } from './Condition';
import noImage from 'assets/images/no-image.png'
import {ReactComponent as NoteIcon} from 'assets/images/note-icon.svg'
class PartItem extends Component {

  render () {
    const {classes, project, linkHandler, part, md, prioritized} = this.props;
    const {images, title, condition, amount, remaining_lifetime, projects} = part;
    const imageUrl = images.length > 0
        ? `https://ik.imagekit.io/aw3ddey5g/${images[0].s3_image_id}?tr=w-223,h-167`
        : noImage
    return (
         <Grid item sm={12} md={md} className={classes.partGrid} onClick={ linkHandler }>
            <Badge
                overlap="circular"
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
                }}
                badgeContent={prioritized && <Tooltip title="Skal prioriteres"><ErrorIcon className={classes.iconWarning}/></Tooltip>}
            >
                <Avatar className={classes.partAvatar} src={imageUrl} />
            </Badge>
            {project ? <Typography>
                <b>{ title }</b><br/>
                {(prioritized ? 'Hurtigst muligt' : project.year)}
                {part.notes.length ? <Tooltip PopperProps={{ style: { marginTop: -20 } }} title="Indeholder noter">
                    <NoteIcon className={classes.noteIcon}/>
                </Tooltip> : null}
            </Typography>
             : <>
            <Typography className={classes.partTypographyPadding}>
                <b>Bygningsdel:</b><br/>
                <b>Tilstand:</b> <br/>
                <b>Antal:</b><br/>
                <b>Restlevetid:</b>
            </Typography>
            <Typography>
                { title }<br/>
                <Condition condition={ condition } hasText={ false } /><br/>
                {amount}<br/>
                {remaining_lifetime}
            </Typography>
            </>}
        </Grid>
    )
  }
}


const mapStateToProps = state => {
  return {
    plan: state.data.customer.plan
  }
}

const mapDispatchToProps = dispatch => ({
  dispatch
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PartItem))
