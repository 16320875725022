import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { Link } from 'react-router'
import classNames from 'classnames'
import DateTime from 'client/components/DateTime'
import actions from './actions'
import { PlanStatus } from './index'
import { push } from 'react-router-redux'
import numeral from 'common/lib/numeral'
import { filter } from 'lodash'
import PlanPurchasedInspectionLabel from "./PlanPurchasedInspectionLabel";

const PlanRow = ({ plan, handler, locked }) => {
    const {
        id, case_no, name, zip_code,
        internal_contact, published, archived,
        created_at, updated_at, erected, residential_area, commercial_area, purchased_inspection, inspection_date
    } = plan;

    return (
        <tr onClick={ handler(`/admin/plans/edit/${id}`) }>
            <td key="lock">{ locked ? <i className="fa fa-lock"/> : null }</td>
            <td key="case_no">{ case_no }</td>
            <td key="created_at" className="hidden-xs hidden-sm"><DateTime format="DD-MM-YYYY" source={ created_at } /></td>
            <td key="updated_at" className="hidden-xs hidden-sm"><DateTime format="DD-MM-YYYY" source={ updated_at } /></td>
            <td key="status" className="hidden-xs"><PlanStatus published={ published } archived={ archived } /></td>
            <td key="name">{ name }</td>
            <td key="zip_code">{ zip_code }</td>
            <td key="purchased_inspection"><PlanPurchasedInspectionLabel purchased_inspection={purchased_inspection} inspection_date={inspection_date}/></td>
            <td key="erected" className="hidden-xs">{ erected }</td>
            <td key="residential_area" className="hidden-xs">{ numeral(residential_area).format('0,0') }</td>
            <td key="commercial_area" className="hidden-xs">{ numeral(commercial_area).format('0,0') }</td>
            <td key="internal_contact_name">{ internal_contact ? internal_contact.name : null }</td>
            <td key="preview_url"><a href={ `/c/${id}` } target="_blank">Vis</a></td>
        </tr>
    )
}
PlanRow.propTypes = {
    plan: PropTypes.object.isRequired,
    handler: PropTypes.func.isRequired,
}

class PlanTable extends Component {

    static propTypes = {
        list: PropTypes.array.isRequired,
        is_valid: PropTypes.bool.isRequired,
        dispatch: PropTypes.func.isRequired,
        fetchPlans: PropTypes.func.isRequired,
        editLinkHandler: PropTypes.func.isRequired,
    }

    constructor (props) {
        super(props)
        this.state = {
            planFilter: 'own',
            sortProperty: "name",
            sortDirection: "asc",
        }
    }

    componentDidMount () {
        const { dispatch, fetchPlans, is_valid } = this.props
        if (!is_valid) {
            dispatch(fetchPlans())
        }
    }

    /**
     * @param {string} headerName
     * @returns {boolean}
     */
    static isHiddenXs(headerName) {
        return headerName === 'Status'
            || headerName === 'Opført'
            || headerName === 'Areal'
            || headerName === 'Oprettet'
            || headerName === 'Opdateret'
            || headerName === 'Ansvarlig'
    }

    /**
     * @param {string} headerName
     * @returns {boolean}
     */
    static isHiddenSm(headerName) {
        return headerName === 'Oprettet' || headerName === 'Opdateret';
    }

    /**
     * @param {string} propertyValue
     * @param {string} propertyName
     * @param {object} plan
     * @returns {*}
     */
    formatPropertyForSort = (propertyValue, propertyName, plan) => {
        switch (propertyName) {
            case "internal_contact_name":
                return plan["internal_contact"].name;
            case "purchased_inspection":
                // Re-map 'purchased_inspection' values to a sensible ordering scheme
                switch (propertyValue) {
                    case 0:
                        return 2 + " " + (120 - plan.inspection_date);
                    case 1:
                        return 0 + " " + (120 - plan.inspection_date);
                    case 2:
                        return 1 + " " + (120 - plan.inspection_date);
                    default:
                        return 0;
                }
            case "published":
                switch (true) {
                    case plan.published:
                        return 0;
                    case plan.archived:
                        return 2;
                    default:
                        return 1;
                }
            default:
                return propertyValue;
        }
    }

    /**
     * @param {object} a
     * @param {object} b
     * @returns {number}
     */
    rowSort = (a, b) => {
        const { sortProperty, sortDirection } = this.state;

        const aProp = this.formatPropertyForSort(a[sortProperty], sortProperty, a);
        const bProp = this.formatPropertyForSort(b[sortProperty], sortProperty, b);

        if (aProp === bProp) {
            return 1;
        }

        if (aProp === null) {
            return 1;
        } else if (bProp === null) {
            return -1;
        }

        if (aProp < bProp){
            return sortDirection === "desc" ? 1 : -1;
        }

        if (aProp > bProp){
            return sortDirection === "desc" ? -1 : 1;
        }

        return 0;
    }

    /**
     * @returns {object[]}
     */
    getRows = () => {
        const { role, list, userId, editLinkHandler, ownPlans } = this.props;

        let plans = this.state.planFilter === 'own' ? ownPlans : list;
        plans = plans.sort(this.rowSort);

        return plans.map((plan, idx) => {
            const locked = role === 'editor' && plan.internal_contact_id !== userId;

            return <PlanRow key={ idx } plan={ plan } handler={ editLinkHandler } locked={ locked } />;
        });
    }

    /**
     * @param {string} headerName
     * @param {string|null} propertyName
     * @returns {object}
     */
    getTableHeader = (headerName, propertyName) => {
        const { sortProperty, sortDirection } = this.state;

        if (typeof propertyName !== "string") {
            return headerName;
        }

        return (
            <div className="d-flex pointer" onClick={() => this.newSort(propertyName)}>
                <span className="part-header-name">{headerName}</span>
                <div className="sort-container">
                    {["asc", "desc"].map(direction => {
                        return (<i className={`fa fa-sort-${direction} part-sort-${direction} ${sortProperty === propertyName && sortDirection === direction ? "active-sort" : ""}`} aria-hidden="true"/>);
                    })}
                </div>
            </div>
        )
    }

    /**
     * @param {string} newSortProperty
     */
    newSort = (newSortProperty) => {
        const { sortDirection, sortProperty } = this.state;

        let newSortDirection = sortDirection;

        if (newSortProperty === sortProperty) {
            newSortDirection = sortDirection === "asc" ? "desc" : "asc";
        }

        this.setState({
            sortProperty: newSortProperty,
            sortDirection: newSortDirection,
        });
    }

    headers = [
        ['', null],
        ['Sagsnr.', 'case_no'],
        ['Oprettet', 'created_at'],
        ['Opdateret', 'updated_at'],
        ['Status', 'published'],
        ['Navn', 'name'],
        ['Postnr', 'zip_code'],
        ['Service', 'purchased_inspection'],
        ['Opført', 'erected'],
        ['Areal', 'residential_area'],
        ['Erhvervsareal', 'commercial_area'],
        ['Ansvarlig', 'internal_contact_name'],
        ['Vis', null],
    ]

    render () {
        return (
            <div>
                <div className="pull-right">
                    <Link to="/admin/plans/create" className="btn btn-primary">Opret driftsplan</Link>
                </div>
                <h3>
                    Driftsplaner
                </h3>
                <div className="row">
                    <div className="col-md-2 col-sm-3">
                        <select defaultValue="own" onChange={ (e) => this.setState({ planFilter: e.target.value }) } className="form-control">
                            <option value="own">Mine driftsplaner</option>
                            <option value="all">Alle driftsplaner</option>
                        </select>
                    </div>
                </div>
                <hr />
                <table className="table table-striped table-condensed table-hover maintenance-plan-list">
                    <thead>
                    <tr>
                        {
                            this.headers.map((header, idx) => {
                                const headerName = header[0];
                                const headerProperty = header[1];

                                const hiddenXs = PlanTable.isHiddenXs(headerName) ? "hidden-xs" : "";
                                const hiddenSm = PlanTable.isHiddenSm(headerName) ? "hidden-sm" : "";

                                return (
                                    <th className={`${hiddenSm} ${hiddenXs}`} key={idx}>
                                        {this.getTableHeader(headerName, headerProperty)}
                                    </th>
                                )
                            })
                        }
                    </tr>
                    </thead>
                    <tbody>
                    { this.getRows() }
                    </tbody>
                </table>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const list = state.data.plan.list.content
    const userId = state.auth.userId
    const ownPlans = filter(list, plan => plan.internal_contact_id === userId)
    return {
        list,
        ownPlans,
        is_valid: state.data.plan.list.is_valid,
        role: state.auth.role,
        userId,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
        fetchPlans: actions.fetchPlans,
        editLinkHandler: (uri) => (ev) => dispatch(push(uri)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PlanTable)
