import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Link } from 'react-router'
import { connect } from 'react-redux'
import moment from 'moment'
import 'moment/locale/da'
import kind from 'kind-of'
import DateTime from 'client/components/DateTime'
import actions from 'client/actions'
import CustomerInformation from 'client/routes/Customer/CustomerInformation';
import { map, uniqBy, merge, forEach, reduce } from 'lodash'
import numeral from 'common/lib/numeral'
import classNames from 'classnames'
import DocumentTitle from 'react-document-title'
import ReactTooltip from 'react-tooltip'
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import MailIcon from '@material-ui/icons/Mail';
import PhoneIcon from '@material-ui/icons/Phone';
import WarningIcon from '@material-ui/icons/Warning';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Tooltip from '@material-ui/core/Tooltip';
import {styles} from 'common/lib/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from 'client/components/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Contact from 'client/components/Contact';
import { PlanFileSchema } from 'common/schemas';
import { Condition } from 'client/components/Condition';

class Dashboard extends Component {

  static propTypes = {
    readonly: PropTypes.bool.isRequired,
    plan: PropTypes.object.isRequired,
    texts: PropTypes.object.isRequired,
    notes: PropTypes.array.isRequired,
    params: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    saveCustomerData: PropTypes.func.isRequired,
    route: PropTypes.object.isRequired,
  }

  constructor (props) {
    super(props)
    this.toggleView = this.toggleView.bind(this)
    this.handleBasisDialog = this.handleBasisDialog.bind(this)
    this.handleGuidanceDialog = this.handleGuidanceDialog.bind(this)
    this.state = {
      isEditing: false,
      isBasisDialogOpen: false,
      isGuidanceDialogOpen: false,
    }
  }

  componentDidMount() {
      const { dispatch, setBreadcrumb } = this.props
      dispatch(setBreadcrumb('Start'));
  }

  toggleView () {
    this.setState({
      isEditing: !this.state.isEditing,
    })
  }

  handleBasisDialog (e) {
    e.preventDefault()
    this.setState({
      isBasisDialogOpen: !this.state.isBasisDialogOpen
    })
  }

  handleGuidanceDialog (e) {
    e.preventDefault()
    this.setState({
      isGuidanceDialogOpen: !this.state.isGuidanceDialogOpen
    })
  }

  render () {
    const {
      plan, notes, texts,
      saveCustomerData, params: { customer_id },
      dispatch, route, readonly, classes
    } = this.props

    const {isBasisDialogOpen, isGuidanceDialogOpen} = this.state
    const { internal_contact = {} } = plan
    const notesList = map(notes, (note, idx) => {
      return (
        <li>
          <Link key={ idx } to={ `/c/${customer_id}/parts/${note.part_id}` }>
            { note.count } { note.count > 1 ? 'noter' : 'note' } på { `"${note.part_name}"` }
            { note.attachments.length > 0 ? <span> <i className="fa fa-paperclip"></i> { note.attachments.length }</span> : null }
          </Link>
        </li>
      )
    })

    let notification_count = ''
    if (kind(plan.email_notifications) === 'string' && plan.email_notifications !== '') {
      notification_count = plan.email_notifications.split(',').length
    }

    return (
      <div>
        <DocumentTitle title={ route.title } />
        {/* <section id="project-image" className="coverbackground wrapper" style={ { backgroundImage: `url(https://ik.imagekit.io/aw3ddey5g/${plan.s3_image_id}?tr=w-200)` } }>
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-md-offset-3 col-xs-8 col-xs-offset-2 text-center">
                <div className="project-header">
                  <h3>{ plan.name }</h3>
                  <Link to={ `/c/${customer_id}/overview` } className="btn btn-lg btn-primary page-scroll">Start her &raquo;</Link>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <Grid container spacing={3}>
            <Grid item sm={12} md={6}>
            <Paper className={classes.paper}>
                <Typography className={classes.header} variant="h5">{ plan.name }</Typography>
                <table>
                    <tr>
                        <td className={classes.td}>
                            <Typography paragraph className={classes.greyText}>Adresse:</Typography>
                        </td>
                        <td className={classes.td}>
                            <Typography paragraph>
                                {plan.address1} {plan.address2 ? plan.address2 : null} {plan.address3 ? plan.address3 : null} {plan.zip_code ?  ", " + plan.zip_code + " " + plan.city : null}
                            </Typography>
                        </td>
                    </tr>
                    <tr>
                        <td className={classes.td}>
                            <Typography paragraph className={classes.greyText}>Opført år:</Typography>
                        </td>
                        <td className={classes.td}>
                            <Typography paragraph>{plan.erected}</Typography>
                        </td>
                    </tr>
                    <tr>
                        <td className={classes.td}>
                            <Typography paragraph className={classes.greyText}>Samlet bruttoareal (privat):</Typography>
                        </td>
                        <td className={classes.td}>
                            <Typography paragraph>{numeral(plan.residential_area).format('0,0') } m<sup>2</sup></Typography>
                        </td>
                    </tr>
                    <tr>
                        <td className={classes.td}>
                            <Typography paragraph className={classes.greyText}>Samlet bruttoareal (erhverv):</Typography>
                        </td>
                        <td className={classes.td}>
                            <Typography paragraph>{numeral(plan.commercial_area).format('0,0') } m<sup>2</sup></Typography>
                        </td>
                    </tr>
                    <tr>
                        <td className={classes.td}>
                            <Typography paragraph className={classes.greyText}>Antal enheder (privat):</Typography>
                        </td>
                        <td className={classes.td}>
                            <Typography paragraph>{plan.residential_units}</Typography>
                        </td>
                    </tr>
                    <tr>
                        <td className={classes.td}>
                            <Typography paragraph className={classes.greyText}>Antal enheder (erhverv):</Typography>
                        </td>
                        <td className={classes.td}>
                            <Typography paragraph>{plan.commercial_units}</Typography>
                        </td>
                    </tr>
                    <tr>
                        <td className={classes.td}>
                            <Typography paragraph className={classes.greyText}>Ejendomsnummer:</Typography>
                        </td>
                        <td className={classes.td}>
                            <Typography paragraph>{plan.bbr_no}</Typography>
                        </td>
                    </tr>
                    <tr>
                        <td className={classes.td}>
                            <Typography paragraph className={classes.greyText}>Matrikelnummer:</Typography>
                        </td>
                        <td className={classes.td}>
                            <Typography paragraph>{plan.cadastral_no}</Typography>
                        </td>
                    </tr>
                    <tr>
                        <td className={classes.td}>
                            <Typography paragraph className={classes.greyText}>Oprettet:</Typography>
                        </td>
                        <td className={classes.td}>
                            <DateTime source={ plan.created_at ? plan.created_at : plan.created_at } format="DD-MM-YYYY" />
                        </td>
                    </tr>
                    <tr>
                        <td className={classes.td}>
                            <Typography paragraph className={classes.greyText}>Sidst opdateret:</Typography>
                        </td>
                        <td className={classes.td}>
                            <DateTime source={ plan.overridden_updated_at ? plan.overridden_updated_at : plan.updated_at } format="DD-MM-YYYY" />
                        </td>
                    </tr>
                    {plan.condition && <tr>
                        <td className={classes.td}>
                            <Typography paragraph className={classes.greyText}>Ejendommens stand:</Typography>
                        </td>
                        <td className={classes.td}>
                            <Typography paragraph><Condition condition={ plan.condition } hasText={ false } /></Typography>
                        </td>
                    </tr>}
                </table>
            </Paper>
            </Grid>
            <Grid item sm={12} md={6}>
                <Typography className={classes.header} variant="h5">Sådan arbejder du med din vedligeholdelsesplan</Typography>
                <Typography className={classes.header} variant="h5">Om ejendommen</Typography>
                <Typography paragraph>
                    { plan.about }
                </Typography>
                <div className={classes.logoLinks}>
                    <svg className="logo-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 18" width="40" height="40">
                        <path d="M15.667 16.33h-.98v-1.664h.98zm0-2.247h-.98v-1.097h.98zm0-3.347h-.98V9.071h.98zm0-2.246h-.98V7.394h.98zm0-3.345h-.98V3.48h.98zm0-2.246h-.98V1.8h.98zM14.183 16.33h-.98v-1.665h.98zm0-2.248h-.98v-1.097h.98zm0-3.347h-.98V9.071h.98zm0-2.246h-.98V7.394h.98zm0-3.345h-.98V3.48h.98zm0-2.246h-.98V1.8h.98zm-2.47 13.432h-.98v-1.665h.98zm0-2.248h-.98v-1.097h.98zm0-3.347h-.98V9.071h.98zm0-2.246h-.98V7.394h.98zm0-3.345h-.98V3.48h.98zm0-2.246h-.98V1.8h.98zm-1.48 13.432H9.25v-1.665h.983zm0-2.248H9.25v-1.097h.983zm0-3.347H9.25V9.071h.983zm0-2.246H9.25V7.394h.983zm0-3.345H9.25V3.48h.983zm0-2.246H9.25V1.8h.983zm-2.475 7.837h-.98V9.071h.98zm0-2.246h-.98V7.394h.98zm0-3.345h-.98V3.48h.98zm0-2.246h-.98V1.8h.98zm-1.482 7.837h-.98V9.071h.98zm0-2.246h-.98V7.394h.98zm0-3.345h-.98V3.48h.98zm0-2.246h-.98V1.8h.98zM3.798 16.33h-.98v-1.665h.98zm0-2.248h-.98v-1.097h.98zm0-3.347h-.98V9.071h.98zm0-2.246h-.98V7.394h.98zm0-3.345h-.98V3.48h.98zm0-2.246h-.98V1.8h.98zM2.316 16.33h-.98v-1.665h.98zm0-2.248h-.98v-1.097h.98zm0-3.347h-.98V9.071h.98zm0-2.246h-.98V7.394h.98zm0-3.345h-.98V3.48h.98zm0-2.246h-.98V1.8h.98zM17 16.559V1.44S17 0 15.64 0H1.36S0 0 0 1.441V16.56s0 1.439 1.36 1.439h3.932v-5.012h2.465V18h7.883c0-.002 1.36-.002 1.36-1.441"></path>
                    </svg>
                    <div>
                    <a href="#" onClick={this.handleGuidanceDialog}>
                        Læs vejledning her
                    </a>
                    <a href="#" onClick={this.handleBasisDialog}>
                        Læs grundlag for vedligeholdelsesplan her
                    </a>
                    </div>
                </div>
            </Grid>
            <Grid item xs={12} md={6}>
                <Contact
                    title="Ejendommens kontaktperson"
                    name={plan.contact_name}
                    email={plan.contact_email}
                    phone={plan.contact_phone}
                    src={plan.s3_image_id ? `https://ik.imagekit.io/aw3ddey5g/${plan.s3_image_id}?tr=w-200` : null}
                    variant="h5"
                    paperClasses={classes.paper}
                />
            </Grid>
            <Grid item sm={12} md={6}>
                <Contact
                    title="Rådgiver fra Plan1"
                    name={internal_contact.name}
                    email={internal_contact.email}
                    phone={internal_contact.phone}
                    src={internal_contact.s3_image_id ? `https://ik.imagekit.io/aw3ddey5g/${internal_contact.s3_image_id}?tr=w-200` : null}
                    variant="h5"
                    paperClasses={classes.paper}
                />
            </Grid>
            <Grid item sm={12} md={6}>
                <Paper className={classes.paper}>
                    <Typography className={classes.header} variant="h5">Serviceaftale  { plan.purchased_inspection ? plan.purchased_inspection === 1 ? <CheckIcon className={classes.checkIcon}/> : <WarningIcon className={classes.warningIcon}/> : <ClearIcon className={classes.clearIcon}/>}</Typography>
                    <Typography className={classes.serviceAgreement}>
                        { plan.purchased_inspection === 1 ? <Typography>
                        Opdateres næste gang: <b className={classes.serviceAgreementDate}>{plan.inspection_date ? moment().month(plan.inspection_date-1).format('MMMM') : null} <DateTime addYear={true} source={ plan.overridden_updated_at ? plan.overridden_updated_at : plan.updated_at } format="YYYY" /></b>
                    </Typography> : <p>{ texts.inspection
                        ? texts.inspection.split('\n').map((line, idx) => {
                        return (
                            <span key={ idx }>
                            { line }
                            <br />
                            </span>
                        )
                        })
                        : null
                    }</p>}
                    </Typography>
                </Paper>
            </Grid>
        </Grid>
        <Dialog onClose={this.handleBasisDialog} open={isBasisDialogOpen}>
            <DialogTitle onClose={this.handleBasisDialog}>
                Grundlag for drifts- og vedligeholdelsesplanen
            </DialogTitle>
            <DialogContent dividers>
                <Typography gutterBottom>
                  { texts.foundation
                    ? texts.foundation.split('\n').map((line, idx) => {
                      return (
                        <span key={ idx }>
                          { line }
                          <br />
                        </span>
                      )
                    })
                    : null
                  }
                </Typography>
            </DialogContent>
        </Dialog>
        <Dialog onClose={this.handleGuidanceDialog} open={isGuidanceDialogOpen}>
            <DialogTitle onClose={this.handleGuidanceDialog}>
                Vejledning
            </DialogTitle>
            <DialogContent dividers>
                <Typography gutterBottom>
                    { texts.terms
                        ? texts.terms.split('\n').map((line, idx) => {
                        return (
                            <span key={ idx }>
                            { line }
                            <br />
                            </span>
                        )
                        })
                        : null
                    }
                </Typography>
            </DialogContent>
        </Dialog>
    </div>
    )
  }
}

const mapStateToProps = state => {
  const notes = state.data.customer.notes
  const uniqueNotes = uniqBy(notes, 'part_id')
  const keyedNotes = reduce(notes, (acc, note) => {
    const part_id = note.part_id
    let sum = acc[part_id] || {}
    sum.part_name = note.part_name
    sum.part_id = note.part_id
    sum.attachments = sum.attachments || []
    sum.attachments = note.attachments && note.attachments.length > 0
      ? sum.attachments.concat(note.attachments)
      : sum.attachments
    sum.count = sum.count + 1 || 1
    acc[part_id] = sum
    return acc
  }, {})
  const limitedNotes = uniqueNotes.splice(0, 16)
  return {
    readonly: state.data.customer.readonly,
    plan: state.data.customer.plan,
    notes: keyedNotes,
    texts: state.data.customer.systemTexts,
  }
}

const mapDispatchToProps = dispatch => ({
  dispatch,
  setBreadcrumb: actions.setBreadcrumb,
  saveCustomerData: actions.saveCustomerData,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Dashboard))
