import { combineReducers } from 'redux'
import { ActionTypes } from 'client/constants'
import { modelReducer, formReducer, modeled, actionTypes as formActions } from 'react-redux-form'
import startsWith from 'lodash/startsWith'
import moment from 'moment'
import { remove } from 'lodash'

const listReducer = (state = {
  content: [],
  is_valid: false,
}, action) => {
  switch (action.type) {
    case ActionTypes.RECEIVE_REQUEST:
      if (action.subtype === 'portfolios') {
        return {
          content: action.payload,
          is_valid: true,
        }
      }
      return state
    case ActionTypes.SEND_REQUEST:
      if (action.subtype === 'part') {
        if (action.request === 'create' || action.request === 'update' || action.request === 'delete') {
          return {
            content: [],
            is_valid: false,
          }
        }
      }
      return state
    case ActionTypes.INVALIDATE_DATA:
      return {
        content: [],
        is_valid: false,
      }
    case ActionTypes.PUBLISH_PART:
      return {
        content: [],
        is_valid: false,
      }
    default:
      return state
  }
}

const initialCurrentState = {
  portfolio: {
    id: null,
    company_name: null,
    contact_name: null,
    contact_email: null,
    contact_phone: null,
    address: null,
    password: null,
    published: false,
    plans: []
  },
}
const currentReducer = (state = initialCurrentState, action) => {
  switch (action.type) {
    case ActionTypes.RECEIVE_REQUEST:
      if (action.subtype === 'portfolio') {
        if (action.request === 'create' || action.request === 'fetch') {
          return {
            ...state.portfolio,
            ...action.payload,
          }
        } else if (action.request === 'publish') {
          return {
            ...state,
            ...action.payload,
          }
        }
      } else if (action.subtype === 'portfolio-plan') {
        if (action.request === 'create') {
          const plans = state.plans.concat([action.payload])
          return {
            ...state,
            plans,
          }
        } else if (action.request === 'delete') {
          const plans = state.plans
          remove(plans, {
            id: action.payload.id,
          })
          return {
            ...state
          }
        }
      } else if (action.subtype === 'part-image') {
        if (action.request === 'create') {
          const images = state.images.concat([action.payload])
          return {
            ...state,
            images,
          }
        } else if (action.request === 'delete') {
          const images = state.images
          remove(images, {
            id: action.payload,
          })
          return {
            ...state,
          }
        }
      }
      return state
    case ActionTypes.PUBLISH_PART:
      return {
        ...state,
        published: action.payload,
      }
    case ActionTypes.INVALIDATE_DATA:
      return initialCurrentState
    default:
      return state
  }
}
const modelPart = modeled(currentReducer, 'data.portfolio.current')

const formDataReducer = (state = {
  start_years: [],
  internal_contacts: [],
  customers: []
}, action) => {
  switch (action.type) {
    case ActionTypes.SET_CUSTOMERS:
      return {
        ...state,
        customers: action.customers,
      }
    case ActionTypes.RECEIVE_REQUEST:
      if (action.subtype === 'internal_contacts') {
        return {
          ...state,
          internal_contacts: action.payload,
        }
      } else if (action.subtype === 'start_years') {
        return {
          ...state,
          start_years: action.payload,
        }
      }
      return state
    case ActionTypes.INVALIDATE_DATA:
      return {
        internal_contacts: [],
        start_years: [],
      }
    default:
      return state
  }
}

export default combineReducers({
  list: listReducer,
  current: modelPart,
  form_data: formDataReducer,
  form: formReducer('data.portfolio.current'),
})
