import { createStore, applyMiddleware, compose } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { browserHistory } from 'react-router'
import { routerMiddleware } from 'react-router-redux'

import rootReducer from 'client/reducers'

export default function configureStore (initialState) {

  const historyMiddleware = routerMiddleware(browserHistory)

  const store = createStore(
    rootReducer,
    initialState,
    compose(
      applyMiddleware(
        historyMiddleware,
        thunkMiddleware
      ),
      f => f
    )
  )

  return store
}
