import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import NavBar from 'client/components/NavBar'
import Spinner from 'client/components/Spinner'
import { ListLink } from 'client/components/NavLink'
import Notifications from 'client/components/Notifications'
import actions from 'client/actions'
import { push } from 'react-router-redux'
import { Link } from 'react-router'
import Footer from 'client/components/Footer'
import CircularProgress from '@material-ui/core/CircularProgress';

class HomeWrapper extends Component {

  static propTypes = {
    children: PropTypes.node.isRequired,
    dispatch: PropTypes.func.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    userId: PropTypes.number,
  }

  componentWillMount () {
    const { dispatch, fetchSystemTexts } = this.props
    dispatch(fetchSystemTexts('/marketing/system-texts'))
  }

  componentDidMount () {
    const { dispatch, isAuthenticated, isAdmin, userId } = this.props
    const token = localStorage.getItem('token')
    if (token && isAuthenticated) {
      const url = isAdmin
        ? '/admin'
        : `/c/${userId}`
      dispatch(push(url))
    }
  }

  render () {
    const { children, isAuthenticating} = this.props

    return (
      <div>
        <div className="marketing-wrapper">
          <div className="container">
            <h1><Link to="/" className="page-header">Driftsplaner.dk</Link> <Link to="/login" className="btn btn-primary pull-right">Log ind</Link></h1>
          </div>
          { children }
          {isAuthenticating && <CircularProgress style={{margin: '140px auto', display: 'block'}} />}
        </div>
        <Footer isHome />
      </div>
    )

  }
}

const mapStateToProps = state => {
  return {
    isAdmin: state.auth.isAdmin,
    isAuthenticated: state.auth.isAuthenticated,
    userId: state.auth.userId,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    fetchSystemTexts: actions.systemTextFetch,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomeWrapper)
