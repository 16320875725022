import auth0 from 'auth0-js';
import { BASE_URL, AUTH0_DOMAIN, AUTH0_CLIENTID } from 'client/constants'

var globalTimeout = null;

export default class Auth {

  userProfile;

  constructor() {
    this.auth0 = new auth0.WebAuth({
      domain: AUTH0_DOMAIN,
      clientID: AUTH0_CLIENTID,
      redirectUri:  BASE_URL + '/auth0/callback',
      audience: 'usercloud',
      responseType: 'token id_token',
      scope: 'openid email profile'
    });
    this.login = this.login.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.getProfile = this.getProfile.bind(this);
    this.scheduleRenewal();
  }

  handleAuthentication() {
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken) {
        this.setSession(authResult);
        //Do a hard reload! This should not be needed, but is because the handleAtuehntication is done to early
        // Should perhaps be fixed.
        window.location.reload(true);
      } else if (err) {
        console.log(err);
      }
    });
  }

  setSession(authResult) {
    // Set the time that the access token will expire at
    let expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
    localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('id_token', authResult.idToken);
    localStorage.setItem('expires_at', expiresAt);
    localStorage.setItem('identity', JSON.stringify(this.parseJwt(authResult.idToken)));

    // schedule a token renewal
    this.scheduleRenewal();
  }

  login() {
    this.auth0.authorize();
  }

  isAuthenticated() {
    // Check whether the current time is past the
    // access token's expiry time
    let expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    return new Date().getTime() < expiresAt;
  }

  getAccessToken() {
    const accessToken = localStorage.getItem('access_token');
    if (!accessToken) {
      throw new Error('No access token found');
    }
    return accessToken;

  }

  parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(this.b64DecodeUnicode(base64));
  }

  /*
   * Base64 decoe which understands unicode. Thanks to StackOverrflow
   *
   * @see https://stackoverflow.com/questions/30106476/using-javascripts-atob-to-decode-base64-doesnt-properly-decode-utf-8-strings
   */
  b64DecodeUnicode (str) {
    return decodeURIComponent(Array.prototype.map.call(atob(str), function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    }).join(''))
  }

  /*
   * Return profile (as a promise
   */
  getProfile() {
    return new Promise((resolve, reject) => {
      let accessToken = this.getAccessToken();
      this.auth0.client.userInfo(accessToken, (err, profile) => {
        if (profile) {
          this.userProfile = profile;
          resolve(profile);
        }
        reject();
      });
    });
  }

  /**
   * Renew token from Auth0
   * @returns {Promise<any>}
   */
  renewToken() {
    return new Promise((resolve, reject) => {
      this.auth0.checkSession({}, (err, result) => {
        if (err) {
          reject(err);
        } else {
          this.setSession(result);
          resolve();
        }
      });
    });
  }

  /**
   * Schedule a renewal of the the access token when it expires.
   */
  scheduleRenewal() {

    const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    const delay = expiresAt - Date.now();

    if (delay > 0) {
      window.clearTimeout(globalTimeout);
      globalTimeout = setTimeout(() => {
        this.renewToken();
      }, delay);
    }
  }
}
