import React from 'react'
import { Route, IndexRoute, IndexRedirect } from 'react-router'

import AdminWrapper from 'client/routes/Admin'
import PlanWrapper from 'client/routes/Admin/Plans'
import PlanTable from 'client/routes/Admin/Plans/PlanTable'
import PlanHeader from 'client/routes/Admin/Plans/PlanHeader'
import Setup from 'client/routes/Admin/Plans/Setup'
import Files from 'client/routes/Admin/Plans/Files'
import Energy from 'client/routes/Admin/Plans/Energy'
import Property from 'client/routes/Admin/Plans/Property'
import PartWrapper from 'client/routes/Admin/Plans/Parts/PartWrapper'
import PartTable from 'client/routes/Admin/Plans/Parts/PartTable'
import NewPart from 'client/routes/Admin/Plans/Parts/NewPart'
import EditPart from 'client/routes/Admin/Plans/Parts/EditPart'
import DistributionTable from 'client/routes/Admin/Plans/Estimates/DistributionTable'
import PartTemplatesWrapper from 'client/routes/Admin/PartTemplates'
import PartTemplatesTable from 'client/routes/Admin/PartTemplates/PartTemplatesTable'
import PartTemplate from 'client/routes/Admin/PartTemplates/PartTemplate'
import SystemTextsWrapper from 'client/routes/Admin/SystemTexts'
import SystemTextsEditor from 'client/routes/Admin/SystemTexts/Editor'
import UserWrapper from 'client/routes/Admin/Users'
import UserTable from 'client/routes/Admin/Users/UserTable'
import UserEdit from 'client/routes/Admin/Users/UserEdit'
import UserNew from 'client/routes/Admin/Users/UserNew'

import PortfolioWrapper from 'client/routes/Admin/Portfolios'
import PortfolioTable from 'client/routes/Admin/Portfolios/PortfolioTable'
import Portfolio from 'client/routes/Admin/Portfolios/Portfolio'

import requireAuth from 'client/components/AuthenticatedComponent'
import FaqWrapper from 'client/routes/Admin/Faq/wrapper'
import FaqIndex from 'client/routes/Admin/Faq/index'

const AdminRoutes = (
  <Route path="admin" component={ requireAuth(AdminWrapper) }>
    <IndexRedirect to="/admin/plans" />
    <Route path="plans" component={ PlanWrapper } title="Driftsplaner - Plan1">
      <IndexRoute component={ PlanTable } />
      <Route path="create" component={ PlanHeader }>
        <IndexRoute component={ Setup } />
        <Route path="property" component={ Property } />
        {/* <Route path="energy" component={ Energy } /> */}
        <Route path="parts" component={ PartWrapper }>
          <IndexRoute component={ PartTable } />
          <Route path="new" component={ NewPart } />
          <Route path="edit/:part_id" component={ EditPart } />
        </Route>
        <Route path="distribution" component={ DistributionTable } />
      </Route>
      <Route path="edit/:id" component={ PlanHeader }>
        <IndexRoute component={ Setup } />
        <Route path="property" component={ Property } />
        <Route path="parts" component={ PartWrapper }>
          <IndexRoute component={ PartTable } />
          <Route path="new" component={ NewPart } />
          <Route path="edit/:part_id" component={ EditPart } />
        </Route>
        <Route path="distribution" component={ DistributionTable } />
        <Route path="files" component={ Files } />
        <Route path="energy" component={ Energy } />
      </Route>
    </Route>
    <Route path="portfolios" component={ PortfolioWrapper } title="Porteføljer - Plan1" >
      <IndexRoute component={ PortfolioTable } />
      <Route path="new" component={ Portfolio } />
      <Route path="edit/:id" component={ Portfolio } />
    </Route>
    <Route path="part-templates" component={ PartTemplatesWrapper } title="Standarder - Plan1">
      <IndexRoute component={ PartTemplatesTable } />
      <Route path="new" component={ PartTemplate } />
      <Route path="edit/:id" component={ PartTemplate } />
    </Route>
    <Route path="system-texts" component={ SystemTextsWrapper } title="System - Plan1">
      <IndexRoute component={ SystemTextsEditor } />
    </Route>
    <Route path="faq" component={ FaqWrapper } title="FAQ - Plan1">
      <IndexRoute component={ FaqIndex } />
    </Route>
    <Route path="users" component={ UserWrapper } title="Brugerstyring - Plan1">
      <IndexRoute component={ UserTable } />
      <Route path="new" component={ UserNew } />
      <Route path="edit/:id" component={ UserEdit } />
    </Route>
  </Route>
)

export default AdminRoutes
