export const PartTemplateSchema = {
  base: {
    properties: {
      id: {
        type: 'integer',
        allowNull: false,
      },
      bips_id: {
        type: 'integer',
        allowNull: false,
      },
      title: {
        type: 'string',
      },
      internal_note: {
        type: 'string',
      },
      specification: {
        type: 'string',
      },
      condition: {
        type: 'integer',
        allowEmpty: '',
        allowNull: true,
      },
      establishment: {
        type: 'string',
      },
      establishment_cost: {
        type: 'integer',
        minValue: 0,
        allowEmpty: '',
        allowNull: true,
      },
      establishment_cost_note: {
        type: 'string',
      },
      maintenance: {
        type: 'string',
      },
      maintenance_interval: {
        type: 'integer',
        allowEmpty: '',
        allowNull: true,
      },
      maintenance_cost: {
        type: 'integer',
        minValue: 0,
        allowEmpty: '',
        allowNull: true,
      },
      maintenance_cost_note: {
        type: 'string',
      },
      scaffolding: {
        type: 'boolean',
      },
      construction_site: {
        type: 'boolean',
      },
      adviser: {
        type: 'boolean',
      },
      building_management: {
        type: 'boolean',
      },
      inspection: {
        type: 'string',
      },
      inspection_interval: {
        type: 'integer',
        allowEmpty: '',
        allowNull: true,
      },
    },
  },
  extensions: {
    update: {
      properties: {
        id: {
          required: true,
        },
      },
    },
  },
}
