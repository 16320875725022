import fetch from 'isomorphic-fetch'
import { push } from 'react-router-redux'
import { actions as FormActions } from 'react-redux-form'
import { ActionTypes, API_URI } from 'client/constants'
import { notifyDanger } from 'client/components/Notifications/actions'
import validate, { ValidationError } from 'common/lib/validator'
import { EstimateSchema } from 'common/schemas'
import { keyBy, reduce, has, inRange, mergeWith, clone } from 'lodash'
import api, { ApiError } from 'client/lib/apiHandler'

const calculateOrdinaryTotalsByYear = (estimates) => {
    return (
        reduce(estimates, (acc, estimate) => {
            const est_year = estimate.year || 'establishment'
            const value = parseFloat(estimate.estimate) || 0
            const sum = acc[est_year] || null
            acc[est_year] = !estimate.scaffolding && !estimate.construction_site && !estimate.adviser && !estimate.building_management && !estimate.free_form_total
                ? sum + value
                : sum
            return acc
        }, {})
    )
}

const calculateOrdinaryTotal = (estimates) => {
    return (
        reduce(estimates, (acc, estimate) => {
            return estimate.year && !estimate.scaffolding && !estimate.construction_site && !estimate.adviser && !estimate.building_management && !estimate.free_form_total
                ? acc + (parseFloat(estimate.estimate) || 0)
                : acc
        }, null)
    )
}

const calculateExtraordinaryTotalsByYear = (parts) => {
    return (
        reduce(parts, (acc, part) => {
            const est_year = part.establishment_year
            const value = part.establishment_cost / 1000
            const sum = acc[est_year] || null
            acc[est_year] = sum + value
            return acc
        }, {})
    )
}

const calculateExtraordinaryTotal = (parts) => {
    return (
        reduce(parts, (acc, part) => {
            return part.establishment_cost
                ? acc + (part.establishment_cost / 1000)
                : acc
        }, null)
    )
}

const calculateTotalsByYearForSCAB = (estimates) => {
    return (
        reduce(estimates, (acc, estimate) => {
            const est_year = estimate.year || 'establishment'
            const value = parseFloat(estimate.estimate) || 0
            const sum = acc[est_year] || null
            acc[est_year] = estimate.scaffolding || estimate.construction_site || estimate.adviser || estimate.building_management
                ? sum + value
                : sum
            return acc
        }, {})
    )
}

const calculateTotalForSCAB = (estimates) => {
    return (
        reduce(estimates, (acc, estimate) => {
            const value = parseFloat(estimate.estimate) || 0
            return estimate.scaffolding || estimate.construction_site || estimate.adviser || estimate.building_management
                ? acc + value
                : acc
        }, null)
    )
}

const calculateTotalsById = (estimates) => {
    return (
        reduce(estimates, (acc, estimate) => {
            const part_id = estimate.part_id !== null && has(estimate, 'part_id')
                ? estimate.part_id
                : estimate.scaffolding
                    ? 'scaffolding'
                    : estimate.construction_site
                        ? 'construction_site'
                        : estimate.adviser
                            ? 'adviser'
                            : estimate.free_form
                                ? 'free_form'
                                : estimate.free_form_total
                                    ? 'free_form_total'
                                    : 'building_management'
            const value = parseFloat(estimate.estimate) || 0
            const sum = acc[part_id] || null
            acc[part_id] = estimate.year
                ? sum + value
                : sum
            return acc
        }, {})
    )
}

const mergeTotals = (t1, t2) => {
    const t1_clone = clone(t1)
    return mergeWith(t1_clone, t2, (o, s) => (parseFloat(o) || 0) + s)
}

const calculateTotalsByPlan = (estimates) => {
    return (
        reduce(estimates, (acc, estimate) => {
            return estimate.year
                ? acc + (parseFloat(estimate.estimate) || 0)
                : acc
        }, null)
    )
}

const sendRequest = (subtype, request) => {
    return {
        type: ActionTypes.SEND_REQUEST,
        subtype,
        request,
    }
}

const receiveRequest = (subtype, request, payload) => {
    return {
        type: ActionTypes.RECEIVE_REQUEST,
        subtype,
        request,
        payload,
    }
}

export const fetchEstimates = (plan_id) => {

    return (dispatch, getState) => {
        dispatch(sendRequest('parts', 'fetch'))
        dispatch(sendRequest('estimates', 'fetch'))

        Promise.all([
            api.get(`/estimates/by-plan/${plan_id}`),
            api.get(`/parts/by-plan/${plan_id}`),
        ])
            .then(([estimates, parts]) => {
                const start_year = getState().data.years.start_year
                const ranged_estimates = estimates.body.filter(estimate => {
                    return inRange(estimate.year, start_year, start_year + 15) || estimate.year === null
                })
                const estimates_by_id = keyBy(ranged_estimates, 'id')
                const estimates_by_year = reduce(ranged_estimates, (acc, estimate) => {
                    const est_year = estimate.year || 'establishment'
                    const part_id = estimate.part_id !== null
                        ? estimate.part_id
                        : estimate.scaffolding
                            ? 'scaffolding'
                            : estimate.construction_site
                                ? 'construction_site'
                                : estimate.adviser
                                    ? 'adviser'
                                    : estimate.free_form
                                        ? 'free_form'
                                        : estimate.free_form_total
                                            ? 'free_form_total'
                                            : 'building_management'

                    acc[est_year] = acc[est_year] || {}
                    acc[est_year][part_id] = acc[est_year][part_id] || estimate.id
                    return acc
                }, {})

                const ordinary_total_by_id = calculateTotalsById(ranged_estimates)
                const ordinary_total_by_year = calculateOrdinaryTotalsByYear(ranged_estimates)
                const ordinary_total = calculateOrdinaryTotal(ranged_estimates)
                const plan_total = calculateTotalsByPlan(ranged_estimates)
                let extraordinary_by_year = calculateExtraordinaryTotalsByYear(parts.body)
                let extraordinary_total = calculateExtraordinaryTotal(parts.body)
                const scab_by_year = calculateTotalsByYearForSCAB(ranged_estimates)
                const scab_total = calculateTotalForSCAB(ranged_estimates)
                extraordinary_by_year = mergeTotals(extraordinary_by_year, scab_by_year)
                extraordinary_total = extraordinary_total + scab_total

                const totals_by_year = mergeTotals(ordinary_total_by_year, extraordinary_by_year)
                dispatch(receiveRequest('parts', 'fetch', parts.body))
                dispatch(receiveRequest('estimates_by_id', 'fetch', estimates_by_id))
                dispatch(receiveRequest('estimates_by_year', 'fetch', estimates_by_year))
                dispatch(receiveRequest('estimates_totals', 'fetch', { by_plan: plan_total, by_id: ordinary_total_by_id, by_year: ordinary_total_by_year, ordinary_total, extraordinary_by_year, extraordinary_total, totals_by_year }))
            })
            .catch(error => {
                dispatch({ type: ActionTypes.RECEIVE_REQUEST, subtype: 'error' })
            })
    }

}

export const saveEstimate = (operation, estimate) => {

    const uri = operation === 'create'
        ? '/estimates'
        : `/estimates/${estimate.id}`

    const method = operation === 'create'
        ? 'post'
        : 'put'

    return (dispatch, getState) => {
        validate(EstimateSchema, estimate)
            .then(result => {
                dispatch(sendRequest('estimate', operation))

                return api[method](uri, result.data)
            })
            .then(({ body }) => {
                const estimates = getState().data.estimates.by_id
                estimates[body.id] = body
                const ordinary_total_by_id = calculateTotalsById(estimates)
                const ordinary_total_by_year = calculateOrdinaryTotalsByYear(estimates)
                const ordinary_total = calculateOrdinaryTotal(estimates)
                const plan_total = calculateTotalsByPlan(estimates)

                // const extraordinary_by_year = getState().data.estimates.totals.extraordinary_by_year
                const parts = getState().data.part.list.content
                let extraordinary_by_year = calculateExtraordinaryTotalsByYear(parts)
                let extraordinary_total = calculateExtraordinaryTotal(parts)
                const scab_by_year = calculateTotalsByYearForSCAB(estimates)
                const scab_total = calculateTotalForSCAB(estimates)
                extraordinary_by_year = mergeTotals(extraordinary_by_year, scab_by_year)
                extraordinary_total = extraordinary_total + scab_total

                const totals_by_year = mergeTotals(ordinary_total_by_year, extraordinary_by_year)
                dispatch(receiveRequest('estimates_by_id', operation, body))
                dispatch(receiveRequest('estimates_by_year', operation, body))
                dispatch(receiveRequest('estimates_totals', operation, { by_plan: plan_total, by_id: ordinary_total_by_id, by_year: ordinary_total_by_year, ordinary_total, extraordinary_by_year, extraordinary_total, totals_by_year }))
            })
            .catch(error => {
                if (error instanceof ValidationError) {
                    if (has(error.errors, 'estimate')) {
                        dispatch(notifyDanger('Estimater skal være tal', 'Error'))
                    }
                } else if (error instanceof ApiError) {
                    dispatch(notifyDanger('Der skete en fejl', 'Error'))
                }
            })
    }
}

export const autoFillEstimates = (plan_id, part_id) => {

    return (dispatch, getState) => {
        dispatch(sendRequest('estimates', 'batch'))

        api.post(`/parts/${part_id}/autofill-estimates`)
            .then(res => {
                dispatch(fetchEstimates(plan_id))
            })
            .catch(error => {
                if (error instanceof ApiError) {
                    dispatch(notifyDanger('Der skete en fejl', 'Error'))
                }
            })
    }

}

export default {
    fetchEstimates,
    saveEstimate,
    autoFillEstimates,
}
