import fetch from 'isomorphic-fetch'
import { push } from 'react-router-redux'
import { actions as FormActions } from 'react-redux-form'
import { ActionTypes, API_URI } from 'client/constants'
import { notifyDanger, notifySuccess, clearNotifications } from 'client/components/Notifications/actions'
import validate, { ValidationError } from 'common/lib/validator'
import { PartSchema } from 'common/schemas'
import api, { ApiError } from 'client/lib/apiHandler'
import { ProjectSchema } from 'common/schemas'

const sendRequest = (subtype, request) => {
  return {
    type: ActionTypes.SEND_REQUEST,
    subtype,
    request,
  }
}

const receiveRequest = (subtype, request, payload) => {
  return {
    type: ActionTypes.RECEIVE_REQUEST,
    subtype,
    request,
    payload,
  }
}

export const fetchProjects = (plan_id) => {

  return dispatch => {
    dispatch(sendRequest('projects', 'fetch'))

    api.get(`/parts/projects/by-part/${plan_id}`)
    .then(({ body }) => {
      dispatch(receiveRequest('projects', 'fetch', body))
    })
    .catch(error => {
      dispatch({ type: ActionTypes.RECEIVE_REQUEST, subtype: 'error' })
    })
  }
}

export const fetchProject = (project) => {

  return dispatch => {
      dispatch(receiveRequest('project', 'fetch', project))
  }
}

export const saveProject = (project) => {
  // operation is always 'update', but we don't need to differentiate

  return dispatch => {

    validate(ProjectSchema, project, false, { ignoreErrors: true })
    .then(result => {
      dispatch(sendRequest('project', 'update'))

      dispatch(FormActions.setValidity('data.project.current', true))
      dispatch(FormActions.setErrors('data.project.current', result.errors))

      return api.put(`/parts/${project.part_id}/project/${project.id}`, result.data)
    })
    .then(({ body }) => {
      dispatch(receiveRequest('project', 'update', body))
      dispatch(clearNotifications('danger'))
    })
    .catch(error => {
      if (error instanceof ApiError) {
        dispatch(notifyDanger('Der skete en fejl', 'Error'))
      }
      dispatch({ type: ActionTypes.RECEIVE_REQUEST, subtype: 'error' })
    })
  }
}

export const createProject = (part_id) => {
  return dispatch => {

    const body = { part_id: part_id, prioritized: false }
    api.post(`/parts/${part_id}/project`, body)
    .then(res => {
      dispatch(receiveRequest('project', 'create', res.body))
    })
    .catch(error => {
      if (error instanceof ApiError) {
        dispatch(notifyDanger('Der skete en fejl', 'Error'))
      }
      dispatch({ type: ActionTypes.RECEIVE_REQUEST, subtype: 'error' })
    })
  }
}

const deleteProject = (project) => {

  return dispatch => {

    const body = { id: project.id }
    api.del(`/parts/${project.part_id}/project/${project.id}`, body)
    .then(res => {
      dispatch(receiveRequest('project', 'delete', project))
    })
    .catch(error => {
      if (error instanceof ApiError) {
        dispatch(notifyDanger('Der skete en fejl', 'Error'))
      }
      dispatch({ type: ActionTypes.RECEIVE_REQUEST, subtype: 'error' })
    })
  }
}

export default {
  fetchProjects,
  fetchProject,
  saveProject,
  createProject,
  deleteProject,
}
