export const da_DK = {
  errors: {
    validation: {
      required_key_missing: '"${ label }" er ikke defineret',
      illegal_key_provided: '"${ label }" er ikke tilladt',
      illegal_null: '"${ label }" må ikke være "null"',
      not_a_boolean: '"${ label }" skal være en sand / falsk værdi',
      not_a_string: '"${ label }" skal være en text værdi',
      string_too_short: '${ label } skal være mindst ${ length } karakterer lang',
      string_too_long: '"${ label }" må ikke være længere end ${ length } karakterer',
      invalid_date: '"${ label }" indeholder ikke en korrekt dato',
      invalid_date_format: '"${ label }" indeholder ikke en korrekt dato. Formatet skal være ${ format }',
      invalid_type: '"${ label }" indeholder ikke et korrekt format',
      regex_invalid: '${ label } svarede ikke til det forventede format',
      value_too_small: '${ label } skal være større end ${minValue}',
      value_too_large: '${ label } skal være mindre end ${maxValue}',
    },
  },
}
