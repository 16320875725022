import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { ListLink, NavLink } from 'client/components/NavLink'
import actions from 'client/actions'
import classnames from 'classnames'
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeIcon from '@material-ui/icons/Home';
import Avatar from '@material-ui/core/Avatar';
import SettingsIcon from '@material-ui/icons/Settings';

const drawerWidth = 240;

const routes = [
    { path: 'overview', breadcrumb: 'overview' },
]

const styles = theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  paper: {
    background: theme.palette.primary.main,
    color: "#FFF",
  },
  breadcrumb: {
    fontSize: '1rem'
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(6) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  navRight: {
    marginLeft: 'auto',
    display: 'flex',
    gap: 16,
  },
  rightLink: {
    color: '#FFF',
    display: 'inline-flex',
    gap: 8,
    alignItems: 'center',
    verticalAlign: 'middle',
    '&:hover': {
            color: '#FFF',
    }
  },
  toolbarContainer: {
    justifyContent: 'flex-start',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(0, 2),
    ...theme.mixins.toolbar,
  },
});

class NavBar extends Component {

  static propTypes = {
    root: PropTypes.string,
    children: PropTypes.node.isRequired,
    dispatch: PropTypes.func.isRequired,
    fixed: PropTypes.bool,
    isAdmin: PropTypes.bool.isRequired,
  }

  constructor (props) {
    super(props)
    this.state = {
        open: true,
    };
    this.onClickLogout = this.onClickLogout.bind(this)
  }

    handleDrawerOpen = () => {
        this.setState({ open: true });
    };

    handleDrawerClose = () => {
        this.setState({ open: false });
    };

  onClickLogout (ev) {
    ev.preventDefault()
    const { dispatch, logout } = this.props
    dispatch(logout())
  }
  

  render () {
    const { root, rootname, children, classes, isAdmin, plan, breadcrumb } = this.props
    const {open} = this.state

    return (
        <div>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar className={classes.toolbarContainer}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={this.handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography className={classes.breadcrumb} noWrap>
            {breadcrumb}
          </Typography>
          <div className={classes.navRight}>
            {isAdmin && (
                <NavLink className={classes.rightLink} to="/admin"><SettingsIcon/> Admin</NavLink>
            )}
            <a href="#" className={classes.rightLink} onClick={ this.onClickLogout }>
                Log ud
                <Avatar src={plan.s3_image_id ? `https://ik.imagekit.io/aw3ddey5g/${plan.s3_image_id}?tr=w-200` : ''}/>
            </a>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx(classes.paper, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
            <svg className="nav-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 18" width="40" height="40">
                <path d="M15.667 16.33h-.98v-1.664h.98zm0-2.247h-.98v-1.097h.98zm0-3.347h-.98V9.071h.98zm0-2.246h-.98V7.394h.98zm0-3.345h-.98V3.48h.98zm0-2.246h-.98V1.8h.98zM14.183 16.33h-.98v-1.665h.98zm0-2.248h-.98v-1.097h.98zm0-3.347h-.98V9.071h.98zm0-2.246h-.98V7.394h.98zm0-3.345h-.98V3.48h.98zm0-2.246h-.98V1.8h.98zm-2.47 13.432h-.98v-1.665h.98zm0-2.248h-.98v-1.097h.98zm0-3.347h-.98V9.071h.98zm0-2.246h-.98V7.394h.98zm0-3.345h-.98V3.48h.98zm0-2.246h-.98V1.8h.98zm-1.48 13.432H9.25v-1.665h.983zm0-2.248H9.25v-1.097h.983zm0-3.347H9.25V9.071h.983zm0-2.246H9.25V7.394h.983zm0-3.345H9.25V3.48h.983zm0-2.246H9.25V1.8h.983zm-2.475 7.837h-.98V9.071h.98zm0-2.246h-.98V7.394h.98zm0-3.345h-.98V3.48h.98zm0-2.246h-.98V1.8h.98zm-1.482 7.837h-.98V9.071h.98zm0-2.246h-.98V7.394h.98zm0-3.345h-.98V3.48h.98zm0-2.246h-.98V1.8h.98zM3.798 16.33h-.98v-1.665h.98zm0-2.248h-.98v-1.097h.98zm0-3.347h-.98V9.071h.98zm0-2.246h-.98V7.394h.98zm0-3.345h-.98V3.48h.98zm0-2.246h-.98V1.8h.98zM2.316 16.33h-.98v-1.665h.98zm0-2.248h-.98v-1.097h.98zm0-3.347h-.98V9.071h.98zm0-2.246h-.98V7.394h.98zm0-3.345h-.98V3.48h.98zm0-2.246h-.98V1.8h.98zM17 16.559V1.44S17 0 15.64 0H1.36S0 0 0 1.441V16.56s0 1.439 1.36 1.439h3.932v-5.012h2.465V18h7.883c0-.002 1.36-.002 1.36-1.441"></path>
            </svg>

          <IconButton onClick={this.handleDrawerClose} color="inherit">
            {<ChevronLeftIcon />}
          </IconButton>
        </div>
        <Divider />
        <List>
        {
          root
          ? <ListLink state="Start" to={ root }>
            <ListItem button>
              <ListItemIcon>
                <HomeIcon color="secondary" />
              </ListItemIcon>
              <ListItemText primary={ rootname ? rootname : 'Start' } />
                </ListItem>
            </ListLink>
          : null
        }
        { children }
        </List>
        <Divider />
      </Drawer>
      </div>
    )
  }
}

const mapStateToProps = state => {
    return {
        breadcrumb: state.ui.breadcrumb,
        isAdmin: state.auth.isAdmin,
        plan: state.data.customer.plan,
        routing: state.routing
    }
}

const mapDispatchToProps = dispatch => ({
  dispatch,
  logout: actions.logout,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(NavBar))
