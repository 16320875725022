import React from 'react';
import { render } from 'react-dom'
import { browserHistory } from 'react-router'
import { syncHistoryWithStore } from 'react-router-redux'
import Root from 'client/components/Root'
import configureStore from 'client/store'
import moment from 'moment'

import 'assets/styles/vendor.scss'
import 'assets/styles/app.scss'

moment.locale('da')

const store = configureStore()
const history = syncHistoryWithStore(browserHistory, store)

const root = document.getElementById('root');
render(
  <React.StrictMode>
    <Root store={ store } history={ history } />
  </React.StrictMode>, root
);