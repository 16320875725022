import React, { Component } from 'react'
import { connect } from 'react-redux'
import actions from 'client/actions'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles } from '@material-ui/core/styles';
import {styles} from 'common/lib/styles';

class FAQ extends Component {

  componentDidMount() {
      const { dispatch, setBreadcrumb } = this.props
      dispatch(setBreadcrumb('FAQ'));
  }

  render () {

    const { faqs, classes } = this.props

    return (
      <div>
        {faqs.map(f => 
            <Accordion className={classes.accordion} id={f.id}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                <Typography>{f.question}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                    {f.answer}
                </Typography>
                </AccordionDetails>
            </Accordion>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    faqs: state.data.customer.faqs,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    setBreadcrumb: actions.setBreadcrumb,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(FAQ))
