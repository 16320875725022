import React, { Component } from 'react';
import { ListLink, NavLink } from '../../components/NavLink'
import NavBar from '../../components/NavBar'
import actions from '../../actions'
import { connect } from 'react-redux'
import HomeIcon from '@material-ui/icons/Home';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

class Unauthorized extends Component {

  onClickBack = ev => {
    ev.preventDefault();
    const { dispatch, redirectFrontPage } = this.props;
    dispatch(redirectFrontPage());
  };

    render () {
        return (
            <div>
                <div className="marketing-wrapper">
                    <nav className="navbar-default">
                        <div className="container">
                          <NavBar>
                            <ListLink to="/">
                                <ListItem button>
                                <ListItemIcon>
                                    <HomeIcon color="secondary" />
                                </ListItemIcon>
                                <ListItemText primary="Driftsplaner" />
                                </ListItem>
                            </ListLink>
                          </NavBar>
                        </div>
                    </nav>
                    <div className="text-center container unauthorized">
                        <h1>Du har ikke adgang til driftsplaner.</h1>
                    </div>
                </div>
            </div>
        )

    }
}

const mapStateToProps = (state) => {
  return {
  }
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    redirectFrontPage: actions.redirectFrontPage,
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Unauthorized)
