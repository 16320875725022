import React, { Component } from 'react'
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {savePlan} from "../actions";

class TitleInput extends Component {
    static propTypes = {
        canEdit: PropTypes.bool.isRequired,
        title: PropTypes.string.isRequired,
        dispatch: PropTypes.func.isRequired,
        planId: PropTypes.number.isRequired,
        savePlan: PropTypes.func.isRequired,
        startYear: PropTypes.number.isRequired,
        // The EXACT name of the Plan field to update
        fieldToUpdate: PropTypes.string.isRequired,
    }

    constructor(props) {
        super(props);

        this.switchMode = this.switchMode.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleBlur = this.handleBlur.bind(this);

        const { title } = this.props;

        this.state = {
            edit: false,
            newTitle: title,
        }
    }

    componentDidUpdate() {
        if (this.state.edit) {
            document.getElementById("free-form-title").focus();
        }
    }

    handleBlur() {
        const { dispatch, savePlan, planId, startYear, fieldToUpdate } = this.props;
        const { newTitle } = this.state;

        const plan = {
            id: planId,
            start_year: startYear,
        };

        plan[fieldToUpdate] = newTitle;

        dispatch(savePlan('update', plan));

        this.switchMode();
    }

    switchMode() {
        const { canEdit } = this.props;

        if (canEdit) {
            this.setState({
                edit: !this.state.edit,
            });
        }
    }

    handleKeyPress(e) {
        if (e.key === "Enter") {
            this.handleBlur();
        }
    }

    render() {
        const { edit } = this.state;
        const { newTitle, title } = this.props;
        const titleElement = edit ?
            (
                <div>
                    <input
                        type="text"
                        placeholder={newTitle}
                        value={newTitle}
                        className="w-100 form-control"
                        onBlur={this.handleBlur}
                        onKeyPress={this.handleKeyPress}
                        onChange={(e) => {
                            this.setState({
                                newTitle: e.target.value,
                            });
                        }}
                        id={"free-form-title"}
                    />
                </div>
            )
            :
            (
                <div onClick={this.switchMode}>
                    {title}
                </div>
            )

        return (
            <div>
                {titleElement}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = dispatch => {
    return {
        savePlan: savePlan,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TitleInput);