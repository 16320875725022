import React from 'react'
import PropTypes from 'prop-types';
const App = ({ children, location }) => {

  return (
    <div>

      { children }

      {/* {
        location.pathname !== '/' && location.pathname !== '/login'
          ? (
            <section id="footer" className="page-break-before hidden-print">
              <div className="container">
                <p className="small text-muted pull-right hidden-print">
                  <strong>Online Driftsplaner - version 1.1</strong><br />
                  Kontakt <a href="mailto:johan@mekanisme.com">teknisk support</a>
                </p>
                <img src={ logo } />
                <p className="small">
                  plan 1 arkitekter A/S. Besøg vores <a href="http://plan1.dk" target="_blank">hjemmeside</a>.
                </p>
              </div>
            </section>
          )
          : null
      } */}
    </div>
  )
}
App.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
}

export default App
