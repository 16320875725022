import React from 'react'
import PropTypes from 'prop-types';
import { StickyContainer, Sticky } from 'react-sticky'
import { Link } from 'react-router'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { map } from 'lodash'
import classnames from 'classnames'

const UserRow = ({ user, handler }) => {

  const { id, email, name, phone, deleted, role } = user

  return (
    <tr
      onClick={ handler(`/admin/users/edit/${id}`) }
      className={ classnames({ deleted }) }
      >
      <td>{ id }</td>
      <td className="email">{ email }</td>
      <td>{ name }</td>
      <td>{ phone }</td>
      <td>{ role == 'admin' ? 'Admin' : 'Redaktør' }</td>
      <td><span className={ classnames('label', {'label-danger': deleted, 'label-success': !deleted }) }>{ deleted ? 'Deaktiveret' : 'Aktiv' }</span></td>
    </tr>
  )
}
UserRow.propTypes = {
  user: PropTypes.object.isRequired,
  handler: PropTypes.func.isRequired,
}

const UserTable = ({ users, editLinkHandler, role }) => {

  const rows = map(users, (user, idx) => <UserRow key={ idx } user={ user } handler={ editLinkHandler } />)
  const readOnly = role !== 'admin'
  return (
    <StickyContainer>

    <Sticky>
     {() => (
        <div className="row sub-header">
            {
            readOnly
                ? null
                : (
                <div className="action-bar pull-right">
                    <Link to="/admin/users/new" className="btn btn-primary">Opret ny bruger</Link>
                </div>
                )
            }
            <h3>
            Brugerstyring
            </h3>
        </div>
    )}
    </Sticky>

      <table className="table table-striped table-condensed table-hover maintenance-plan-list">
        <thead>
          <tr>
            <th>ID</th>
            <th>Email</th>
            <th>Navn</th>
            <th>Telefon</th>
            <th>Rolle</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          { rows }
        </tbody>
      </table>

    </StickyContainer>
  )
}
UserTable.propTypes = {
  users: PropTypes.array.isRequired,
  editLinkHandler: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  users: state.data.users.list,
  role: state.auth.role,
})

const mapDispatchToProps = dispatch => ({
  editLinkHandler: (uri) => (ev) => dispatch(push(uri)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserTable)
