import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Field, actions as formActions } from 'react-redux-form'
import { connect } from 'react-redux'
import { NavLink } from 'client/components/NavLink'
import AutoSave from 'client/components/AutoSave'
import S3Uploader from 'react-s3-uploader'
import { API_URI } from 'client/constants'
import { has } from 'lodash'
import { Sticky, StickyContainer } from 'react-sticky'
import { PartButtons } from './index'
import { parseIntOrNull } from 'common/lib/validator'

import {
  InputField,
  TextField,
  SelectField,
} from 'client/components/Field'

import actions from 'client/actions'
import ProjectDialog from 'client/components/ProjectDialog';
import { fetchProject, fetchProjects } from '../Projects/actions';
import numeral from 'common/lib/numeral'

let intervals = []
let interval = ''
for (let i = 1; i <= 15; i++) {
  interval = <option value={ i } key={ i }>{ i } år</option>
  intervals = intervals.concat(interval)
}


class EditPart extends Component {

  static propTypes = {
    current: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    form_model: PropTypes.string.isRequired,
    plan: PropTypes.object.isRequired,
    params: PropTypes.object,
    fetch: PropTypes.func.isRequired,
    resetForm: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    setRenderedComponent: PropTypes.func.isRequired,
    publishPart: PropTypes.func.isRequired,
    unpublishPart: PropTypes.func.isRequired,
    deletePart: PropTypes.func.isRequired,
    saveUpload: PropTypes.func.isRequired,
    deleteUpload: PropTypes.func.isRequired,
    is_saving: PropTypes.bool.isRequired,
    last_saved: PropTypes.number.isRequired,
    start_year: PropTypes.any,
    years: PropTypes.array,
    hasEditRights: PropTypes.bool.isRequired,
  }

  constructor (props) {
    super(props)
    this.onUploadProgress = this.onUploadProgress.bind(this)
    this.onFinishUpload = this.onFinishUpload.bind(this)
    this.deleteUpload = this.deleteUpload.bind(this)
    this.onImageLoaded = this.onImageLoaded.bind(this)
    this.onPublishHandler = this.onPublishHandler.bind(this)
    this.onDraftHandler = this.onDraftHandler.bind(this)
    this.onDeleteHandler = this.onDeleteHandler.bind(this)
    this.handleDialog = this.handleDialog.bind(this)
    this.handleCreateProject = this.handleCreateProject.bind(this)
    this.state = {
      isUploading: false,
      isDialogOpen: false,
    }
  }

  componentDidMount () {
    const { dispatch, fetch, params, setRenderedComponent } = this.props

    dispatch(setRenderedComponent('part'))

    if (params.part_id) {
      dispatch(fetch(params.id, params.part_id))
      dispatch(fetchProjects(params.part_id))
    }
  }

  componentWillUnmount () {
    const { form_model, dispatch, resetForm } = this.props
    dispatch(resetForm(form_model))
  }

  onPublishHandler () {
    const { dispatch, publishPart, current } = this.props
    dispatch(publishPart(current.id, current))
  }

  onDraftHandler () {
    const { dispatch, unpublishPart, current, plan } = this.props
    dispatch(unpublishPart(current.id, plan))
  }

  onDeleteHandler () {
    const { dispatch, deletePart, current } = this.props
    const confirmation = window.confirm('Er du sikker?')
    if (confirmation) {
      dispatch(deletePart(current.plan_id, current.id))
    }
  }

  handleDialog (project) {
    const { dispatch } = this.props
    this.setState({
      isDialogOpen: !this.state.isDialogOpen
    })
    dispatch(fetchProject(project))
  }

  onUploadProgress (percentage, message, signResult) {
    this.setState({
      isUploading: true,
    })
  }

  onFinishUpload (result) {
    const { dispatch, saveUpload, current } = this.props
    dispatch(saveUpload(current.id, result.filename))
  }

  onImageLoaded () {
    this.setState({
      isUploading: false,
    })
  }

  deleteUpload (id) {
    return ev => {
      ev.preventDefault()
      const { dispatch, deleteUpload, current } = this.props
      const confirmation = window.confirm('Er du sikker?')
      if (confirmation) {
        dispatch(deleteUpload(current.id, id))
      }
    }
  }

  handleCreateProject () {
    const { params, dispatch, createProject } = this.props;
    dispatch(createProject(params.part_id));
  }

  render () {
    const {
      current, hasEditRights, current: { maintenance_start, inspection_start, bips, establishment_year }, form_model,
      is_saving, last_saved, params, years = [], start_year, plan, projects
    } = this.props
    const form = this.props.form.$form
    const { isUploading, isDialogOpen } = this.state
    const maintenance_start_years = maintenance_start < start_year && maintenance_start
      ? [maintenance_start].concat(years)
      : years
    const inspection_start_years = inspection_start < start_year && inspection_start
      ? [inspection_start].concat(years)
      : years
    const establishment_years = establishment_year < start_year && establishment_year
      ? [establishment_year].concat(years)
      : years

    const readOnly = plan.published || current.published || !hasEditRights

    return (
      <div>

          <Sticky>
            {() => (
            <div className="row sub-header">
            <div className="pull-right action-bar">
              <AutoSave is_saving={ is_saving } last_saved={ last_saved } />
              <PartButtons
                published={ current.published }
                plan_id={ params.id }
                publishHandler={ this.onPublishHandler }
                draftHandler={ this.onDraftHandler }
                deleteHandler={ this.onDeleteHandler }
                />
            </div>
              <h3>Tilføj Bygningsdel</h3>
              <p className="text-muted">Driftsplan: <NavLink className="plan" to={ `/c/${plan.id}` } target="_blank">{ plan.name }&nbsp;&nbsp;<i className="fa fa-external-link" aria-hidden="true"></i></NavLink></p>
              {
                hasEditRights
                  ? null
                  : (
                    <p className="text-muted text-danger"><i className="fa fa-lock"></i> <small>Du har ikke adgang til at redigere denne bygningsdel</small></p>
                  )
              }
              </div>
            )}
          </Sticky>


        <div className="row">
          <div className="col-md-6">
            <div className="well">

              <InputField
                model={ `${form_model}.title` }
                label="Title"
                className="form-control input-lg"
                valid={ form.validity.title }
                disabled={ readOnly }
                />

              <div className="form-group">
                <label>BIPS Kategori</label>
                <input className="form-control" type="text" value={ `${bips.id}. ${bips.name}` } disabled />
              </div>

              <TextField
                model={ `${form_model}.internal_note` }
                label="Intern arbejdsbeskrivelse (Vises ikke i driftsplan)"
                className="form-control"
                rows="8"
                cols="40"
                disabled
                />


            <div className="row">
                <div className="col-xs-8">
                    <TextField
                        model={ `${form_model}.condition_text` }
                        label="Tilstandsvurdering"
                        className="form-control"
                        rows="8"
                        cols="40"
                        valid={ form.validity.condition_text }
                        disabled={ readOnly }
                        />
                </div>
                <div className="col-xs-4">
                            <InputField
                                model={ `${form_model}.remaining_lifetime` }
                                label="Rest levetid"
                                type="number"
                                className="form-control"
                                parser={ parseIntOrNull }
                                placeholder="Levetid minus indbygningsår"
                                valid={ form.validity.remaining_lifetime }
                                disabled={ readOnly }
                                />

                            <SelectField
                                model={ `${form_model}.condition` }
                                label="Tilstand"
                                className="form-control"
                                valid={ form.validity.condition }
                                disabled={ readOnly }
                                >
                                <option value="">Vælg tilstand</option>
                                <option value="1">Rød (under middel)</option>
                                <option value="2">Gul (middel)</option>
                                <option value="3">Grøn (god)</option>
                            </SelectField>

                </div>
            </div>
            <div className="row">
                <div className="col-xs-8">
                <TextField
                    model={ `${form_model}.specification` }
                    label="Specifikation"
                    className="form-control"
                    rows="8"
                    cols="40"
                    valid={ form.validity.specification }
                    disabled={ readOnly }
                    />
                </div>
                <div className="col-xs-4">
                  <InputField
                    model={ `${form_model}.integrated_year` }
                    label="Indbygget år"
                    type="number"
                    className="form-control"
                    placeholder="F.eks 1903"
                    parser={ parseIntOrNull }
                    valid={ form.validity.integrated_year }
                    disabled={ readOnly }
                    >
                    <span className="help-block">Bygning opført i [ÅRSTAL]</span>
                  </InputField>
                  <InputField
                    model={ `${form_model}.amount` }
                    label="Antal"
                    type="text"
                    className="form-control"
                    placeholder="Mængde i en passende enhed"
                    valid={ form.validity.amount }
                    disabled={ readOnly }
                    />
                </div>
            </div>

              <div className="form-group">
                <label>
                  Billeder
                  {
                    isUploading
                      ? (
                          <span style={ { marginLeft: '10px', fontWeight: 'normal', fontSize: '13px', color: '#2c3e50' } }>
                            <i className="fa fa-cog fa-spin"></i>&nbsp;
                            <span>Uploader</span>
                          </span>
                        )
                      : null
                  }
                </label>
                <S3Uploader
                  className="form-control input-sm"
                  accept="image/*"
                  capture="camera"
                  signingUrl="/s3/sign"
                  uploadRequestHeaders={ { 'x-amz-acl': 'public-read' } }
                  contentDisposition="auto"
                  server={ API_URI }
                  onProgress={ this.onUploadProgress }
                  onFinish={ this.onFinishUpload }
                  disabled={ !has(current, 'id') || readOnly }
                  />
              </div>

              <div className="row image-gallery">
                { current.images.map((image, idx) => {
                  return (
                    <div className="col-md-4" key={ idx }>
                      <a href={ `https://ik.imagekit.io/aw3ddey5g/${image.s3_image_id}` } target="_blank">
                        <img src={ `https://ik.imagekit.io/aw3ddey5g/${image.s3_image_id}?tr=w-318,h-185` } className="img-responsive" onLoad={ this.onImageLoaded } />
                      </a>
                      <p>
                        <a href="#" onClick={ this.deleteUpload(image.id) }>Slet billede</a>
                      </p>
                    </div>
                  )
                }) }
              </div>

            </div> {/* END well */}
          </div> {/* END col-md-6 */}

          

          <div className="col-md-6">
            <div className="well">
              <h5><b>Opgaver</b></h5>
              <table className="table table-striped table-condensed table-hover">
                <thead>
                  <tr>
                    <th>Titel</th>
                    <th>Udførelses år</th>
                    <th>Omkostninger ekskl. moms</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {projects.map((p, i) => (
                    <tr key={p.id}>
                      <td>{p.text ? p.text : 'Opgave ' + (i+1)}</td>
                      <td>{p.year}</td>
                      <td>{numeral(p.amount).format('0,0')}</td>
                      <td className="text-right"><button onClick={() => this.handleDialog(p)} disabled={ readOnly } className="btn btn-sm btn-primary">Rediger</button></td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <button onClick={this.handleCreateProject} disabled={ readOnly } className="btn btn-primary">Opret opgave</button>
            </div>
            <div className="well">

              <TextField
                model={ `${form_model}.establishment` }
                label={ 'Ekstraordinær omkostning' }
                className="form-control"
                rows="8"
                cols="40"
                valid={ form.validity.establishment }
                disabled={ readOnly }
                />

              <div className="row">
                <div className="col-xs-12">
                  <Field model={ `${form_model}.prioritized` }>
                    <label className="checkbox-inline">
                      <input type="checkbox" value disabled={ readOnly } /> Skal prioriteres
                    </label>
                  </Field>
                </div>
              </div>

              <div className="row">
                <div className="col-xs-6">
                    <SelectField
                        model={ `${form_model}.establishment_year` }
                        label="Udførelses år"
                        className="form-control"
                        valid={ form.validity.establishment_year }
                        disabled={ readOnly }
                        >
                        <option value="">Udførelses år (årstal)</option>
                        { establishment_years.map(y => <option key={ y } value={ y }>{ y }</option>) }
                    </SelectField>
                </div>
                <div className="col-xs-6">
                  <InputField
                    model={ `${form_model}.establishment_cost` }
                    label="Omkostninger ekskl. moms og prisindeks"
                    type="number"
                    parser={ parseIntOrNull }
                    className="form-control"
                    placeholder="Eks. 8000"
                    valid={ form.validity.establishment_cost }
                    disabled={ readOnly }
                    />
                </div>
              </div>

              <TextField
                model={ `${form_model}.establishment_cost_note` }
                label="Interne noter til udregning (Vises ikke i driftsplan)"
                className="form-control"
                rows="4"
                cols="40"
                valid={ form.validity.establishment_cost_note }
                disabled={ readOnly }
                />

            </div> {/* END well */}

            <div className="well">

              <TextField
                model={ `${form_model}.maintenance` }
                label="Vedligehold"
                className="form-control"
                rows="8"
                cols="40"
                valid={ form.validity.maintenance }
                disabled={ readOnly }
                />

              <div className="row">

                <div className="col-xs-6">
                  <SelectField
                    model={ `${form_model}.maintenance_interval` }
                    label="Interval"
                    className="form-control"
                    valid={ form.validity.maintenance_interval }
                    disabled={ readOnly }
                    >
                    <option value="">Vedligeholds interval</option>
                    { intervals }
                  </SelectField>
                </div>

                <div className="col-xs-6">
                  <SelectField
                    model={ `${form_model}.maintenance_start` }
                    label="Første gang"
                    className="form-control"
                    valid={ form.validity.maintenance_start }
                    disabled={ readOnly }
                    >
                    <option value="">Første gang (årstal)</option>
                    { maintenance_start_years.map(y => <option key={ y } value={ y }>{ y }</option>) }
                  </SelectField>
                </div>

              </div> {/* END row */}

              <div className="row">

                <div className="col-xs-6">
                  <InputField
                    model={ `${form_model}.maintenance_cost` }
                    label="Omkostninger ekskl. moms og prisindeks"
                    type="number"
                    className="form-control"
                    placeholder="Eks. 8000"
                    parser={ parseIntOrNull }
                    valid={ form.validity.maintenance_cost }
                    disabled={ readOnly }
                    />
                </div>

                <div className="col-xs-6">
                  <div className="form-group">
                    <label htmlFor="">Kræver:</label>
                    <br />
                    <Field model={ `${form_model}.scaffolding` }>
                      <label className="checkbox-inline">
                        <input type="checkbox" value disabled={ readOnly } /> Stillads/lift
                      </label>
                    </Field>
                    <Field model={ `${form_model}.construction_site` }>
                      <label className="checkbox-inline">
                        <input type="checkbox" value disabled={ readOnly } /> Byggeplads
                      </label>
                    </Field>
                    <Field model={ `${form_model}.adviser` }>
                      <label className="checkbox-inline">
                        <input type="checkbox" value disabled={ readOnly } /> Teknisk rådgiver
                      </label>
                    </Field>
                    <Field model={ `${form_model}.building_management` }>
                      <label className="checkbox-inline">
                        <input type="checkbox" value disabled={ readOnly } /> Byggesagsadministration
                      </label>
                    </Field>
                  </div>
                </div>
              </div>

              <TextField
                model={ `${form_model}.maintenance_cost_note` }
                label="Interne noter til udregning (Vises ikke i driftsplan)"
                className="form-control"
                rows="4"
                cols="40"
                valid={ form.validity.maintenance_cost_note }
                disabled={ readOnly }
                />

            </div> {/* END well */}

            <div className="well">

              <TextField
                model={ `${form_model}.inspection` }
                label="Eftersyn"
                className="form-control"
                rows="8"
                cols="40"
                valid={ form.validity.inspection }
                disabled={ readOnly }
                />

              <div className="row">

                <div className="col-xs-6">
                  <SelectField
                    model={ `${form_model}.inspection_interval` }
                    label="Interval"
                    className="form-control"
                    valid={ form.validity.inspection_interval }
                    disabled={ readOnly }
                    >
                    <option value="">Eftersyns interval</option>
                    { intervals }
                  </SelectField>
                </div>

                <div className="col-xs-6">
                  <SelectField
                    model={ `${form_model}.inspection_start` }
                    label="Første gang"
                    className="form-control"
                    valid={ form.validity.inspection_start }
                    disabled={ readOnly }
                    >
                    <option value="">Første gang (årstal)</option>
                    { inspection_start_years.map(y => <option key={ y } value={ y }>{ y }</option>) }
                  </SelectField>
                </div>

              </div> {/* END row */}

            </div> {/* END well */}
          </div> {/* END col-md-6 */}
        </div> {/* END row */}
        <ProjectDialog partId={params.part_id} handleDialog={this.handleDialog} isDialogOpen={isDialogOpen} establishmentYears={establishment_years} />
      </div>

    )
  }
}

const mapStateToProps = state => {
  const hasEditRights =  state.auth.role === 'admin' || (state.auth.role === 'editor' && state.data.plan.current.internal_contact_id === state.auth.userId) || !state.data.plan.current.internal_contact_id
  return {
    current: state.data.part.current,
    form: state.data.part.form,
    form_model: 'data.part.current',
    plan: state.data.plan.current,
    is_saving: state.data.part.auto_save.is_saving,
    last_saved: state.data.part.auto_save.last_saved,
    start_year: state.data.years.start_year,
    years: state.data.years.years,
    projects: state.data.project.list.content,
    hasEditRights,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    fetch: actions.fetchPart,
    resetForm: formActions.reset,
    setRenderedComponent: actions.setRenderedComponent,
    saveUpload: actions.savePartUpload,
    deleteUpload: actions.deletePartUpload,
    createProject: actions.createProject,
    publishPart: actions.publishPart,
    unpublishPart: actions.unpublishPart,
    deletePart: actions.deletePart,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditPart)
