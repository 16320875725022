import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import actions from 'client/actions'
import numeral from 'common/lib/numeral'
import { toArray, map, has } from 'lodash'
import classnames from 'classnames'
import { Link } from 'react-router'
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  ArcElement,
  BarElement,
  Tooltip as ChartTooltip
} from 'chart.js';
import { Bar, Pie } from 'react-chartjs-2';
ChartJS.register(
  ArcElement,
  LinearScale,
  CategoryScale,
  BarElement,
  ChartTooltip
);
const BipsPieChart = ({ estimates_by_bips }) => {

  let data = [];

  let labels = []
  const estimateList = toArray(estimates_by_bips.estimates)
  for(let i = 0; i < estimateList.length; i++) {
    const bips = estimateList[i]
    data.push(bips.estimate)
    labels.push(bips.label)
  }

    const chartData = {
        labels: labels,
        datasets: [{
            data: data,
            backgroundColor: [
                '#2e4c5f',
                '#4e79a7',
                '#f28e2c',
                '#e15759',
                '#76b7b2',
                '#59a14f',
                '#edc949',
                '#af7aa1',
                '#ff9da7',
                '#9c755f',
                '#bab0ab',
                '#AFDC8F',
                '#5E40BE'
            ]
        }],
    }


 const options = {
        plugins: {
            tooltip: {
            callbacks: {
                label: function (value, idx) {
                return numeral(value.raw.toFixed(2)).format('000,000');
                }
            }
            },
        }
    }
  return (
    <Pie data={ chartData } options={options}/>
  )
}
BipsPieChart.propTypes = {
  estimates_by_bips: PropTypes.object.isRequired,
}

const AreaPieChart = ({ residentialArea, commercialArea }) => {
    const chartData = {
        labels: ['Boliger', 'Erhverv'],
        datasets: [{
            label: 'm2',
            data: [
                residentialArea, commercialArea
            ],
            backgroundColor: ['#A6B8C5', '#2e4c5f']
        }],
    }

  return (
    <Pie data={ chartData }/>
  )
}
AreaPieChart.propTypes = {
  residentialArea: PropTypes.number.isRequired,
  commercialArea: PropTypes.number.isRequired,
}

const YearBarChart = ({ totals, years }) => {
    const data = map(years, year => {
        return has(totals.ordinary_total_by_year, year)
            ? totals.ordinary_total_by_year[year]
            : 0
    })
    const options = {
        plugins: {
            legend: {
                display: false
            }
        },
        scales: {
            x: {
                grid: {
                display: false
                }
            }
        }
    }

    const chartData = {
        labels: years,
        datasets: [{
            label: 'Omkostninger til vedligeholdelse',
            data: data,
            borderRadius: 5,
            backgroundColor: '#A6B8C5',
        }],
    }

    return (
        <Bar data={chartData} options={options} />
    )
}
YearBarChart.propTypes = {
  totals: PropTypes.object.isRequired,
  years: PropTypes.array.isRequired,
}

class PortfolioPage extends Component {

  static propTypes = {
    params: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    role: PropTypes.string.isRequired,
    portfolio: PropTypes.object.isRequired,
    totals: PropTypes.object.isRequired,
    years: PropTypes.array.isRequired

  }

  componentDidMount () {
    const { dispatch, fetchPortfolio, params } = this.props
    dispatch(fetchPortfolio(params.id))
  }

  componentDidUpdate () {
    if (this.props.route.print) {
      setTimeout(function(){
        window.print()
      }, 1000)
    }
  }

  onSelectPlan = ev => { //TODO: Consider redirecting to admin view if admin, or how could we handle going to plan admin?
    const { dispatch } = this.props
    const planPath = `/c/${ev.target.value}`
    //dispatch(push(planPath)) //TODO: New window or moda?
    window.open(planPath)
  }

  render () {
    const { portfolio, totals, years, role, route } = this.props
    const print = route.print
    return (
      <div className="portfolio-client">
        <section className="wrapper">
          <div className={ classnames({ 'container-fluid': print, 'container': !print }) }>
            <div className="row sub-header">
                <h3>Porteføljeoversigt - Start år {totals.startYear }
                  { !print && <p className="pull-right print-button"><i className="fa fa-print"></i><Link to={ `/p/${portfolio.id}/print` } target="_blank"> Print</Link></p> }
                </h3>
            </div>
            <hr className="hr--space"/>
            <div className="row">
              <div className={ classnames('portfolio-statistics', { 'col-xs-8': print, 'col-md-8': !print }) }>
                <div className="row">
                  <div className={ classnames({ 'col-xs-6': print, 'col-md-6': !print }) }>
                    <div className="well text-center">
                      <h4>Ejendomme</h4>
                      <h3>{totals && totals.properties}</h3>
                    </div>
                  </div>
                  <div className={ classnames({ 'col-xs-6': print, 'col-md-6': !print }) }>
                    <div className="well text-center">
                      <h4>Enheder ialt</h4>
                      <h3>{totals && totals.totalUnits}</h3>
                    </div>
                  </div>
                  <div className={ classnames({ 'col-xs-6': print, 'col-md-6': !print }) }>
                    <div className="well text-center">
                      <h4>Enheder (Bolig)</h4>
                      <h3>{totals && totals.residentialUnits}</h3>
                    </div>
                  </div>
                  <div className={ classnames({ 'col-xs-6': print, 'col-md-6': !print }) }>
                    <div className="well text-center">
                      <h4>Enheder (Erhverv)</h4>
                      <h3>{totals && totals.commercialUnits}</h3>
                    </div>
                  </div>
                  <div className={ classnames({ 'col-xs-6': print, 'col-md-6': !print }) }>
                    <div className="well text-center">
                      <h4>Bruttoetageareal (Bolig)</h4>
                      <h3>{totals && numeral(totals.residentialArea).format('0,0')} m<sup>2</sup></h3>
                    </div>
                  </div>
                  <div className={ classnames({ 'col-xs-6': print, 'col-md-6': !print }) }>
                    <div className="well text-center">
                      <h4>Bruttoetageareal (Erhverv)</h4>
                      <h3>{totals && numeral(totals.commercialArea).format('0,0')} m<sup>2</sup></h3>
                    </div>
                  </div>
                  <div className={ classnames({ 'col-xs-6': print, 'col-md-6': !print }) }>
                    <div className="well text-center">
                      <h4>Samlet bruttoetageareal</h4>
                      <h3>{totals && numeral(totals.totalArea).format('0,0')} m<sup>2</sup></h3>
                    </div>
                  </div>
                  <div className={ classnames({ 'col-xs-6': print, 'col-md-6': !print }) }>
                    <div className="well text-center">
                      <h4>Gnms. vedligehold pr. m<sup>2</sup></h4>
                      <h3>{totals && numeral(totals.averageMaintenancePerSquareMeter).format('0')} kr.</h3>
                    </div>
                  </div>
                  <div className={ classnames({ 'col-xs-6': print, 'col-md-6': !print }) }>
                    <div className="well text-center">
                      <h4>Ordinær drift for 15 år</h4>
                      <h3>{totals && numeral(totals.ordinaryMaintenance / 1000).format('0.00')} mill kr.</h3>
                    </div>
                  </div>
                  <div className={ classnames({ 'col-xs-6': print, 'col-md-6': !print }) }>
                    <div className="well text-center">
                      <h4>Ekstraordinær drift for 15 år</h4>
                      <h3>{totals && numeral(totals.extraOrdinaryMaintenance / 1000).format('0.00')} mill kr.</h3>
                    </div>
                  </div>
                  <div className={ classnames({ 'col-xs-6': print, 'col-md-6': !print }) }>
                    <div className="well text-center">
                      <h4>Samlet drift for 15 år</h4>
                      <h3>{totals && numeral(totals.totalMaintenance / 1000).format('000,000.00')} mill kr.</h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className={ classnames('portfolio-meta', { 'col-xs-4': print, 'col-md-4': !print }) }>
                {portfolio.internal_contact &&
                  <div className="well">
                    <h3>Plan1 Kontaktperson</h3>
                    <div className="text-muted">
                      {portfolio.internal_contact.name}<br />
                      Email: <a href={`mailto:${portfolio.internal_contact.email}`}>{portfolio.internal_contact.email}</a><br />
                      <span>Tlf.: {portfolio.internal_contact.phone}</span>
                    </div>
                  </div>}

                <div className="well">
                  <h3>Firmaoplysninger</h3>
                  <div className="text-muted">
                    {portfolio.company_name}<br />
                    {portfolio.address && portfolio.address.split('\n').map((item, key) => {
                      return <span key={`${key}.${item}`}>{item}<br/></span>
                    })}<br />
                    Kontaktperson: {portfolio.contact_name}<br />
                    Email: <a href={`mailto:${portfolio.contact_email}`}>{portfolio.contact_name}</a><br />
                    Tlf.: {portfolio.contact_phone}
                  </div>
                </div>
                <div className="well hidden-print">
                  <h3>Driftsplaner</h3>
                  <label className="text-muted">Vælg en ejendom for at se driftsplanen</label>
                  <select className="form-control" onChange={ this.onSelectPlan }>
                    <option value="">Vælg en driftsplan</option>
                    { totals.activePlans.map((plan, idx) => <option key={ idx } value={ plan.id } disabled={plan.disabled ? ' disabled="disabled"' : ''}>{ plan.name }</option>) }
                  </select>
                </div>
                {(totals.inActivePlans && role == 'admin' || role == 'editor') &&
                  <div className="well hidden-print">
                    <h3>Inaktive driftsplaner</h3>
                    <label className="text-muted">Driftsplaner i denne liste er inaktive fordi de enten er kladder eller ikke passer til startåret som er {totals.startYear}</label>
                    <select className="form-control" onChange={ this.onSelectPlan }>
                      <option value="">Vælg en driftsplan</option>
                      { totals.inActivePlans.map((plan, idx) => <option key={ idx } value={ plan.id } disabled={plan.disabled ? ' disabled="disabled"' : ''}>{ `${plan.name} (${plan.published ? plan.start_year : 'Kladde'})` }</option>) }
                    </select>
                  </div>
                }
              </div>
            </div>
          </div>
        </section>
        <section className={ classnames({ 'section-background': !print }) }>
          <div className="container">
            <div className="row">
              <div className={ classnames({ 'col-xs-12': print, 'col-md-12': !print }) }>
                <h2 className="text-center"><i className="fa fa-area-chart"></i> Samlede omkostninger for portfølje </h2>
                <p className="lead text-center">Udgifter fordelt pr. år i tusinde kr.</p>
                <p className="text-center">Prisen dækker materiale, og arbejdsløn inkl. moms og prisindeks</p>
                <hr className="hr--space"/>
                <div className="text-center">
                    <span className="label label-blue">&nbsp;&nbsp;&nbsp;</span><span className="small"> Omkostninger til vedligeholdelse</span>
                </div>
                {totals && <YearBarChart totals={ totals } years={ years } />}
              </div>
            </div>
            <hr className="hr--space"/>
            <div className="row page-break-before">
              <div className={ classnames('text-center', { 'col-xs-8 col-xs-offset-2': print, 'col-md-6 col-md-offset-3': !print }) }>
                <h3>Fordeling</h3>
                <p className="text-muted">Bolig og ervherv på m<sup>2</sup></p>
                <div className={ classnames('meter-pie-chart', { 'print': print }) }>
                  {(totals.residentialArea || totals.commercialArea) && <AreaPieChart residentialArea={ totals.residentialArea } commercialArea={ totals.commercialArea } />}
                </div>
              </div>
            </div>
            <hr className="hr--space"/>
            <div className="row">
              <div className={ classnames('text-center', { 'col-xs-12': print, 'col-md-6 col-md-offset-3': !print }) }>
                <h3 className="text-center">Udgifterne fordelt pr. BIPS kategori i tusinde kr.</h3>
                <p className="text-center text-muted">Prisen dækker materiale og arbejdsløn inkl. moms og prisindeks</p>
                <div className={ classnames('bips-pie-chart', { 'print': print }) }>
                  {totals.estimates_by_bips && <BipsPieChart estimates_by_bips={ totals.estimates_by_bips } />}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )

  }
}

const mapStateToProps = state => {
  return {
    role: state.auth.role,
    portfolio: state.data.portfolioFrontend.portfolio.portfolio,
    totals: state.data.portfolioFrontend.portfolio.totals,
    years: state.data.years.years
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    fetchPortfolio: actions.fetchPortfolioFrontend,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PortfolioPage)
