export const PlanSchema = {
  base: {
    properties: {
      id: {
        type: 'integer',
        allowNull: false,
      },
      published: {
        label: 'Offentlig',
        type: 'boolean',
        allowNull: false,
      },
      archived: {
        label: 'Arkiveret',
        type: 'boolean',
        allowNull: false,
      },
      case_no: {
        type: 'string',
      },
      internal_contact_id: {
        type: 'integer',
      },
      name: {
        type: 'string',
      },
      address1: {
        type: 'string',
      },
      address2: {
        type: 'string',
      },
      address3: {
        type: 'string',
      },
      zip_code: {
        type: 'string',
      },
      city: {
        type: 'string',
      },
      s3_image_id: {
        type: 'string',
      },
      contact_name: {
        type: 'string',
      },
      contact_email: {
        type: 'email',
      },
      contact_phone: {
        type: 'string',
      },
      contact_address: {
        type: 'string',
      },
      email_notifications: {
        type: 'string',
        allowEmpty: '',
      },
      residential_area: {
        type: 'integer',
        minValue: 0,
        allowEmpty: '',
        allowNull: true,
      },
      commercial_area: {
        type: 'integer',
        minValue: 0,
        allowEmpty: '',
        allowNull: true,
      },
      erected: {
        type: 'integer',
        minValue: 0,
        allowEmpty: '',
        allowNull: true,
      },
      residential_units: {
        type: 'integer',
        minValue: 0,
        allowEmpty: '',
        allowNull: true,
      },
      commercial_units: {
        type: 'integer',
        minValue: 0,
        allowEmpty: '',
        allowNull: true,
      },
      bbr_no: {
        type: 'string',
      },
      cadastral_no: {
        type: 'string',
      },
      condition: {
        type: 'integer',
        allowEmpty: '',
        allowNull: true,
      },
      conclusion: {
        type: 'string',
      },
      about: {
        type: 'string',
      },
      recommendations: {
        type: 'string',
      },
      purchased_inspection: {
        label: 'Service aftale tilkøbt',
        type: 'integer',
        allowNull: false,
      },
      price_index: {
        type: 'string',
        minValue: 0,
        regex: /^\s*[0-9]{0,3}(,[0-9])?\s*$/,
        allowEmpty: '',
      },
      price_index_date: {
        type: 'date',
        format: 'YYYY-MM-DD',
        strict: true,
        allowEmpty: '',
        allowNull: true,
      },
      start_year: {
        type: 'integer',
        allowEmpty: '',
        allowNull: true,
      },
      overridden_updated_at: {
        type: 'date',
        format: 'YYYY-MM-DD',
        allowEmpty: '',
        allowNull: true,
      },
      inspection_date: {
        type: 'integer',
        allowEmpty: '',
        allowNull: true,
      },
      asset_id: {
        type: 'integer',
      },
      estimate_free_form_title: {
        type: 'string',
      },
      estimate_free_form_total_title: {
        type: 'string',
      },
      energy_enable: {
        type: 'boolean',
        allowNull: false,
      },
      energy_consumption: {
        type: 'string',
        minValue: null,
        allowNull: true,
      },
      energy_consumption_potential: {
        type: 'string',
        allowNull: true,
      },
      energy_emission: {
        type: 'string',
        allowNull: true,
      },
      energy_emission_potential: {
        type: 'string',
        allowNull: true,
      },
      energy_label: {
        type: 'string',
        minValue: 0,
        allowEmpty: '',
        allowNull: true,
      },
      energy_label_potential: {
        type: 'string',
        minValue: 0,
        allowEmpty: '',
        allowNull: true,
      },
      energy_label_text: {
        type: 'string',
      },
      energy_report_number: {
        type: 'string',
        minValue: 0,
        allowEmpty: '',
        allowNull: true,
      },
      energy_expense: {
        type: 'string',
        allowEmpty: '',
        allowNull: true,
      },
      energy_consumption_new: {
        type: 'string',
        allowEmpty: '',
        minValue: 0,
        regex: /^\d*\,?\d*$/,
      },
      energy_consumption_new_potential: {
        type: 'string',
        allowEmpty: '',
        minValue: 0,
        regex: /^\d*\,?\d*$/,
      },
      energy_type: {
        type: 'integer',
        allowNull: true,
      },
      energy_electricity_purpose: {
        type: 'integer',
        allowNull: true,
      },
      energy_electricity_purpose_potential: {
        type: 'integer',
        allowNull: true,
      },
      energy_yearly_saving: {
        type: 'integer',
        allowNull: true,
      },
      energy_report_date: {
        type: 'date',
        format: 'YYYY-MM-DD',
        strict: true,
        allowEmpty: '',
        allowNull: true,
      },
    },
  },
  extensions: {
    update: {
      properties: {
        id: {
          required: true,
        },
      },
    },
    publish: {
      properties: {
        id: {
          required: true,
        },
        published: {
          required: true,
        },
        archived: {
          required: true,
        },
        case_no: {
          required: true,
        },
        internal_contact_id: {
          required: true,
        },
        name: {
          required: true,
        },
        address1: {
          required: true,
        },
        zip_code: {
          required: true,
        },
        city: {
          required: true,
        },
        s3_image_id: {
          required: true,
        },
        contact_name: {
          required: true,
        },
        contact_email: {
          required: true,
        },
        contact_phone: {
          required: true,
        },
        contact_address: {
          required: true,
        },
        email_notifications: {
          required: true,
        },
        residential_area: {
          required: true,
          allowNull: false,
        },
        commercial_area: {
          required: true,
          allowNull: false,
        },
        erected: {
          required: true,
          allowNull: false,
        },
        residential_units: {
          required: true,
          allowNull: false,
        },
        commercial_units: {
          required: true,
          allowNull: false,
        },
        bbr_no: {
          required: true,
        },
        cadastral_no: {
          required: true,
        },
        condition: {
          required: true
        },
        conclusion: {
          required: true,
        },
        about: {
          required: true,
        },
        recommendations: {
          required: true,
        },
        purchased_inspection: {
          required: true,
        },
        price_index: {
          required: true,
        },
        price_index_date: {
          required: true,
        },
        start_year: {
          required: true,
          allowNull: false,
        },
        overridden_updated_at: {
          required: true,
        },
      },
    },
    customer: {
      properties: {
        id: {
          required: true,
        },
        name: {
          required: true,
        },
        address1: {
          required: true,
        },
        zip_code: {
          required: true,
        },
        city: {
          required: true,
        },
        contact_name: {
          required: true,
        },
        contact_email: {
          required: true,
        },
        contact_phone: {
          required: true,
        },
        email_notifications: {
          required: true,
        },
        password: {
          required: true,
          strip: true,
        },

        published: {
          strip: true,
        },
        archived: {
          strip: true,
        },
        case_no: {
          strip: true,
        },
        internal_contact_id: {
          strip: true,
        },
        s3_image_id: {
          strip: true,
        },
        residential_area: {
          strip: true,
        },
        commercial_area: {
          strip: true,
        },
        erected: {
          strip: true,
        },
        residential_units: {
          strip: true,
        },
        commercial_units: {
          strip: true,
        },
        bbr_no: {
          strip: true,
        },
        cadastral_no: {
          strip: true,
        },
        condition: {
            strip: true
        },
        conclusion: {
          strip: true,
        },
        about: {
          strip: true,
        },
        recommendations: {
          strip: true,
        },
        purchased_inspection: {
          strip: true,
        },
        price_index: {
          strip: true,
        },
        price_index_date: {
          strip: true,
        },
        start_year: {
          strip: true,
        },
        overridden_updated_at: {
          strip: true,
        },
      },
    },
  },
}

export const PlanImageSchema = {
  base: {
    properties: {
      id: {
        type: 'integer',
        allowNull: false,
        required: true,
      },
      s3_image_id: {
        type: 'string',
        required: true,
      },
    },
  },
}

export const PlanFileSchema = {
  base: {
    properties: {
      plan_id: {
        type: 'integer',
        required: true,
      },
      s3_file_id: {
        type: 'string',
        required: true,
      },
      mimetype: {
        type: 'string',
        required: true,
      },
      file_name: {
        type: 'string',
        required: true,
      },
      category: {
        type: 'string',
        required: false,
      }
    },
  },
  extensions: {
    update: {
      properties: {
        id: {
          required: true,
        },
        plan_id: {
          type: 'integer',
          required: false,
        },
        s3_file_id: {
          type: 'string',
          required: false,
        },
        mimetype: {
          type: 'string',
          required: false,
        },
        file_name: {
          type: 'string',
          required: false,
        },
        category: {
          type: 'string',
          required: true,
        }
      },
    },
  }
}
