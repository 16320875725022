import React from 'react'
import PropTypes from 'prop-types';
import DocumentTitle from 'react-document-title'

const PartsWrapper = ({ children, route }) => {
  return (
    <DocumentTitle title={ route.title }>
      { children }
    </DocumentTitle>
  )
}
PartsWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  route: PropTypes.object.isRequired,
}

export default PartsWrapper
