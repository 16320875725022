import React, { Fragment, Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import numeral from 'common/lib/numeral'
import actions from 'client/actions'
import DocumentTitle from 'react-document-title'
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import {styles} from 'common/lib/styles';
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  Tooltip
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import Contact from 'client/components/Contact';
ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  Tooltip
);

const BarChart = ({ now, potential, labels }) => {
    const options = {
        plugins: {
            legend: {
                display: false
            }
        },
        scales: {
            x: {
                grid: {
                display: false
                }
            }
        }
    }

    const chartData = {
        labels: labels,
        datasets: [{
            label: 'I dag',
            data: now,
            borderRadius: 5,
            backgroundColor: '#2e4c5f',
        },
        {
            label: 'Efter rentable tiltag',
            data: potential,
            borderRadius: 5,
            backgroundColor: '#A6B8C5',
        }
    ],
    }

    return (
        <Bar data={chartData} options={options} />
    )
}
class Energy extends Component {

    constructor (props) {
        super(props)
        this.energyColumn = this.energyColumn.bind(this)
    }

    energyColumn (className, object) {
        return <div className="energy-column">
            <div className={"bg-" + className}>
                <span className="label-char-sm">{object.char}</span>
                {object.year ? <span className="label-year">{object.year}</span> : null}
            </div>
        </div>
    }

    componentDidMount() {
        const { dispatch, setBreadcrumb } = this.props
        dispatch(setBreadcrumb('Energi'));
    }

  
    render () {
        const {
            plan, params: { customer_id },
            parts, estimates, years, route,
            classes,
        } = this.props
        const { internal_contact = {} } = plan
        const energyOptions = {
            'a2020': {char: 'A', year: 2020},
            'a2013': {char: 'A', year: 2013},
            'a2010': {char: 'A', year: 2010},
            'b': {char: 'B'},
            'c': {char: 'C'},
            'd': {char: 'D'},
            'e': {char: 'E'},
            'f': {char: 'F'},
            'g': {char: 'G'}
        }
    const energyTypes = {1: 'm3 naturgas', 2: 'm3', 3: 'kWh elektricitet', 4: 'kWh fra solceller', 5: 'kWh', 6: 'Liter', 7: 'Mwh fjernvarme'}

        return (
            <div>
                <DocumentTitle title={ route.title } />

                <Grid container alignItems="stretch" spacing={3}>
                    <Grid item sm={12} md={7} lg={6}>
                        <div id="energy-label">
                            <div className="energy-title">
                                <Typography className={classes.header} variant="h5">Ejendommen har energimærke</Typography>
                                <div className="energy-column-current">
                                    {plan.energy_label && this.energyColumn(plan.energy_label, energyOptions[plan.energy_label])}
                                </div>
                            </div>
                            <div className="row grid">
                                <div className="energy-column-container col-md-12">
                                    {Object.keys(energyOptions).map(e => (
                                        this.energyColumn(e,energyOptions[e])
                                    ))}
                                </div>
                            </div>
                            {plan.energy_label_potential && <div className="row">
                                <div className={"arrow-position-" + plan.energy_label_potential}>
                                    <div className="arrow-wrapper">
                                        <div className="arrow-up"/>
                                        <div className="arrow-text">Energimærke<br/>(potentiale)</div>
                                    </div>
                                </div>
                            </div>}
                        </div>
                        {plan.energy_consumption &&
                        <div>
                            <Typography className={classes.header} variant="h5">Ejendommens energiforbrug</Typography>
                            <div className={classes.chartEnergy}>
                                <span className="label label-round label-now">&nbsp;</span><span className="small">I dag</span><span className="label label-round label-blue">&nbsp;</span><span className="small">Efter rentable tiltag</span>
                            </div>
                            <BarChart now={[parseInt(plan.energy_consumption_new), plan.energy_electricity_purpose]} potential={[parseInt(plan.energy_consumption_new_potential), plan.energy_electricity_purpose_potential]} labels={[energyTypes[plan.energy_type], "El til andet"]}/>
                        </div>}
                    </Grid>
                    <Grid item sm={12} md={5} lg={6}>
                    <Paper className={classes.paper}>
                        <Grid container>
                            <Grid item sm={12} md={6}>
                                <Typography className={classes.textCenter} paragraph>
                                    Samlet CO2-udledning<br/>(nuværende)
                                </Typography>
                                <Typography className={classes.textCenter} paragraph>
                                    <b>{
                                        plan.energy_emission ? numeral(plan.energy_emission).format('0.00') : 0
                                    } ton</b>
                                </Typography>
                            </Grid>
                            <Grid item sm={12} md={6}>
                                <Typography className={classes.textCenter} paragraph>
                                    Samlet CO2-udledning<br/>(potentiale)
                                </Typography>
                                <Typography className={classes.textCenter} paragraph>
                                    <b>{
                                        plan.energy_emission_potential
                                          ? <span>{numeral(plan.energy_emission_potential).format('0.00')}</span>
                                          : 0
                                    } ton</b>
                                </Typography>
                            </Grid>
                            <Grid item sm={12} md={6}>
                                <Typography className={classes.textCenter} paragraph>
                                    Årligt varmeforbrug<br/>(nuværende)
                                </Typography>
                                <Typography className={classes.textCenter} paragraph>
                                    <b>{
                                        plan.energy_consumption
                                          ? numeral(plan.energy_consumption).format('0.00')
                                          : 0
                                    } {energyTypes[plan.energy_type]}</b>
                                </Typography>
                            </Grid>
                            <Grid item sm={12} md={6}>
                                <Typography className={classes.textCenter} paragraph>
                                    Årligt varmeforbrug<br/>(potentiale)
                                </Typography>
                                <Typography className={classes.textCenter} paragraph>
                                    <b>{
                                        plan.energy_consumption_potential
                                        ? <span>{numeral(plan.energy_consumption_potential).format('0.00')}</span>
                                        : 0
                                    } {energyTypes[plan.energy_type]}</b>
                                </Typography>
                            </Grid>
                            <Grid item sm={12} md={6}>
                                <Typography className={classes.textCenter} paragraph>
                                    Samlet energiudgift
                                </Typography>
                                <Typography className={classes.textCenter} paragraph>
                                    <b>{ numeral(plan.energy_expense).format('0,0') } kr.</b>
                                </Typography>
                            </Grid>
                            <Grid item sm={12} md={6}>
                                <Typography className={classes.textCenter} paragraph>
                                    Energiudgift pr. m<sup>2</sup> / pr. år
                                </Typography>
                                <Typography className={classes.textCenter} paragraph>
                                    <b>{ numeral(plan.energy_expense / plan.total_area / 15).format('0,0') } kr.</b>
                                </Typography>
                            </Grid>
                            <Grid item sm={12}>
                                <p className="text-center text-muted-dark">Energimærkningsrapport {plan.energy_report_number} {plan.energy_report_date && <>(Udløber den {moment(plan.energy_report_date).format('DD. MMMM YYYY')})</>}</p>
                                <p className="text-center text-muted text-muted-small">
                                      Data er hentet fra energimærkning<br/>
                                      Der tages forbehold for fejl og ændringer
                                </p>
                            </Grid>
                        </Grid>
                    </Paper>
                    <Paper className={`${classes.paper} ${classes.paperMarginTop}`}>
                        <Typography className={classes.textCenter} paragraph>
                            I følge dit energimærke, betaler du hvert år mere i energiudgifter end du behøver.  Der er mulighed for en årlig besparelse på:
                        </Typography>
                        {<Typography className={classes.textCenter} variant="h4">
                            <b>{plan.energy_yearly_saving ? numeral(plan.energy_yearly_saving).format('0,0') : 0} kr.</b>
                        </Typography>}
                    </Paper>
                    <Paper className={`${classes.paper} ${classes.paperMarginTop}`}>
                        <Typography className={classes.textCenter}>
                            {plan.energy_label_text ? plan.energy_label_text : 'Udarbejdelse af løsningsforslag for energioptimering tilbydes særskilt'}
                        </Typography>
                    </Paper>
                    <Contact
                        title={"Kontakt Plan1 for at høre om mulighederne"}
                        name={internal_contact.name}
                        email={internal_contact.email}
                        phone={internal_contact.phone}
                        src={internal_contact.s3_image_id ? `https://ik.imagekit.io/aw3ddey5g/${internal_contact.s3_image_id}?tr=w-200` : null}
                        variant="h6"
                        paperClasses={`${classes.paper} ${classes.paperMarginTop}`}
                    />
                    </Grid>
                </Grid>
                                    {/* <div className="col-md-6">
                                        <h4 className="text-muted">Årligt varmeforbrug (nuværende)</h4>
                                        <h3>
                                            {
                                                plan.energy_consumption
                                                  ? numeral(plan.energy_consumption).format('0.00')
                                                  : 0
                                            }
                                            &nbsp;MWh
                                        </h3>
                                    </div>
                                    <div className="col-md-6">
                                        <h4 className="text-muted">Samlet CO2 udledning (nuværende)</h4>
                                        <h3>
                                          {
                                            plan.energy_emission ? numeral(plan.energy_emission).format('0.00') : 0
                                          }
                                          &nbsp;ton
                                        </h3>
                                    </div>
                                    <div className="col-md-6">
                                        <h4 className="text-muted">Årligt varmeforbrug (potentiale)</h4>
                                        <h3 className="green-text">
                                          {
                                            plan.energy_consumption_potential
                                              ? <span>{numeral(plan.energy_consumption_potential).format('0.00')} MWh</span>
                                              : "..."
                                          }
                                        </h3>
                                    </div>
                                    <div className="col-md-6">
                                        <h4 className="text-muted">Samlet CO2 udledning (potentiale)</h4>
                                        <h3 className="green-text">
                                            {
                                                plan.energy_emission_potential
                                                  ? <span>{numeral(plan.energy_emission_potential).format('0.00')} ton</span>
                                                  : "..."
                                            }
                                        </h3>
                                    </div>
                                    <p className="text-muted-dark">Energimærkningsrapport {plan.energy_report_number} (Udløber den {moment(plan.energy_report_date).format('DD. MMMM YYYY')})</p>
                                    <p className="text-muted text-muted-small">
                                      Data er hentet fra energimærkning<br/>
                                      Der tages forbehold for fejl og ændringer
                                    </p>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </section> */}

            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        plan: state.data.customer.plan,
        parts: state.data.customer.parts,
        estimates: state.data.customer.estimates,
        years: state.data.years.years,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
        setBreadcrumb: actions.setBreadcrumb,
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(Energy))
