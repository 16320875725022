// import Promise from 'bluebird';
import _ from 'lodash';
import klass from 'klass';
import Events from 'events';
import S3Upload from 'react-s3-uploader/s3upload.js';
import createerror from 'create-error'

const EventEmitter = Events.EventEmitter;

const S3Error = createerror('S3Error')

const S3FileUploader = klass({

    initialize(file, options) {
        this.category = 'Bilag'
        this.file = file;
        this.preview = file.preview || URL.createObjectURL(file);
        this.emitter = new EventEmitter();
        this.opts = _.defaults(options || {}, {
            signingUrl: '/s3/sign'
        });
    },

    getFile() {
        return this.file;
    },

    getFileType() {
        return this.getFile().type;
    },

    getPreview() {
        return this.preview;
    },

    getFilename() {
        return this.getFile().name;
    },

    isImage() {
        return /^image\//.exec(this.getFileType());
    },

    getResultS3Filename() {
        return this.result && this.result.filename;
    },

    getS3Id() {
        return this.result && this.result.filename;
    },

    getPublicUrl() {
        return this.result && this.result.publicUrl;
    },

    on(event, callback) {
        this.emitter.addListener(event, callback);
    },

    off(event, callback) {
        this.emitter.removeListener(event, callback);
    },

    cancel() {
        if (this.upload) {
            this.upload.abortUpload();
            this.emitter.emit('abort');
            this.upload = null;
        }
    },

    markFailed() {
        this.failed = true;
    },

    isFailed() {
        return !!this.failed;
    },

    getContentDisposition() {
        let isPdf = this.getFileType() == 'application/pdf';
        return isPdf ? 'inline' : 'auto';
    },

    start() {
        return new Promise((resolve, reject) => {
            this.upload = new S3Upload({
                files: [this.file],
                signingUrl: this.opts.signingUrl,
                signingUrlQueryParams: this.opts.signingUrlQueryParams,
                uploadRequestHeaders: { 'x-amz-acl': 'public-read' },
                contentDisposition: this.getContentDisposition(),
                server: this.opts.server,
                onProgress: (percent, status) => {
                    this.emitter.emit('progress', percent, status);
                },
                onFinishS3Put: (result) => {
                    this.result = result;
                    this.emitter.emit('complete', result, this.getFilename(), this.getFileType());
                    return resolve(result);
                },
                onError: (status) => {
                    this.emitter.emit('error', status);
                    this.markFailed();
                    return reject(new S3Error('S3 Error', {
                      status: status,
                    }));
                }
            });
            this.emitter.once('abort', resolve);
        });
    }

});

export default S3FileUploader;
