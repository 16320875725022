import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { NavLink } from 'client/components/NavLink'
import { actions as formActions } from 'react-redux-form'
import actions from 'client/actions'
import AutoSave from 'client/components/AutoSave'
import DateTime from 'client/components/DateTime'
import { AUTO_SAVE_INTERVAL } from 'client/constants'
import moment from 'moment'
import { has, throttle } from 'lodash'
import { StickyContainer, Sticky } from 'react-sticky'

class PlanHeader extends Component {

  static propTypes = {
    children: PropTypes.node.isRequired,
    current: PropTypes.object.isRequired,
    rendered_component: PropTypes.string.isRequired,
    form_model: PropTypes.object.isRequired,
    params: PropTypes.object,
    dispatch: PropTypes.func.isRequired,
    save: PropTypes.object.isRequired,
    fetch: PropTypes.func.isRequired,
    fetchInternalContacts: PropTypes.func.isRequired,
    should_save: PropTypes.object.isRequired,
    is_saving: PropTypes.object.isRequired,
    last_saved: PropTypes.object.isRequired,
    auto_save_interval: PropTypes.number.isRequired,
    clearAutosave: PropTypes.func.isRequired,
    invalidateData: PropTypes.func.isRequired,
    forceUpdateAutosave: PropTypes.func.isRequired,
    resetForm: PropTypes.func.isRequired,
    hasFetched: PropTypes.bool.isRequired,
    hasEditRights: PropTypes.bool.isRequired,
  }

  componentWillMount () {
    const { dispatch, fetch, fetchInternalContacts, params, forceUpdateAutosave } = this.props

    if (params.id) {
      dispatch(fetch(params.id))
    } else {
      dispatch(fetchInternalContacts())
    }

    const throttled = throttle(() => dispatch(forceUpdateAutosave()), 30000)
    // handle autosave
    const funcId = setInterval(() => {
      const rendered_component = this.props.rendered_component
      throttled()

      if (this.props.should_save[rendered_component] && !this.props.current[rendered_component].published) {
        const save = this.props.save[rendered_component]
        if (has(this.props.current[rendered_component], 'id')) {
          dispatch(save('update', this.props.current[rendered_component]))
        } else {
          dispatch(save('create', this.props.current[rendered_component]))
        }
      }
    }, this.props.auto_save_interval)
    this.setState({
      auto_save_handler: funcId,
    })
  }

  componentWillUnmount () {
    const { form_model, dispatch, resetForm, clearAutosave, invalidateData, rendered_component } = this.props

    dispatch(clearAutosave())
    dispatch(resetForm(form_model[rendered_component]))
    dispatch(invalidateData())

    // clear auto save interval handler
    clearInterval(this.state.auto_save_handler)
  }

  render () {
    const { children, params, rendered_component, hasFetched, hasEditRights } = this.props

    const current = this.props.current[rendered_component]
    const is_saving = this.props.is_saving[rendered_component]
    const last_saved = this.props.last_saved[rendered_component]

    const urlPart = params.id
      ? `edit/${params.id}`
      : 'create'

    // const lastEditedAt = current.updated_at
    //   ? <span>Sidst redigeret af [USER] den <DateTime source={ this.props.current.plan.updated_at } format="DD-MM-YYYY, H:mm:ss" />.<br /></span>
    //   : null
    const renderChildren = hasFetched
      ? children
      : null

    const section = rendered_component === 'plan'
      ? <div>
          <Sticky>
            {() => (
            <div className="row sub-header">
                <div className="action-bar plan-actions pull-right hidden-xs hidden-sm">
                <AutoSave is_saving={ is_saving } last_saved={ last_saved } />
                <NavLink to={ `/admin/plans/${urlPart}` } className="btn btn-primary" onlyActiveOnIndex>Opsætning</NavLink>&nbsp;
                <NavLink to={ `/admin/plans/${urlPart}/property` } className="btn btn-primary"><i className="fa fa-building-o"></i> Ejendommen</NavLink>&nbsp;
                <NavLink to={ `/admin/plans/${urlPart}/parts` } className="btn btn-primary"><i className="fa fa-bars"></i> Bygningsdele</NavLink>&nbsp;
                <NavLink to={ `/admin/plans/${urlPart}/distribution` } className="btn btn-primary"><i className="fa fa-tasks"></i> Fordelingsplan</NavLink>&nbsp;
                <NavLink to={ `/admin/plans/${urlPart}/files` } className="btn btn-primary"><i className="fa fa-file"></i> Filer</NavLink>&nbsp;
                <NavLink to={ `/admin/plans/${urlPart}/energy` } className="btn btn-primary"><i className="fa fa-lightbulb-o"></i> Energi</NavLink>
                </div>
                <div>
                <h3>Rediger Driftsplan</h3>
                <AutoSave is_saving={ is_saving } last_saved={ last_saved } className="hidden-md hidden-lg pull-right" />
                </div>
                <div>
                <p className="text-muted">Driftsplan: <NavLink className="plan" to={ `/c/${this.props.current.plan.id}` } target="_blank">{ this.props.current.plan.name }&nbsp;&nbsp;<i className="fa fa-external-link" aria-hidden="true"></i></NavLink></p>
                {
                    !hasEditRights
                    ? (
                        <p className="text-muted text-danger"><i className="fa fa-lock"></i> <small>Du har ikke adgang til at redigere denne driftsplan</small></p>
                    )
                    : null
                }
                </div>
                <div className="action-bar plan-actions hidden-md hidden-lg">
                <NavLink to={ `/admin/plans/${urlPart}` } className="btn btn-primary" onlyActiveOnIndex>Opsætning</NavLink>
                <NavLink to={ `/admin/plans/${urlPart}/property` } className="btn btn-primary"><i className="fa fa-building-o"></i> Ejendommen</NavLink>
                <NavLink to={ `/admin/plans/${urlPart}/parts` } className="btn btn-primary"><i className="fa fa-bars"></i> Bygningsdele</NavLink>
                <NavLink to={ `/admin/plans/${urlPart}/distribution` } className="btn btn-primary"><i className="fa fa-tasks"></i> Fordelingsplan</NavLink>
                <NavLink to={ `/admin/plans/${urlPart}/files` } className="btn btn-primary"><i className="fa fa-file"></i> Filer</NavLink>
                <NavLink to={ `/admin/plans/${urlPart}/energy` } className="btn btn-primary"><i className="fa fa-lightbulb-o"></i> Energi</NavLink>
                </div>
            </div>
          )}
          </Sticky>
          <div>
            { renderChildren }
          </div>
        </div>
      : <div className="row">
          { renderChildren }
        </div>
    return (
      <StickyContainer>

        { section }
      </StickyContainer>
    )
  }
}

const mapStateToProps = state => {
  const hasEditRights =  state.auth.role === 'admin' || (state.auth.role === 'editor' && state.data.plan.current.internal_contact_id === state.auth.userId) || !state.data.plan.current.internal_contact_id
  return {
    rendered_component: state.ui.rendered_component,
    current: {
      plan: state.data.plan.current,
      part: state.data.part.current,
    },
    should_save: {
      plan: state.data.plan.auto_save.should_save,
      part: state.data.part.auto_save.should_save,
    },
    is_saving: {
      plan: state.data.plan.auto_save.is_saving,
      part: state.data.part.auto_save.is_saving,
    },
    last_saved: {
      plan: state.data.plan.auto_save.last_saved,
      part: state.data.part.auto_save.last_saved,
    },
    auto_save_interval: AUTO_SAVE_INTERVAL,
    resetForm: formActions.reset,
    form_model: {
      plan: 'data.plan.current',
      part: 'data.part.current',
    },
    hasFetched: state.data.plan.hasFetched,
    hasEditRights,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    save: {
      plan: actions.savePlan,
      part: actions.savePart,
    },
    clearAutosave: actions.clearAutosave,
    fetch: actions.fetchPlan,
    fetchInternalContacts: actions.fetchInternalContacts,
    forceUpdateAutosave: actions.forceUpdateAutosave,
    invalidateData: actions.invalidateData,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlanHeader)
