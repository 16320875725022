import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import actions from './actions'
import classnames from 'classnames'

class Login extends Component {

  static propTypes = {
    hasError: PropTypes.bool.isRequired,
    errorType: PropTypes.number,
    errorMessage: PropTypes.string,
    location: PropTypes.object,
    dispatch: PropTypes.func.isRequired,
    login: PropTypes.func.isRequired,
    route: PropTypes.object.isRequired,
  }

  constructor (props) {
    super(props)
  }

  render () {
    const { hasError, errorType, errorMessage, route, auth } = this.props
    return (
        <section>
            <div className="container">
                <div className="row">
                    <div>
                      <div className="col-md-8 col-md-offset-2">
                        <div className="well login-container">
                          <h2 className="text-center mb-30">
                            Nyt adgangssystem
                          </h2>
                            <p className="lead">
                                Vi har sammen med resten af selskaberne i Cobblestone lanceret et nyt adgangssystem, som vi nu også bruger på Driftsplaner.dk  og som gør det nemmere at få adgang og samtidig gør at vi kan styre adgangen til systemet bedre.
                                <br/><br/>
                                Når du klikker på login bliver du sendt videre til en skærm, hvor du kan logge ind med Google eller Facebook eller alternativt en selvvalgt e-mail/kodeord kombination. Hvis vi allerede har tilknyttet din e-mail til en DV-plan vil du derefter blive sendt direkte videre.
                                <br/><br/>
                                Er din e-mail ikke sat op beder vi dig udfylde formularen på siden og angive hvilken DV-plan du bør have adgang til. Vi vil så validere din adgang hurtigst muligt og derefter give dig adgang.
                                <br/><br/>
                              Du kan også skrive til os på <a href="mailto: support@cobblestone.dk">support@cobblestone.dk</a>.
                            </p>
                            <div className="text-center">
                              <button onClick={() => auth.login()} className="btn btn-lg btn-primary">Log ind</button>
                            </div>
                        </div>
                      </div>
                      {
                        hasError
                          ? <div
                            className={classnames('alert', { 'alert-danger': errorType === 1 }, { 'alert-warning': errorType === 2 })}
                            role="alert"><i className="fa fa-exclamation-circle"></i> {errorMessage}</div>
                          : null
                      }
                    </div>
                </div>
            </div>
        </section>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching: state.auth.isFetching,
    hasError: state.auth.hasError,
    errorType: state.auth.errorType,
    errorMessage: state.auth.errorMessage,
    location: ownProps.location,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    login: actions.login,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login)
