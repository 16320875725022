import React from 'react'
import PropTypes from 'prop-types';
import classNames from 'classnames'
import Tooltip from '@material-ui/core/Tooltip';

export const Condition = ({ condition, hasText }) => {

  const attributes = condition === 1
    ? { text: 'Under middel', class: 'text-danger' }
    : condition === 2
      ? { text: 'Middel', class: 'text-warning' }
      : condition === 3
        ? { text: 'God', class: 'text-success' }
        : { }

  const icon = <i className={ `fa fa-lg fa-circle ${attributes.class}` }></i>

  const output = hasText
    ? (
      <h4>
        { icon }
        <span> { attributes.text }</span>
      </h4>
    )
    : <Tooltip title={attributes.text}>{icon}</Tooltip>

  const element = condition
    ? output
    : <span></span>

  return element

}
Condition.propTypes = {
  condition: PropTypes.number.isRequired,
  hasText: PropTypes.bool.isRequired,
}

export const ConditionLegend = ({ inline }) => {

  return (
    <ul className={ classNames('list-unstyled', { 'list-inline': inline }) }>
      <li><i className="fa fa-circle text-danger"></i> = Under middel</li>
      <li><i className="fa fa-circle text-warning"></i> = Middel</li>
      <li><i className="fa fa-circle text-success"></i> = God</li>
    </ul>
  )

}
ConditionLegend.propTypes = {
  inline: PropTypes.bool,
}
