import React from 'react'
import PropTypes from 'prop-types';
import DateTime from 'client/components/DateTime'
import classnames from 'classnames'
import moment from 'moment'

moment.locale('da')

const AutoSave = ({ is_saving, last_saved, className }) => {
  let innerText = ''
  if (is_saving) {
    innerText = (
      <span>
        Gemmer ...
      </span>
    )
  } else if (!isNaN(last_saved)) {
    innerText = (
      <span>
        Sidst gemt { moment.unix(last_saved).fromNow() }
      </span>
    )
  } else {
    innerText = null
  }
  return (
    <div className={ classnames('autosave', className) }>
      <span className={ classnames({ 'is-saving': is_saving }) }>
        { innerText }
      </span>
    </div>
  )
}
AutoSave.propTypes = {
  is_saving: PropTypes.bool.isRequired,
  className: PropTypes.string,
  last_saved: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
}

export default AutoSave


// : <DateTime format="HH:mm:ss" source={ last_saved }
