import React from 'react'
import { Route, IndexRoute } from 'react-router'
import requireAuth from 'client/components/AuthenticatedComponent'

import PortfolioWrapper from 'client/routes/Portfolios/index'
import PortfolioPage from 'client/routes/Portfolios/Portfolio'

const PortfolioRoutes = (
  <Route path="p/:id" component={ requireAuth(PortfolioWrapper) }>
    <IndexRoute component={ PortfolioPage } title="Portefølje - Plan1" />
    <Route path="print" component={ PortfolioPage } title="Portefølje - Plan1" print />
  </Route>
)

export default PortfolioRoutes
