import React, { Component } from 'react'
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import MailIcon from '@material-ui/icons/Mail';
import PhoneIcon from '@material-ui/icons/Phone';
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import {styles} from 'common/lib/styles';

class Contact extends Component {

  render () {
    const {classes, title, name, email, phone, src, variant} = this.props;
    return (
        <Paper className={this.props.paperClasses}>
            <Grid container spacing={3}>
                <Grid item md={9}>
                <Typography className={classes.header} variant={variant}>{title}</Typography>
                <Typography paragraph>
                    { name }<br/>
                    {email ? <a className={classes.iconlink} href={`mailto:${email}`}><MailIcon/>{email}</a> : null }
                    {phone ? <a className={classes.iconlink} href={`tel:${phone}`}><PhoneIcon/>{phone}</a> : null }
                </Typography>
                </Grid>
                <Grid item md={3} className={classes.avatarGrid}>
                    <Avatar className={classes.avatar} src={src}/>
                </Grid>
            </Grid>
        </Paper>
    )
  }
}


const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = dispatch => ({
  dispatch
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Contact))
