export const PartSchema = {
  base: {
    properties: {
      id: {
        type: 'integer',
        allowNull: false,
      },
      plan_id: {
        type: 'integer',
        allowNull: false,
      },
      bips_id: {
        type: 'integer',
        allowNull: false,
      },
      title: {
        type: 'string',
      },
      published: {
        type: 'boolean',
      },
      internal_note: {
        type: 'string',
      },
      specification: {
        type: 'string',
      },
      condition_text: {
        type: 'string',
        allowNull: true
      },
      integrated_year: {
        type: 'integer',
        minValue: 0,
        allowEmpty: '',
        allowNull: true,
      },
      remaining_lifetime: {
        type: 'integer',
        minValue: 0,
        allowEmpty: '',
        allowNull: true,
      },
      amount: {
        type: 'string',
      },
      condition: {
        type: 'integer',
        allowEmpty: '',
        allowNull: true,
      },
      establishment: {
        type: 'string',
      },
      establishment_cost: {
        type: 'integer',
        minValue: 0,
        allowEmpty: '',
        allowNull: true,
      },
      establishment_cost_note: {
        type: 'string',
      },
      maintenance: {
        type: 'string',
      },
      maintenance_interval: {
        type: 'integer',
        allowEmpty: '',
        allowNull: true,
      },
      maintenance_start: {
        type: 'integer',
        allowEmpty: '',
        allowNull: true,
      },
      maintenance_cost: {
        type: 'integer',
        minValue: 0,
        allowEmpty: '',
        allowNull: true,
      },
      maintenance_cost_note: {
        type: 'string',
      },
      scaffolding: {
        type: 'boolean',
      },
      construction_site: {
        type: 'boolean',
      },
      adviser: {
        type: 'boolean',
      },
      building_management: {
        type: 'boolean',
      },
      inspection: {
        type: 'string',
      },
      inspection_interval: {
        type: 'integer',
        allowEmpty: '',
        allowNull: true,
      },
      inspection_start: {
        type: 'integer',
        allowEmpty: '',
        allowNull: true,
      },
      establishment_year: {
        type: 'integer',
        allowEmpty: '',
        allowNull: true,
      },
      prioritized: {
        type: 'boolean',
      },
    },
  },
  extensions: {
    update: {
      properties: {
        id: {
          required: true,
        },
      },
    },
    publish: {
      properties: {
        id: {
          required: true,
        },
        plan_id: {
          required: true,
        },
        bips_id: {
          required: true,
        },
        title: {
          required: true,
        },
        published: {
          required: true,
        },
        specification: {
          required: true,
        },
        condition_text: {
          required: true,
        },
        integrated_year: {
          required: true,
          allowNull: false,
        },
        remaining_lifetime: {
          required: true,
          allowNull: false,
        },
        amount: {
          required: true,
        },
        condition: {
          required: true,
          allowNull: false,
        },
        maintenance: {
          required: true,
        },
        maintenance_interval: {
          required: true,
          allowNull: false,
        },
        maintenance_start: {
          required: true,
          allowNull: false,
        },
        maintenance_cost: {
          required: true,
          allowNull: false,
        },
        scaffolding: {
          required: true,
        },
        construction_site: {
          required: true,
        },
        adviser: {
          required: true,
        },
        building_management: {
          required: true,
        },
        inspection: {
          required: true,
        },
        inspection_interval: {
          required: true,
          allowNull: false,
        },
        inspection_start: {
          required: true,
          allowNull: false,
        },
      },
    },
  },
}

export const PartImageSchema = {
  base: {
    properties: {
      part_id: {
        type: 'integer',
        required: true,
      },
      s3_image_id: {
        type: 'string',
        required: true,
      },
    },
  },
}
