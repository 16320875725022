import fetch from 'isomorphic-fetch'
import { push } from 'react-router-redux'
import { jwtDecode } from "jwt-decode"
import { actions as FormActions } from 'react-redux-form'
import { ActionTypes, API_URI } from 'client/constants'
import { notifyDanger } from 'client/components/Notifications/actions'
import validate, { ValidationError } from 'common/lib/validator'
import { PlanSchema } from 'common/schemas'
import { startsWith, toLower } from 'lodash'
import api, { ApiError } from 'client/lib/apiHandler'

const loginRequest = () => {
  return {
    type: ActionTypes.LOGIN_REQUEST,
  }
}

const loginSuccess = (token) => {
  return {
    type: ActionTypes.LOGIN_SUCCESS,
    token,
  }
}

const setPlans = (plans) => {
  return {
    type: ActionTypes.SET_PLANS,
    plans
  }
}

const loginFailure = (errorType, errorMessage) => {
  return {
    type: ActionTypes.LOGIN_FAILURE,
    errorType,
    errorMessage,
  }
}

export const login = (accessToken, idToken, nextUrl = '/') => {
  // XXX do we want to do some validation on user and pass beforehand?
  const body = { accessToken: accessToken, idToken: idToken }

  return dispatch => {
    dispatch(loginRequest())

    api.login('/auth/login', body)
    .then(({ body }) => {
      // If email is not verified
      if (body.emailNotVerified !== undefined && body.emailNotVerified === false) {
        window.location.replace('https://cloud.cobblestone.dk/not-verified?sub=' + body.sub);
      }

      // we have succesfully logged in
      localStorage.setItem('token', body.token);
      // sessionStorage.setItem('availableAssets', body.availableAssets.result);
      // sessionStorage.setItem('availableCustomers', body.availableCustomers.result)

      const decoded = jwtDecode(body.token);

      if (!decoded.admin && !decoded.published && decoded.length === 1) {
        if (decoded.plan_id) {
          dispatch(loginFailure(2, 'Denne plan er ikke tilgængelig'))
        } else {
          dispatch(loginFailure(2, 'Dette portfolio er ikke tilgængeligt'))
        }
        dispatch(push('/no-access'));

      } else {
        dispatch(loginSuccess(decoded))

        let defaultUrl = ''
        if (decoded.admin) {
          defaultUrl = '/admin'
        } else {
          let id = null
          if (decoded.type === 'plan' && decoded.data.length > 1) {
            defaultUrl = '/plans';
            dispatch(setPlans(decoded.data))
          }
          if (decoded.id) { //Check if the login is concerning an admin or a plan or portfolio user
            id = decoded.id
          } else if (decoded.plan_id) {
            id = decoded.plan_id
            defaultUrl = `/c/${id}`
          } else if (decoded.portfolio_id) {
            id = decoded.portfolio_id
            defaultUrl = `/p/${id}`
          }
        }

        /*const defaultUrl = decoded.admin
          ? '/admin'
          : `/c/${decoded.id}`*/
        const wantsAdmin = startsWith(nextUrl, '/admin')
        const pushToAdmin = wantsAdmin && decoded.admin
        dispatch(push(
          pushToAdmin
            ? nextUrl
            : defaultUrl
        ))

      }
    })
    .catch(error => {
      if (error instanceof ApiError) {
        // Must be here to prevent a loop of API requests
        localStorage.removeItem('expires_at');
        dispatch(push('/no-access'));
      }
    })
  }
}

export const logout = () => {
  return dispatch => {
    localStorage.removeItem('token')
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('identity');
    dispatch({ type: ActionTypes.LOGOUT })
    dispatch(push('/'))
  }
}

export const redirectFrontPage = () => {
  return dispatch => {
    dispatch(push('/'))
  }
}

export const checkAdmin = () => {
  const token = localStorage.getItem('token')
  let isAdmin = false
  if (token) {
    const decoded = jwtDecode(token)
    isAdmin = decoded.admin || false
  }
  return { type: ActionTypes.CHECK_ADMIN_AUTH, isAdmin }
}

export default {
  login,
  logout,
  setPlans,
  checkAdmin,
  redirectFrontPage,
}
