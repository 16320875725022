import React from 'react'
import PropTypes from 'prop-types';
import { Link } from 'react-router'

const PrintButton = ({ component, customer_id, param = '' }) => {

  const url = `/c/${customer_id}/print/${component}/${param}`

  return (
    <div className="col-xs-5">
      <div className="well">
        <h4 className="pull-right"><i className="fa fa-print"></i><Link to={ url } target="_blank"> Print</Link></h4>
      </div>
    </div>
)

}
PrintButton.propTypes = {
  component: PropTypes.string.isRequired,
  customer_id: PropTypes.string.isRequired,
  param: PropTypes.number,
}

export default PrintButton
