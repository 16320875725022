import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import moment from 'moment'
import classnames from 'classnames'
import actions from 'client/actions'
import Dropzone from 'react-dropzone'
import { Line, Circle } from 'rc-progress';
import S3FileUploader from 'client/components/S3FileUploader'
import Promise from 'bluebird';
import _ from 'lodash'
import { API_URI } from 'client/constants'
import { notifyDanger, notifySuccess, clearNotifications } from 'client/components/Notifications/actions'

class Files extends Component {

  static propTypes = {
    current: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    setRenderedComponent: PropTypes.func.isRequired,
    saveFile: PropTypes.func.isRequired,
    deleteFile: PropTypes.func.isRequired,
    updateFile: PropTypes.func.isRequired,
    files: PropTypes.object.isRequired,
    hasEditRights: PropTypes.bool.isRequired,
  }

  constructor (props) {
    super(props)
    this.onDrop = this.onDrop.bind(this)
    this.onDropRejected = this.onDropRejected.bind(this)
    this.renderUploaders = this.renderUploaders.bind(this)
    this.renderFiles = this.renderFiles.bind(this)
    this.state = {
      uploaders: [],
      uploading: false,
      filesCount: null,
      filesDone: 0,
      totalPercent: null,
    }
  }


  componentWillMount () {
    const { dispatch, setRenderedComponent } = this.props
    dispatch(setRenderedComponent('plan'))
  }

  acceptsType (type) {
    return _.includes(['image/jpeg', 'image/png', 'application/pdf'], type)
  }

  calculateTotalPercent (percent) {
    const filesCount = this.state.filesCount
    const filesDone = this.state.filesDone
    const totalPercent = (((filesDone * 100) + percent) / (filesCount * 100)) * 100
    this.setState({
      ...this.state,
      totalPercent,
    })
  }

  onDropRejected (file) {
    const { dispatch } = this.props
    dispatch(notifyDanger('Fil må kun være af type PDF, jpg, eller png, og fylde maks 20MB', ''))
  }

  onDrop(files) {
    const { dispatch, current, hasEditRights } = this.props
    dispatch(clearNotifications())
    if (this.state.uploading) {
      return
    }
    if (!hasEditRights) {
      dispatch(notifyDanger('Du har ikke adgang til at uploade filer', ''))
      return
    }
    let existingUploaders = this.state.uploaders;
    let existingUploadersByName = _.keyBy(existingUploaders, uploader => uploader.getFilename());
    let uploaders = _.map(files, (file) => {
        let filename = file.name;
        if (existingUploadersByName[filename] || !this.acceptsType(file.type) || file.size > 20971520) {
            return null;
        }

        let uploader = new S3FileUploader(file, {
          signingUrl: "/s3/sign",
          server: API_URI,
        });

        this.setState({
          ...this.state,
          [`progress-${filename}`]: {
              percent: 0,
          }
        })

        uploader.on('progress', (percent) => {
            this.calculateTotalPercent(percent)
            this.setState({
                [`progress-${filename}`]: {
                    percent: percent,
                }
            });
        });

        uploader.on('complete', (result, file_name, mimetype) => {
          const { saveFile, current } = this.props
          dispatch(saveFile(current.id, result.filename, mimetype, file_name))
          this.setState({
              ...this.state,
              filesDone: this.state.filesDone + 1,
              [`progress-${filename}`]: {
                  isComplete: true,
              }
          });
          this.calculateTotalPercent(0)
        });

        uploader.on('error', (status) => {
            this.setState({
                hasFailedUpload: true,
                uploading: false,
                [`progress-${filename}`]: {
                    failed: true,
                }
            });
        });

        return uploader;
    });

    uploaders = _.compact(uploaders);
    // if (!this.props.allowMultiple && uploaders.length) {
    //     _.each(existingUploaders, up => this.removeUpload(up));
    //     existingUploaders = [];
    // }

    this.setState({
        ...this.state,
        uploaders: existingUploaders.concat(uploaders),
        filesCount: files.length,
        filesDone: 0,
        totalPercent: 0,
        uploading: true,
        hasFailedUpload: false,
    });

    return Promise.resolve()
        .then(() => {
            return Promise.each(uploaders, uploader => uploader.start());
        })
        .finally(() => {
          this.setState({
              ...this.state,
              uploading: false,
          });
          if (uploaders.length > 0 && !this.state.hasFailedUpload) {
            dispatch(notifySuccess(`${files.length} ${files.length > 1 ? 'filer' : 'fil' } uploaded`))
          }
        })
        .catch(e => {
          dispatch(notifyDanger('Der skete en fejl', ''))
        })
  }

  deleteUpload (id, uploader) {
    return ev => {
      ev.preventDefault()
      const { dispatch, deleteFile, current } = this.props
      const confirmation = window.confirm('Er du sikker?')
      if (confirmation) {
        dispatch(deleteFile(current.id, id))
        if (uploader) {
          const uploaders = this.state.uploaders
           _.remove(uploaders, uploader)
          this.setState({
            ...this.state,
            uploaders,
          })
        }
      }
    }
  }

  updateUpload (file, uploader) {
    return ev => {
      ev.preventDefault()
      const { dispatch, updateFile, current } = this.props
      dispatch(updateFile(current.id, file || uploader))
      if (uploader) {
        uploader.category = uploader.category == 'Bilag' ? 'Tegninger' : 'Bilag'
      }
    }
  }

  renderUploaders (type) {
    const { current, files, hasEditRights } = this.props
    const uploaders = _.reduce(this.state.uploaders, (acc, uploader) => {
      const category = uploader.category
      acc[category] = acc[category] || []
      acc[category] = _.concat(acc[category], uploader)
      return acc
    }, {})

    if (!uploaders[type]) {
      return null
    }

    return (
      uploaders[type].map((uploader, idx) => {
      const filename = uploader.getFilename()
      const progress = this.state[`progress-${filename}`]
      const s3Id = uploader.getS3Id()
      const planFiles = current.files
      const planFile = _.find(planFiles, { s3_file_id: s3Id })
      const id = planFile && planFile.id
      uploader.id = id
      if (progress && progress.isComplete) {
        const actions = !hasEditRights
          ? null
          : (
            <p>
              <a href="#" onClick={ this.deleteUpload(id, uploader) }><i className="fa fa-times"></i> Slet</a> <br/>
              <a href="#" onClick={ this.updateUpload(null, uploader) }><i className="fa fa-arrow-right"></i> Flyt til { uploader.category == 'Bilag' ? 'Tegninger' : 'Bilag'  }</a>
            </p>
          )
        return (
          <div className="col-md-3 col-sm-4" key={ idx }>
            <div className="well-sm">
              <div className="hovereffect uploader">
                {uploader.getFileType() == 'application/pdf' ? <div className="pdf-responsive"/> : <img className="img-responsive" src={ `https://ik.imagekit.io/aw3ddey5g/${encodeURIComponent(s3Id)}?tr=w-420,h-297` } alt="" />}
                <div className="overlay">
                  <i className={ classnames('fa file-icon', { 'fa-file-pdf-o': uploader.getFileType() == 'application/pdf', 'fa-file-image-o': uploader.getFileType() != 'application/pdf'  }) }></i>
                  <p>
                    <a href={ `https://ik.imagekit.io/aw3ddey5g/${encodeURIComponent(s3Id)}` } download><i className="fa fa-download"></i> Download</a> <br/>
                    { actions }
                  </p>
                </div>
              </div>
              <p className="file-title">{ uploader.getFilename() }</p>
            </div>
          </div>
        )
      }

      if (progress) {
        const percent = progress.failed
          ? <p className="file-progress">Fejl</p>
          : <p className="file-progress">{ progress.percent } %</p>
        const preview = uploader.getFileType() == 'application/pdf'
          ? <embed className="img-responsive" src={ uploader.getPreview() } width="420" height="297" type="application/pdf" />
          : <img className="img-responsive" src={ uploader.getPreview() } alt="" />
        return (
          <div className="col-md-3 col-sm-4" key={ idx }>
            <div className="well-sm">
              <div className="uploading">
                { preview }
                <div className="overlay">
                  <Circle percent={progress.percent} strokeWidth={ 4 } trailWidth={ 3 }/>
                  { percent }
                </div>
              </div>
              <p className="file-title">{ uploader.getFilename() }</p>
              {/* <p className="file-title">{ progress.percent } %</p> */}
            </div>
          </div>
        )
      }
      })
    )
  }

  renderFiles (type) {
    const files = this.props.files[type]
    const { current, hasEditRights } = this.props

    if (!files) {
      return null
    }

    return (
      files.map((file, idx) => {
        const existingUploaders = this.state.uploaders;
        const existingUploadersByName = _.keyBy(existingUploaders, uploader => uploader.getS3Id());
        if (existingUploadersByName[file.s3_file_id]) {
            return null;
        }
        const actions = !hasEditRights
          ? null
          : (
            <p>
              <a href="#" onClick={ this.deleteUpload(file.id) }><i className="fa fa-times"></i> Slet</a> <br/>
              <a href="#" onClick={ this.updateUpload(file) }><i className="fa fa-arrow-right"></i> Flyt til { file.category == 'Bilag' ? 'Tegniner' : 'Bilag'  }</a>
            </p>
          )
        return (
          <div className="col-md-3 col-sm-4" key={ idx }>
            <div className="well-sm">
              <div className="hovereffect">
                {file.mimetype == 'application/pdf' ? <div className="pdf-responsive"/> : <img className="img-responsive" src={ `https://ik.imagekit.io/aw3ddey5g/${encodeURIComponent(file.s3_file_id)}?tr=w-420,h-297` } alt="" />}
                <div className="overlay">
                  <i className={ classnames('fa file-icon', { 'fa-file-pdf-o': file.mimetype == 'application/pdf', 'fa-file-image-o': file.mimetype != 'application/pdf'  }) }></i>
                  <p>
                    <a href={ `https://ik.imagekit.io/aw3ddey5g/${encodeURIComponent(file.s3_file_id)}` } download><i className="fa fa-download"></i> Download</a> <br/>
                    { actions }
                  </p>
                </div>
              </div>
              <p className="file-title">{ file.file_name }</p>
            </div>
          </div>
        )
      })
    )

  }

  render () {
    const { uploading, totalPercent, filesCount, filesDone, hasEditRights } = this.state
    const { current } = this.props

    const totalUploadProgress = uploading
      ? (
        <div>
          <Line percent={totalPercent} strokeWidth={ 2 } trailWidth={ 2 }/>
          <p>Uploader { filesDone + 1 } / { filesCount } { filesCount > 1 ? 'filer' : 'fil'}</p>
        </div>
      )
      : null

    return (
      <div>
        <h2 className="text-center">
          Filer
        </h2>
        <hr />
        <Dropzone
          className="dropzone"
          activeClassName="dropzoneActive"
          rejectClassName="label-danger dropzoneReject"
          onDrop={ this.onDrop }
          maxSize={ 20971520 }
          onDropRejected={ this.onDropRejected }
          accept="image/jpeg, image/png, application/pdf"
          disableClick={ !hasEditRights }
          >
          Klik her for at uploade en fil. Det er også muligt at trække og slippe filer. <br/>
          Det er kun muligt at uploade filer af typerne: PDF, jpg, png. Filerne kan være max. 20 mb pr stk.
          <div className="row">
            <div className="col-md-8 col-md-offset-2">
            { totalUploadProgress }
            </div>
          </div>
        </Dropzone>
        <h3>Bilag</h3>
        <div className="row image-gallery admin">
          { this.renderFiles('Bilag') }
          { this.renderUploaders('Bilag') }
        </div>
        <h3>Tegninger</h3>
        <div className="row image-gallery admin">
          { this.renderFiles('Tegninger') }
          { this.renderUploaders('Tegninger') }
        </div>
      </div>
    )
  }
}


const mapStateToProps = state => {
  const hasEditRights =  state.auth.role === 'admin' || (state.auth.role === 'editor' && state.data.plan.current.internal_contact_id === state.auth.userId) || !state.data.plan.current.internal_contact_id
  const files = state.data.plan.current.files
  const mappedFiles = _.reduce(files, (acc, file) => {
    const category = file.category
    acc[category] = acc[category] || []
    acc[category] = _.concat(acc[category], file)
    return acc
  }, {})
  return {
    current: state.data.plan.current,
    files: mappedFiles,
    hasEditRights,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setRenderedComponent: actions.setRenderedComponent,
    saveFile: actions.saveFileUpload,
    deleteFile: actions.deleteFileUpload,
    updateFile: actions.updateFileUpload,
    dispatch,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Files)
