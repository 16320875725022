import React from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import classNames from 'classnames'
import { ActionTypes } from 'client/constants'

export const actions = {
  show: () => ({
    type: ActionTypes.SHOW_SPINNER,
  }),

  hide: () => ({
    type: ActionTypes.HIDE_SPINNER,
  }),
}

const Spinner = ({
  isWorking,
}) => {
  return (
    <div>
      <div id="spinnerOverlay" className={ classNames('hideSpinner', { showSpinner: isWorking === true }) }>
        <i className="fa fa-cog fa-spin fa-5x"></i>
      </div>
    </div>
  )
}
Spinner.propTypes = {
  isWorking: PropTypes.bool.isRequired,
}

const mapStateToProps = (state) => {
  return {
    isWorking: state.ui.isWorking,
  }
}
export default connect(
  mapStateToProps
)(Spinner)
