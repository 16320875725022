import React from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { push as routerPush } from 'react-router-redux'
import actions from 'client/actions'
function requireAuth (Component) {

  class AuthenticatedComponent extends React.Component {

    static propTypes = {
      isAuthenticated: PropTypes.bool.isRequired,
      nextRoute: PropTypes.string.isRequired,
      dispatch: PropTypes.func.isRequired,
      push: PropTypes.func.isRequired,
      checkAdmin: PropTypes.func.isRequired,
    }

    constructor (props) {
      super(props)
      this.checkAuth = this.checkAuth.bind(this)
    }

    componentDidMount () {
      const { checkAdmin, dispatch } = this.props
      dispatch(checkAdmin())
      this.checkAuth()
    }

    componentWillReceiveProps (nextProps) {
      this.checkAuth()
    }

    checkAuth () {
      const { isAuthenticated, nextRoute, dispatch, push } = this.props
      if (!isAuthenticated) {
        dispatch(push(`/login?next=${nextRoute}`))
      }
    }

    render () {
      const { isAuthenticated } = this.props
      return (
        <div>
          {
            isAuthenticated
              ? <Component { ...this.props } />
              : null
          }
        </div>
      )
    }

  }

  const mapStateToProps = (state, ownProps) => {
    return {
      isAuthenticated: state.auth.isAuthenticated,
      nextRoute: `${ownProps.location.pathname}`,
    }
  }

  const mapDispatchToProps = dispatch => {
    return {
      dispatch,
      push: routerPush,
      checkAdmin: actions.checkAdmin,
    }
  }

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(AuthenticatedComponent)
}

export default requireAuth
