import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'
import { ActionTypes } from 'client/constants'

import planReducers from 'client/routes/Admin/Plans/reducers'
import partTemplateReducers from 'client/routes/Admin/PartTemplates/reducers'
import partReducers from 'client/routes/Admin/Plans/Parts/reducers'
import projectReducers from 'client/routes/Admin/Plans/Projects/reducers'
import portfolioReducers from 'client/routes/Admin/Portfolios/reducers'
import portfolioFrontendReducers from 'client/routes/Portfolios/reducers'
import estimateReducers from 'client/routes/Admin/Plans/Estimates/reducers'
import systemTextsReducer from 'client/routes/Admin/SystemTexts/reducers'
import notificationReducers from 'client/components/Notifications/reducers'
import authReducer from 'client/routes/Auth/reducers'
import userReducer from 'client/routes/Admin/Users/reducers'

import customerReducers from 'client/routes/Customer/reducers'

import moment from 'moment'
import faqReducer from './routes/Admin/Faq/reducers'

const spinnerReducer = (state = false, action) => {
  switch (action.type) {
    case ActionTypes.SHOW_SPINNER:
      return true
    case ActionTypes.HIDE_SPINNER:
      return false
    case (ActionTypes.SEND_REQUEST) :
      if (action.request === 'fetch') {
        return true
      }
      return state
    case (ActionTypes.RECEIVE_REQUEST) :
      if (action.request === 'fetch' || action.subtype === 'error') {
        return false
      }
      return state
    case (ActionTypes.SUBMIT_GENERATOR) :
      return true
    case (ActionTypes.RECEIVE_GENERATOR) :
      return false
    default:
      return state
  }
}

const componentReducer = (state = 'plan', action) => {
  switch (action.type) {
    case (ActionTypes.SET_RENDERED_COMPONENT) :
      if (action.subtype === 'plan') {
        return 'plan'
      } else if (action.subtype === 'part') {
        return 'part'
      }
      return state
    default:
      return state
  }
}

const yearsReducer = (state = {
  start_year: null,
  years: [],
}, action) => {
  switch (action.type) {
    case (ActionTypes.RECEIVE_REQUEST) :
      if (action.subtype === 'plan') {
        const start_year = action.payload.start_year || moment().year()
        let years = []
        let yr = moment().year(start_year).subtract(1, 'y')
        for (let i = 0; i < 15; i++) {
          yr = yr.add(1, 'y')
          years = years.concat(yr.year())
        }
        return {
          start_year,
          years,
        }
      }
      return state
    case (ActionTypes.RECEIVE_PLAN_FOR_CUSTOMER) : {
      const start_year = action.payload.plan.start_year || moment().year()
      let years = []
      let yr = moment().year(start_year).subtract(1, 'y')
      for (let i = 0; i < 15; i++) {
        yr = yr.add(1, 'y')
        years = years.concat(yr.year())
      }
      return {
        start_year,
        years,
      }
    }
    case (ActionTypes.RECEIVE_PORTFOLIO) : {
      const start_year = action.payload.totals.startYear
      let years = []
      let yr = moment().year(start_year).subtract(1, 'y')
      for (let i = 0; i < 15; i++) {
        yr = yr.add(1, 'y')
        years = years.concat(yr.year())
      }
      return {
        start_year,
        years,
      }
    }
    case ActionTypes.INVALIDATE_DATA:
      return {
        start_year: null,
        years: [],
      }
    default:
      return state
  }
}

const breadcrumbReducer = (state = 'Start', action) => {
  
  switch (action.type) {
    case ActionTypes.SET_BREADCRUMB:
      return action.payload
    default:
      return state
  }
}

const data = combineReducers({
  plan: planReducers,
  portfolio: portfolioReducers,
  part_template: partTemplateReducers,
  part: partReducers,
  project: projectReducers,
  estimates: estimateReducers,
  users: userReducer,
  customer: customerReducers,
  systemTexts: systemTextsReducer,
  faq: faqReducer,
  years: yearsReducer,
  portfolioFrontend: portfolioFrontendReducers,
})

const ui = combineReducers({
  isWorking: spinnerReducer,
  rendered_component: componentReducer,
  breadcrumb: breadcrumbReducer,
  ...notificationReducers,
})

export default combineReducers({
  routing: routerReducer,
  auth: authReducer,
  ui,
  data,
})
