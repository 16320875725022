import React, {Component} from "react";
import PropTypes from 'prop-types';

class PlanPurchasedInspectionLabel extends Component {
    /**
     * @returns {string}
     */
    color = () => {
        const { purchased_inspection } = this.props;

        switch (purchased_inspection) {
            case 0:
                return "danger"
            case 1:
                return "success"
            case 2:
                return "warning"
            default:
                return "info"
        }
    };

    /**
     * @returns {string}
     */
    labelClass = () => {
        const { purchased_inspection } = this.props;

        return purchased_inspection === 1 ? "" : "opacity-0";
    };

    render() {
        const { inspection_date } = this.props;

        return (
            <span className={`label label-${this.color()} purchased-inspection-label w-100`}><span className={this.labelClass()}>{inspection_date}</span></span>
        );
    }
}

PlanPurchasedInspectionLabel.propTypes = {
    purchased_inspection: PropTypes.number.isRequired,
    inspection_date: PropTypes.number.isRequired,
};

export default PlanPurchasedInspectionLabel;