import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Link } from 'react-router'
import { connect } from 'react-redux'
import moment from 'moment'
import numeral from 'common/lib/numeral'
import classNames from 'classnames'
import { first, last, map, filter, has, keyBy, find, reduce, forEach, sortBy } from 'lodash'
import { push } from 'react-router-redux'
import actions from 'client/actions'
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import {styles} from 'common/lib/styles';
import { Typography } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Select from "react-select";
import PartDialog from 'client/components/PartDialog';

function calculateYearInterval (inspection_start, interval) {
  let years = [inspection_start] // eslint-disable-line prefer-const
  for (let i = 1; i < 15; i++) {
    if (i % interval === 0) {
      years.push(inspection_start + i)
    }
  }
  return years
}

const InspectionRow = ({ part, showPartHandler, classes }) => {
  const { id, title } = part

  return (
    <TableRow className={classes.cursor} onClick={ showPartHandler(part.id, 'inspection') }>
      <TableCell className={classes.underline}>{ part.title }</TableCell>
      {/* <TableCell align="right">{ part.amount }</TableCell> */}
    </TableRow>
  )
}
InspectionRow.propTypes = {
  part: PropTypes.object.isRequired,
  showPartHandler: PropTypes.func.isRequired,
}

const MaintenanceRow = ({ part, estimates: { by_id, by_year }, years, customer_id, current_year, showPartHandler, classes }) => {
  const { id, title } = part

  const currentYear = by_year[current_year]
  const currentEstimate = currentYear[id]
  return (
    <TableRow className={classes.cursor} onClick={ showPartHandler(part.id, 'maintenance') }>
      <TableCell className={classes.underline}>{ part.title }</TableCell>
      {/* <TableCell>{ part.amount }</TableCell> */}
      <TableCell align="right">{ numeral(by_id[currentEstimate].estimate * 1000).format('0,0') }</TableCell>
    </TableRow>
  )
}
MaintenanceRow.propTypes = {
  part: PropTypes.object.isRequired,
  estimates: PropTypes.object.isRequired,
  years: PropTypes.array.isRequired,
  customer_id: PropTypes.string.isRequired,
  current_year: PropTypes.number.isRequired,
  showPartHandler: PropTypes.func.isRequired,
}

const ExtraordinaryRow = ({ part, showPartHandler }) => {
  const { id, title } = part

  return (
    <tr>
      <td><a onClick={ showPartHandler(part.id, 'maintenance') }>{ part.title }</a></td>
      <td className="text-warning">{ numeral(part.establishment_cost).format('0,0') }</td>
    </tr>
  )
}
ExtraordinaryRow.propTypes = {
  part: PropTypes.object.isRequired,
  showPartHandler: PropTypes.func.isRequired,
}

class ActivityPlan extends Component {

  static propTypes = {
    current_year: PropTypes.number.isRequired,
    years: PropTypes.array.isRequired,
    params: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    estimates: PropTypes.object.isRequired,
    maintenance_parts: PropTypes.array.isRequired,
    inspection_parts_by_year: PropTypes.object.isRequired,
    parts: PropTypes.object.isRequired,
    plan: PropTypes.object.isRequired,
  }

  constructor (props) {
    super(props)
    this.onYearChange = this.onYearChange.bind(this)
    this.toggleModal = this.toggleModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.handleYearClick = this.handleYearClick.bind(this)
    this.state = {
      currentPartId: null,
      currentModalType: '',
      modalVisible: false,
      isYearOpen: false,
    }
  }

  componentDidMount() {
      const { dispatch, setBreadcrumb, params, current_year } = this.props
      dispatch(setBreadcrumb('Overblik - år ' + (params.activity_year ? params.activity_year : current_year)));
  }

  onYearChange (selected) {
    const { dispatch, params, setBreadcrumb } = this.props
    this.setState({ isYearOpen: false })
    dispatch(setBreadcrumb('Overblik - år ' + selected.value))
    dispatch(push(`/c/${params.customer_id}/activity-plan/${selected.value}`))
  }

  handleYearClick (e) {
      e.preventDefault();
      this.setState({isYearOpen: !this.state.isYearOpen})
  }

  toggleModal (part_id, type) {
    return e => {
      this.setState({
        ...this.state,
        modalVisible: !this.state.modalVisible,
        currentPartId: part_id,
        currentModalType: type,
      })
    }
  }

  closeModal () {
    this.setState({
      ...this.state,
      modalVisible: !this.state.modalVisible,
    })
  }

  render () {
    const {
      current_year, years, params: { customer_id },
      estimates, maintenance_parts, inspection_parts_by_year,
      parts, plan, extraordinary_parts, classes
    } = this.props

    const part = parts[this.state.currentPartId]
    const type = this.state.currentModalType

    const { modalVisible, currentPartId } = this.state

    const maintenanceRows = map(maintenance_parts, (part, idx) => <MaintenanceRow key={ idx } part={ part } years={ years } customer_id={ customer_id } current_year={ current_year } estimates={ estimates } showPartHandler={ this.toggleModal }  classes={classes} />)
    const maintenanceHeaders = [
      'Bygningsdel',
      //'Mængde',
      'Omkostninger',
    ]
    const extraordinaryHeaders = [
      'Bygningsdel', 'Omkostninger',
    ]

    const next_year_link = current_year + 1 <= last(years)
      ? <Link to={ `/c/${customer_id}/activity-plan/${current_year + 1}` } className="pull-right no-select">Frem <i className="fa fa-angle-double-right"></i></Link>
      : null
    const previous_year_link = current_year - 1 >= first(years)
      ? <Link to={ `/c/${customer_id}/activity-plan/${current_year - 1}` } className="pull-right no-select"><i className="fa fa-angle-double-left"></i> Tilbage</Link>
      : null

    const inspectionHeaders = [
      'Bygningsdel',
      //'Mængde',
    ]
    const current_inspection = inspection_parts_by_year[current_year]
    const inspectionRows = map(current_inspection, (part_id, idx) => <InspectionRow key={ idx } part={ parts[part_id] } showPartHandler={ this.toggleModal } classes={classes} />)

    const extraordinaryRows = map(extraordinary_parts, (part, idx) => <ExtraordinaryRow key={ idx } part={ part } showPartHandler={ this.toggleModal }  classes={classes} />)

    const subtotal = estimates.totals.by_year[current_year]
    const scaffolding_construction_total = numeral(estimates.totals.by_year_for_sc[current_year] * 1000).format('0,0') || 0
    const adviser_building_management_total = numeral(estimates.totals.by_year_for_ab[current_year] * 1000).format('0,0') || 0
    const extraordinary_total = estimates.totals.extraordinary_by_year[current_year] || 0
    const total = subtotal + extraordinary_total

    return (
      <div>
        <Grid container spacing={3}>
            <Grid item sm={12} md={12}>
                <div className={classes.chartHeader}>
                <Typography className={classes.header} variant="h5">Projektoversigt</Typography>
                    <div className={classes.chartYear}>
                        {this.state.isYearOpen ? <div className="form-inline text-center">
                        <div className="form-group">
                            <Select
                                className={classes.select}
                                onChange={this.onYearChange}
                                noOptionsMessage={() =>"Ingen resultat"}
                                placeholder="Vælg"
                                options={ years.map(year => (({ value: year, label: year }))) }
                            />
                        </div>
                    </div> : <h2>{ current_year }</h2>}
                        <a href="#" onClick={this.handleYearClick}>Vælg et årstal</a>
                    </div>
                </div>
            </Grid>
            <Grid item sm={12} md={6}>
            <TableContainer component={Paper} className={classes.paper}>
                <Typography className={`${classes.tablePadding} ${classes.header}`} variant="h5">
                    Omkostninger i { current_year }
                </Typography>
                 <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            { maintenanceHeaders.map((header, idx) => <TableCell align={idx == 1 ? "right" : "left"} key={ idx }>{ header }</TableCell>) }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { maintenanceRows }
                    </TableBody>
                 </Table>
            </TableContainer>
            <TableContainer component={Paper} className={`${classes.paper} ${classes.paperMarginTop}`}>
                 <Table className={classes.table}>
                    <TableBody>
                        <TableCell colSpan={2}>Total:</TableCell>
                        <TableCell align="right"><b>{ numeral(subtotal * 1000).format('0,0') }</b></TableCell>
                    </TableBody>
                 </Table>
                <p className={`${classes.tablePadding} text-muted`}>Prisen er ekskl. finansiering mv.</p>
            </TableContainer>
            <TableContainer component={Paper} className={`${classes.paper} ${classes.paperMarginTop}`}>
                 <Table className={classes.table}>
                    <TableBody>
                        <TableRow>
                            <TableCell colSpan={2}>Stillads og byggeplads:</TableCell>
                            <TableCell align={"right"}>{ scaffolding_construction_total }</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={2}>Teknisk rådgiver og byggesagsadministration:</TableCell>
                            <TableCell align={"right"}>{ adviser_building_management_total }</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={2}>Total:</TableCell>
                            <TableCell align={"right"}>{ numeral(total * 1000).format('0,0') }</TableCell>
                        </TableRow>
                    </TableBody>
                 </Table>
            </TableContainer>
            </Grid>
            {/* <Grid item sm={12} md={6}>
                <TableContainer component={Paper} className={classes.paper}>
                    <Typography className={`${classes.tablePadding} ${classes.header}`} variant="h5">
                        Eftersynsaktiviteter
                    </Typography>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                { inspectionHeaders.map((header, idx) => <TableCell align={idx == 1 ? "right" : "left"} key={ idx }>{ header }</TableCell>) }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { inspectionRows }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid> */}
        </Grid>
        <PartDialog type={type} part={part} closeModal={this.closeModal} customerId={customer_id} modalVisible={modalVisible} />
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  const start_year = state.data.years.start_year
  const current_year = parseInt(props.params.activity_year || start_year, 10)
  const estimates = state.data.customer.estimates
  const parts = keyBy(state.data.customer.parts, 'id')
  let filteredEstimatesById = filter(estimates.by_id, estimate => {
    return estimate.estimate !== null && estimate.estimate !== 0
  })
  filteredEstimatesById = keyBy(filteredEstimatesById, 'id')
  const maintenance_parts = filter(parts, part => {
    return estimates.by_year[current_year] && estimates.by_year[current_year][part.id] && has(filteredEstimatesById, estimates.by_year[current_year][part.id])
  })
  const inspection_parts_by_year = reduce(parts, (acc, part) => {
    const { inspection_start, inspection_interval } = part
    const year_intervals = inspection_start && inspection_interval
      ? calculateYearInterval(part.inspection_start, part.inspection_interval)
      : []
    forEach(year_intervals, (year, idx) => {
      acc[year] = acc[year] || []
      acc[year].push(part.id)
    })
    return acc
  }, {})
  const extraordinary_parts = sortBy(filter(parts, part => part.establishment_cost && part.establishment_year == current_year), 'bips_id')

  return {
    current_year,
    years: state.data.years.years,
    parts,
    maintenance_parts,
    inspection_parts_by_year,
    extraordinary_parts,
    estimates: state.data.customer.estimates,
    plan: state.data.customer.plan,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setBreadcrumb: actions.setBreadcrumb,
    dispatch,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ActivityPlan))
