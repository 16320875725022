import { combineReducers } from 'redux'
import { ActionTypes } from 'client/constants'
import { modelReducer, formReducer, modeled, actionTypes as formActions } from 'react-redux-form'
import startsWith from 'lodash/startsWith'
import moment from 'moment'

const listReducer = (state = {
  content: [],
  is_valid: false,
}, action) => {
  switch (action.type) {
    case ActionTypes.RECEIVE_REQUEST:
      if (action.subtype === 'part-templates') {
        return {
          content: action.payload,
          is_valid: true,
        }
      }
      return state
    case ActionTypes.SEND_REQUEST:
      if (action.subtype === 'part-template') {
        if (action.request === 'create' || action.request === 'update' || action.request === 'delete') {
          return {
            content: [],
            is_valid: false,
          }
        }
      }
      return state
    default:
      return state
  }
}

const initialCurrentState = {
  bips_id: null,
  title: null,
  internal_note: null,
  specification: null,
  condition: null,
  establishment: null,
  establishment_cost: null,
  establishment_cost_note: null,
  maintenance: null,
  maintenance_interval: null,
  maintenance_cost: null,
  maintenance_cost_note: null,
  scaffolding: false,
  construction_site: false,
  adviser: false,
  building_management: false,
  inspection: null,
  inspection_interval: null,
}
const currentReducer = (state = initialCurrentState, action) => {
  switch (action.type) {
    case ActionTypes.RECEIVE_REQUEST:
      if (action.subtype === 'part-template') {
        if (action.request === 'create') {
          return {
            ...state,
            id: action.payload.id,
            created_at: action.payload.created_at,
            updated_at: action.payload.updated_at,
          }
        } else if (action.request === 'fetch') {
          return {
            ...state,
            ...action.payload,
          }
        } else if (action.request === 'delete') {
          return {}
        }
      }
      return state
    case ActionTypes.INVALIDATE_DATA:
      return initialCurrentState
    default:
      return state
  }
}
const modelCurrent = modeled(currentReducer, 'data.part_template.current')

const autoSaveReducer = (state = {
  should_save: false,
  last_saved: NaN,
  is_saving: false,
  rand: 1,
}, action) => {
  switch (action.type) {
    case ActionTypes.SEND_REQUEST:
      if (action.subtype === 'part-template') {
        if (action.request === 'create' || action.request === 'update') {
          return {
            ...state,
            is_saving: true,
          }
        }
      }
      return state
    case ActionTypes.RECEIVE_REQUEST:
      if (action.subtype === 'part-template') {
        if (action.request === 'fetch') {
          const last_saved = moment(action.payload.updated_at).unix()
          return {
            ...state,
            should_save: false,
            last_saved,
            is_saving: false,
          }
        }
        const now = moment().unix()
        return {
          ...state,
          should_save: false,
          last_saved: now,
          is_saving: false,
        }
      } else if (action.subtype === 'error') {
        return {
          ...state,
          should_save: false,
          is_saving: false,
        }
      }
      return state
    case formActions.CHANGE:
      if (!state.should_save && startsWith(action.model, 'data.part_template.current')) {
        return {
          ...state,
          should_save: true,
        }
      }
      return state
    case ActionTypes.CLEAR_AUTOSAVE:
      return {
        ...state,
        should_save: false,
        last_saved: NaN,
        is_saving: false,
      }
    case ActionTypes.FORCE_UPDATE_AUTOSAVE: {
      const rand = Date.now()
      return {
        ...state,
        rand,
      }
    }
    default:
      return state
  }
}
const formDataReducer = (state = {
  bips: [],
}, action) => {
  switch (action.type) {
    case ActionTypes.RECEIVE_REQUEST:
      if (action.subtype === 'bips') {
        return {
          ...state,
          bips: action.payload,
        }
      }
      return state
    case ActionTypes.INVALIDATE_DATA:
      return {
        bips: [],
      }
    default:
      return state
  }
}

export default combineReducers({
  list: listReducer,
  current: modelCurrent,
  auto_save: autoSaveReducer,
  form: formReducer('data.part_template.current'),
  form_data: formDataReducer,
})
