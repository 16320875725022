import { combineReducers } from 'redux'
import { ActionTypes } from 'client/constants'
import { findIndex, merge } from 'lodash'

const listReducer = (state = [], action) => {
  switch (action.type) {
    case ActionTypes.RECEIVE_USER_REQUEST:
      return action.payload
    case ActionTypes.RECEIVE_USER_UPDATE_REQUEST: {
      // find the index to update state at
      const id = action.payload.id
      const userIdx = findIndex(state, user => user.id === id)
      if (userIdx !== -1) {
        state[userIdx] = action.payload
      }
      return state
    }
    case ActionTypes.RECEIVE_USER_CREATE_REQUEST: {
      return state.concat(action.payload)
    }
    case ActionTypes.RECEIVE_USER_DEACTIVATE_REQUEST: {
      const id = action.payload
      const userIdx = findIndex(state, user => user.id === id)
      if (userIdx !== -1) {
        state[userIdx].deleted = true
      }
      return state
    }
    case ActionTypes.RECEIVE_USER_ACTIVATE_REQUEST: {
      const id = action.payload
      const userIdx = findIndex(state, user => user.id === id)
      if (userIdx !== -1) {
        state[userIdx].deleted = false
      }
      return state
    }
    case ActionTypes.RECEIVE_USER_UPLOAD_REQUEST: {
      // find the index to update state at
      const id = action.payload.id
      const userIdx = findIndex(state, user => user.id === id)
      if (userIdx !== -1) {
        state[userIdx].s3_image_id = action.payload.s3_image_id
      }
      return state
    }
    default:
      return state
  }
}

export default combineReducers({
  list: listReducer,
})
