import numeral from 'numeral'

// load a language
numeral.language('dk', {
  delimiters: {
    thousands: '.',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't',
  },
  ordinal (number) {
    return '.'
  },
  currency: {
    symbol: 'DKK',
  },
})

numeral.language('dk')

export default numeral
