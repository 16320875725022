import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { Field, actions as rdxActions } from 'react-redux-form'
import moment from 'moment'
import DatePicker from 'client/components/DatePicker'
import { PlanStatus, PlanButtons } from './index'
import classnames from 'classnames'
import {
  InputField,
  TextField,
  SelectField,
  SearchableSelectField,
} from 'client/components/Field'
import actions from 'client/actions'
import { has, includes, map, get } from 'lodash'
import Select from "react-select";

let years = []
let year = moment().subtract(1, 'y')
for (let i = 0; i < 6; i++) {
  year = year.add(1, 'y')
  years = years.concat(year.year())
}

class Setup extends Component {
  state = {
    selectedAsset: '',
  };

  static propTypes = {
    current: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    form_data: PropTypes.object.isRequired,
    form_model: PropTypes.string.isRequired,
    dispatch: PropTypes.func.isRequired,
    setRenderedComponent: PropTypes.func.isRequired,
    publishPlan: PropTypes.func.isRequired,
    unpublishPlan: PropTypes.func.isRequired,
    hasEditRights: PropTypes.bool.isRequired,
  }

  constructor (props) {
    super(props)
    this.onPublishHandler = this.onPublishHandler.bind(this)
    this.onDraftHandler = this.onDraftHandler.bind(this)
  }

  componentWillReceiveProps(nextProps, nextState) {
    if (nextProps.form_data.assets !== this.props.form_data.assets) {
        const selectedAsset = nextProps.form_data.assets.find(asset => asset.id == this.props.current.asset_id);
        if (!selectedAsset) {
            return;
        }
        this.setState({selectedAsset: {value: selectedAsset.id, label: selectedAsset.title}});
    }    
  }

  componentWillMount () {
    const { dispatch,fetchAssets, setRenderedComponent } = this.props
    dispatch(fetchAssets())
    dispatch(setRenderedComponent('plan'))
  }

  onPublishHandler () {
    const { dispatch, publishPlan, current } = this.props
    dispatch(publishPlan(current.id, current))
  }

  onDraftHandler () {
    const { dispatch, unpublishPlan, current } = this.props
    dispatch(unpublishPlan(current.id))
  }

  handleAssetChange = (selectedAsset) => {
    this.setState({ selectedAsset });

    let value;

    if (selectedAsset !== null) {
      value = selectedAsset.value;
    } else {
      value = null;
    }

    const { dispatch, form_model } = this.props;

    dispatch(rdxActions.change(`${form_model}.asset_id`, value));
  };


  render () {
    const { current, form_data, form_model, hasEditRights, role, userId } = this.props

    const form = this.props.form.$form

    const readOnly = current.published || !hasEditRights
    const defaultInternalContact = role === 'editor' && !has(current, 'id')
      ? userId
      : ''
    const start_years = !includes(years, current.start_year) && current.start_year
      ? [current.start_year].concat(years)
      : years

    const assetOptions = (form_data.assets && form_data.assets.map(asset => ({ value: asset.id, label: asset.title })));
    // const filterOptions = createFilterOptions({
    //     options: assetOptions,
    // });

    return (
      <div>
        <h2 className="text-center">
          Opsætning
        </h2>
        <hr />
        <div className="row">

          <div className="col-md-6">

            <div className="well">
              <PlanButtons
                plan_id={ current.id }
                published={ current.published }
                archived={ current.archived }
                publishHandler={ this.onPublishHandler }
                draftHandler={ this.onDraftHandler }
                />
            </div>

            <div className="well">

              <div className="form-group">
                Status: <PlanStatus published={ current.published } archived={ current.archived } />
              </div>

              <fieldset>

                <InputField
                  model={ `${form_model}.case_no` }
                  label="Sagsnr"
                  type="text"
                  className="form-control input-sm"
                  placeholder="Eks. 1287"
                  valid={ form.validity.case_no }
                  helper="Sagsnr. skal være unikt"
                  disabled={ readOnly }
                  />

                <SelectField
                  model={ `${form_model}.internal_contact_id` }
                  label="PLAN1 kontaktperson"
                  className="form-control input-sm"
                  valid={ form.validity.internal_contact_id }
                  disabled={ readOnly || role === 'editor' }
                  defaultValue={ defaultInternalContact }
                  >
                  <option value="">---</option>
                  { form_data.internal_contacts.map(contact => <option key={ contact.id } value={ contact.id }>{ contact.name }</option>) }
                </SelectField>

              </fieldset>

              <fieldset>
                <legend>Driftsplans indstillinger</legend>

                <div className="row">
                  <div className="col-md-6">
                    <div className={ classnames('form-group', { 'has-error': form.validity.purchased_inspection === false }) }>
                      <label htmlFor="">Service aftale tilkøbt af forening</label>
                      <br />
                      <Field model={ `${form_model}.purchased_inspection` }>
                        <label className="radio-inline">
                          <input type="radio" value={ 1 }  disabled={ readOnly } /> Ja
                        </label>
                      </Field>
                      <Field model={ `${form_model}.purchased_inspection` }>
                        <label className="radio-inline">
                          <input type="radio" value={ 0 } disabled={ readOnly } /> Nej
                        </label>
                      </Field>
                      <Field model={ `${form_model}.purchased_inspection` }>
                          <label className="radio-inline">
                              <input type="radio" value={ 2 } disabled={ readOnly } /> Ikke besluttet
                          </label>
                      </Field>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <SelectField
                      model={ `${form_model}.inspection_date` }
                      label="Opfølgningsmåned"
                      className="form-control input-sm"
                      valid={ form.validity.inspection_date }
                      disabled={ readOnly || role === 'editor' }
                      >
                      <option value="">---</option>
                      { map([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12], month => <option value={month}>{month}</option>) }
                    </SelectField>

                  </div>
                </div>

                <div className="row">

                  <div className="col-xs-6">
                    <InputField
                      model={ `${form_model}.price_index` }
                      label="Prisindeks"
                      type="text"
                      className="form-control input-sm"
                      placeholder="Skriv prisindeks her"
                      valid={ form.validity.price_index }
                      helper="Prisindeks skal have et format som 123,1"
                      disabled={ readOnly }
                      />
                  </div>

                  <div className="col-xs-6">
                    <div className={ classnames('form-group', { 'has-error': form.validity.price_index_date === false }) }>
                      <label htmlFor="">Dato for prisindeks</label>
                      <DatePicker model={ `${form_model}.price_index_date` } displayFormat="DD/MM/YYYY" dataFormat="YYYY-MM-DD" className="form-control input-sm" placeholderText="Vælg dato for prisindeks" disabled={ readOnly } />
                    </div>
                  </div>

                </div>
                
                <div className="row">

                  <div className="col-xs-6">
                    <SelectField
                      model={ `${form_model}.start_year` }
                      label="Start år"
                      className="form-control input-sm"
                      valid={ form.validity.start_year }
                      disabled={ readOnly }
                      >
                      <option value="">Opretningsår</option>
                      { start_years.map(y => <option key={ y } value={ y }>{ y }</option>) }
                    </SelectField>
                    <span className="help-block">Det år hvor opretning skal udføres</span>
                  </div>

                  <div className="col-xs-6">
                    <div className={ classnames('form-group', { 'has-error': form.validity.overridden_updated_at === false }) }>
                      <label htmlFor="">Sidst opdateret dato</label>
                      <DatePicker model={ `${form_model}.overridden_updated_at` } displayFormat="DD/MM/YYYY" dataFormat="YYYY-MM-DD" className="form-control input-sm" placeholderText="Vælg dato for sidst opdateret" disabled={ readOnly } />
                    </div>
                  </div>

                </div>

              </fieldset>

            </div>
          </div>

          <div className="col-md-6">
            <div className="well">

              <fieldset>
                <legend>Foreningens stamdata</legend>

                <div className="row">
                  <div className="col-md-6">
                    <InputField
                      model={ `${form_model}.name` }
                      label="Foreningsnavn"
                      type="text"
                      className="form-control input-sm"
                      valid={ form.validity.name }
                      disabled={ readOnly }
                      />
                  </div>
                  <div className="col-md-6">
                    <InputField
                      model={ `${form_model}.address1` }
                      label="Adresse 1"
                      type="text"
                      className="form-control input-sm"
                      valid={ form.validity.address1 }
                      disabled={ readOnly }
                      />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <InputField
                      model={ `${form_model}.address2` }
                      label="Adresse 2"
                      type="text"
                      className="form-control input-sm"
                      valid={ form.validity.address2 }
                      disabled={ readOnly }
                      />
                  </div>
                  <div className="col-md-6">
                    <InputField
                      model={ `${form_model}.address3` }
                      label="Adresse 3"
                      type="text"
                      className="form-control input-sm"
                      valid={ form.validity.address3 }
                      disabled={ readOnly }
                      />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                      <div className={ 'form-group' }>
                          <Field model={`${form_model}.asset_id`}>
                              <label>Aktiv</label>
                              <Select
                                  onChange={this.handleAssetChange}
                                  value={ this.state.selectedAsset }
                                  options={ assetOptions }
                                  disabled={ readOnly }
                              />
                          </Field>
                      </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-4">
                    <InputField
                      model={ `${form_model}.zip_code` }
                      label="Postnummer"
                      type="text"
                      className="form-control input-sm"
                      valid={ form.validity.zip_code }
                      disabled={ readOnly }
                      />
                  </div>
                  <div className="col-md-8">
                    <InputField
                      model={ `${form_model}.city` }
                      label="By"
                      type="text"
                      className="form-control input-sm"
                      valid={ form.validity.city }
                      disabled={ readOnly }
                      />
                  </div>
                </div>

                <TextField
                  model={ `${form_model}.email_notifications` }
                  label="Email notifikationer (adskil med komma)"
                  className="form-control"
                  rows="3"
                  cols="40"
                  valid={ form.validity.email_notifications }
                  disabled={ readOnly }
                  />

                <a href={ `mailto:${current.contact_email}?cc=${current.email_notifications}&subject=${current.name}` } className="btn btn-default btn-sm">Send email</a>

              </fieldset>



              <fieldset>
                <legend>Foreningens kontaktperson</legend>

                <div className="row">
                  <div className="col-md-6">
                    <InputField
                      model={ `${form_model}.contact_name` }
                      label="Navn"
                      type="text"
                      className="form-control input-sm"
                      valid={ form.validity.contact_name }
                      disabled={ readOnly }
                      />
                  </div>
                  <div className="col-md-6">
                    <InputField
                      model={ `${form_model}.contact_email` }
                      label="Email"
                      type="text"
                      className="form-control input-sm"
                      valid={ form.validity.contact_email }
                      helper="Feltet skal indeholde en valid email"
                      disabled={ readOnly }
                      />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <InputField
                      model={ `${form_model}.contact_phone` }
                      label="Telefon"
                      type="text"
                      className="form-control input-sm"
                      valid={ form.validity.contact_phone }
                      disabled={ readOnly }
                      />
                  </div>
                  <div className="col-md-6">
                    <InputField
                      model={ `${form_model}.contact_address` }
                      label="Adresse"
                      type="text"
                      className="form-control input-sm"
                      valid={ form.validity.contact_address }
                      disabled={ readOnly }
                      />
                  </div>
                </div>
              </fieldset>

            </div>
          </div>
        </div>
      </div>
    )
  }
}


const mapStateToProps = state => {
  const hasEditRights =  state.auth.role === 'admin' || (state.auth.role === 'editor' && state.data.plan.current.internal_contact_id === state.auth.userId) || !state.data.plan.current.internal_contact_id
  return {
    current: state.data.plan.current,
    form: state.data.plan.form,
    form_data: state.data.plan.form_data,
    form_model: 'data.plan.current',
    hasEditRights,
    role: state.auth.role,
    userId: state.auth.userId
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setRenderedComponent: actions.setRenderedComponent,
    dispatch,
    unpublishPlan: actions.unpublishPlan,
    publishPlan: actions.publishPlan,
    fetchAssets: actions.fetchAssets
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Setup)
