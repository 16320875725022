import React, { Fragment, Component } from 'react'
import PropTypes from 'prop-types';
import { Link } from 'react-router'
import { connect } from 'react-redux'
import moment from 'moment'
import { push } from 'react-router-redux'
import numeral from 'common/lib/numeral'
import { map, has, isNaN, keyBy } from 'lodash'
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  Tooltip as ChartTooltip
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import DocumentTitle from 'react-document-title'
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import {styles} from 'common/lib/styles';
import Select from "react-select";
import {ReactComponent as SamledeOmkostningerIcon} from 'assets/images/samlede-omkostninger.svg'
import {ReactComponent as SamletEnergiudgiftIcon} from 'assets/images/samlet-energiudgift.svg'
import {ReactComponent as Co2Icon} from 'assets/images/co2.svg'
import {ReactComponent as InfoIcon} from 'assets/images/info-icon.svg'
import PartItem from 'client/components/PartItem';
import actions from 'client/actions'
import PartRow from 'client/components/PartRow';
import PartDialog from 'client/components/PartDialog';
import { isNumeral } from 'numeral';

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  ChartTooltip
);

const OptionsRow = ({ estimates: { by_id, by_year, totals }, title, description, type, params, years, textWarning = true }) => {
    const optionCells = years.map(year => {
        if (by_year[year] && by_year[year][type]) {
            const currentYear = by_year[year]
            const currentEstimate = currentYear[type]
            return <td className={textWarning && "text-warning"} key={ year }>{ numeral(by_id[currentEstimate].estimate).format('0,0.0') }</td>
        }
        return <td key={ year }></td>
    })

    let total = (totals.by_id[type])
    if (isNaN(total)) {
        total = 0
    }

    return (
        <tr className={type === "free_form_total" ? "free-form-total" : ""}>
            <td key="Title">
                { title }<br />
                <span className="text-muted small">{ description }</span>
            </td>
            { optionCells }
            <td className={textWarning ? "text-warning" : ""} key="Samlet">{ numeral(total).format('0,0.0') }</td>
        </tr>
    )
}
OptionsRow.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    params: PropTypes.object.isRequired,
    years: PropTypes.array.isRequired,
    estimates: PropTypes.object.isRequired,
    textWarning: PropTypes.bool,
}

const YearOrdinarySubtotalsRow = ({ totals, years }) => {
    const totalCells = years.map(year => {
        if (totals.by_year[year]) {
            const currentSum = numeral(totals.by_year[year]).format('0,0.0')
            return <td key={ year }>{ currentSum }</td>
        }
        return <td key={ year }>0</td>
    })

    return (
        <tr>
            <td key="Title">Omkostninger til drift og vedligeholdelse <br/><span className="text-muted">Subtotal</span>
            </td>
            { totalCells }
            <td>{ numeral(totals.ordinary_total).format('0,0.00') }</td>
        </tr>
    )
}
YearOrdinarySubtotalsRow.propTypes = {
    totals: PropTypes.object.isRequired,
    years: PropTypes.array.isRequired,
}

const YearExtraordinarySubtotalsRow = ({ totals, years }) => {
    const totalCells = years.map(year => {
        return totals.extraordinary_by_year[year] ? <td key={ year }><span className="text-warning">{ numeral(totals.extraordinary_by_year[year]).format('0,0.0') }</span></td> : <td key={ year }><span className="text-warning">0</span></td>
    })

    return (
        <tr>
            <td key="Title">Ekstraordinær omkostning <br/><span className="text-muted">Subtotal</span>
            </td>
            { totalCells }
            <td className="text-warning">{ numeral(totals.extraordinary_total).format('0,0.0') }</td>
        </tr>
    )
}
YearExtraordinarySubtotalsRow.propTypes = {
    totals: PropTypes.object.isRequired,
    years: PropTypes.array.isRequired,
}

const YearTotalsRow = ({ totals, years }) => {
    // const taxOptionCells = years.map(year => {
    //     if (totals.totals_by_year[year]) {
    //         return <td key={ year }>{ numeral(totals.totals_by_year[year]*0.25).format('0,0.0') }</td>
    //     }
    //     return <td key={ year }>0</td>
    // })
    const optionCells = years.map(year => {
        if (totals.totals_by_year[year]) {
            return <td key={ year }>{ numeral(totals.totals_by_year[year]).format('0,0.0') }</td>
        }
        return <td key={ year }>0</td>
    })

    const total = totals.ordinary_total + totals.extraordinary_total
    const tax = total * 0.25;

    return (<>
            {/* <tr>
                <td key="Title">Moms<br />
                </td>
                { taxOptionCells }
                <td>{ numeral(tax).format('0,0.0') }</td>
            </tr> */}
            <tr>
                <td key="Title">Total<br />
                </td>
                { optionCells }
                <td>{ numeral(total).format('0,0.0') }</td>
            </tr>
        </>
    )
}
YearTotalsRow.propTypes = {
    totals: PropTypes.object.isRequired,
    years: PropTypes.array.isRequired,
}

const YearBarChart = ({ totals, years }) => {
    const data = map(years, year => {
        return has(totals.by_year, year)
            ? totals.by_year[year]
            : 0
    })
    const options = {
        plugins: {
            legend: {
                display: false
            }
        },
        scales: {
            x: {
                grid: {
                display: false
                }
            }
        }
    }

    const chartData = {
        labels: years,
        datasets: [{
            label: 'Omkostninger til vedligeholdelse',
            data: data,
            borderRadius: 5,
            backgroundColor: '#A6B8C5',
        }],
    }

    return (
        <Bar data={chartData} options={options} />
    )
}
YearBarChart.propTypes = {
    totals: PropTypes.object.isRequired,
    years: PropTypes.array.isRequired,
}

 
/**
 * @param {object} a
 * @param {object} b
 * @returns {number}
 */
const rowSort = (a, b) => {
    if (a.prioritized) {
        return -1;
    }
    if (a.condition > b.condition) {
        return 1;
    }
    return 0;
}

class Overview extends Component {

    static propTypes = {
        plan: PropTypes.object.isRequired,
        parts: PropTypes.array.isRequired,
        estimates: PropTypes.object.isRequired,
        params: PropTypes.object.isRequired,
        years: PropTypes.array.isRequired,
        route: PropTypes.object.isRequired,
        dispatch: PropTypes.func.isRequired,
    }

    constructor (props) {
        super(props)
        this.state = {
            currentPartId: null,
            isYearOpen: false,
            modalVisible: false,
        }
        this.onYearChange = this.onYearChange.bind(this)
        this.handleYearClick = this.handleYearClick.bind(this)
        this.toggleModal = this.toggleModal.bind(this)
        this.closeModal = this.closeModal.bind(this)
        numeral.zeroFormat('')
    }

    componentDidMount() {
        const { dispatch, setBreadcrumb } = this.props
        dispatch(setBreadcrumb('Overblik'));
    }

    toggleModal (part_id) {
        this.setState({
            modalVisible: !this.state.modalVisible,
            currentPartId: part_id
        })
    }

    closeModal () {
        this.setState({
            modalVisible: !this.state.modalVisible,
        })
    }

    onYearChange (selected) {
        const { dispatch, params } = this.props

        dispatch(push(`/c/${params.customer_id}/activity-plan/${selected.value}`))
    }

    handleYearClick (e) {
        e.preventDefault();
        this.setState({isYearOpen: !this.state.isYearOpen})
    }

    hasEstimateType(estimateType) {
        const { estimates } = this.props;
        const estimatesById = estimates.by_id;

        let estimateMatch = null;

        for (let key in estimatesById) {
            if (estimatesById.hasOwnProperty(key) && estimateMatch === null) {
                const estimate = estimatesById[key];

                if (estimate[estimateType] && estimate.estimate > 0) {
                    estimateMatch = estimate;
                }
            }
        }

        return estimateMatch;
    }

    partLinkHandler (id) {
        const { dispatch, params } = this.props
        dispatch(push(`/c/${params.customer_id}/parts/${id}`))
    }

    render () {
        const {
            plan, params: { customer_id },
            parts, estimates, years, route, sortedProjectParts,
            classes, projects
        } = this.props

        const partRows = map(parts, (part, idx) => <PartRow linkHandler={() => this.toggleModal(part.id)} key={ idx } part={ part } years={ years } customer_id={ customer_id } { ...this.props } />)

        const freeFormEstimate = this.hasEstimateType("free_form");
        const freeFormTotalEstimate = this.hasEstimateType("free_form_total");

        const part = parts[this.state.currentPartId]

        let tableHeaders = years.concat() //eslint-disable-line prefer-const
        tableHeaders.unshift('Aktiviteter')
        tableHeaders.push('Samlet')
        const table = years.length > 0
            ? (
                <table className="table distribution-table-customer">
                    <thead>
                        <tr>{ tableHeaders.map((header, idx) => <th key={ idx }>{ header }</th>) }</tr>
                    </thead>
                    <tbody>
                        { partRows }
                        <OptionsRow title="Stillads/lift" description="" type="scaffolding" years={ years } { ...this.props } />
                        <OptionsRow title="Byggeplads" description="" type="construction_site" years={ years } { ...this.props } />
                        <OptionsRow title="Teknisk rådgiver" description="" type="adviser" years={ years } { ...this.props } />
                        <OptionsRow title="Byggesagsadministration" description="" type="building_management" years={ years } { ...this.props } />
                        {freeFormEstimate !== null && (
                            <OptionsRow title={plan.estimate_free_form_title} description="" type={"free_form"} years={years} textWarning={false} { ...this.props }/>
                        )}
                    </tbody>
                    <tfoot >
                        <YearOrdinarySubtotalsRow years={ years } totals= { estimates.totals } />
                        <YearExtraordinarySubtotalsRow years={ years } totals= { estimates.totals } />
                        <YearTotalsRow years={ years } totals= { estimates.totals } />
                        {freeFormTotalEstimate !== null && (
                            <OptionsRow title={plan.estimate_free_form_total_title} description="" type={"free_form_total"} years={years} textWarning={false} { ...this.props }/>
                        )}
                    </tfoot>
                </table>
            )
            : null
        return (
            <div>
                <DocumentTitle title={ route.title } />

                <Grid container alignItems="stretch" spacing={3}>
                    <Grid item sm={12} md={6}>
                    <Paper className={classes.paper}>
                        <Typography className={classes.header} variant="h5">Konklusion</Typography>
                        <Typography>
                            { plan.conclusion
                                ? plan.conclusion.split('\n').map((line, idx) => {
                                    return (
                                        <span key={ idx }>
                                        { line }
                                        <br />
                                        </span>
                                    )
                                }): null }
                        </Typography>
                    </Paper>
                    <Paper className={`${classes.paper} ${classes.paperMarginTop}`}>
                        <Typography className={classes.header} variant="h5">Anbefalinger</Typography>
                        <Typography paragraph>
                            { plan.recommendations }
                        </Typography>
                    </Paper>
                    </Grid>
                    <Grid className="page-break-before page-break" item sm={12} md={6}>
                        {projects.length ? <Paper className={`${classes.paper}`}>
                            <Typography className={classes.header} variant="h5">Opgaver der bør prioriteres</Typography>
                            <Typography component="div">
                                { projects.map(p => (
                                    <PartItem
                                        linkHandler={() => this.partLinkHandler(p.part.id)}
                                        key={ p.id }
                                        part={ p.part }
                                        project={p}
                                        prioritized={p.prioritized}
                                        md={12}
                                    />)) }
                            </Typography>
                        </Paper> : null}
                    </Grid>
                    <Grid className={classes.grid + " page-break-before page-break"} item sm={12} md={4}>
                        <Paper className={classes.paper}>
                            <SamledeOmkostningerIcon className={classes.topIcons}/>
                            <Typography className={classes.textCenter} paragraph>
                                Samlede omkostninger til vedligeholdelse
                            </Typography>
                            <Typography className={classes.textCenter} paragraph>
                                <b>
                                    <span className={classes.blueText}>{ numeral(estimates.totals.ordinary_total * 1000).format('0,0') } kr.</span> 
                                </b>
                            </Typography>
                            <Typography className={classes.textCenter} paragraph>
                                Vedligehold pr. m<sup>2</sup> / pr. år
                            </Typography>
                            <Typography className={classes.textCenter} paragraph>
                                <b>
                                    <span className={classes.blueText}>{ numeral(estimates.totals.ordinary_total * 1000 / plan.total_area / 15).format('0,0') } kr.</span>
                                </b>
                            </Typography>
                            <Tooltip PopperProps={{ style: { marginTop: -20 } }} title="Tekst">
                                <InfoIcon className={classes.infoIcon}/>
                            </Tooltip>
                        </Paper>
                    </Grid>
                    {plan.energy_expense ? <Grid className={classes.grid} item sm={12} md={4}>
                        <Paper className={classes.paper}>
                            <SamletEnergiudgiftIcon className={classes.topIcons}/>
                            <Typography className={classes.textCenter} paragraph>
                                Samlet energiudgift
                            </Typography>
                            <Typography className={classes.textCenter} paragraph>
                                <b>
                                    <span className={classes.blueText}>{ numeral(plan.energy_expense).format('0,0') } kr.</span>
                                </b>
                            </Typography>
                            <Typography className={classes.textCenter} paragraph>
                                Energiudgift pr. m<sup>2</sup> / pr. år
                            </Typography>
                            <Typography className={classes.textCenter} paragraph>
                                <b>
                                    <span className={classes.blueText}>{ numeral(plan.energy_expense / plan.total_area / 15).format('0,0') } kr.</span>
                                </b>
                            </Typography>
                        </Paper>
                    </Grid> : null}
                    {plan.energy_emission ? <Grid className={classes.grid} item sm={12} md={4}>
                        <Paper className={classes.paper}>
                            <Co2Icon className={classes.topIcons}/>
                            <Typography className={classes.textCenter} paragraph>
                                Samlet CO2 udledning
                            </Typography>
                            <Typography className={classes.textCenter} paragraph>
                                <b>
                                    <span className={classes.blueText}>{numeral(plan.energy_emission).format('0.00')} ton</span>
                                </b>
                            </Typography>
                            <Typography className={classes.textCenter} paragraph>
                                CO2 udledning pr. m<sup>2</sup> / pr. år
                            </Typography>
                            <Typography className={classes.textCenter} paragraph>
                                <b>
                                    <span className={classes.blueText}>{isNumeral(plan.energy_emission) ? numeral(parseFloat(plan.energy_emission) * 1000 / plan.total_area / 15).format('0.00') : 0} kg.</span>
                                </b>
                            </Typography>
                        </Paper>
                    </Grid> : null}
                    <Grid className="page-break-before page-break" item md={12}>
                        <Paper className={classes.paper}>
                            <div className={classes.chartHeader}>
                                <div className={classes.chartYear}>
                                    <span className="label label-round label-blue">&nbsp;</span><span className="small">Omkostninger til vedligeholdelse</span>
                                </div>
                                <div className={classes.chartYear}>
                                    {this.state.isYearOpen ? <div className="form-inline text-center">
                                    <div className="form-group">
                                        <Select
                                            className={classes.select}
                                            onChange={this.onYearChange}
                                            noOptionsMessage={() =>"Ingen resultat"}
                                            placeholder="Vælg"
                                            options={ years.map(year => (({ value: year, label: year }))) }
                                        />
                                    </div>
                                </div> : <h2>15 år</h2>}
                                    <a href="#" onClick={this.handleYearClick}>Vælg et årstal</a>
                                </div>
                            </div>
                            <div className={classes.chart}>
                                <YearBarChart years={ years } totals= { estimates.totals } />
                            </div>
                        </Paper>
                    </Grid>
                    <Grid className="page-break-before page-break" item md={12}>
                        <Paper className={classes.paper}>
                            <Typography className={classes.header} variant="h5">
                                Fordelingsplan
                            </Typography>
                            <div className="table-responsive">
                                { table }
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
                <PartDialog type="maintenance" part={part} closeModal={this.closeModal} customerId={customer_id} modalVisible={this.state.modalVisible} />
            </div>
        )
    }
}

const mapStateToProps = state => {
    const parts = state.data.customer.parts;
    return {
        plan: state.data.customer.plan,
        parts: keyBy(parts, 'id'),
        projects: state.data.customer.projects,
        estimates: state.data.customer.estimates,
        years: state.data.years.years,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setBreadcrumb: actions.setBreadcrumb,
        dispatch,
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(Overview))
