import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { has, map } from 'lodash'
import { Field } from 'react-redux-form'
import S3Uploader from 'react-s3-uploader'
import { API_URI } from 'client/constants'
import { parseIntOrNull } from 'common/lib/validator'
import classnames from 'classnames'
import {
  InputField,
  TextField,
  SelectField,
} from 'client/components/Field'
import actions from 'client/actions'
import DatePicker from '../../../components/DatePicker'

class Property extends Component {

  static propTypes = {
    current: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    form_data: PropTypes.object.isRequired,
    form_model: PropTypes.string.isRequired,
    dispatch: PropTypes.func.isRequired,
    setRenderedComponent: PropTypes.func.isRequired,
    saveUpload: PropTypes.func.isRequired,
    deleteUpload: PropTypes.func.isRequired,
    hasEditRights: PropTypes.bool.isRequired,
  }

  constructor (props) {
    super(props)
    this.onUploadProgress = this.onUploadProgress.bind(this)
    this.onFinishUpload = this.onFinishUpload.bind(this)
    this.deleteUpload = this.deleteUpload.bind(this)
    this.onImageLoaded = this.onImageLoaded.bind(this)
    this.state = {
      isUploading: false,
    }
  }

  componentWillMount () {
    const { dispatch, setRenderedComponent } = this.props
    dispatch(setRenderedComponent('plan'))
  }

  onUploadProgress (percentage, message, signResult) {
    this.setState({
      isUploading: true,
    })
  }

  onFinishUpload (result) {
    const { dispatch, saveUpload, current } = this.props
    dispatch(saveUpload(current.id, result.filename))
  }

  onImageLoaded () {
    this.setState({
      isUploading: false,
    })
  }

  deleteUpload (ev) {
    ev.preventDefault()
    const { dispatch, deleteUpload, current } = this.props
    const confirmation = window.confirm('Er du sikker?')
    if (confirmation) {
      dispatch(deleteUpload(current.id))
    }
  }

  render () {
    const { current, form_data, form_model, hasEditRights } = this.props
    const form = this.props.form.$form
    const { isUploading } = this.state
    const readOnly = current.published || !hasEditRights

    return (
      <div>
        <h2 className="text-center">
          Ejendommen
        </h2>
        <hr />

        <div className="row">
          <div className="col-md-4">
            <div className="well">
              <fieldset>
                <legend>Bygningsinfo</legend>
                <InputField
                  model={ `${form_model}.residential_area` }
                  label="Samlet bruttoetageareal (Privat)"
                  type="number"
                  className="form-control input-sm"
                  parser={ parseIntOrNull }
                  valid={ form.validity.residential_area }
                  disabled={ readOnly }
                  />
                <InputField
                  model={ `${form_model}.commercial_area` }
                  label="Samlet bruttoetageareal (Erhverv)"
                  type="number"
                  className="form-control input-sm"
                  parser={ parseIntOrNull }
                  valid={ form.validity.commerical_area }
                  disabled={ readOnly }
                />
                <InputField
                  model={ `${form_model}.residential_units` }
                  label="Antal enheder"
                  type="number"
                  className="form-control input-sm"
                  parser={ parseIntOrNull }
                  valid={ form.validity.residential_units }
                  disabled={ readOnly }
                  />
                <InputField
                  model={ `${form_model}.commercial_units` }
                  label="Antal enheder (Erhverv)"
                  type="number"
                  className="form-control input-sm"
                  parser={ parseIntOrNull }
                  valid={ form.validity.commercial_units }
                  disabled={ readOnly }
                />
                <InputField
                  model={ `${form_model}.erected` }
                  label="Opført år"
                  type="number"
                  className="form-control input-sm"
                  parser={ parseIntOrNull }
                  valid={ form.validity.erected }
                  disabled={ readOnly }
                  />
                <InputField
                  model={ `${form_model}.bbr_no` }
                  label="Ejendomsnr."
                  type="text"
                  className="form-control input-sm"
                  valid={ form.validity.bbr_no }
                  disabled={ readOnly }
                  />
                <InputField
                  model={ `${form_model}.cadastral_no` }
                  label="Matrikel nr."
                  type="text"
                  className="form-control input-sm"
                  valid={ form.validity.cadastral_no }
                  disabled={ readOnly }
                  />
                  <SelectField
                    model={ `${form_model}.condition` }
                    label="Ejendommens stand"
                    className="form-control"
                    disabled={ readOnly }
                    >
                    <option value="">Vælg tilstand</option>
                    <option value="1">Rød (under middel)</option>
                    <option value="2">Gul (middel)</option>
                    <option value="3">Grøn (god)</option>
                  </SelectField>
                <div className={ classnames('form-group', { 'has-error': form.validity.s3_image_id === false }) }>
                  {/* TODO: add image handler */}
                  <label>Intro billede</label>
                  {
                    (current.s3_image_id || isUploading)
                      ? null
                      : (
                          <S3Uploader
                            className="form-control input-sm"
                            accept="image/*"
                            capture="camera"
                            signingUrl="/s3/sign"
                            uploadRequestHeaders={ { 'x-amz-acl': 'public-read' } }
                            contentDisposition="auto"
                            server={ API_URI }
                            onProgress={ this.onUploadProgress }
                            onFinish={ this.onFinishUpload }
                            disabled={ !has(current, 'id') || readOnly }
                            />
                        )
                  }
                  <p className="help-block">Intro billede skal være 1600x2000 pixels og være af filtypen .jpg</p>
                </div>
                {
                  isUploading
                    ? (
                        <div className="uploadSpinner">
                          <i className="fa fa-cog fa-spin fa-5x"></i>
                          <span>Uploading</span>
                        </div>
                      )
                    : null
                }
                <div className="introImage">
                  {
                    current.s3_image_id
                      ? <img src={ `https://ik.imagekit.io/aw3ddey5g/${current.s3_image_id}?tr=w-318` } onLoad={ this.onImageLoaded } width="100%" />
                      : null
                  }
                  <p>
                    {
                      (current.s3_image_id && !isUploading)
                        ? <a href="#" onClick={ this.deleteUpload }>Slet billede</a>
                        : null
                    }
                  </p>
                </div>
              </fieldset>
            </div>
          </div>
          <div className="col-md-8">
            <div className="well">
              <TextField
                model={ `${form_model}.about` }
                label="Om ejendommen"
                className="form-control"
                rows="8"
                cols="40"
                valid={ form.validity.about }
                disabled={ readOnly }
                />
              <TextField
                model={ `${form_model}.conclusion` }
                label="Konklusion"
                className="form-control"
                rows="20"
                cols="40"
                valid={ form.validity.conclusion }
                disabled={ readOnly }
                />
              <TextField
                model={ `${form_model}.recommendations` }
                label="Anbefalinger"
                className="form-control"
                rows="8"
                cols="40"
                valid={ form.validity.recommendations }
                disabled={ readOnly }
                />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  const hasEditRights =  state.auth.role === 'admin' || (state.auth.role === 'editor' && state.data.plan.current.internal_contact_id === state.auth.userId) || !state.data.plan.current.internal_contact_id
  return {
    current: state.data.plan.current,
    form: state.data.plan.form,
    form_data: state.data.plan.form_data,
    form_model: 'data.plan.current',
    hasEditRights,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setRenderedComponent: actions.setRenderedComponent,
    saveUpload: actions.savePlanUpload,
    deleteUpload: actions.deletePlanUpload,
    dispatch,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Property)
