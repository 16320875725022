export const FaqSchema = {
  base: {
    properties: {
      id: {
        type: 'integer',
        allowNull: false,
      },
      question: {
        type: 'string',
      },
      answer: {
        type: 'string',
      },
    },
  },
}
