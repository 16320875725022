export const styles = theme => ({
    grid: {
        display: 'flex'
    },
    paper: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        width: '100%',
        borderRadius: 4,
        background: 'transparent',
        border: '1px solid #8a8a8a',
        position: 'relative'
    },
    accordion: {
        background: 'transparent',
        border: '1px solid #8a8a8a',
        boxShadow: 'none'
    },
    dialogTitleRoot: {
        margin: 0,
        padding: theme.spacing(2),
    },
    dialogImage: {
        maxWidth: '100%',
        height: 'auto'
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    paperMarginTop: {
        marginTop: 24,
    },
    textCenter: {
        textAlign: 'center'
    },
    blueText: {
        color: '#4f6d7a'
    },
    header: {
        marginBottom: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        gap: 10
    },
    paragraphMargin: {
        marginBottom: theme.spacing(3),
    },
    select: {
        width: 90,
        fontSize: 13
    },
    table: {
        '& .MuiTableCell-head': {
            fontWeight: 700
        },
        '& .MuiTableCell-root': {
            border: 0,
            padding: '8px 8px'
        }
    },
    tablePadding: {
        paddingLeft: theme.spacing(1),
        margin: 0
    },
    td: {
        padding: 10,
        '&:first-child': {
            paddingLeft: 0,
        },
        '& p': {
            margin: 0
        }
    },
    cursor: {
        cursor: 'pointer'
    },
    underline: {
        textDecoration: 'underline'
    },
    iconWarning: {
        color: '#F39C12',
        background: '#FFF',
        borderRadius: 50,
        marginRight: 52,
        fontSize: 30
    },
    partTypographyPadding: {
        paddingRight: 10
    },
    partGrid: {
        alignItems: 'center',
        display: 'flex',
        cursor: 'pointer',
        padding: '20px 0'
    },
    noPaddingTop: {
        paddingTop: 0
    },
    partAvatar: {
        width: theme.spacing(14),
        height: theme.spacing(14),
        marginRight: 30
    },
    partItemTitle: {
        display: 'flex',
        borderBottom: '1px solid',
        paddingBottom: 10,
        alignItems: 'center',
        '& .MuiTypography-root': {
            fontSize: 24,
            paddingRight: 20,
            lineHeight: '24px',
        }
    },
    greyText: {
        color: '#8A8A8A',
        '&:last-child': {
            margin: 0
        }
    },
    avatarGrid: {
        display: 'flex',
        alignItems: 'center'
    },
    avatar: {
        width: theme.spacing(14),
        height: theme.spacing(14),
        marginLeft: 'auto'
    },
    iconlink: {
        display: 'flex',
        '& svg': {
            paddingRight: theme.spacing(1)
        }
    },
    logoLinks: {
        display: 'flex',
        alignItems: 'center',
        '& a': {
            display: 'flex',
            paddingLeft: theme.spacing(1),
        }
    },
    serviceAgreement: {
        marginTop: 10,
        display: 'flex'
    },
    serviceAgreementDate: {
        textTransform: 'capitalize'
    },
    checkIcon: {
        color: '#18BC9C'
    },
    warningIcon: {
        color: '#F39C12'
    },
    clearIcon: {
        color: '#E74C3C'
    },
    root: {
        display: 'flex'
    },
    container: {
        width: 'calc(100% - 40px)',
        position: 'relative',
        top: '64px',
        flexGrow: 1,
        padding: '40px',
    },
    chartHeader: {
        display: 'flex'
    },
    chart: {
        '& canvas': {
            maxWidth: '100%',
            maxHeight: 350,
        }
    },
    chartYear: {
        marginLeft: 'auto',
        '& h2': {
            fontWeight: 700,
            fontSize: 35,
            textAlign: 'center',
            margin: 0,
            width: 90
        },
        '& a': {
            textDecoration: 'underline',
            fontSize: 13,
            textAlign: 'center',
            display: 'block',
            width: 90
        }
    },
    chartEnergy: {
        textAlign: 'center',
        '& .label-blue': {
            marginLeft: 15
        }
    },
    topIcons: {
        width: 100,
        height: 100,
        margin: '0 auto',
        left: 'calc(50% - 50px)',
        marginTop: '-60px'
    },
    infoIcon: {
        width: 50,
        height: 50,
        position: 'absolute',
        bottom: 0,
        right: 0
    },
    noteIcon: {
        width: 50,
        height: 50,
        display: 'block',
        marginLeft: '-10px',
        marginTop: '-10px'
    }
})