import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import classNames from 'classnames'
import actions from 'client/actions'
import numeral from 'common/lib/numeral'
import { toNumber } from 'lodash'

class EstimateCell extends Component {

    static propTypes = {
        data: PropTypes.object,
        year: PropTypes.number,
        plan_id: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
        ]).isRequired,
        part_id: PropTypes.number,
        optionType: PropTypes.string,
        dispatch: PropTypes.func.isRequired,
        saveEstimate: PropTypes.func.isRequired,
        isInterval: PropTypes.bool,
        active: PropTypes.bool,
        handleSave: PropTypes.func.isRequired,
        plan_published: PropTypes.bool.isRequired,
        hasEditRights: PropTypes.bool.isRequired,
        extraordinary: PropTypes.string,
        textNormal: PropTypes.bool,
    }

    constructor (props) {
        super(props)
        numeral.zeroFormat('')
        this.switchElement = this.switchElement.bind(this)
        this.handleBlur = this.handleBlur.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleKeyPress = this.handleKeyPress.bind(this)
        this.state = {
            isInput: false,
            tempValue: null,
            error: false,
        }
    }

    componentDidUpdate () {
        const { year, part_id, optionType } = this.props
        const refId = part_id || optionType
        if (this.state.isInput) {
            this.refs[`${refId}-${year}`].focus()
        }
    }

    switchElement () {
        const plan_published = this.props.plan_published
        const hasEditRights = this.props.hasEditRights
        if (!plan_published && hasEditRights) {
            this.setState({
                ...this.state,
                isInput: !this.state.isInput,
            })
        }
    }

    handleChange (e) {
        const value = e.target.value
        const number = toNumber(value.replace(',', '.'))
        let error = false
        let tempValue = number
        if (isNaN(number) || number < 0) {
            error = true
            tempValue = value
        }
        this.setState({
            ...this.state,
            tempValue,
            error,
        })
    }

    handleBlur () {
        const { dispatch, saveEstimate, plan_id, part_id, optionType, year, data: { id } = {}, handleSave, plan_published } = this.props
        const value = this.state.tempValue
        const error = this.state.error
        if (id && value !== null && !error) {
            const data = this.props.data
            data.estimate = value
            handleSave('update', data)
        } else if (value && !error) {
            const scaffolding = optionType === 'scaffolding';
            const construction_site = optionType === 'construction_site';
            const adviser = optionType === 'adviser';
            const building_management = optionType === 'building_management';
            const free_form = optionType === 'free_form';
            const free_form_total = optionType === 'free_form_total';

            const data = {
                plan_id,
                part_id,
                year,
                estimate: value,
                scaffolding,
                construction_site,
                adviser,
                building_management,
                free_form,
                free_form_total,
            }
            handleSave('create', data)
        }
        this.switchElement()
    }

    handleKeyPress (e) {
        if (e.key === 'Enter') {
            this.handleBlur()
        }
    }

    render () {
        const { year, part_id, optionType, data: { estimate } = {}, isInterval, active, extraordinary, textNormal } = this.props
        const { isInput, error } = this.state
        const value = error
            ? this.state.tempValue
            : estimate || this.state.tempValue
        const formattedValue = isNaN(toNumber(value))
            ? value
            : numeral(value).format('0,0.0')
        const refId = part_id || optionType
        const element = isInput
            ? (
                <td>
                    <input className={ classNames('form-control input-sm', { 'has-error': error }) } onBlur={ this.handleBlur } onChange={ this.handleChange } onKeyPress={ this.handleKeyPress } type="text" key={ year } defaultValue={ formattedValue } ref={ `${refId}-${year}` } />
                    { extraordinary ? <div><span className="text-warning">{ extraordinary }</span></div> : null }
                </td>
            )
            : (
                <td className={ classNames({ 'has-error': error }, { highlighted: isInterval && active }, { establishment: !year }, { 'text-warning': optionType && !textNormal }) } onClick={ this.switchElement } key={ year }>
                    { formattedValue }
                    { extraordinary ? <div><span className="text-warning">{ extraordinary }</span></div> : null }
                </td>
            )

        return element
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
        saveEstimate: actions.saveEstimate,
    }
}

export default connect(
    mapDispatchToProps
)(EstimateCell)
