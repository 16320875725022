import React, { Component } from 'react'
import { connect } from 'react-redux'
import actions from 'client/actions'
import Modal from 'react-bootstrap-modal'
import { merge, forEach, map, isEmpty, filter } from 'lodash'
import classnames from 'classnames'
import { isEmail } from 'common/lib/validator'
import { Link } from 'react-router'
import BannerImg from 'assets/images/plan1-marketing-banner.jpg'

const getInitialGeneratorState = _ => ({
  condition: 'Over middel',
  cadastral: 1,
  area: 100,
  name: '',
  email: '',
  company_address: '',
  company_zip: null,
  company_name: '',
  // condition: 'Over middel',
  // cadastral: 1,
  // area: 5700,
  // name: 'Sebastian',
  // email: 'sebastian@niepoortmedia.com',
  // company_address: 'Hørkær 30',
  // company_zip: '2400',
  // company_name: 'Mekanisme',
})

class Marketing extends Component {

  constructor (props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.state = {
      modalVisible: false,
      generator: getInitialGeneratorState(),
      errors: {},
      testimonial: 0,
    }
  }

  componentDidMount () {
    this.setState({ interval: setInterval(() => {
      const { testimonials } = this.props
      const currentPositon = this.state.testimonial
      const newPosition = currentPositon === testimonials.length - 1 ? 0 : currentPositon + 1
      this.setState({
        testimonial: newPosition
      })
    }, 5000)})
  }

  componentWillUnmount () {
    clearInterval(this.state.interval)
  }

  handleChange (field) {
    return ev => {
      const value = ev.target.value
      const state = merge(this.state, {
        generator: {
          [field]: value,
        },
      })
      this.setState(state)
    }
  }

  handleSubmit () {
    const { dispatch, submitGenerator } = this.props
    this.setState({ errors: {} })
    const generator = this.state.generator
    let errors = {}
    const required = 'Dette felt er påkrævet'
    forEach(generator, (field, key) => {
      if (field == '' || !field) {
        errors[key] = { required }
      }
    })
    if (!isEmail(generator.email)) {
      errors.email = { ...errors.email, notEmail: 'Du skal indtaste en gyldig email adresse' }
    }
    if (generator.company_zip && generator.company_zip.length !== 4) {
      errors.company_zip = { ...errors.company_zip, notZip: 'Du skal indtaste et gyldigt postnummer' }
    }
    if (generator.area < 100) {
      errors.area = { ...errors.area, minValue: 'Areal skal være mindst 100 m2' }
    }
    if (generator.cadastral < 1) {
      errors.cadastral = { ...errors.cadastral, minValue: 'Der skal være mindst 1 ejendom på matriklen' }
    }
    this.setState({ errors })
    if (isEmpty(errors)) {
      dispatch(submitGenerator(generator))
      .then(_ => {
        this.setState({ showSuccess: true, generator: getInitialGeneratorState() })
        setTimeout(() => this.setState({ showSuccess: false }), 5000)
      })
      .catch(e => {
      })
    }
  }

  renderErrors (field) {
    const errors = this.state.errors[field]
    return map(errors, error => <span className="help-block">{ error }</span>)
  }

  renderTestimonial (testimonial) {
    return (
      <div key={ testimonial.name }>
        <p className="lead">
          <em>"{ testimonial.content }"</em>
        </p>
        <p className="text-muted">
          { testimonial.name }
        </p>
      </div>
    )
  }

  render () {
    const { generator, testimonial } = this.state
    const { isWorking, testimonials, systemTexts } = this.props
    return (
      <div>
        <section className="coverbackground wrapper marketing-cover" style={ { backgroundImage: `url(${BannerImg})` } }>
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-xs-8 text-center">
                <div className="marketing-header">
                  <h1>{ systemTexts.banner_header }</h1>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section className="row">
          <div className="col-md-12 video">
            <div className="embed-container"><iframe src='https://www.youtube.com/embed/n_AKVfyFt_Y' frameborder='0' allowfullscreen></iframe></div>
          </div>
        </section> */}
        <div className="container">
          <section className="row">
            <div className="col-md-8 col-md-offset-2 text-center">
              <h2>{ systemTexts.intro_header }</h2>
              <p className="lead">
                { systemTexts.intro_content }
              </p>
              <div>
                <button className="btn btn-primary btn-lg" onClick={ e => this.setState({ modalVisible: true }) }>{ systemTexts.intro_button }</button>
                <p>{ systemTexts.intro_button_footnote }</p>
              </div>
            </div>
          </section>
          <section className="row">
            <div className="col-md-6">
              <div className="well text-center">
                <h3>{ systemTexts.testimonial_header }</h3>
                { testimonials.length > 0 ? this.renderTestimonial(testimonials[testimonial]) : null }
                <div>
                  <Link to="/login" className="btn btn-primary">{ systemTexts.testimonial_demo_button }</Link>
                </div>
              </div>
            </div>
            <div className="col-md-6 text-center">
              <div className="well">
                <h2>{ systemTexts.sales_header }</h2>
                <div className="embed-container"><iframe src="https://www.youtube.com/embed//_GMNk2qd_Kw" frameBorder="0" allowFullScreen></iframe></div>
              </div>
            </div>
          </section>
        </div>

        <Modal
          show={ this.state.modalVisible }
          onHide={ e => this.setState({ modalVisible: false }) }
          aria-labelledby="ModalHeader"
          large
          >
          <Modal.Header closeButton>
            <Modal.Title id="ModalHeader">
              { systemTexts.offer_title }
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
            { systemTexts.offer_text }
            </p>
            <div className="row">
              <form>
                <div className="col-md-6">
                  <div className="well">
                    <div className={ classnames('form-group', { 'has-error': !isEmpty(this.state.errors['name']) }) }>
                      <label>Navn</label>
                      <input
                        className="form-control"
                        onChange={ this.handleChange('name') }
                        required
                        value={ generator.name }
                      />
                      { this.renderErrors('name') }
                    </div>
                    <div className={ classnames('form-group', { 'has-error': !isEmpty(this.state.errors['email']) }) }>
                      <label>Email</label>
                      <input
                        type="email"
                        className="form-control"
                        onChange={ this.handleChange('email') }
                        required
                        value={ generator.email }
                      />
                      { this.renderErrors('email') }
                    </div>
                    <div className={ classnames('form-group', { 'has-error': !isEmpty(this.state.errors['company_name']) }) }>
                      <label>Virksomheds-/foreningsnavn</label>
                      <input
                        className="form-control"
                        onChange={ this.handleChange('company_name') }
                        required
                        value={ generator.company_name }
                      />
                      { this.renderErrors('company_name') }
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className={ classnames('form-group', { 'has-error': !isEmpty(this.state.errors['company_address']) }) }>
                          <label>Ejendommens adresse</label>
                          <input
                            className="form-control"
                            onChange={ this.handleChange('company_address') }
                            required
                            value={ generator.company_address }
                          />
                          { this.renderErrors('company_address') }
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className={ classnames('form-group', { 'has-error': !isEmpty(this.state.errors['company_zip']) }) }>
                          <label>Postnummer</label>
                          <input
                            type="number"
                            className="form-control"
                            onChange={ this.handleChange('company_zip') }
                            value={ generator.company_zip }
                            required
                          />
                          { this.renderErrors('company_zip') }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="well">
                    <div className={ classnames('form-group', { 'has-error': !isEmpty(this.state.errors['condition']) }) }>
                      <label>Ejendommens stand</label>
                      <div className="radio">
                        <label>
                          <input type="radio" value="Over middel" name="condition" onChange={ this.handleChange('condition') } required checked={ generator.condition == 'Over middel' }/>
                          Over middel
                        </label>
                      </div>
                      <div className="radio">
                        <label>
                          <input type="radio" value="Middel" name="condition" onChange={ this.handleChange('condition') } required checked={ generator.condition == 'Middel' } />
                          Middel
                        </label>
                      </div>
                      <div className="radio">
                        <label>
                          <input type="radio" value="Under middel" name="condition" onChange={ this.handleChange('condition') } required checked={ generator.condition == 'Under middel' } />
                          Under middel
                        </label>
                      </div>
                      { this.renderErrors('condition') }
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className={ classnames('form-group', { 'has-error': !isEmpty(this.state.errors['area']) }) }>
                          <label>Areal i m<sup>2</sup></label>
                          <input
                            type="number"
                            className="form-control"
                            onChange={ this.handleChange('area') }
                            value={ generator.area }
                          />
                          { this.renderErrors('area') }
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className={ classnames('form-group', { 'has-error': !isEmpty(this.state.errors['cadastral']) }) }>
                          <label>Ejendomme på matriklen</label>
                          <input
                            type="number"
                            className="form-control"
                            onChange={ this.handleChange('cadastral') }
                            value={ generator.cadastral }
                          />
                          { this.renderErrors('cadastral') }
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-center">
                    <button type="button" className="btn btn-success" onClick={ this.handleSubmit } disabled={ isWorking }><i className={ classnames('fa fa-spinner fa-spin fa-fw', { hidden: !isWorking }) }></i> Udregn tilbud</button>
                    <div className={ classnames('text-success', { hidden: !this.state.showSuccess }) }>
                      <p className="">{ systemTexts.offer_success_text }</p>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    )

  }
}

const mapStateToProps = state => {
  const systemTexts = state.data.systemTexts
  const testimonials = filter([
    { content: systemTexts.testimonial_1_content, name: systemTexts.testimonial_1_name },
    { content: systemTexts.testimonial_2_content, name: systemTexts.testimonial_2_name },
    { content: systemTexts.testimonial_3_content, name: systemTexts.testimonial_3_name }], t => t.content && t.name)
  return {
    isWorking: state.ui.isWorking,
    systemTexts,
    testimonials,
  }
}

const mapDispatchToProps = dispatch => ({
  dispatch,
  submitGenerator: actions.submitGenerator,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Marketing)
