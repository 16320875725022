import fetch from 'isomorphic-fetch'
import { push } from 'react-router-redux'
import { actions as FormActions } from 'react-redux-form'
import { ActionTypes, API_URI } from 'client/constants'
import { notifyDanger, notifySuccess, clearNotifications } from 'client/components/Notifications/actions'
import validate, { ValidationError } from 'common/lib/validator'
import { PortfolioSchema } from 'common/schemas'
import api, { ApiError } from 'client/lib/apiHandler'
import { findIndex, orderBy } from 'lodash'

const sendRequest = (subtype, request) => {
  return {
    type: ActionTypes.SEND_REQUEST,
    subtype,
    request,
  }
}

const receiveRequest = (subtype, request, payload) => {
  return {
    type: ActionTypes.RECEIVE_REQUEST,
    subtype,
    request,
    payload,
  }
}

export const fetchPortfolios = () => {

  return dispatch => {
    dispatch(sendRequest('portfolios', 'fetch'))

    api.get(`/portfolios`)
    .then(({ body }) => {
      const portfolios = orderBy(body, [portfolio => portfolio.portfolio_no != null ? portfolio.portfolio_no.toLowerCase() : ''], 'asc')
      dispatch(receiveRequest('portfolios', 'fetch', portfolios))
    })
    .catch(error => {
      console.log(error)
      dispatch({ type: ActionTypes.RECEIVE_REQUEST, subtype: 'error' })
    })
  }
}

export const fetchPortfolio = (portfolio_id) => {

  return dispatch => {
    dispatch(sendRequest('portfolio', 'fetch'))

    Promise.all([
      api.get(`/portfolios/${portfolio_id}`)
    ])
    .then(([response]) => {
      const portfolio = response.body.portfolio
      const users = response.body.users
      let start_years = []
      for (let i = 0; i < portfolio.plans.length; i++) {
        const start_year = portfolio.plans[i].start_year
        if (findIndex(start_years, {year: start_year}) == -1) {
          start_years.push({
            year: start_year
          })
        }
      }
      start_years = orderBy(start_years, 'year', 'asc')

      dispatch(receiveRequest('portfolio', 'fetch', portfolio))
      dispatch(receiveRequest('internal_contacts', 'fetch', users))
      dispatch(receiveRequest('start_years', 'fetch', start_years))

    })
    .catch(error => {
      console.log(error)
      if (error instanceof ApiError) {
        dispatch(push(`/admin/portfolios`))
      }
      dispatch({ type: ActionTypes.RECEIVE_REQUEST, subtype: 'error' })
    })
  }
}

export const savePortfolio = (portfolio, action) => {
  //const body = portfolio

  return (dispatch, getState) => {
    validate(PortfolioSchema, portfolio, false, { ignoreErrors: false })
      .then(result => {
        dispatch(sendRequest('portfolio', action))

        dispatch(FormActions.setValidity('data.portfolio.current', true))
        dispatch(FormActions.setErrors('data.portfolio.current', result.errors))

        if (action == 'create') {
          return api.post('/portfolios', result.data)
        } else {
          return api.put(`/portfolios/${portfolio.id}`, result.data)
        }
      })
      .then(({ body }) => {
        dispatch(receiveRequest('portfolio', action, body))
        if (action == 'create') { //Creating a new portfolio? Redirect to the edit page after creation
          const path = getState().routing.locationBeforeTransitions.pathname
          const editPath = path.replace('new', `edit/${body.id}`)
          dispatch(push(editPath))
        }
      })
      .catch(error => {
        if (error instanceof ValidationError) {
          dispatch(FormActions.setValidity('data.portfolio.current', true))
          dispatch(FormActions.setErrors('data.portfolio.current', error.errors))
          dispatch(notifyDanger('Alle felter skal udfyldes', 'Error')) //TODO: Adjust error message, which fields are required?
        } else if (error instanceof ApiError) {
          dispatch(notifyDanger('Der skete en fejl', 'Error'))
        }
        dispatch({ type: ActionTypes.RECEIVE_REQUEST, subtype: 'error' })
      })
  }
}

export const publishPortfolio = (portfolio, action) => {

  return (dispatch, getState) => {
    validate(PortfolioSchema, portfolio, false, { ignoreErrors: false })
      .then(result => {
        dispatch(sendRequest('portfolio', action))

        dispatch(FormActions.setValidity('data.portfolio.current', true))
        dispatch(FormActions.setErrors('data.portfolio.current', result.errors))

        return api.put(`/portfolios/${portfolio.id}/publish`, {
          publish: action
        })
      })
      .then(({ body }) => {
        dispatch(receiveRequest('portfolio', 'publish', body))
      })
      .catch(error => {
        if (error instanceof ValidationError) {
          dispatch(FormActions.setValidity('data.portfolio.current', true))
          dispatch(FormActions.setErrors('data.portfolio.current', error.errors))
          dispatch(notifyDanger('Alle felter skal udfyldes', 'Error')) //TODO: Adjust error message, which fields are required?
        } else if (error instanceof ApiError) {
          dispatch(notifyDanger('Der skete en fejl', 'Error'))
        }
        dispatch({ type: ActionTypes.RECEIVE_REQUEST, subtype: 'error' })
      })
  }
}

export const addPlanToPortfolio = (portfolio_id, plan) => {
  const body = { portfolio_id, plan_id: plan.id }

  return (dispatch, getState) => {
    dispatch(sendRequest('portfolio-plan', 'create'))

    api.post(`/portfolios/${portfolio_id}/plan`, body)
      .then(({ body }) => {
        dispatch(receiveRequest('portfolio-plan', 'create', plan))
        //TODO Add plan to state?
      })
      .catch(error => {
        if (error instanceof ApiError) {
          dispatch(notifyDanger('Der skete en fejl', 'Error'))
        }
        dispatch({ type: ActionTypes.RECEIVE_REQUEST, subtype: 'error' })
      })
  }
}

export const deletePlanFromPortfolio = (portfolio_id, plan) => {

  return (dispatch, getState) => {
    dispatch(sendRequest('portfolio-plan', 'delete'))

    api.del(`/portfolios/${portfolio_id}/plan/${plan.id}`)
      .then(({ body }) => {
        dispatch(receiveRequest('portfolio-plan', 'delete', plan))
        //TODO Add plan to state?
      })
      .catch(error => {
        if (error instanceof ApiError) {
          dispatch(notifyDanger('Der skete en fejl', 'Error'))
        }
        dispatch({ type: ActionTypes.RECEIVE_REQUEST, subtype: 'error' })
      })
  }
}

export const fetchCustomers = () => {
  return dispatch => {
    api.get(`/portfolios/customers`)
      .then(({ body }) => {
        dispatch({
          type: ActionTypes.SET_CUSTOMERS,
          customers: body
        })
      });
  }
}

export default {
  fetchPortfolios,
  fetchPortfolio,
  savePortfolio,
  publishPortfolio,
  addPlanToPortfolio,
  deletePlanFromPortfolio,
  fetchCustomers,
}
