import React from "react";
import Spinner from 'client/components/Spinner';
import Notifications from 'client/components/Notifications';
import Footer from 'client/components/Footer';
import NavBar from 'client/components/NavBar';
import { ListLink } from 'client/components/NavLink';
import {connect} from "react-redux";
import actions from 'client/actions';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListAltIcon from '@material-ui/icons/ListAlt';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import {styles} from 'common/lib/styles';
import { jwtDecode } from "jwt-decode";

class Plans extends React.Component {

    onClickLogout = ev => {
        ev.preventDefault();
        const { dispatch, logout } = this.props;
        dispatch(logout());
    };

    componentDidMount() {
        const { dispatch, setPlans } = this.props;
        const decoded = jwtDecode(localStorage.getItem('token'));
        if (decoded) {
            dispatch(setPlans(decoded.data))
        }
    }


    onSelectPlan = ev => {
        const targetPlanId = ev.target.value;

        if (targetPlanId) {
            const planPath = `/c/${targetPlanId}`;
            window.open(planPath)
        }
    };

    render() {
        return (
            <div className={this.props.classes.root}>
                    <Spinner />
                    <NavBar>
                        <ListLink to="/plans">
                            <ListItem button>
                            <ListItemIcon>
                                <ListAltIcon color="secondary" />
                            </ListItemIcon>
                            <ListItemText primary="Driftsplaner" />
                            </ListItem>
                        </ListLink>
                    </NavBar>

            <div>
                <div className={this.props.classes.container}>
                <Grid container alignItems="stretch" spacing={3}>
                        <label className="text-muted">Vælg en ejendom for at se driftsplanen</label>
                        <select className="form-control" onChange={ this.onSelectPlan }>
                            <option value="">Vælg en driftsplan</option>
                            {this.props.plans.map(plan => <option key={plan.plan_id} value={plan.plan_id} disabled={plan.disabled ? ' disabled="disabled"' : ''}>{plan.name}</option>)}
                        </select>
                        </Grid>
                    </div>

                    <Notifications />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        plans: state.auth.plans
    }
};

const mapDispatchToProps = dispatch => {
    return {
        dispatch,
        logout: actions.logout,
        setPlans: actions.setPlans,
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(Plans))

