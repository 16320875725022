import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { Link } from 'react-router'
import classNames from 'classnames'
import DateTime from 'client/components/DateTime'
import actions from './actions'
import { push } from 'react-router-redux'
import { filter } from 'lodash'

const PortfolioRow = ({ portfolio, handler }) => {
  const {
    portfolio_no, name, company_name, contact_name,
    created_at, updated_at, id
  } = portfolio

  let active_plans_count = 0
  let inactive_plans_count = 0
  for (let i = 0; i < portfolio.plans.length; i++) {
    const plan = portfolio.plans[i]
    if (plan.published && plan.start_year == portfolio.start_year) {
      active_plans_count++
    } else {
      inactive_plans_count++
    }
  }

  return (
    <tr onClick={ handler(`/admin/portfolios/edit/${id}`) }>
      <td key="portfolio_no">{ portfolio_no }</td>
      <td key="name">{ name }</td>
      <td key="company_name">{ company_name }</td>
      {/* TODO: add view link */}
      <td key="contact_name">{ contact_name }</td>
      <td key="created_at"><DateTime format="DD-MM-YYYY" source={ created_at } /></td>
      <td key="updated_at"><DateTime format="DD-MM-YYYY" source={ updated_at } /></td>
      <td key="preview_url"><a href={ `/p/${id}` } target="_blank">Vis</a></td>
    </tr>
  )
}
PortfolioRow.propTypes = {
  portfolio: PropTypes.object.isRequired,
  handler: PropTypes.func.isRequired,
}

class PortfolioTable extends Component {

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    editLinkHandler: PropTypes.func.isRequired,
  }

  componentDidMount () {
    const { dispatch, fetchPortfolios } = this.props
    dispatch(fetchPortfolios())
  }

  headers = [
    'Sags nr.', 'Navn', 'Firma navn', 'Kontaktperson', 'Oprettet', 'Opdateret', 'Vis'
  ]

  render () {
    const { list, editLinkHandler, hasEditRights } = this.props

    return (
      <div>
        {hasEditRights && <div className="pull-right">
          <Link to="/admin/portfolios/new" className="btn btn-primary">Opret Portefølje</Link>
        </div>}
        <h3>
          Porteføljer
        </h3>
        <hr />
        <table className="table table-striped table-condensed table-hover">
          <thead>
            <tr>
              {
                this.headers.map((header, idx) => <th key={ idx }>{ header }</th> )
              }
            </tr>
          </thead>
          <tbody>
            {
              list.map((portfolio, idx) => <PortfolioRow key={ idx } portfolio={ portfolio } handler={ editLinkHandler } />)
            }
          </tbody>
        </table>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    list: state.data.portfolio.list.content,
    hasEditRights : state.auth && state.auth.role === 'admin'
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    fetchPortfolios: actions.fetchPortfolios,
    editLinkHandler: (uri) => (ev) => dispatch(push(uri))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PortfolioTable)
