import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import NavBar from 'client/components/NavBar'
import Spinner from 'client/components/Spinner'
import { ListLink } from 'client/components/NavLink'
import Notifications from 'client/components/Notifications'
import actions from 'client/actions'
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListAltIcon from '@material-ui/icons/ListAlt';
import { withStyles } from '@material-ui/core/styles';
import {styles} from 'common/lib/styles';

class PortfolioWrapper extends Component {

  static propTypes = {
    params: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
  }

  onClickLogout = ev => {
    ev.preventDefault()
    const { dispatch, logout } = this.props
    dispatch(logout())
  }

  render () {
    const { params, children } = this.props
    const portfolio_url = `/p/${params.id}`

    return (
        <div className={this.props.classes.root}>
          <Spinner />
          <NavBar>
            <ListLink to={portfolio_url}>
                <ListItem button>
                <ListItemIcon>
                    <ListAltIcon color="secondary" />
                </ListItemIcon>
                <ListItemText primary="Portefølje" />
                </ListItem>
            </ListLink>
          </NavBar>
          <div className={this.props.classes.container}>
          <Notifications />

          { children }
          </div>

      </div>
    )

  }
}

const mapStateToProps = state => {
  return {
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    logout: actions.logout
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PortfolioWrapper))
