import { map, reduce, clone, mergeWith, has } from 'lodash'

export function addPriceIndexAndTaxToEstimate(estimates, price_index) {
    return map(estimates, estimate => {
        estimate.estimate *= (price_index * 1.25)
        return estimate
    })
}

export function addPriceIndexAndTaxToParts(parts, price_index) {
    return map(parts, part => {
        part.establishment_cost *= (price_index * 1.25)
        part.maintenance_cost *= (price_index * 1.25)
        return part
    })
}

export function calculateOrdinaryTotal(estimates) {
    return (
        reduce(estimates, (acc, estimate) => {
            return estimate.year && !estimate.scaffolding && !estimate.construction_site && !estimate.adviser && !estimate.building_management && !estimate.free_form_total
                ? acc + (parseFloat(estimate.estimate) || 0)
                : acc
        }, null)
    )
}

export function calculateExtraordinaryTotalsByYear(parts) {
    return (
        reduce(parts, (acc, part) => {
            const est_year = part.establishment_year
            const value = part.establishment_cost / 1000
            const sum = acc[est_year] || null
            acc[est_year] = sum + value
            return acc
        }, {})
    )
}

export function calculateExtraordinaryTotal(parts) {
    return (
        reduce(parts, (acc, part) => {
            return part.establishment_cost
                ? acc + (part.establishment_cost / 1000)
                : acc
        }, null)
    )
}

export function calculateTotalForSCAB(estimates) {
    return (
        reduce(estimates, (acc, estimate) => {
            const value = parseFloat(estimate.estimate) || 0
            return estimate.scaffolding || estimate.construction_site || estimate.adviser || estimate.building_management
                ? acc + value
                : acc
        }, null)
    )
}

export function calculateOrdinaryTotalsByYear(estimates) {
    return (
        reduce(estimates, (acc, estimate) => {
            const est_year = estimate.year || 'establishment'
            const value = parseFloat(estimate.estimate) || 0
            const sum = acc[est_year] || null
            acc[est_year] = !estimate.scaffolding && !estimate.construction_site && !estimate.adviser && !estimate.building_management && !estimate.free_form_total
                ? sum + value
                : sum
            return acc
        }, {})
    )
}

// Totals for scaffolding and construction
export function calculateTotalsByYearForSC(estimates) {
    return (
        reduce(estimates, (acc, estimate) => {
            const est_year = estimate.year || 'establishment'
            const value = parseFloat(estimate.estimate) || 0
            const sum = acc[est_year] || null
            acc[est_year] = estimate.scaffolding || estimate.construction_site
                ? sum + value
                : sum
            return acc
        }, {})
    )
}

// Totals for adviser and building_management
export function calculateTotalsByYearForAB(estimates) {
    return (
        reduce(estimates, (acc, estimate) => {
            const est_year = estimate.year || 'establishment'
            const value = parseFloat(estimate.estimate) || 0
            const sum = acc[est_year] || null
            acc[est_year] = estimate.adviser || estimate.building_management
                ? sum + value
                : sum
            return acc
        }, {})
    )
}

export function calculateTotalsByYearForSCAB(estimates) {
    return (
        reduce(estimates, (acc, estimate) => {
            const est_year = estimate.year || 'establishment'
            const value = parseFloat(estimate.estimate) || 0
            const sum = acc[est_year] || null
            acc[est_year] = estimate.scaffolding || estimate.construction_site || estimate.adviser || estimate.building_management
                ? sum + value
                : sum
            return acc
        }, {})
    )
}

export function calculateTotalsById(estimates) {
    return (
        reduce(estimates, (acc, estimate) => {
            const part_id = estimate.part_id !== null && has(estimate, 'part_id')
                ? estimate.part_id
                : estimate.scaffolding
                    ? 'scaffolding'
                    : estimate.construction_site
                        ? 'construction_site'
                        : estimate.adviser
                            ? 'adviser'
                            : estimate.free_form
                                ? 'free_form'
                                : estimate.free_form_total
                                    ? 'free_form_total'
                                    : 'building_management'
            const value = parseFloat(estimate.estimate) || 0
            const sum = acc[part_id] || null
            acc[part_id] = estimate.year
                ? sum + value
                : sum
            return acc
        }, {})
    )
}

export function mergeTotals(t1, t2) {
    const t1_clone = clone(t1)
    return mergeWith(t1_clone, t2, (o, s) => (parseFloat(o) || 0) + s)
}
