import { combineReducers } from 'redux'
import { ActionTypes } from 'client/constants'
import { modelReducer, formReducer, modeled, actionTypes as formActions } from 'react-redux-form'
import startsWith from 'lodash/startsWith'
import moment from 'moment'
import { remove } from 'lodash'

const listReducer = (state = {
  content: [],
  is_valid: false,
}, action) => {
  switch (action.type) {
    case ActionTypes.RECEIVE_REQUEST:
      if (action.subtype === 'parts') {
        return {
          content: action.payload,
          is_valid: true,
        }
      }
      return state
    case ActionTypes.SEND_REQUEST:
      if (action.subtype === 'part') {
        if (action.request === 'create' || action.request === 'update' || action.request === 'delete') {
          return {
            content: [],
            is_valid: false,
          }
        }
      }
      return state
    case ActionTypes.INVALIDATE_DATA:
      return {
        content: [],
        is_valid: false,
      }
    case ActionTypes.PUBLISH_PART:
      return {
        content: [],
        is_valid: false,
      }
    default:
      return state
  }
}

const initialCurrentState = {
  id: null,
  plan_id: null,
  bips_id: null,
  published: false,
  title: null,
  integrated_year: null,
  remaining_lifetime: null,
  amount: null,
  internal_note: null,
  specification: null,
  condition: null,
  establishment: null,
  establishment_cost: null,
  establishment_cost_note: null,
  maintenance: null,
  maintenance_interval: null,
  maintenance_cost: null,
  maintenance_start: null,
  maintenance_cost_note: null,
  scaffolding: false,
  construction_site: false,
  adviser: false,
  building_management: false, 
  inspection: null,
  inspection_interval: null,
  inspection_start: null,
  images: [],
  bips: {},
  establishment_year: null,
}
const currentReducer = (state = initialCurrentState, action) => {
  switch (action.type) {
    case ActionTypes.RECEIVE_REQUEST:
      if (action.subtype === 'part') {
        if (action.request === 'create' || action.request === 'fetch') {
          return {
            ...state,
            ...action.payload,
          }
        }
      } else if (action.subtype === 'part-image') {
        if (action.request === 'create') {
          const images = state.images.concat([action.payload])
          return {
            ...state,
            images,
          }
        } else if (action.request === 'delete') {
          const images = state.images
          remove(images, {
            id: action.payload,
          })
          return {
            ...state,
          }
        }
      }
      return state
    case ActionTypes.PUBLISH_PART:
      return {
        ...state,
        published: action.payload,
      }
    case ActionTypes.INVALIDATE_DATA:
      return initialCurrentState
    default:
      return state
  }
}
const modelPart = modeled(currentReducer, 'data.part.current')

const autoSaveReducer = (state = {
  should_save: false,
  last_saved: NaN,
  is_saving: false,
  rand: 1,
}, action) => {
  switch (action.type) {
    case ActionTypes.SEND_REQUEST:
      if (action.subtype === 'part') {
        if (action.request === 'create' || action.request === 'update') {
          return {
            ...state,
            is_saving: true,
          }
        }
      }
      return state
    case ActionTypes.RECEIVE_REQUEST:
      if (action.subtype === 'part' || action.subtype === 'part-image') {
        if (action.request === 'fetch') {
          const last_saved = moment(action.payload.updated_at).unix()
          return {
            ...state,
            should_save: false,
            last_saved,
            is_saving: false,
          }
        }
        const now = moment().unix()
        return {
          ...state,
          should_save: false,
          last_saved: now,
          is_saving: false,
        }
      } else if (action.subtype === 'error') {
        return {
          ...state,
          should_save: false,
          is_saving: false,
        }
      }
      return state
    case ActionTypes.PUBLISH_PART: {
      const now = moment().unix()
      return {
        ...state,
        should_save: false,
        last_saved: now,
        is_saving: false,
      }
    }
    case formActions.CHANGE:
      if (!state.should_save && startsWith(action.model, 'data.part.current')) {
        return {
          ...state,
          should_save: true,
        }
      }
      return state
    case ActionTypes.CLEAR_AUTOSAVE:
      return {
        ...state,
        should_save: false,
        last_saved: NaN,
        is_saving: false,
      }
    case ActionTypes.FORCE_UPDATE_AUTOSAVE: {
      const rand = Date.now()
      return {
        ...state,
        rand,
      }
    }
    default:
      return state
  }
}


export default combineReducers({
  list: listReducer,
  current: modelPart,
  auto_save: autoSaveReducer,
  form: formReducer('data.part.current'),
})
