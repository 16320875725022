import PropTypes from 'prop-types';

const PartWrapper = ({ children }) => {
  return (
    children
  )
}
PartWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

export default PartWrapper
