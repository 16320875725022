import kindof from 'kind-of'
import moment from 'moment'
import createerror from 'create-error'
import { has, reduce, size, merge, includes, isNaN } from 'lodash'
import { translate } from './i18n'

export const ValidationError = createerror('ValidationError')

const emailRegex = /.+\@.+\..+/
export function isEmail (value) {
  return kindof(value) === 'string' && emailRegex.test(value)
}

export function parseIntOrNull (value) {
  const parsed = parseInt(value, 10)
  return isNaN(parsed)
    ? null
    : parsed
}

function castNumber (value) {
  const type = kindof(value)
  if (type !== 'number' && type !== 'string') {
    return false
  }
  var n = +value
  return ((n - n + 1) >= 0 && value !== '')
    ? n
    : false
}

function castInteger (value) {
  const n = castNumber(value)
  if (n !== undefined && n !== null && Number.isInteger(n)) {
    return n
  }
  return false
}

function castString (value) {
  const type = kindof(value)
  if (type === 'object') {
    return false
  } else if (type === 'string') {
    return value.trim()
  }
  return String(value)
}

const TRUE = [true, 'true', 't', '1']
const FALSE = [false, 'false', 'f', '0']
function castBoolean (value) {
  let result
  if (includes(TRUE, value)) {
    result = true
  } else if (includes(FALSE, value)) {
    result = false
  } else {
    throw new Error()
  }
  return result
}

function cast (type, value) {
  switch (type) {
    case 'number':
      return castNumber(value)
    case 'integer':
      return castInteger(value)
    case 'string':
      return castString(value)
    case 'boolean':
      return castBoolean(value)
    case 'email': {
      const email = castString(value)
      return isEmail(email) ? email : false
    }
    default:
      // if we don't know what it is, we don't want
      // it in the database ... return undefined
      return undefined
  }
}

export default function validate (modelSchema, data, extension, override = {}) {

  return new Promise((resolve, reject) => {

    // create the schema we will be using (possibly merging in extension and override)
    const schema = merge({}, modelSchema.base,
      (extension && has(modelSchema.extensions, extension))
        ? modelSchema.extensions[extension]
        : {},
      override
    )

    // pull out top level constraints
    const breakEarly = schema.breakEarly || false // default is false
    const strip = !(schema.strip === false) // default is true
    const ignoreErrors = schema.ignoreErrors || false

    const properties = schema.properties

    // first check that our data has all required keys
    const hasRequired = reduce(properties, (acc, value, key) => {
      // break early if we must
      // XXX size call can be optimized away
      if (breakEarly && size(acc.errors) > 0) {
        return acc
      }
      if (value.required && !has(data, key)) {
        acc.errors[key] = translate('errors.validation.required_key_missing', { label: key })
      }
      return acc
    }, { data: {}, errors: {} })


    // if we should break early and the previous reducer
    // returned errors, return immediately
    if (breakEarly && size(hasRequired.errors) > 0 && !ignoreErrors) {
      return reject(new ValidationError('Validation Error', { errors: hasRequired.errors }))
    }

    const result = reduce(data, (acc, value, key) => {
      // break early if we must
      // XXX size call can be optimized away
      if (breakEarly && size(acc.errors) > 0) {
        return acc
      }

      // if schema does not have the key and we should not strip
      // return an error and possibly break
      if (!has(properties, key)) {
        // if we should strip, just return quickly
        if (strip) {
          return acc
        }
        acc.errors[key] = translate('errors.validation.illegal_key_provided', { key })
        return acc
      }

      const rules = properties[key]
      // false by default
      const required = (has(rules, 'required') && rules.required === true)
      // false by default
      const strict = (has(rules, 'strict') && rules.strict === true)
      // false by default
      const allowNull = (has(rules, 'allowNull') && rules.allowNull === true)
      // false by default
      const forceStrip = (has(rules, 'strip') && rules.strip === true)

      // FORCE STRIP
      //////////////

      if (forceStrip) {
        return acc
      }

      // UNDEFINED
      //////////////////////////////

      // if the value is undefined, skip, unless
      // it is required, then error and skip
      if (value === void 0) {
        if (required) {
          const label = rules.label || key
          acc.errors[key] = translate('errors.validation.required_key_missing', { label })
        }
        return acc
      }

      // NULL
      //////////////////////////////

      // if value is null, check if we should error
      // XXX this would prevent a string from being set to 'null'
      if (value === null || value === 'null') {
        if (!allowNull) {
          if (required) {
            const label = rules.label || key
            acc.errors[key] = translate('errors.validation.illegal_null', { label })
          }
        } else {
          acc.data[key] = null
        }
        return acc
      }

      // BOOLEANS
      //////////////////////////////

      if (rules.type === 'boolean') {
        if (strict) {
          if (value === false || value === true) {
            acc.data[key] = value
          } else {
            if (required) {
              const label = rules.label || key
              acc.errors[key] = translate('errors.validation.not_a_boolean', { label })
            }
          }
        } else {
          // XXX the only way we can test if a boolean is not
          // really a boolean is by throwing (or possibly using null)
          try {
            const castValue = cast('boolean', value)
            acc.data[key] = castValue
          } catch (e) {
            console.log('something blew up:', e)
            if (required) {
              const label = rules.label || key
              acc.errors[key] = translate('errors.validation.not_a_boolean', { label })
            }
          }
        }
        return acc
      }

      // STRINGS
      //////////////////////////////

      if (rules.type === 'string') {
        const castValue = cast(rules.type, value)
        if (castValue === false) {
          const label = rules.label || key
          acc.errors[key] = translate('errors.validation.not_a_string', { label })
        } else {
          if (required && castValue === '') {
            const label = rules.label || key
            acc.errors[key] = translate('errors.validation.required_key_missing', { label })
          }
          if (breakEarly && has(acc.errors, key)) {
            return acc
          }
          if (has(rules, 'allowEmpty') && castValue === rules.allowEmpty) {
            acc.data[key] = null
            return acc
          }
          // TODO check if minLength is a positive integer
          // perhaps also check if minLength is smaller than maxLength
          if (has(rules, 'minLength') && castValue.length < rules.minLength) {
            const label = rules.label || key
            acc.errors[key] = translate('errors.validation.string_too_short', { label, length: rules.minLength })
          }
          if (breakEarly && has(acc.errors, key)) {
            return acc
          }
          if (has(rules, 'maxLength') && castValue.length > rules.maxLength) {
            const label = rules.label || key
            acc.errors[key] = translate('errors.validation.string_too_long', { label, length: rules.maxLength })
          }
          if (breakEarly && has(acc.errors, key)) {
            return acc
          }
          if (has(rules, 'regex') && !rules.regex.test(castValue)) {
            const label = rules.label || key
            acc.errors[key] = translate('errors.validation.regex_invalid', { label })
          }
          if (!has(acc.errors, key)) {
            acc.data[key] = castValue
          }
        }
        return acc
      }

      // DATES
      //////////////////////////////

      if (rules.type === 'date') {
        if (kindof(value) !== 'string') {
          acc.errors[key] = rules
        } else {
          if (has(rules, 'allowEmpty') && value === rules.allowEmpty) {
            acc.data[key] = null
            return acc
          }
          // TODO check that format is a string or moment.ISO_8601
          let format = moment.ISO_8601
          if (has(rules, 'format')) {
            format = rules.format
          }
          const date = moment(value, format, strict)
          if (!date.isValid()) {
            const label = rules.label || key
            if (has(rules, 'format')) {
              acc.errors[key] = translate('errors.validation.invalid_date_format', { label, format })
            } else {
              acc.errors[key] = translate('errors.validation.invalid_date', { label })
            }
          }
        }
        // TODO finish!
        if (!has(acc.errors, key)) {
          acc.data[key] = value
        }
        return acc
      }

      // integers we want to cast
      const castValue = cast(rules.type, value)
      if (castValue === false) {
        if (has(rules, 'allowEmpty') && value === rules.allowEmpty) {
          acc.data[key] = null
          return acc
        }

        const label = rules.label || key
        acc.errors[key] = translate('errors.validation.invalid_type', { label })
      } else {
        if (has(rules, 'minValue')) {
          if (castValue < rules.minValue) {
            const label = rules.label || key
            acc.errors[key] = translate('errors.validation.value_too_small', { label, minValue: rules.minValue })
          }
        }
        if (has(rules, 'maxValue')) {
          if (castValue > rules.maxValue) {
            const label = rules.label || key
            acc.errors[key] = translate('errors.validation.value_too_large', { label, maxValue: rules.maxValue })
          }
        }

        if (!has(acc.errors, key)) {
          acc.data[key] = castValue
        }
      }

      return acc
    }, hasRequired)

    // reject with errors if present or resolve with data
    if (size(result.errors) > 0 && !ignoreErrors) {
      return reject(new ValidationError('Validation Error', { errors: result.errors }))
    }
    return resolve(result)
  })
}
