import { combineReducers } from 'redux'
import { ActionTypes } from 'client/constants'
import { modelReducer, formReducer, modeled, actionTypes as formActions } from 'react-redux-form'
import has from 'lodash/has'
import moment from 'moment'

const estimateByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.RECEIVE_REQUEST:
      if (action.subtype === 'estimates_by_id') {
        if (action.request === 'fetch') {
          return action.payload
        }
        return {
          ...state,
          [action.payload.id]: action.payload,
        }
      }
      return state
    case ActionTypes.INVALIDATE_DATA:
      return {}
    default:
      return state
  }
}

const estimateByYearReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.RECEIVE_REQUEST:
      if (action.subtype === 'estimates_by_year') {
        if (action.request === 'fetch') {
          return action.payload
        }
        const year = action.payload.year || 'establishment'
        const yearObject = state[year] || {}
        const part_id = has(action.payload, 'part_id')
          ? action.payload.part_id
          : action.payload.scaffolding
            ? 'scaffolding'
            : action.payload.construction_site
             ? 'construction_site'
             : action.payload.adviser
              ? 'adviser'
              : action.payload.free_form
                ? 'free_form'
                : action.payload.free_form_total
                  ? 'free_form_total'
                  : 'building_management'
        yearObject[part_id] = action.payload.id
        return {
          ...state,
          [year]: yearObject,
        }
      }
      return state
    case ActionTypes.INVALIDATE_DATA:
      return {}
    default:
      return state
  }
}

const estimateTotalReducer = (state = {
  by_plan: null,
  by_id: {},
  by_year: {},
  extraordinary_by_year: {},
  extraordinary_total: null,
  ordinary_total: null,
  totals_by_year: {},
}, action) => {
  switch (action.type) {
    case ActionTypes.RECEIVE_REQUEST:
      if (action.subtype === 'estimates_totals') {
        const extraordinary_by_year = action.payload.extraordinary_by_year || state.extraordinary_by_year
        const extraordinary_total = action.payload.extraordinary_total || state.extraordinary_total
        return {
          by_plan: action.payload.by_plan,
          by_id: action.payload.by_id,
          by_year: action.payload.by_year,
          extraordinary_by_year,
          extraordinary_total,
          ordinary_total: action.payload.ordinary_total,
          totals_by_year: action.payload.totals_by_year,
        }
      }
      return state
    case ActionTypes.INVALIDATE_DATA:
      return {
        by_plan: null,
        by_id: {},
        by_year: {},
        extraordinary_by_year: {},
        extraordinary_total: null,
        ordinary_total: null,
        totals_by_year: {},
      }
    default:
      return state
  }
}

const isValidReducer = (state = false, action) => {
  switch (action.type) {
    case ActionTypes.RECEIVE_REQUEST:
      if (action.subtype === 'estimates_by_id') {
        return true
      }
      return state
    case ActionTypes.SEND_REQUEST:
      // Also invalidate if start year changes
      if (action.subtype === 'part' || action.subtype === 'plan') {
        if (action.request === 'create' || action.request === 'update') {
          return false
        }
      }
      return state
    case ActionTypes.INVALIDATE_DATA:
      return false
    default:
      return state
  }
}

export default combineReducers({
  is_valid: isValidReducer,
  by_id: estimateByIdReducer,
  by_year: estimateByYearReducer,
  totals: estimateTotalReducer,
})
