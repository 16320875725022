import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { StickyContainer, Sticky } from 'react-sticky'
import { Link } from 'react-router'
import { connect } from 'react-redux'
import actions from 'client/actions'
import { merge } from 'lodash'
import { push } from 'react-router-redux'

class UserNew extends Component {

  static propTypes = {
    user: PropTypes.object.isRequired,
    createUser: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
  }

  constructor (props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleCreateUser = this.handleCreateUser.bind(this)
  }

  componentWillMount () {
    const { user, role, dispatch } = this.props
    this.setState({ user })
    if (role !== 'admin') {
      dispatch(push('/admin/users'))
    }
  }

  handleCreateUser (ev) {
    ev.preventDefault()
    const { dispatch, createUser } = this.props
    const { user } = this.state
    dispatch(createUser(user))
  }

  handleChange (field) {
    return ev => {
      const value = ev.target.value
      const state = merge(this.state, {
        user: {
          [field]: value,
        },
      })
      this.setState(state)
    }
  }

  render () {
    const { id, email, name, phone, password, role } = this.state.user

    return (
      <StickyContainer>

        <Sticky>
        {() => (
            <div className="row sub-header">
                <div className="action-bar pull-right">
                    <Link to="/admin/users" className="btn btn-primary">Tilbage</Link>
                    &nbsp;
                    <button className="btn btn-primary" onClick={ this.handleCreateUser }>Gem</button>
                </div>
                <h3>
                    Ny Bruger
                </h3>
            </div>
        )}
        </Sticky>

        <div className="well">

          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              className="form-control"
              value={ email }
              onChange={ this.handleChange('email') }
              />
          </div>

          <div className="form-group">
            <label>Navn</label>
            <input
              type="text"
              className="form-control"
              value={ name }
              onChange={ this.handleChange('name') }
              />
          </div>

          <div className="form-group">
            <label>Telefon</label>
            <input
              type="text"
              className="form-control"
              value={ phone }
              onChange={ this.handleChange('phone') }
              />
          </div>

          <div className="form-group">
            <label>Adgangskode</label>
            <input
              type="text"
              className="form-control"
              value={ password }
              onChange={ this.handleChange('password') }
              />
          </div>

          <div className="form-group">
            <label>Rolle</label>
            <select
              className="form-control"
              value={ role }
              onChange={ this.handleChange('role') }
            >
              <option value="admin">Administrator</option>
              <option value="editor">Redaktør</option>
            </select>
          </div>

        </div>

      </StickyContainer>
    )
  }
}

const mapStateToProps = (state, props) => ({
  user: {},
  role: state.auth.role
})


const mapDispatchToProps = dispatch => ({
  dispatch,
  createUser: actions.createUser,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserNew)
