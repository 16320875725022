import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { TextField } from 'client/components/Field'
import { StickyContainer, Sticky } from 'react-sticky'
import actions from 'client/actions'
import FaqDialog from 'client/components/FaqDialog';

class FaqIndex extends Component {

    static propTypes = {
        faqs: PropTypes.object.isRequired,
        dispatch: PropTypes.func.isRequired,
        save: PropTypes.func.isRequired,
        change: PropTypes.func.isRequired,
        fetch: PropTypes.func.isRequired,
    }

    constructor (props) {
        super(props)
        this.state = {
            isDialogOpen: false,
        }
        this.handleCreateFaq = this.handleCreateFaq.bind(this)
        this.handleDialog = this.handleDialog.bind(this)
    }

    componentWillMount () {
        const { dispatch, fetchFaqs } = this.props
        dispatch(fetchFaqs())
    }


    handleCreateFaq () {
        const { dispatch, createFaq } = this.props
        dispatch(createFaq());
    }

    handleDialog (faq) {
        const { dispatch, fetchFaq } = this.props
        this.setState({
            isDialogOpen: !this.state.isDialogOpen
        })
        dispatch(fetchFaq(faq))
    }
    render () {
        const { faqs, role } = this.props
        const { isDialogOpen } = this.state
        const readOnly = role !== 'admin'
        return (
            <StickyContainer>
                <div className="well">
                    <div className="form-group">
                        <label>FAQ</label>
                    </div>
                    <div className="form-group">
                        <table className="table table-striped table-condensed table-hover">
                            <thead>
                            <tr>
                                <th>Spørgsmål</th>
                                <th>Svar</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {faqs.map((f, i) => (
                                <tr key={f.id}>
                                <td>{f.question ? f.question : 'Spørgsmål ' + (i+1)}</td>
                                <td>{f.answer}</td>
                                <td className="text-right"><button onClick={() => this.handleDialog(f)} disabled={ readOnly } className="btn btn-sm btn-primary">Rediger</button></td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                        <button onClick={this.handleCreateFaq} className="btn btn-primary">Opret FAQ</button>
                    </div>
                </div>
                <FaqDialog handleDialog={this.handleDialog} isDialogOpen={isDialogOpen} />
            </StickyContainer>
        )
    }
}

const mapStateToProps = state => {
    return {
        faqs: state.data.faq.list.content,
        role: state.auth.role,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatch,
        createFaq: actions.createFaq,
        fetchFaqs: actions.fetchFaqs,
        fetchFaq: actions.fetchFaq
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FaqIndex)
