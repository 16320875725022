import React, { Component } from 'react'
import { connect } from 'react-redux'
import numeral from 'common/lib/numeral'

class PartRow extends Component {

  render () {
    const { part, estimates: { by_id, by_year, totals }, years, linkHandler, customer_id } = this.props;
    const { id, title, maintenance_interval, plan_id, bips_id } = part
    const estimateCells = years.map(year => {
        const extraordinary = part.establishment_year == year && part.establishment_cost ? numeral(part.establishment_cost/1000).format('0,0.0') : null
        if (by_year[year] && by_year[year][id]) {
            const currentYear = by_year[year]
            const currentEstimate = currentYear[id]
            return <td key={ year }>{ numeral(by_id[currentEstimate].estimate).format('0,0.0') } <div><span className="text-warning">{ extraordinary }</span></div></td>
        }
        return <td key={ year }><div><span className="text-warning">{ extraordinary }</span></div></td>
    })

    let total = (totals.by_id[id])
    if (isNaN(total)) {
        total = 0
    }
    return (
         <tr>
            <td key="Title">
                <a onClick={linkHandler}>{ title }</a>
                <br />
                <span className="text-muted small">Frekv.: { maintenance_interval } år | BIPS: { bips_id }</span>
            </td>
            { estimateCells }
            <td key="Samlet">{ numeral(total).format('0,0.0') }</td>
        </tr>
    )
  }
}


const mapStateToProps = state => {
  return {
    plan: state.data.customer.plan
  }
}

const mapDispatchToProps = dispatch => ({
  dispatch
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PartRow)
