import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { StickyContainer, Sticky } from 'react-sticky'
import S3Uploader from 'react-s3-uploader'
import { Link } from 'react-router'
import { connect } from 'react-redux'
import actions from 'client/actions'
import { API_URI } from 'client/constants'
import { find, merge } from 'lodash'

class UserEdit extends Component {

  static propTypes = {
    user: PropTypes.object.isRequired,
    saveUser: PropTypes.func.isRequired,
    deactivateUser: PropTypes.func.isRequired,
    activateUser: PropTypes.func.isRequired,
    updatePassword: PropTypes.func.isRequired,
    uploadImage: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
  }

  constructor (props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleChangePassword = this.handleChangePassword.bind(this)
    this.handleSaveUser = this.handleSaveUser.bind(this)
    this.handleSavePassword = this.handleSavePassword.bind(this)
    this.handleUserStatus = this.handleUserStatus.bind(this)
    this.onUploadProgress = this.onUploadProgress.bind(this)
    this.onFinishUpload = this.onFinishUpload.bind(this)
    this.state = {
      user: {},
      isUploading: false,
    }
  }

  componentWillMount () {
    const { user } = this.props
    const state = merge(this.state, { user })
    this.setState(state)
  }

  onUploadProgress (percentage, message, signResult) {
    const state = merge(this.state, {
      isUploading: true,
    })
    this.setState(state)
  }

  onFinishUpload (result) {
    const { dispatch, uploadImage, user } = this.props
    dispatch(uploadImage(user.id, result.filename))
    const state = merge(this.state, {
      isUploading: false,
      user: {
        s3_image_id: result.filename,
      },
    })
    this.setState(state)
  }

  handleSaveUser (ev) {
    ev.preventDefault()
    const { dispatch, saveUser } = this.props
    const { user } = this.state
    dispatch(saveUser(user))
  }

  handleSavePassword (ev) {
    ev.preventDefault()
    const { dispatch, updatePassword } = this.props
    const { user, new_password } = this.state
    dispatch(updatePassword(user.id, new_password))
  }

  handleChangePassword (ev) {
    ev.preventDefault()
    const state = merge(this.state, {
      new_password: ev.target.value,
    })
    this.setState(state)
  }

  handleChange (field) {
    return ev => {
      const value = ev.target.value
      const state = merge(this.state, {
        user: {
          [field]: value,
        },
      })
      this.setState(state)
    }
  }

  handleUserStatus (deleted) {
    return ev => {
      ev.preventDefault()
      const { dispatch, deactivateUser, activateUser } = this.props
      const { user } = this.state
      const ok = window.confirm('Er du sikker?')
      if (ok) {
        if (deleted) {
          dispatch(activateUser(user.id))
        }else {
          dispatch(deactivateUser(user.id))
        }
      }
    }
  }

  render () {
    const { user, isUploading, new_password } = this.state
    const { role } = this.props
    // const { id, email, name, phone, new_password, s3_image_id } = this.state.user
    const readOnly = role !== 'admin'
    return (
      <StickyContainer>

        <Sticky>
        {() => (
            <div className="row sub-header">
                <div className="action-bar pull-right">
                    <Link to="/admin/users" className="btn btn-primary">Tilbage</Link>
                    {
                    readOnly
                        ? null
                        : (
                        <span>
                            &nbsp;
                            <button className="btn btn-primary" onClick={ this.handleSaveUser }>Gem</button>
                        </span>
                        )
                    }
                </div>
                <h3>
                    Rediger Bruger
                </h3>
            </div>
        )}
        </Sticky>

        <div className="well">

          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              className="form-control"
              value={ user.email }
              onChange={ this.handleChange('email') }
              disabled={ readOnly }
            />
          </div>

          <div className="form-group">
            <label>Navn</label>
            <input
              type="text"
              className="form-control"
              value={ user.name }
              onChange={ this.handleChange('name') }
              disabled={ readOnly }
              />
          </div>

          <div className="form-group">
            <label>Telefon</label>
            <input
              type="text"
              className="form-control"
              value={ user.phone }
              onChange={ this.handleChange('phone') }
              disabled={ readOnly }
              />
          </div>

          <div className="form-group">
            <label>Rolle</label>
            <select
              className="form-control"
              value={ user.role }
              onChange={ this.handleChange('role') }
              disabled={ readOnly }
              >
              <option value="admin">Administrator</option>
              <option value="editor">Redaktør</option>
            </select>
          </div>

        </div>

        <div className="well">
          <div className="form-group">
            <label>Billede</label>
            {
              (user.s3_image_id && !isUploading)
                ? (
                    <img
                      src={ `https://ik.imagekit.io/aw3ddey5g/${user.s3_image_id}` }
                      className="img-circle"
                      style={ { maxWidth: '100px' } }
                      />
                  )
                : null
            }
            {
              isUploading
                ? (
                    <div className="uploadSpinner">
                      <i className="fa fa-cog fa-spin fa-5x"></i>
                      <span>Uploading</span>
                    </div>
                  )
                : (
                    <S3Uploader
                      className="form-control"
                      accept="image/*"
                      capture="camera"
                      signingUrl="/s3/sign"
                      uploadRequestHeaders={ { 'x-amz-acl': 'public-read' } }
                      contentDisposition="auto"
                      server={ API_URI }
                      onProgress={ this.onUploadProgress }
                      onFinish={ this.onFinishUpload }
                      disabled={ readOnly }
                      />
                  )
            }
          </div>
        </div>

        <div className="well">

          <div className="form-group">
            <label>Ny Adgangskode</label>
            <input
              type="text"
              className="form-control"
              value={ new_password }
              onChange={ this.handleChangePassword }
              disabled={ readOnly }
            />
            <button
              onClick={ this.handleSavePassword }
              className="btn btn-primary"
              disabled={ readOnly }
              >
              Sæt ny adgangskode
            </button>
          </div>

        </div>

      </StickyContainer>
    )
  }
}

const mapStateToProps = (state, props) => {
  const id = props.params.id
  const users = state.data.users.list
  const user = find(users, user => user.id == id) || {} // eslint-disable-line eqeqeq
  return {
    user,
    role: state.auth.role
  }
}

const mapDispatchToProps = dispatch => ({
  dispatch,
  saveUser: actions.saveUser,
  deactivateUser: actions.deactivateUser,
  activateUser: actions.activateUser,
  updatePassword: actions.updatePassword,
  uploadImage: actions.uploadImage,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserEdit)
