import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import {styles} from 'common/lib/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from 'client/components/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { Link } from 'react-router'

class PartDialog extends Component {

  render () {
    const {classes, part, type, customerId, modalVisible, closeModal} = this.props;
    return (
        <Dialog onClose={closeModal} open={modalVisible}>
            {part && <>
            <DialogTitle onClose={closeModal}>
                {part.title}
            </DialogTitle>
            <DialogContent dividers>
                { part.condition_text ? <>
                <h4 className="text-center">Tilstandsvurdering</h4>
                <p className={classes.paragraphMargin}>
                        {part.condition_text.split('\n').map((line, idx) => {
                            return (
                                <span key={ idx }>
                                { line }
                                <br />
                                </span>
                            )
                        })}
                </p></> : null }
                <h4 className="text-center">Specifikation</h4>
                <p className={classes.paragraphMargin}>
                { part.specification }
                </p>
                <h4 className="text-center">
                {
                    type === 'maintenance'
                    ? 'Vedligeholdelse'
                    : 'Eftersyn'
                }
                </h4>
                <p className={classes.paragraphMargin}>
                { part[type] }
                </p>
                <h4 className="text-center">Billeder</h4>
                <div className="row image-gallery">
                {
                    part.images.map((image, idx) =>
                    <div key={ idx } className="col-md-4">
                        <img src={ `https://ik.imagekit.io/aw3ddey5g/${image.s3_image_id}?tr=w-400,h-233` } className="img-responsive" />
                    </div>
                    )
                }
            </div>
            </DialogContent>
            <DialogActions>
                <Link to={ `/c/${customerId}/parts/${part.id}` } className="btn btn-primary">Vis bygningdel</Link>
            </DialogActions>
            </>}
        </Dialog>
    )
  }
}


const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = dispatch => ({
  dispatch
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PartDialog))