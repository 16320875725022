import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Field, actions as formActions } from 'react-redux-form'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import moment from 'moment'
import classnames from 'classnames'
import { StickyContainer, Sticky } from 'react-sticky'
import DateTime from 'client/components/DateTime'
import { PlanStatus } from '../Plans/index'
import { AddPlanModal } from './index'
import { sortBy, find, map, orderBy } from 'lodash'
import { NavLink } from 'client/components/NavLink'
import classNames from 'classnames'

import {
  InputField,
  TextField,
  SelectField,
} from 'client/components/Field'

import actions from 'client/actions'

const PlanRow = ({ plan, handleDelete, hasEditRights , portfolio_start_year}) => {
  const {
    id, case_no, status, name, zip_code,
    internal_contact, published, archived,
    created_at, updated_at, erected, area, portfolio_no, start_year
  } = plan

  function isActive(start_year, published) {
    return start_year == portfolio_start_year && published
  }

  return (
    <tr>
      <td key="case_no">{ case_no }</td>
      <td key="created_at" className="hidden-xs hidden-sm"><DateTime format="DD-MM-YYYY" source={ created_at } /></td>
      <td key="inclusive" className="hidden-xs">{ isActive(start_year, published) ? <span className="label label-success">Aktiv</span> : <span className="label label-warning">In-aktiv</span>}</td>
      <td key="status" className="hidden-xs"><PlanStatus published={ published } archived={ archived } /></td>
      <td key="start_year" className="hidden-xs">{ start_year}</td>
      <td key="name">{ name }</td>
      <td key="zip_code">{ zip_code }</td>
      <td key="internal_contact_name">{ internal_contact.name }</td>
      <td key="preview_url"><a href={ `/c/${id}` } target="_blank">Vis</a></td>
      {hasEditRights &&
        <td key="remove">
          <button className="btn btn-danger btn-sm" onClick={ handleDelete }>Fjern</button>
        </td>
      }

    </tr>
  )
}
PlanRow.propTypes = {
  plan: PropTypes.object.isRequired,
}

class Portfolio extends Component {

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  }

  constructor (props) {
    super(props)
    this.handleSave = this.handleSave.bind(this)
    this.handlePublish = this.handlePublish.bind(this)
  }

  state = {
    modalAddVisible: false,
    modalDeleteVisible: false,
  }

  componentDidMount () {
    const { dispatch, fetchPlans, fetchPortfolio, fetchCustomers, fetchInternalContacts, params, hasEditRights } = this.props
    if (!hasEditRights) {
      this.headers.pop()
    }
    dispatch(fetchPlans())
    dispatch(fetchCustomers())
    if (params.id) {
      dispatch(fetchPortfolio(params.id))
      // TODO: Fetch portfolio if edit params, if not do nothing
    } else {
      dispatch(fetchInternalContacts())
    }
  }



  componentWillUnmount () {
    const { dispatch, invalidateData } = this.props
    dispatch(invalidateData())
  }

  headers = [
    'Sagsnr.', 'Oprettet', 'Inkluderet', 'Status', 'Start år',  'Navn', 'Postnr',
    'Ansvarlig', 'Vis', 'Fjern'
  ]

  handleSave () {
    const { dispatch, savePortfolio, params, portfolio, form, form_model } = this.props
    //TODO: Validate save
    const action = params.id ? 'update' : 'create'
    dispatch(savePortfolio(portfolio, action))
  }

  handlePublish () {
    const { dispatch, publishPortfolio, params, portfolio, form, form_model } = this.props
    //TODO: Validate save
    const action = !portfolio.published
    dispatch(publishPortfolio(portfolio, action))
  }

  handleAddPlan = (plan_id) => e => {
    const { dispatch, addPlanToPortfolio, params, plans } = this.props
    let { portfolio_start_years } = this.props
    // TODO: Dispatch add plan action
    const plan = find(plans, {id: parseInt(plan_id)})
    dispatch(addPlanToPortfolio(params.id, plan))
    this.toggleAddPlanModal()
    if (plan.start_year && !find(portfolio_start_years, {year: plan.start_year})) { //TODO: move to reducer / action
      portfolio_start_years.push({year: plan.start_year})
      portfolio_start_years = orderBy(portfolio_start_years, 'year', 'asc')
    }
  }

  handleDeletePlan = (plan_id, plan_name) => e => {
    const { dispatch, deletePlanFromPortfolio, params, plans } = this.props
    const plan = find(plans, {id: parseInt(plan_id)})
    if (window.confirm(`Er du sikker på du vil fjerne ${plan_name} fra porteføljen?`)) {
      // TODO: Dispatch delete plan action
      dispatch(deletePlanFromPortfolio(params.id, plan))
    }
  }

  toggleAddPlanModal = _ => {
    this.setState({ modalAddVisible: !this.state.modalAddVisible })
  }

  renderPlans = _ => {
    const { plans, portfolio_plans, hasEditRights } = this.props

    return (
      <div>

        <div className="row sub-header">
          {hasEditRights &&
            <div className="pull-right action-bar">
              <button className="btn btn-primary" onClick={ this.toggleAddPlanModal } >Tilføj Driftsplan</button>
            </div>
          }
          <h3>Tilknyttede Driftsplaner</h3>
        </div>

        <table className="table table-striped table-condensed table-hover">
          <thead>
            <tr>
              {
                this.headers.map((header, idx) =>  <th key={ idx }>{ header }</th>)
              }
            </tr>
          </thead>
          <tbody>
            { portfolio_plans.map((plan, idx) => <PlanRow key={ idx } plan={ plan } handleDelete={ this.handleDeletePlan(plan.id, plan.name) } hasEditRights={ hasEditRights } portfolio_start_year={this.props.current.start_year} />) }
          </tbody>
        </table>

        {hasEditRights &&
          <AddPlanModal visible={ this.state.modalAddVisible } plans={ plans } handleAdd={ this.handleAddPlan } onClose={ this.toggleAddPlanModal } />}
      </div>
    )
  }

  render () {
    const { form_model, customers, plans, portfolio_plans, hasEditRights, portfolio_start_years, internal_contacts } = this.props;
    const editView = this.props.params.id;
    const published = this.props.current.published;
    const readOnly = !hasEditRights;

    const draftLabel = published
      ? { name: 'Sæt som kladde', icon: 'fa-lock' }
      : { name: 'Udgiv', icon: 'fa-unlock' };

    return (
      <StickyContainer>

          <Sticky>
            {() => (
            <div className="row sub-header">
            {hasEditRights &&
            <div className="pull-right action-bar">
              <Link to="/admin/portfolios" className="btn btn-primary">Tilbage</Link>&nbsp;
              <button className={ classNames('btn btn-primary', { 'btn-warning': published }) } onClick={ this.handlePublish } >
                <i className={ `fa ${draftLabel.icon}` }></i> {draftLabel.name}
              </button>&nbsp;
              <button className="btn btn-danger"><i className="fa fa-trash"></i> Slet</button>&nbsp;
              <button className="btn btn-primary" onClick={ this.handleSave}>Gem</button>
            </div>}
            <h3>
              { editView ? 'Rediger Portefølje' : 'Opret Portefølje' }
            </h3>
            <div>
              <p className="text-muted">Portefølje: <NavLink className="plan" to={ `/p/${this.props.current.id}` } target="_blank">Vis&nbsp;&nbsp;<i className="fa fa-external-link" aria-hidden="true"></i></NavLink></p>
              {!hasEditRights &&
                <p className="text-muted text-danger"><i className="fa fa-lock"></i> <small>Du har ikke adgang til at redigere denne portefølje</small></p>}
            </div>
            </div>)}
          </Sticky>


        <div className="row">
          <div className="col-md-12">
            <div className="well">

              <div className="row">

                <div className="col-md-6">
                  <InputField
                    model={ `${form_model}.name` }
                    label="Portefølje navn"
                    className="form-control"
                    disabled={ readOnly }
                  />
                  <div className="row">

                    <div className="col-xs-12">
                      <InputField
                        model={ `${form_model}.company_name` }
                        label="Firma navn"
                        className="form-control"
                        disabled={ readOnly }
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-xs-6">
                      <InputField
                        model={ `${form_model}.contact_name` }
                        label="Kontaktperson"
                        className="form-control"
                        disabled={ readOnly }
                      />
                    </div>
                    <div className="col-xs-6">
                      <InputField
                        model={ `${form_model}.contact_email` }
                        label="Kontakt email"
                        type="email"
                        className="form-control"
                        disabled={ readOnly } />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-6">
                      <InputField
                        model={ `${form_model}.contact_phone` }
                        label="Kontakt tlf."
                        className="form-control col-xs-6"
                        disabled={ readOnly } />
                    </div>
                    <div className="col-xs-6">
                      <SelectField
                        model={`${form_model}.customer_id`}
                        label="Vælg kunde"
                        className="form-control"
                        disabled={ readOnly }>
                        <option>Vælg kunde</option>
                        { customers.map(customer => <option key={customer.id} value={customer.id}>{customer.title}</option>) }
                      </SelectField>
                    </div>
                  </div>

                </div>
                <div className="col-md-6">

                  <TextField
                    model={ `${form_model}.address` }
                    label="Adresse"
                    className="form-control"
                    rows="5"
                    cols="40"
                    disabled={ readOnly }
                    />

                  <SelectField
                    model={ `${form_model}.start_year` }
                    label="Start år"
                    className="form-control"
                    disabled={ readOnly }>
                    <option value="">Start år</option>
                    { portfolio_start_years && portfolio_start_years.map(start_year => <option key={ start_year.year } value={ start_year.year }>{ start_year.year }</option>) }
                  </SelectField>


                   <SelectField
                   model={ `${form_model}.internal_contact_id` }
                   label="Plan1 kontaktperson"
                   className="form-control"
                   disabled={ readOnly }
                   defaultValue=''>
                   <option value="">---</option>
                   { internal_contacts.map(contact => <option key={ contact.id } value={ contact.id }>{ contact.name }</option>) }
                   </SelectField>


                </div>

              </div>

            </div> {/* END well */}
          </div> {/* END col-md-12 */}

        </div> {/* END row */}

        { editView ? this.renderPlans() : null }

      </StickyContainer>
    )
  }
}

const mapStateToProps = state => {
  const portfolio = state.data.portfolio.current
  const portfolio_plans = portfolio.plans ? sortBy(portfolio.plans, plan => plan.name) : []
  const customers = state.data.portfolio.form_data.customers ? state.data.portfolio.form_data.customers : []

  const ordered_plans = sortBy(
    map(state.data.plan.list.content, function(plan) {
      plan.disabled = find(portfolio_plans, {id: plan.id}) !== undefined
        return plan
    }), plan => plan.name)

  return {
    current: portfolio,
    form: state.data.portfolio.form,
    form_model: 'data.portfolio.current',
    plans: ordered_plans,
    portfolio_plans,
    portfolio,
    hasEditRights : state.auth && state.auth.role === 'admin',
    portfolio_start_years: state.data.portfolio.form_data.start_years, //TODO: Is this the right place?
    internal_contacts: state.data.portfolio.form_data.internal_contacts,
    customers
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    fetchPortfolio: actions.fetchPortfolio,
    fetchInternalContacts: actions.fetchInternalContacts,
    fetchPlans: actions.fetchPlans,
    savePortfolio: actions.savePortfolio,
    publishPortfolio: actions.publishPortfolio,
    addPlanToPortfolio: actions.addPlanToPortfolio,
    deletePlanFromPortfolio: actions.deletePlanFromPortfolio,
    invalidateData: actions.invalidateData,
    fetchCustomers: actions.fetchCustomers
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Portfolio)
