import { combineReducers } from 'redux'
import { ActionTypes } from 'client/constants'
import { findIndex } from 'lodash'

const readonlyReducer = (state = true, action) => {
  switch (action.type) {
    case ActionTypes.RECEIVE_PLAN_FOR_CUSTOMER:
      return action.payload.readonly
    default:
      return state
  }
}

const planReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.RECEIVE_PLAN_FOR_CUSTOMER:
      return {
        ...action.payload.plan,
      }
    case ActionTypes.RECEIVE_CUSTOMER_DATA:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

const partsReducer = (state = [], action) => {
  switch (action.type) {
    case ActionTypes.RECEIVE_PLAN_FOR_CUSTOMER:
      return action.payload.parts
    case ActionTypes.RECEIVE_NOTE_FOR_CUSTOMER: {
      const partIndex = findIndex(state, { id: action.payload.part_id })
      state[partIndex].notes.unshift(action.payload)
      state[partIndex] = Object.assign({}, state[partIndex])
      return state.concat()
    }
    case ActionTypes.DELETE_NOTE_FOR_CUSTOMER: {
      const partIndex = findIndex(state, { id: action.payload.part_id })
      const noteIndex = findIndex(state[partIndex].notes, { id: action.payload.note_id })
      const notes = [
        ...state[partIndex].notes.slice(0, noteIndex),
        ...state[partIndex].notes.slice(noteIndex + 1),
      ]
      state[partIndex].notes = notes
      state[partIndex] = Object.assign({}, state[partIndex])
      return state.concat()
    }
    default:
      return state
  }
}

const notesReducer = (state = [], action) => {
  switch (action.type) {
    case ActionTypes.RECEIVE_PLAN_FOR_CUSTOMER:
      return action.payload.notes
    case ActionTypes.RECEIVE_NOTE_FOR_CUSTOMER: {
      state.unshift(action.payload)
      return state
    }
    case ActionTypes.DELETE_NOTE_FOR_CUSTOMER: {
      const noteIndex = findIndex(state, { id: action.payload.note_id })
      return [
        ...state.slice(0, noteIndex),
        ...state.slice(noteIndex + 1),
      ]
    }
    default:
      return state
  }
}

const estimatesReducer = (state = {
  by_id: {},
  by_year: {},
  totals: {
    by_plan: null,
    by_id: {},
    by_year: {},
    by_year_for_sc: {},
    by_year_for_ab: {},
    extraordinary_by_year: {},
    extraordinary_total: null,
    ordinary_total: null,
    totals_by_year: {},
  },
}, action) => {
  switch (action.type) {
    case ActionTypes.RECEIVE_PLAN_FOR_CUSTOMER: {
      const estimates = action.payload.estimates
      const totals = action.payload.estimates.totals
      return {
        by_id: estimates.by_id,
        by_year: estimates.by_year,
        totals: {
          by_plan: totals.by_plan,
          by_id: totals.by_id,
          by_year: totals.by_year,
          by_year_for_sc: totals.by_year_for_sc,
          by_year_for_ab: totals.by_year_for_ab,
          extraordinary_by_year: totals.extraordinary_by_year,
          extraordinary_total: totals.extraordinary_total,
          ordinary_total: totals.ordinary_total,
          totals_by_year: totals.totals_by_year,
        },
      }
    }
    default:
      return state
  }
}

const systemTextReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.RECEIVE_PLAN_FOR_CUSTOMER: {
      const texts = action.payload.texts
      return texts
    }
    default:
      return state
  }
}

const projectsReducer = (state = [], action) => {
  switch (action.type) {
    case ActionTypes.RECEIVE_PLAN_FOR_CUSTOMER: {
      return action.payload.projects
    }
    default:
      return state
  }
}

const faqsReducer = (state = [], action) => {
  switch (action.type) {
    case ActionTypes.RECEIVE_PLAN_FOR_CUSTOMER: {
      return action.payload.faqs
    }
    default:
      return state
  }
}

export default combineReducers({
  plan: planReducer,
  parts: partsReducer,
  estimates: estimatesReducer,
  notes: notesReducer,
  systemTexts: systemTextReducer,
  faqs: faqsReducer,
  projects: projectsReducer,
  readonly: readonlyReducer,
})
