import fetch from 'isomorphic-fetch'
import { push } from 'react-router-redux'
import { actions as FormActions } from 'react-redux-form'
import { ActionTypes, API_URI } from 'client/constants'
import { notifyDanger, notifySuccess, clearNotifications } from 'client/components/Notifications/actions'
import validate, { ValidationError } from 'common/lib/validator'
import { PlanSchema } from 'common/schemas'
import api, { ApiError } from 'client/lib/apiHandler'

const sendRequest = (subtype, request) => {
    return {
        type: ActionTypes.SEND_REQUEST,
        subtype,
        request,
    }
}

const receiveRequest = (subtype, request, payload) => {
    return {
        type: ActionTypes.RECEIVE_REQUEST,
        subtype,
        request,
        payload,
    }
}

const receivePublishPlan = (publish) => {
    return {
        type: ActionTypes.PUBLISH_PLAN,
        payload: publish,
    }
}

export const fetchPlans = () => {

    return dispatch => {
        dispatch(sendRequest('plans', 'fetch'))

        api.get('/plans')
            .then(({ body }) => {
                dispatch(receiveRequest('plans', 'fetch', body))
            })
            .catch(error => {
                dispatch({ type: ActionTypes.RECEIVE_REQUEST, subtype: 'error' })
            })
    }
}

export const fetchPlan = (case_no) => {

    return dispatch => {
        dispatch(sendRequest('plan', 'fetch'))

        Promise.all([
            api.get(`/plans/${case_no}`),
            api.get('/users'),
        ])
            .then(([plan, contact]) => {
                dispatch(receiveRequest('plan', 'fetch', plan.body))
                dispatch(receiveRequest('internal_contacts', 'fetch', contact.body))
            })
            .catch(error => {
                if (error instanceof ApiError) {
                    dispatch(push('/admin/plans'))
                }
                dispatch({ type: ActionTypes.RECEIVE_REQUEST, subtype: 'error' })
            })
    }
}

export const fetchInternalContacts = () => {

    return dispatch => {
        dispatch(sendRequest('internal_contacts', 'fetch'))

        api.get('/users')
            .then(({ body }) => {
                dispatch(receiveRequest('internal_contacts', 'fetch', body))
            })
            .catch(error => {
                dispatch({ type: ActionTypes.RECEIVE_REQUEST, subtype: 'error' })
            })
    }
}

export const savePlan = (operation, plan) => {
    const uri = operation === 'create'
        ? '/plans'
        : `/plans/${plan.id}`

    const method = operation === 'create'
        ? 'post'
        : 'put'

    return (dispatch, getState) => {

        validate(PlanSchema, plan, false, { ignoreErrors: true })
            .then(result => {
                dispatch(sendRequest('plan', operation))

                dispatch(FormActions.setValidity('data.plan.current', true))
                dispatch(FormActions.setErrors('data.plan.current', result.errors))

                return api[method](uri, result.data)
            })
            .then(({ body }) => {
                dispatch(receiveRequest('plan', operation, body))

                if (operation === 'create') {
                    // since we don't know where we are in the path, replace create with edit
                    const path = getState().routing.locationBeforeTransitions.pathname
                    const editPath = path.replace('create', `edit/${body.id}`)
                    dispatch(push(editPath))
                }
                dispatch(clearNotifications('danger'))
            })
            .catch(error => {
                if (error instanceof ApiError) {
                    if (error.body && error.body.errorCode && error.body.errorCode === 1) {
                        dispatch(FormActions.setErrors('data.plan.current', { case_no: true }))
                        dispatch(notifyDanger('Sagsnr. er ikke unik', 'Error'))
                    } else {
                        dispatch(notifyDanger('Der skete en fejl', 'Error'))
                    }
                }
                dispatch({ type: ActionTypes.RECEIVE_REQUEST, subtype: 'error' })
            })
    }
}

const savePlanUpload = (id, s3_image_id) => {

    const body = { id, s3_image_id }

    return dispatch => {
        dispatch(sendRequest('plan-image', 'fetch'))
        api.put(`/plans/${id}/image`, body)
            .then(({ body }) => {
                dispatch(receiveRequest('plan-image', 'fetch', body))
            })
            .catch(error => {
                if (error instanceof ApiError) {
                    dispatch(notifyDanger('Der skete en fejl', 'Error'))
                }
                dispatch({ type: ActionTypes.RECEIVE_REQUEST, subtype: 'error' })
            })
    }
}

const deletePlanUpload = (id) => {

    return dispatch => {
        api.del(`/plans/${id}/image`)
            .then(({ body }) => {
                dispatch(receiveRequest('plan-image', 'delete', body))
            })
            .catch(error => {
                if (error instanceof ApiError) {
                    dispatch(notifyDanger('Der skete en fejl', 'Error'))
                }
                dispatch({ type: ActionTypes.RECEIVE_REQUEST, subtype: 'error' })
            })
    }
}

const saveFileUpload = (id, s3_file_id, mimetype, file_name) => {
    const body = { id, s3_file_id, mimetype, file_name }

    return dispatch => {
        dispatch(sendRequest('plan-file', 'create'))
        api.post(`/plans/${id}/file`, body)
            .then(({ body }) => {
                dispatch(receiveRequest('plan-file', 'create', body))
            })
            .catch(error => {
                if (error instanceof ApiError) {
                    dispatch(notifyDanger('Der skete en fejl', 'Error'))
                }
                dispatch({ type: ActionTypes.RECEIVE_REQUEST, subtype: 'error' })
            })
    }
}

const updateFileUpload = (plan_id, file) => {

    return dispatch => {
        dispatch(sendRequest('plan-file', 'update'))
        api.put(`/plans/${plan_id}/file/${file.id}`, { category: file.category, id: file.id })
            .then(({ body }) => {
                dispatch(receiveRequest('plan-file', 'update', body))
            })
            .catch(error => {
                if (error instanceof ApiError) {
                    dispatch(notifyDanger('Der skete en fejl', 'Error'))
                }
                dispatch({ type: ActionTypes.RECEIVE_REQUEST, subtype: 'error' })
            })
    }
}

const deleteFileUpload = (plan_id, id) => {

    return dispatch => {
        api.del(`/plans/${plan_id}/file/${id}`)
            .then(res => {
                dispatch(receiveRequest('plan-file', 'delete', id))
            })
            .catch(error => {
                if (error instanceof ApiError) {
                    dispatch(notifyDanger('Der skete en fejl', 'Error'))
                }
                dispatch({ type: ActionTypes.RECEIVE_REQUEST, subtype: 'error' })
            })
    }
}


const publishPlan = (plan_id, plan) => {

    return dispatch => {
        validate(PlanSchema, plan, 'publish')
            .then(result => {
                dispatch(FormActions.setValidity('data.plan.current', true))

                const body = { publish: true }
                return api.put(`/plans/${plan_id}/publish/`, body)
            })
            .then(res => {
                dispatch(receivePublishPlan(true))
                dispatch(notifySuccess('Plan er udgivet'))
            })
            .catch(error => {
                if (error instanceof ValidationError) {
                    dispatch(FormActions.setValidity('data.plan.current', true))
                    dispatch(FormActions.setErrors('data.plan.current', error.errors))
                    dispatch(notifyDanger('Plan er ikke komplet', 'Error'))
                } else if (error instanceof ApiError) {
                    if (error.body.status === 1) {
                        dispatch(notifyDanger('Alle bygningsdele skal være udgivet', 'Error'))
                    }
                    dispatch({ type: ActionTypes.RECEIVE_REQUEST, subtype: 'error' })
                }
            })
    }
}

const unpublishPlan = (plan_id) => {

    return dispatch => {

        const body = { publish: false }
        api.put(`/plans/${plan_id}/publish/`, body)
            .then(res => {
                dispatch(receivePublishPlan(false))
                dispatch(notifySuccess('Plan er sat som kladde'))
            })
            .catch(error => {
                if (error instanceof ApiError) {
                    dispatch(notifyDanger('Der skete en fejl', 'Error'))
                }
                dispatch({ type: ActionTypes.RECEIVE_REQUEST, subtype: 'error' })
            })
    }
}

export const fetchAssets = () => {
    return dispatch => {
        api.get(`/plans/assets`)
            .then(({ body }) => {
                dispatch({
                    type: ActionTypes.SET_ASSETS,
                    assets: body
                })
            });
    }
}


export default {
    fetchPlans,
    fetchPlan,
    fetchInternalContacts,
    savePlan,
    savePlanUpload,
    deletePlanUpload,
    publishPlan,
    unpublishPlan,
    saveFileUpload,
    deleteFileUpload,
    updateFileUpload,
    fetchAssets
}
