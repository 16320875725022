import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { Link } from 'react-router'
import classNames from 'classnames'
import DateTime from 'client/components/DateTime'
import actions from 'client/actions'
import { Condition } from 'client/components/Condition'
import { push } from 'react-router-redux'

const PartRow = ({ part, handler, params }) => {
    const {
        id, condition, title, amount,
        remaining_lifetime, inspection_interval,
        maintenance_interval, published, bips_id,
    } = part

    return (
        <tr onClick={ handler(`/admin/plans/edit/${params.id}/parts/edit/${id}`) }>
            <td key="ID">{ id }</td>
            <td key="Tilstand" className="hidden-xs"><Condition condition={ condition } hasText={ false } /></td>
            <td key="BIPS" className="hidden-xs">{ bips_id }</td>
            <td key="Bygningsdel">{ title }</td>
            <td key="Antal" className="hidden-xs">{ amount }</td>
            <td key="Rest Levetid" className="hidden-xs">{ remaining_lifetime } { remaining_lifetime ? ' år' : null }</td>
            <td key="Eftersyn">{ inspection_interval }{ inspection_interval ? '. år' : null }</td>
            <td key="Vedligehold">{ maintenance_interval }{ maintenance_interval ? '. år' : null }</td>
            <td key="Status" className="hidden-xs"><span className={ classNames('label label-success', { 'label-danger': !published }) }>{ published ? 'Udgivet' : 'Kladde' }</span></td>
        </tr>
    )
}
PartRow.propTypes = {
    part: PropTypes.object.isRequired,
    handler: PropTypes.func.isRequired,
    params: PropTypes.object.isRequired,
}

class PartTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sortProperty: "bips_id",
            sortDirection: "asc",
        };
    }

    static propTypes = {
        list: PropTypes.array.isRequired,
        plan_published: PropTypes.bool.isRequired,
        is_valid: PropTypes.bool.isRequired,
        dispatch: PropTypes.func.isRequired,
        fetchParts: PropTypes.func.isRequired,
        editLinkHandler: PropTypes.func.isRequired,
        params: PropTypes.object.isRequired,
        setRenderedComponent: PropTypes.func.isRequired,
        hasEditRights: PropTypes.bool.isRequired,
    }

    componentDidMount () {
        const { dispatch, fetchParts, is_valid, params, setRenderedComponent } = this.props
        dispatch(setRenderedComponent('plan'))
        if (!is_valid) {
            if (params.id) {
                dispatch(fetchParts(params.id))
            }
        }
    }

    /**
     * @param {string} propertyValue
     * @param {string} propertyName
     * @returns {*}
     */
    formatPropertyForSort = (propertyValue, propertyName) => {
        switch (propertyName) {
            case "amount":
                return parseInt(propertyValue.split(" ")[0]);
            default:
                return propertyValue;
        }
    }

    /**
     * @param {object} a
     * @param {object} b
     * @returns {number}
     */
    partRowsSort = (a, b) => {
        const { sortProperty, sortDirection } = this.state;

        if (!a.hasOwnProperty(sortProperty) || !b.hasOwnProperty(sortProperty)) {
            return 0;
        }

        const aProp = this.formatPropertyForSort(a[sortProperty], sortProperty);
        const bProp = this.formatPropertyForSort(b[sortProperty], sortProperty);

        if (aProp < bProp){
            return sortDirection === "desc" ? 1 : -1;
        }

        if (aProp > bProp){
            return sortDirection === "desc" ? -1 : 1;
        }

        return 0;
    }

    /**
     * @returns {object[]}
     */
    getPartRows = () => {
        const { list, editLinkHandler, params } = this.props;

        const sortedParts = list.sort(this.partRowsSort);

        return sortedParts.map((part, idx) => <PartRow key={ idx } part={ part } handler={ editLinkHandler } params={ params } />);
    }

    isHiddenXs = (headerName) => {
        return headerName === 'Tilstand' || headerName === 'Antal' || headerName === 'Rest Levetid' || headerName === 'Status' || headerName === 'BIPS';
    }

    /**
     * @param {string} headerName
     * @param {string} propertyName
     * @returns {object}
     */
    getTableHeader = (headerName, propertyName) => {
        const { sortProperty, sortDirection } = this.state;

        return (
            <div className="d-flex pointer" onClick={() => this.newSort(propertyName)}>
                <span className="part-header-name">{headerName}</span>
                <div className="sort-container">
                    {["asc", "desc"].map(direction => {
                        return (<i className={`fa fa-sort-${direction} part-sort-${direction} ${sortProperty === propertyName && sortDirection === direction ? "active-sort" : ""}`} aria-hidden="true"/>);
                    })}
                </div>
            </div>
        )
    }

    /**
     * @param {string} newSortProperty
     */
    newSort = (newSortProperty) => {
        const { sortDirection, sortProperty } = this.state;

        let newSortDirection = sortDirection;

        if (newSortProperty === sortProperty) {
            newSortDirection = sortDirection === "asc" ? "desc" : "asc";
        }

        this.setState({
            sortProperty: newSortProperty,
            sortDirection: newSortDirection,
        });
    }

    headers = [
        ['ID', 'id'],
        ['Tilstand', 'condition'],
        ['BIPS', 'bips_id'],
        ['Bygningsdel', 'title'],
        ['Antal', 'amount'],
        ['Rest Levetid', 'remaining_lifetime'],
        ['Eftersyn', 'inspection_interval'],
        ['Vedligehold', 'maintenance_interval'],
        ['Status', 'published'],
    ]

    render () {
        const { plan_published, params, hasEditRights } = this.props;

        return (
            <div>
                <h2 className="text-center">
                    Bygningsdele
                </h2>
                <hr />
                <table className="table table-striped table-condensed table-hover maintenance-part-list">
                    <thead>
                    <tr>
                        {
                            this.headers.map((header, idx) => {
                                const headerName = header[0];
                                const propertyName = header[1];

                                return (
                                    <th className={this.isHiddenXs(headerName) ? "hidden-xs" : null} key={ idx }>
                                        {this.getTableHeader(headerName, propertyName)}
                                    </th>
                                );
                            })
                        }
                    </tr>
                    </thead>
                    <tbody>
                    { this.getPartRows() }
                    </tbody>
                </table>
                <p className="text-center"><Link to={ `/admin/plans/edit/${params.id}/parts/new` } className={ classNames('btn btn-primary', { disabled: params.id === void 0 || plan_published || !hasEditRights }) }><i className="fa fa-plus"></i>Tilføj bygningsdel</Link></p>

            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const hasEditRights =  state.auth.role === 'admin' || (state.auth.role === 'editor' && state.data.plan.current.internal_contact_id === state.auth.userId) || !state.data.plan.current.internal_contact_id
    return {
        list: state.data.part.list.content,
        is_valid: state.data.part.list.is_valid,
        plan_published: state.data.plan.current.published,
        hasEditRights,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
        fetchParts: actions.fetchParts,
        editLinkHandler: (uri) => (ev) => dispatch(push(uri)),
        setRenderedComponent: actions.setRenderedComponent,
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PartTable)
