import { ActionTypes } from 'client/constants'
import curry from 'lodash/curry'
import wrap from 'lodash/wrap'

export const clearNotifications = (type = false) => {
  return {
    type: ActionTypes.CLEAR_NOTIFICATIONS,
    payload: {
      type,
    },
  }
}

export const notify = (type, duration, message, title = '') => {
  return {
    type: ActionTypes.SHOW_NOTIFICATION,
    payload: {
      type,
      message,
      title,
      duration,
    },
  }
}
const notifier = curry(notify, 3)

export const notifySuccess = notifier('success', 3700)
export const notifyInfo = notifier('info', 3700)
export const notifyWarning = notifier('warning', null)
export const notifyDanger = notifier('danger', null)

export const notifyHide = (idx) => {
  return {
    type: ActionTypes.HIDE_NOTIFICATION,
    payload: idx,
  }
}

export default {
  clearNotifications,
  notify,
  notifySuccess,
  notifyInfo,
  notifyWarning,
  notifyDanger,
  notifyHide,
}
