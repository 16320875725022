import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import Notifications from 'client/components/Notifications'
import NavBar from 'client/components/NavBar'
import Spinner from 'client/components/Spinner'
import { ListLink } from 'client/components/NavLink'
import actions from 'client/actions'
import { withStyles } from '@material-ui/core/styles';
import {styles} from 'common/lib/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListAltIcon from '@material-ui/icons/ListAlt';
import DescriptionIcon from '@material-ui/icons/Description';
import ArchiveIcon from '@material-ui/icons/Archive';
import GroupIcon from '@material-ui/icons/Group';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';

class AdminWrapper extends Component {

  static propTypes = {
    children: PropTypes.node.isRequired,
    logout: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
  }

  constructor (props) {
    super(props)
    this.onClickLogout = this.onClickLogout.bind(this)
  }

  onClickLogout (ev) {
    ev.preventDefault()
    const { dispatch, logout } = this.props
    dispatch(logout())
  }

  render () {
    const { children, classes, role } = this.props

    return (
      <div className={classes.root}>

        <Spinner />

        <NavBar>
            <ListLink to="/admin/plans">
                <ListItem button>
                <ListItemIcon>
                    <ListAltIcon color="secondary" />
                </ListItemIcon>
                <ListItemText primary="Driftsplaner" />
                </ListItem>
            </ListLink>
            <ListLink to="/admin/portfolios">
                <ListItem button>
                <ListItemIcon>
                    <ListAltIcon color="secondary" />
                </ListItemIcon>
                <ListItemText primary="Porteføljer" />
                </ListItem>
            </ListLink>
            <ListLink to="/admin/part-templates">
                <ListItem button>
                <ListItemIcon>
                    <DescriptionIcon color="secondary" />
                </ListItemIcon>
                <ListItemText primary="Standarder" />
                </ListItem>
            </ListLink>
            <ListLink to="/admin/system-texts">
                <ListItem button>
                <ListItemIcon>
                    <ArchiveIcon color="secondary" />
                </ListItemIcon>
                <ListItemText primary="System" />
                </ListItem>
            </ListLink>
            <ListLink to="/admin/faq">
                <ListItem button>
                <ListItemIcon>
                    <LiveHelpIcon color="secondary" />
                </ListItemIcon>
                <ListItemText primary="FAQ" />
                </ListItem>
            </ListLink>
            <ListLink to="/admin/users">
                <ListItem button>
                <ListItemIcon>
                    <GroupIcon color="secondary" />
                </ListItemIcon>
                <ListItemText primary="Brugerstyring" />
                </ListItem>
            </ListLink>
          </NavBar>
          <Notifications />
          <div className={classes.container}>
            { children }
          </div>
        </div>
      )

  }
}

const mapStateToProps = state => {
  return {
    role: state.auth.role,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    logout: actions.logout,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AdminWrapper))
