import React, { Component } from 'react'
import PropTypes from 'prop-types';
import DocumentTitle from 'react-document-title'
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from 'client/components/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

const PortfolioWrapper = ({ children, route }) => {
  return (
    <DocumentTitle title={ route.title }>
      { children }
    </DocumentTitle>
  )
}
PortfolioWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  route: PropTypes.object.isRequired,
}

export class AddPlanModal extends Component {

  state = {
    plan_id: null
  }

  render () {
    const { plans, handleAdd, visible, onClose } = this.props

    return (
        <Dialog onClose={onClose} open={visible}>
            <DialogTitle onClose={onClose}>
                Vælg Driftsplan
            </DialogTitle>
            <DialogContent dividers>
               <div className="form-group">
                    <select className="form-control" onChange={ e => this.setState({ plan_id: e.target.value }) }>
                    <option value="">Vælg en driftsplan</option>
                    { plans.map((plan, idx) => <option key={ idx } value={ plan.id } disabled={plan.disabled ? ' disabled="disabled"' : ''}>{ plan.name }</option>) }
                    </select>
              </div>
            </DialogContent>
            <DialogActions>
                <button className="btn btn-primary" onClick={ handleAdd(this.state.plan_id) } disabled={ !this.state.plan_id }>
                Tilføj
                </button>
            </DialogActions>
        </Dialog>
    )

  }
}
AddPlanModal.propTypes = {
  plans: PropTypes.array.isRequired,
  handleAdd: PropTypes.func.isRequired,
  visible: PropTypes.bool,
  onClose: PropTypes.func
}

export default PortfolioWrapper
