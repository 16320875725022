import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { TextField } from 'client/components/Field'
import { StickyContainer, Sticky } from 'react-sticky'
import actions from 'client/actions'

class SystemTextsEditor extends Component {

    static propTypes = {
        systemTexts: PropTypes.object.isRequired,
        dispatch: PropTypes.func.isRequired,
        save: PropTypes.func.isRequired,
        change: PropTypes.func.isRequired,
        fetch: PropTypes.func.isRequired,
    }

    constructor (props) {
        super(props)
        this.handleSave = this.handleSave.bind(this)
        this.onTextChange = this.onTextChange.bind(this)
    }

    componentWillMount () {
        const { dispatch, fetch } = this.props
        dispatch(fetch())
    }

    onTextChange (key) {
        const { dispatch, change } = this.props
        return ev => {
            const value = ev.target.value
            dispatch(change(key, value))
        }
    }

    handleSave () {
        const { dispatch, save } = this.props
        dispatch(save())
    }

    render () {
        const { systemTexts, role } = this.props
        const readOnly = role !== 'admin'
        return (
            <StickyContainer>
                <Sticky>
            {() => (
                <div className="row sub-header">
                    {
                        readOnly
                            ? null
                            : (
                                <div className="pull-right action-bar">
                                    <button className="btn btn-primary" onClick={ this.handleSave }>Gem</button>
                                </div>
                            )
                    }
                    <h3>
                        System indstillinger
                    </h3>
                    </div>
                )}
                </Sticky>

                <div className="well">
                    <div className="form-group">
                        <label>Grundlag for driftsplanen</label>
                        <textarea
                            className="form-control"
                            rows="18"
                            cols="40"
                            value={ systemTexts.foundation }
                            onChange={ this.onTextChange('foundation') }
                            disabled={ readOnly }
                        />
                    </div>
                </div>

                <div className="well">
                    <div className="form-group">
                        <label>Betingelser</label>
                        <textarea
                            className="form-control"
                            rows="18"
                            cols="40"
                            value={ systemTexts.terms }
                            onChange={ this.onTextChange('terms') }
                            disabled={ readOnly }
                        />
                    </div>
                </div>

                <div className="well">
                    <div className="form-group">
                        <label>Service aftale</label>
                        <textarea
                            className="form-control"
                            rows="8"
                            cols="40"
                            value={ systemTexts.inspection }
                            onChange={ this.onTextChange('inspection') }
                            disabled={ readOnly }
                        />
                    </div>
                </div>

            </StickyContainer>
        )
    }
}

const mapStateToProps = state => {
    return {
        systemTexts: state.data.systemTexts,
        role: state.auth.role,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatch,
        save: actions.systemTextSave,
        change: actions.systemTextChange,
        fetch: actions.systemTextFetch,
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SystemTextsEditor)
