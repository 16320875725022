import React from 'react'
import PropTypes from 'prop-types';
import classNames from 'classnames'
import DocumentTitle from 'react-document-title'

const PlanWrapper = ({ children, route }) => {
  return (
    <DocumentTitle title={ route.title }>
      { children }
    </DocumentTitle>
  )
}
PlanWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  route: PropTypes.object.isRequired,
}

export const PlanStatus = ({ published, archived }) => {
  // if plan is published, it can't be archived.
  // if plan is archived, it can't be in draft
  const label = published
    ? { name: 'Udgivet', class: 'success' }
    : archived
      ? { name: 'Arkiveret', class: 'danger' }
      : { name: 'Kladde', class: 'info' }

  return (
    <span className={ `label label-${label.class}` }>{ label.name }</span>
  )
}
PlanStatus.propTypes = {
  published: PropTypes.bool.isRequired,
  archived: PropTypes.bool.isRequired,
}

export const PlanButtons = ({
  published, archived, plan_id,
  children, publishHandler, draftHandler,
}) => {
  // if plan is published, it can't be archived.
  // if plan is archived, it can't be in draft
  const draftLabel = published
    ? { name: 'Sæt som kladde', icon: 'fa-lock' }
    : { name: 'Udgiv', icon: 'fa-unlock' }

  const archiveLabel = archived
    ? { name: 'Gendan' }
    : { name: 'Arkiver' }

  const handler = published
    ? draftHandler
    : publishHandler

  return (
    <div>
      { children }
      <div>
        { plan_id
            ? <a href={ `/c/${plan_id}` } target="_blank" className="btn btn-primary"><i className="fa fa-external-link"></i> Vis driftsplan</a>
            : null
        }
        &nbsp;
        <button className={ classNames('btn btn-primary', { disabled: archived }, { 'btn-warning': published }) } onClick={ handler }><i className={ `fa ${draftLabel.icon}` }></i> { draftLabel.name }</button>
      </div>
    </div>
  )
}
PlanButtons.propTypes = {
  published: PropTypes.bool.isRequired,
  archived: PropTypes.bool.isRequired,
  publishHandler: PropTypes.func.isRequired,
  draftHandler: PropTypes.func.isRequired,
  plan_id: PropTypes.number,
  children: PropTypes.node,
}

export default PlanWrapper
