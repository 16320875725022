export const PortfolioSchema = {
  base: { //TODO: Publish properties?
    properties: {
      id: {
        type: 'integer',
        allowNull: false,
      },
      name: {
        type: 'string',
        required: true,
        allowNull: false,
      },
      company_name: {
        type: 'string',
        required: true,
        allowNull: false,
      },
      contact_name: {
        type: 'string',
        allowNull: false,
      },
      contact_email: {
        type: 'email',
        required: true,
      },
      contact_phone: {
        type: 'string',
        required: true,
      },
      address: {
        type: 'string',
      },
      start_year: {
        type: 'integer',
        allowNull: false,
      },
      internal_contact_id: {
        type: 'integer',
      },
      customer_id: {
        type: 'integer',
      },
    },
  },
  extensions: {
    update: {
      properties: {
        id: {
          required: true,
        },
      },
    }
  },
}

export const PortfolioPlanSchema = {
  base: {
    properties: {
      portfolio_id: {
        type: 'integer',
        required: true,
        allowNull: false,
      },
      plan_id: {
        type: 'integer',
        required: true,
        allowNull: false,
      },
    }
  },
}
