import { push } from 'react-router-redux'
import fetch from 'isomorphic-fetch'
import { actions as FormActions } from 'react-redux-form'
import { ActionTypes, API_URI } from 'client/constants'
import { notifyDanger, clearNotifications } from 'client/components/Notifications/actions'
import validate, { ValidationError } from 'common/lib/validator'
import { PartTemplateSchema } from 'common/schemas'
import api, { ApiError } from 'client/lib/apiHandler'
import { actions as spinner } from 'client/components/Spinner'

// general
const sendRequest = (subtype, request) => {
  return {
    type: ActionTypes.SEND_REQUEST,
    subtype,
    request,
  }
}

const receiveRequest = (subtype, request, payload) => {
  return {
    type: ActionTypes.RECEIVE_REQUEST,
    subtype,
    request,
    payload,
  }
}

// fetch all templates
export const fetchPartTemplates = () => {

  return dispatch => {
    dispatch(sendRequest('part-templates', 'fetch'))

    api.get('/part_templates')
    .then(({ body }) => {
      dispatch(receiveRequest('part-templates', 'fetch', body))
    })
    .catch(error => {
      dispatch({ type: ActionTypes.RECEIVE_REQUEST, subtype: 'error' })
    })
  }
}

// fetch single template
export const fetchPartTemplate = (id) => {

  return dispatch => {
    dispatch(sendRequest('part-template', 'fetch'))

    Promise.all([
      api.get(`/part_templates/${id}`),
      api.get('/bips'),
    ])
    .then(([partTemplate, bips]) => {
      dispatch(receiveRequest('part-template', 'fetch', partTemplate.body))
      dispatch(receiveRequest('bips', 'fetch', bips.body))
    })
    .catch(error => {
      if (error instanceof ApiError) {
        dispatch(push('/admin/part-templates/'))
      }
      dispatch({ type: ActionTypes.RECEIVE_REQUEST, subtype: 'error' })
    })
  }
}

// fetch BIPS
export const fetchBips = () => {

  return dispatch => {
    dispatch(sendRequest('part-template', 'fetch'))

    api.get('/bips')
    .then(({ body }) => {
      dispatch(receiveRequest('bips', 'fetch', body))
    })
    .catch(error => {
      dispatch({ type: ActionTypes.RECEIVE_REQUEST, subtype: 'error' })
    })
  }
}

// Create part template
export const savePartTemplate = (operation, partTemplate) => {

  const uri = operation === 'create'
  ? '/part_templates'
  : `/part_templates/${partTemplate.id}`

  const method = operation === 'create'
  ? 'post'
  : 'put'

  return dispatch => {

    validate(PartTemplateSchema, partTemplate, false, { ignoreErrors: true })
    .then(result => {
      dispatch(sendRequest('part-template', operation))

      dispatch(FormActions.setValidity('data.part_template.current', true))
      dispatch(FormActions.setErrors('data.part_template.current', result.errors))

      return api[method](uri, result.data)
    })
    .then(({ body }) => {
      dispatch(receiveRequest('part-template', operation, body))
      if (operation === 'create') {
        dispatch(push(`/admin/part-templates/edit/${body.id}`))
      }
      dispatch(clearNotifications('danger'))
    })
    .catch(error => {
      if (error instanceof ApiError) {
        dispatch(notifyDanger('Der skete en fejl', 'Error'))
      }
      dispatch({ type: ActionTypes.RECEIVE_REQUEST, subtype: 'error' })
    })
  }
}

export const deletePartTemplate = (id) => {

  return dispatch => {
    dispatch(sendRequest('part-template', 'delete'))

    api.del(`/part_templates/${id}`)
    .then(res => {
      dispatch(receiveRequest('part-template', 'delete', { id }))
      dispatch(push('/admin/part-templates/'))
    })
    .catch(error => {
      if (error instanceof ApiError) {
        dispatch(notifyDanger('Der skete en fejl', 'Error'))
      }
      dispatch({ type: ActionTypes.RECEIVE_REQUEST, subtype: 'error' })
    })
  }
}

export default {
  fetchPartTemplates,
  fetchPartTemplate,
  savePartTemplate,
  deletePartTemplate,
  fetchBips,
}
