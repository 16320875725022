export const ActionTypes = {
  SEND_REQUEST: 'SEND_REQUEST',
  RECEIVE_REQUEST: 'RECEIVE_REQUEST',
  RECEIVE_BIPS: 'RECEIVE_BIPS',
  SET_RENDERED_COMPONENT: 'SET_RENDERED_COMPONENT',
  SHOW_NOTIFICATION: 'SHOW_NOTIFICATION',
  HIDE_NOTIFICATION: 'HIDE_NOTIFICATION',
  CLEAR_NOTIFICATIONS: 'CLEAR_NOTIFICATIONS',
  CLEAR_AUTOSAVE: 'CLEAR_AUTOSAVE',
  INVALIDATE_DATA: 'INVALIDATE_DATA',
  FORCE_UPDATE_AUTOSAVE: 'FORCE_UPDATE_AUTOSAVE',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',
  CHECK_ADMIN_AUTH: 'CHECK_ADMIN_AUTH',
  LOGOUT: 'LOGOUT',
  PUBLISH_PART: 'PUBLISH_PART',
  PUBLISH_PLAN: 'PUBLISH_PLAN',
  RECEIVE_PLAN_FOR_CUSTOMER: 'RECEIVE_PLAN_FOR_CUSTOMER',
  RECEIVE_NOTE_FOR_CUSTOMER: 'RECEIVE_NOTE_FOR_CUSTOMER',
  DELETE_NOTE_FOR_CUSTOMER: 'DELETE_NOTE_FOR_CUSTOMER',
  RECEIVE_CUSTOMER_DATA: 'RECEIVE_CUSTOMER_DATA',
  SYSTEM_TEXT_CHANGE: 'SYSTEM_TEXT_CHANGE',
  SYSTEM_TEXT_RECEIVE: 'SYSTEM_TEXT_RECEIVE',
  FAQ_RECEIVE: 'FAQ_RECEIVE',
  RECEIVE_PORTFOLIO: 'RECEIVE_PORTFOLIO',
  SET_BREADCRUMB: 'SET_BREADCRUMB',
  SHOW_SPINNER: 'SHOW_SPINNER',
  HIDE_SPINNER: 'HIDE_SPINNER',
  RECEIVE_USER_REQUEST: 'RECEIVE_USER_REQUEST',
  RECEIVE_USER_UPDATE_REQUEST: 'RECEIVE_USER_UPDATE_REQUEST',
  RECEIVE_USER_CREATE_REQUEST: 'RECEIVE_USER_CREATE_REQUEST',
  RECEIVE_USER_DEACTIVATE_REQUEST: 'RECEIVE_USER_DEACTIVATE_REQUEST',
  RECEIVE_USER_ACTIVATE_REQUEST: 'RECEIVE_USER_ACTIVATE_REQUEST',
  RECEIVE_USER_UPLOAD_REQUEST: 'RECEIVE_USER_UPLOAD_REQUEST',
  SUBMIT_GENERATOR: 'SUBMIT_GENERATOR',
  RECEIVE_GENERATOR: 'RECEIVE_GENERATOR',
  SUBMIT_CONTACT: 'SUBMIT_CONTACT',
  RECEIVE_CONTACT: 'RECEIVE_CONTACT',
  SET_ASSETS: 'SET_ASSETS',
  SET_CUSTOMERS: 'SET_CUSTOMERS',
  SET_PLANS: 'SET_PLANS'
}

export const API_URI = process.env.REACT_APP_API_URI

export const BASE_URL = process.env.REACT_APP_BASE_URL

export const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN

export const AUTH0_CLIENTID = process.env.REACT_APP_AUTH0_CLIENTID

export const S3_BUCKET = process.env.REACT_APP_S3_BUCKET

export const S3_REGION = process.env.REACT_APP_S3_REGION

export const GOOGLE_ANALYTICS_TRACKING = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING

// interval in miliseconds
export const AUTO_SAVE_INTERVAL = 1000

export default { ActionTypes }
