import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import actions from './actions'
import DocumentTitle from 'react-document-title'

class Logout extends Component {

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    route: PropTypes.object.isRequired,
  }

  componentDidMount () {
    this.logout()
  }

  componentWillReceiveProps (nextProps) {
    this.logout()
  }

  logout = () => {
    const { dispatch, logout } = this.props
    dispatch(logout())
  }

  render () {
    const { route } = this.props

    return (
      <DocumentTitle title={ route.title }>
      </DocumentTitle>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    logout: actions.logout,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Logout)
