import { ActionTypes, API_URI } from 'client/constants'
import { keyBy, reduce, has, inRange, map, mergeWith, clone } from 'lodash'
import { logout } from 'client/routes/Auth/actions'
import validate, { ValidationError } from 'common/lib/validator'
import { notifyDanger, notifySuccess, clearNotifications } from 'client/components/Notifications/actions'
import { NoteSchema } from 'common/schemas'
import { jwtDecode } from "jwt-decode"
import moment from 'moment'
import api, { ApiError } from 'client/lib/apiHandler'
import {
    addPriceIndexAndTaxToEstimate,
    addPriceIndexAndTaxToParts,
    calculateOrdinaryTotal,
    calculateExtraordinaryTotalsByYear,
    calculateExtraordinaryTotal,
    calculateTotalForSCAB,
    calculateOrdinaryTotalsByYear,
    calculateTotalsByYearForSC,
    calculateTotalsByYearForAB,
    calculateTotalsByYearForSCAB,
    calculateTotalsById,
    mergeTotals} from 'client/lib/keyNumbers'

const calculateTotalsByPlan = (estimates) => {
    return (
        reduce(estimates, (acc, estimate) => {
            return estimate.year
                ? acc + (parseFloat(estimate.estimate) || 0)
                : acc
        }, null)
    )
}

export const fetchPlanForCustomer = (customer_id) => {

    return dispatch => {

        api.get(`/customers/${customer_id}`)
            .then(({ body }) => {
                const plan = body.plan
                const readonly = false //Never readonly
                const texts = reduce(body.texts, (acc, text) => {
                    acc[text.label] = text.content
                    return acc
                }, {})
                const projects = body.projects
                const faqs = body.faqs
                // adjust prices with price index and tax
                // if null, set baseline 100
                const parsed_price_index = 1

                /*const estimates = map(body.estimates, estimate => {
                  estimate.estimate *= (parsed_price_index * 1.25)
                  // estimate.estimate = (estimate.estimate * (parsed_price_index * 1.25)).toFixed(1)
                  return estimate
                })*/
                const estimates = addPriceIndexAndTaxToEstimate(body.estimates, parsed_price_index)
                // adjust prices with price index and tax
                const parts = addPriceIndexAndTaxToParts(body.parts, parsed_price_index)
                const start_year = plan.start_year
                const ranged_estimates = estimates.filter(estimate => {
                    return inRange(estimate.year, start_year, start_year + 15) || estimate.year === null
                })
                const combined_notes = reduce(parts, (acc, part) => {
                    const notes = part.notes
                    const moment_notes = map(notes, note => {
                        note.moment = moment(note.created_at)
                        note.part_name = part.title
                        return note
                    })
                    return acc.concat(moment_notes)
                }, [])
                // sort by most recent
                const sorted_notes = combined_notes.sort((a, b) => {
                    return a.moment.isBefore(b.moment) ? 1 : -1
                })
                const estimates_by_id = keyBy(ranged_estimates, 'id')
                const estimates_by_year = reduce(ranged_estimates, (acc, estimate) => {
                    const est_year = estimate.year || 'establishment'
                    const part_id = estimate.part_id !== null
                        ? estimate.part_id
                        : estimate.scaffolding
                            ? 'scaffolding'
                            : estimate.construction_site
                                ? 'construction_site'
                                : estimate.adviser
                                    ? 'adviser'
                                    : estimate.free_form
                                        ? 'free_form'
                                        : estimate.free_form_total
                                            ? 'free_form_total'
                                            : 'building_management'

                    acc[est_year] = acc[est_year] || {}
                    acc[est_year][part_id] = acc[est_year][part_id] || estimate.id
                    return acc
                }, {})

                const ordinary_total_by_id = calculateTotalsById(ranged_estimates)
                const ordinary_total_by_year = calculateOrdinaryTotalsByYear(ranged_estimates)
                const ordinary_total = calculateOrdinaryTotal(ranged_estimates)
                const plan_total = calculateTotalsByPlan(ranged_estimates)
                let extraordinary_by_year = calculateExtraordinaryTotalsByYear(parts)
                let extraordinary_total = calculateExtraordinaryTotal(parts)
                const scab_by_year = calculateTotalsByYearForSCAB(ranged_estimates)
                const scab_total = calculateTotalForSCAB(ranged_estimates)
                extraordinary_by_year = mergeTotals(extraordinary_by_year, scab_by_year)
                extraordinary_total = extraordinary_total + scab_total
                const estimates_total_by_year_for_sc = calculateTotalsByYearForSC(ranged_estimates)
                const estimates_total_by_year_for_ab = calculateTotalsByYearForAB(ranged_estimates)

                const totals_by_year = mergeTotals(ordinary_total_by_year, extraordinary_by_year)
                dispatch({
                    type: ActionTypes.RECEIVE_PLAN_FOR_CUSTOMER, payload: {
                        plan,
                        parts,
                        notes: sorted_notes,
                        texts,
                        projects,
                        faqs,
                        estimates: {
                            by_id: estimates_by_id,
                            by_year: estimates_by_year,
                            totals: {
                                by_plan: plan_total,
                                by_id: ordinary_total_by_id,
                                by_year: ordinary_total_by_year,
                                by_year_for_sc: estimates_total_by_year_for_sc,
                                by_year_for_ab: estimates_total_by_year_for_ab,
                                ordinary_total,
                                extraordinary_by_year,
                                extraordinary_total,
                                totals_by_year
                            },
                        },
                        readonly,
                    } })
            })
    }
}

export const saveNote = (note, part_name) => {
    const token = localStorage.getItem('token')
    // Set name from token if logged in user is admin
    if (token) {
        const decoded = jwtDecode(token)
        note.name = decoded.admin
            ? decoded.name
            : note.name
    }

    return dispatch => {

        api.post('/customers/note', note)
            .then(({ body }) => {
                body.moment = moment(body.created_at)
                body.part_name = part_name
                dispatch({ type: ActionTypes.RECEIVE_NOTE_FOR_CUSTOMER, payload: body })
            })
            .catch(error => {
                if (error instanceof ApiError) {
                    dispatch(notifyDanger('Der skete en fejl', 'Error'))
                }
            })
    }
}

export const deleteNote = (note_id, part_id) => {

    return dispatch => {
        api.del(`/customers/note/${note_id}`)
            .then(_ => {
                dispatch({ type: ActionTypes.DELETE_NOTE_FOR_CUSTOMER, payload: { note_id, part_id } })
            })
            .catch(error => {
                if (error instanceof ApiError) {
                    dispatch(notifyDanger('Der skete en fejl', 'Error'))
                }
            })
    }
}

export const saveCustomerData = (plan) => {

    return dispatch => {

        api.put(`/customers/plan/${plan.id}`, plan)
            .then(({ body }) => {
                dispatch({ type: ActionTypes.RECEIVE_CUSTOMER_DATA, payload: body })
                dispatch(notifySuccess('Dine instillinger blev gemt'))
            })
            .catch(error => {
                if (error instanceof ApiError) {
                    dispatch(notifyDanger('Der skete en fejl', 'Error'))
                }
            })
    }
}

export default {
    fetchPlanForCustomer,
    saveNote,
    saveCustomerData,
    deleteNote,
}
