import superagent from 'superagent'
import createerror from 'create-error'
import { browserHistory } from 'react-router'
import { push } from 'react-router-redux'
import { API_URI } from 'client/constants'
//why aren't the constants reloading?

export const ApiError = createerror('ApiError')

export function request (endpoint, method, data = {}, isLogin) {
  const uri = `${API_URI}${endpoint}`

  const token = localStorage.getItem('token')

  return new Promise((resolve, reject) => {
    const request = superagent(method, uri)
      .accept('json')
      .type('json')

    if (token) {
      request.set('Authorization', `Bearer ${token}`)
    }

    request
      .send(data)
      .end((err, res) => {
        if (err) {
          // TODO test timeout errors
          // handle unathorized errors harshly
          if (err.status === 401 && !isLogin) {
            return browserHistory.push('/logout')
          }
          if (err.status === 403) {
            return browserHistory.push('/403')            
          }
          return reject(new ApiError('Api Error', {
            status: err.status,
            body: err.response.body,
          }))
        }
        return resolve({
          status: res.status,
          body: res.body,
        })
      })
  })
}

const api = {
  get (endpoint) {
    return request(endpoint, 'GET')
  },

  post (endpoint, data) {
    return request(endpoint, 'POST', data)
  },

  put (endpoint, data) {
    return request(endpoint, 'PUT', data)
  },

  del (endpoint) {
    return request(endpoint, 'DELETE')
  },

  // Handle login specifically, so we don't redirect browser to login page if login fails
  login (endpoint, data) {
    return request(endpoint, 'POST', data, true)
  },

}
export default api
