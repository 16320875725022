import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { Link } from 'react-router'
import classNames from 'classnames'
import actions from './actions'
import DateTime from 'client/components/DateTime'
import { push } from 'react-router-redux'
import { StickyContainer, Sticky } from 'react-sticky'

const PartTemplateRow = ({ partTemplate, handler }) => {
  const { id, bips, title, updated_at } = partTemplate
  return (
    <tr onClick={ handler(`/admin/part-templates/edit/${id}`) }>
      <td key="BIPS">{ bips.id }. { bips.name }</td>
      <td key="Navn">{ title }</td>
      <td key="Opdateret" className="hidden-xs"><DateTime source={ updated_at } format="DD-MM-YYYY" /></td>
      <td key="ID">{ id }</td>
    </tr>
  )
}
PartTemplateRow.propTypes = {
  partTemplate: PropTypes.object.isRequired,
  handler: PropTypes.func.isRequired,
}

class PartTemplatesTable extends Component {

  static propTypes = {
    list: PropTypes.array.isRequired,
    is_valid: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
    fetchPartTemplates: PropTypes.func.isRequired,
    editLinkHandler: PropTypes.func.isRequired,
  }

  componentDidMount () {
    const { dispatch, fetchPartTemplates, is_valid } = this.props
    if (!is_valid) {
      dispatch(fetchPartTemplates())
    }
  }

  tableHeaders = ['BIPS Kategori', 'Navn', 'Opdateret', 'ID']

  render () {
    const { list, editLinkHandler, role } = this.props
    const rows = list.map((item, idx) => <PartTemplateRow key={ idx } partTemplate={ item } handler={ editLinkHandler } />)
    return (
      <StickyContainer>

        <Sticky>
        {() => (
          <div className="row sub-header">
            {role === 'admin'
              ? (
                <div className="action-bar pull-right">
                  <Link to="/admin/part-templates/new" className="btn btn-primary">Opret ny standard</Link>
                </div>
              )
              : null
          }
          <h3>
            Standarder
          </h3>
          </div>
        )}
        </Sticky>

        <table className="table table-striped table-condensed table-hover maintenance-plan-list">
          <thead>
            <tr>
              {
                this.tableHeaders.map((header, idx) => {
                  return header === 'Opdateret'
                    ? <th className="hidden-xs" key={ idx }>{ header }</th>
                    : <th key={ idx }>{ header }</th>
                })
              }
            </tr>
          </thead>
          <tbody>
            { rows }
          </tbody>
        </table>

      </StickyContainer>

    )
  }
}

const mapStateToProps = (state) => {
  return {
    list: state.data.part_template.list.content,
    is_valid: state.data.part_template.list.is_valid,
    role: state.auth.role,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    fetchPartTemplates: actions.fetchPartTemplates,
    editLinkHandler: (uri) => (ev) => dispatch(push(uri)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PartTemplatesTable)
