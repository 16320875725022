import { ActionTypes } from 'client/constants'

const initialNotificationState = []

const notificationReducer = (state = initialNotificationState, action) => {
  switch (action.type) {
    case ActionTypes.SHOW_NOTIFICATION: {
      const idx = state.length
      const notification = {
        ...action.payload,
        idx,
      }

      // purge previous notifications with same type
      const cleaned = state.filter(el => {
        return el.type !== notification.type
      })

      return [notification].concat(...cleaned)
    }
    case ActionTypes.HIDE_NOTIFICATION: {
      const idx = action.payload
      return [
        ...state.slice(0, idx),
        ...state.slice(idx + 1),
      ]
    }
    case ActionTypes.CLEAR_NOTIFICATIONS:
      if (action.payload.type) {
        return state.filter(el => el.type !== action.payload.type)
      }
      return initialNotificationState
    default:
      return state
  }
}

export default {
  notifications: notificationReducer,
}
