export const EstimateSchema = {
  base: {
    properties: {
      id: {
        type: 'integer',
        allowNull: false,
      },
      plan_id: {
        type: 'integer',
        allowNull: false,
      },
      part_id: {
        type: 'integer',
        allowNull: true,
      },
      year: {
        type: 'string',
      },
      estimate: {
        type: 'number',
        minValue: 0,
        allowEmpty: '',
        allowNull: true,
      },
      scaffolding: {
        type: 'boolean',
      },
      construction_site: {
        type: 'boolean',
      },
      adviser: {
        type: 'boolean',
      },
      building_management: {
        type: 'boolean',
      },
      free_form: {
        type: 'boolean',
      },
      free_form_total: {
        type: 'boolean',
      },
    },
  },
  extensions: {
    update: {
      properties: {
        id: {
          required: true,
        },
      },
    },
  },
}
