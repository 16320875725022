import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { has, map } from 'lodash'
import { Field } from 'react-redux-form'
import S3Uploader from 'react-s3-uploader'
import { API_URI } from 'client/constants'
import { parseIntOrNull } from 'common/lib/validator'
import classnames from 'classnames'
import {
  InputField,
  TextField,
  SelectField,
} from 'client/components/Field'
import actions from 'client/actions'
import DatePicker from '../../../components/DatePicker'

class Energy extends Component {

  static propTypes = {
    current: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    form_data: PropTypes.object.isRequired,
    form_model: PropTypes.string.isRequired,
    dispatch: PropTypes.func.isRequired,
    setRenderedComponent: PropTypes.func.isRequired,
    hasEditRights: PropTypes.bool.isRequired,
  }

  constructor (props) {
    super(props)
  }

  componentWillMount () {
    const { dispatch, setRenderedComponent } = this.props
    dispatch(setRenderedComponent('plan'))
  }

  render () {
    const { current, form_data, form_model, hasEditRights } = this.props
    const form = this.props.form.$form
    const readOnly = current.published || !hasEditRights
    const energyOptions = {'a2020': 'A (2020)','a2013': 'A (2013)','a2010': 'A (2010)', 'b': 'B', 'c': 'C', 'd': 'D', 'e': 'E', 'f': 'F'}
    const energyTypes = {1: 'm3 naturgas', 2: 'm3', 3: 'kWh elektricitet', 4: 'kWh fra solceller', 5: 'kWh', 6: 'Liter', 7: 'Mwh fjernvarme'}

    return (
      <div>
        <h2 className="text-center">
          Energi
        </h2>
        <hr />

        <div className="row">
          <div className="col-md-12">
            <div className="well">
              <fieldset>
                <legend>Energimærkning</legend>
              </fieldset>
              <p>
                <Field model={ `${form_model}.energy_enable` }>
                  <label className="checkbox-inline">
                    <input type="checkbox" value disabled={ readOnly } /> Aktiver
                  </label>
                </Field>
              </p>
            <div className="row">
              <div className="col-md-6">
                <InputField
                    model={ `${form_model}.energy_emission` }
                    label="Samlet CO2 udledning (nuværende)"
                    type="text"
                    className="form-control input-sm"
                    valid={ form.validity.energy_emission }
                    disabled={ readOnly }
                />
              </div>
              <div className="col-md-6">
                <InputField
                    model={ `${form_model}.energy_emission_potential` }
                    label="Samlet CO2 udledning (potentiale)"
                    type="text"
                    className="form-control input-sm"
                    valid={ form.validity.energy_emission_potential }
                    disabled={ readOnly }
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <SelectField
                    model={ `${form_model}.energy_label` }
                    label="Energimærke"
                    className="form-control input-sm"
                    valid={ form.validity.energy_label }
                    disabled={ readOnly }
                >
                    <option value="">---</option>
                    { map(energyOptions, (value, key) => <option value={key}>{value}</option>) }
                </SelectField>
              </div>
              <div className="col-md-6">
                <SelectField
                    model={ `${form_model}.energy_label_potential` }
                    label="Energimærke (potentiale)"
                    className="form-control input-sm"
                    valid={ form.validity.energy_label_potential }
                    disabled={ readOnly }
                >
                    <option value="">---</option>
                    { map(energyOptions, (value, key) => <option value={key}>{value}</option>) }
                </SelectField>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <InputField
                    model={ `${form_model}.energy_label_text` }
                    label="Energimærke tekst"
                    type="text"
                    className="form-control input-sm"
                    valid={ form.validity.energy_label_text }
                    disabled={ readOnly }
                />
              </div>
              <div className="col-md-6">
                <InputField
                    model={ `${form_model}.energy_report_number` }
                    label="Energimærkningsrapport nummer"
                    type="text"
                    className="form-control input-sm"
                    valid={ form.validity.energy_report_number }
                    disabled={ readOnly }
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <InputField
                    model={ `${form_model}.energy_expense` }
                    label="Samlet energiudgift"
                    type="text"
                    className="form-control input-sm"
                    valid={ form.validity.energy_expense }
                    disabled={ readOnly }
                />
              </div>
              <div className="col-md-6">
                <div className={ classnames('form-group', { 'has-error': form.validity.price_index_date === false }) }>
                    <label>Udløbsdato for rapport</label>
                <DatePicker model={ `${form_model}.energy_report_date` } displayFormat="DD/MM/YYYY" dataFormat="YYYY-MM-DD" className="form-control input-sm" placeholderText="Vælg udløbsdato for rapport" disabled={ readOnly } />
                </div>
              </div>
              </div>
              <div className="row">
              <div className="col-md-6">
                <SelectField
                    model={ `${form_model}.energy_type` }
                    label="Energitype"
                    className="form-control input-sm"
                    valid={ form.validity.energy_label_potential }
                    disabled={ readOnly }
                >
                    <option value="">---</option>
                    { map(energyTypes, (value, key) => <option value={key}>{value}</option>) }
                </SelectField>
              </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <InputField
                      model={ `${form_model}.energy_consumption_new` }
                      label="Varmeforbrug i dag"
                      type="text"
                      className="form-control input-sm"
                      valid={ form.validity.energy_consumption_new }
                      disabled={ readOnly }
                  />
                </div>
                <div className="col-md-6">
                  <InputField
                      model={ `${form_model}.energy_consumption_new_potential` }
                      label="Varmeforbrug efter rentable tiltag"
                      type="text"
                      className="form-control input-sm"
                      valid={ form.validity.energy_consumption_new_potential }
                      disabled={ readOnly }
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <InputField
                      model={ `${form_model}.energy_electricity_purpose` }
                      label="El til andet i dag"
                      type="text"
                      className="form-control input-sm"
                      valid={ form.validity.energy_electricity_purpose }
                      disabled={ readOnly }
                  />
                </div>
                <div className="col-md-6">
                  <InputField
                      model={ `${form_model}.energy_electricity_purpose_potential` }
                      label="El til andet efter rentable tiltag"
                      type="text"
                      className="form-control input-sm"
                      valid={ form.validity.energy_electricity_purpose_potential }
                      disabled={ readOnly }
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <InputField
                      model={ `${form_model}.energy_yearly_saving` }
                      label="I følge dit energimærke, betaler du hvert år mere i energiudgifter end du behøver. Der er mulighed for en årlig besparelse på:"
                      type="text"
                      className="form-control input-sm"
                      valid={ form.validity.energy_yearly_saving }
                      disabled={ readOnly }
                  />
                </div>
              </div>
            <div className="row">
              <div className="col-md-6">
                <InputField
                    model={ `${form_model}.energy_consumption` }
                    label="Årligt varmeforbrug nuværende (bør ikke bruges)"
                    type="text"
                    className="form-control input-sm"
                    valid={ form.validity.energy_consumption }
                    disabled={ readOnly }
                />
              </div>
              <div className="col-md-6">
                <InputField
                    model={ `${form_model}.energy_consumption_potential` }
                    label="Årligt varmeforbrug potentiale (bør ikke bruges)"
                    type="text"
                    className="form-control input-sm"
                    valid={ form.validity.energy_consumption_potential }
                    disabled={ readOnly }
                />
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  const hasEditRights =  state.auth.role === 'admin' || (state.auth.role === 'editor' && state.data.plan.current.internal_contact_id === state.auth.userId) || !state.data.plan.current.internal_contact_id
  return {
    current: state.data.plan.current,
    form: state.data.plan.form,
    form_data: state.data.plan.form_data,
    form_model: 'data.plan.current',
    hasEditRights,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setRenderedComponent: actions.setRenderedComponent,
    saveUpload: actions.savePlanUpload,
    deleteUpload: actions.deletePlanUpload,
    dispatch,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Energy)
