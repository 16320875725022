import { combineReducers } from 'redux'
import { ActionTypes } from 'client/constants'
import { modelReducer, formReducer, modeled, actionTypes as formActions } from 'react-redux-form'

const listReducer = (state = {
  content: [],
  is_valid: false,
}, action) => {
  switch (action.type) {
    case ActionTypes.RECEIVE_REQUEST:
      if (action.subtype === 'project') {
       if (action.request === 'update') {
            return {
                content: state.content.map(a => a.id == action.payload.id ? action.payload : a),
                is_valid: true,
            }
        }
       if (action.request === 'create') {
            return {
                content: state.content.concat(action.payload),
                is_valid: true,
            }
        }
       if (action.request === 'delete') {
            return {
                content: state.content.filter(r => r.id !== action.payload.id),
                is_valid: true,
            }
        }
      }
      if (action.subtype === 'projects') {
        return {
          content: action.payload,
          is_valid: true,
        }
      }
      return state
    case ActionTypes.SEND_REQUEST:
      if (action.subtype === 'project') {
        if (action.request === 'create' || action.request === 'edit' || action.request === 'delete') {
          return {
            content: [],
            is_valid: false,
          }
        }
      }
      return state
    case ActionTypes.INVALIDATE_DATA:
      return {
        content: [],
        is_valid: false,
      }
    default:
      return state
  }
}

const initialCurrentState = {
  id: null,
  part_id: null,
  text: null,
  year: null,
  internal_note: null,
  prioritized: false,
}
const currentReducer = (state = initialCurrentState, action) => {
  switch (action.type) {
    case ActionTypes.RECEIVE_REQUEST:
      if (action.subtype === 'project') {
        if (action.request === 'create' || action.request === 'fetch') {
          return {
            ...state,
            ...action.payload,
          }
        }
      }
      return state
    case ActionTypes.INVALIDATE_DATA:
      return initialCurrentState
    default:
      return state
  }
}
const modelProject = modeled(currentReducer, 'data.project.current')

export default combineReducers({
  list: listReducer,
  current: modelProject,
  form: formReducer('data.project.current'),
})
