import React from 'react'
import PropTypes from 'prop-types';
import classNames from 'classnames'
import { NavLink } from 'client/components/NavLink'

export const PartButtons = ({
  published, plan_id, children,
  publishHandler, draftHandler, deleteHandler,
}) => {
  // if plan is published, it can't be archived.
  // if plan is archived, it can't be in draft
  const draftLabel = published
    ? { name: 'Sæt som kladde' }
    : { name: 'Udgiv' }

  const handler = published
    ? draftHandler
    : publishHandler

  return (
    <span>
        <NavLink to={ `/admin/plans/edit/${plan_id}/parts` } className="btn btn-primary" onlyActiveOnIndex>Tilbage</NavLink>
        &nbsp;
        <button className={ classNames('btn btn-primary', { 'btn-warning': published }) } onClick={ handler }>{ draftLabel.name }</button>
        &nbsp;
        {
          published
            ? null
            : <button className="btn btn-primary" onClick={ deleteHandler }>Slet</button>
        }
    </span>
  )
}
PartButtons.propTypes = {
  published: PropTypes.bool.isRequired,
  children: PropTypes.node,
  plan_id: PropTypes.string.isRequired,
  publishHandler: PropTypes.func.isRequired,
  draftHandler: PropTypes.func.isRequired,
  deleteHandler: PropTypes.func.isRequired,
}
