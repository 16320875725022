import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import actions from 'client/actions'
import Dropzone from 'react-dropzone'
import DocumentTitle from 'react-document-title'
import S3FileUploader from 'client/components/S3FileUploader'
import { Line } from 'rc-progress';
import Promise from 'bluebird';
import _ from 'lodash'
import { API_URI } from 'client/constants'
import classnames from 'classnames'
import { notifyDanger, notifySuccess, clearNotifications } from 'client/components/Notifications/actions'

class Files extends Component {

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    plan: PropTypes.object.isRequired,
    route: PropTypes.object.isRequired,
    files: PropTypes.object.isRequired,
    saveFile: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.onDrop = this.onDrop.bind(this)
    this.onDropRejected = this.onDropRejected.bind(this)
    this.renderFiles = this.renderFiles.bind(this)
    this.state = {
      uploaders: [],
      uploading: false,
      filesCount: null,
      filesDone: 0,
      totalPercent: null,
    }
  }

  componentDidMount() {
      const { dispatch, setBreadcrumb } = this.props
      dispatch(setBreadcrumb('Filarkiv'));
  }

  acceptsType (type) {
    return _.includes(['image/jpeg', 'image/png', 'application/pdf'], type)
  }

  onDropRejected (file) {
    const { dispatch } = this.props
    dispatch(notifyDanger('Fil må kun være af type PDF, jpg, eller png, og fylde maks 20MB', ''))
  }

  calculateTotalPercent (percent) {
    const filesCount = this.state.filesCount
    const filesDone = this.state.filesDone
    const totalPercent = (((filesDone * 100) + percent) / (filesCount * 100)) * 100
    this.setState({
      ...this.state,
      totalPercent,
    })
  }

  onDrop(files) {
    const { dispatch, plan, readonly } = this.props
    dispatch(clearNotifications())
    if (this.state.uploading) {
      return
    }
    if (readonly) {
      dispatch(notifyDanger('Du har ikke adgang til at uploade filer', ''))
      return
    }
    let existingUploaders = this.state.uploaders;
    let existingUploadersByName = _.keyBy(existingUploaders, uploader => uploader.getFilename());
    let uploaders = _.map(files, (file) => {
        let filename = file.name;
        if (existingUploadersByName[filename] || !this.acceptsType(file.type) || file.size > 20971520) {
            return null;
        }

        let uploader = new S3FileUploader(file, {
          signingUrl: "/s3/sign",
          server: API_URI,
        });

        uploader.on('progress', (percent) => {
          this.calculateTotalPercent(percent)
        });

        uploader.on('complete', (result, file_name, mimetype) => {
          const { saveFile, plan } = this.props
          dispatch(saveFile(plan.id, result.filename, mimetype, file_name))
          this.setState({
              ...this.state,
              filesDone: this.state.filesDone + 1,
          });
          this.calculateTotalPercent(0)
        });

        uploader.on('error', (status) => {
            this.setState({
                hasFailedUpload: true,
                uploading: false,
            });
        });

        return uploader;
    });

    uploaders = _.compact(uploaders);

    this.setState({
        ...this.state,
        uploaders: existingUploaders.concat(uploaders),
        filesCount: files.length,
        filesDone: 0,
        totalPercent: 0,
        uploading: true,
        hasFailedUpload: false,
    });

    return Promise.resolve()
        .then(() => {
            return Promise.each(uploaders, uploader => uploader.start());
        })
        .finally(() => {
          this.setState({
              ...this.state,
              uploading: false,
          });
          if (uploaders.length > 0 && !this.state.hasFailedUpload) {
            dispatch(notifySuccess(`${files.length} ${files.length > 1 ? 'filer' : 'fil' } uploaded`))
          }
        })
        .catch(e => {
          console.log(e);
          dispatch(notifyDanger('Der skete en fejl', ''))
        })
  }

  renderFiles (type) {
    const files = this.props.files[type]

    if (!files) {
      return null
    }

    return (
      files.map((file, idx) => {
        return (
          <div className="col-md-3 col-sm-4" key={ idx }>
            <div className="well-sm">
              <div className="hovereffect"> 
                {file.mimetype == 'application/pdf' ? <div className="pdf-responsive"/> : <img className="img-responsive" src={ `https://ik.imagekit.io/aw3ddey5g/${encodeURIComponent(file.s3_file_id)}?tr=w-420,h-297` } alt="" />}
                <div className="overlay">
                  <i className={ classnames('fa file-icon', { 'fa-file-pdf-o': file.mimetype == 'application/pdf', 'fa-file-image-o': file.mimetype != 'application/pdf'  }) }></i>
                  <p>
                    <br/>
                    <a target="_blank" href={ `https://ik.imagekit.io/aw3ddey5g/${encodeURIComponent(file.s3_file_id)}` } download><i className="fa fa-download"></i> Download</a>
                  </p>
                </div>
              </div>
              <p className="file-title">{ file.file_name }</p>
            </div>
          </div>
        )
      })
    )
  }

  render () {
    const { uploading, totalPercent, filesCount, filesDone } = this.state
    const { plan, route, readonly } = this.props

    const bilagFiles = this.renderFiles('Bilag')

    const tegningerFiles = this.renderFiles('Tegninger')

    const totalUploadProgress = uploading
    ? (
      <div>
        <Line percent={totalPercent} strokeWidth={ 2 } trailWidth={ 2 }/>
        <p>Uploader { filesDone + 1 } / { filesCount } { filesCount > 1 ? 'filer' : 'fil'}</p>
      </div>
    )
    : null

    return (
      <div>
        <DocumentTitle title={ route.title } />

        <section>
          {!readonly && <div>
            <div className="row">
              <div className="col-md-12"><h3>Upload</h3>
                <Dropzone
                  className="dropzone"
                  activeClassName="dropzoneActive"
                  rejectClassName="label-danger dropzoneReject"
                  onDrop={ this.onDrop }
                  maxSize={ 20971520 }
                  onDropRejected={ this.onDropRejected }
                  accept="image/jpeg, image/png, application/pdf"
                  >
                  Klik her for at uploade en fil. Det er også muligt at trække og slippe filer. <br/>
                  Det er kun muligt at uploade filer af typerne: PDF, jpg, png. Filerne kan være max. 20 mb pr stk.
                  <div className="row">
                    <div className="col-md-8 col-md-offset-2">
                    { totalUploadProgress }
                    </div>
                  </div>
                </Dropzone>
              </div>
            </div>
          </div>}
          <div>
            { !bilagFiles && !tegningerFiles ? <h4>Der er ikke tilknyttet nogen filer - kontakt Plan1 for at tilknytte filer som f.eks. bygningstegninger og andre relevante filer.</h4> : null }
            <div className="row image-gallery customer">
              <div className="col-md-12">{ bilagFiles ? <h3>Bilag</h3> : null }</div>
              { bilagFiles }
            </div>
            <div className="row image-gallery customer">
              <div className="col-md-12">{ tegningerFiles ? <h3>Tegninger</h3> : null }</div>
              { tegningerFiles }
            </div>

          </div>

        </section>


      </div>
    )
  }
}

const mapStateToProps = state => {
  const files = state.data.customer.plan.files
  const mappedFiles = _.reduce(files, (acc, file) => {
    const category = file.category
    acc[category] = acc[category] || []
    acc[category] = _.concat(acc[category], file)
    return acc
  }, {})
  return {
    plan: state.data.customer.plan,
    readonly: state.data.customer.readonly,
    files: mappedFiles,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    saveFile: actions.saveFileUpload,
    setBreadcrumb: actions.setBreadcrumb,
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Files)
