import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { Field, actions } from 'react-redux-form'
import get from 'lodash/get'
import ReactTooltip from 'react-tooltip'
import classnames from 'classnames'
import { throttle } from 'lodash'

class InputFieldComponent extends Component {

  static propTypes = {
    model: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    type: PropTypes.string,
    children: PropTypes.node,
    valid: PropTypes.bool,
    helper: PropTypes.string,
    dispatch: PropTypes.func.isRequired,
  }

  constructor (props) {
    super(props)
    this.onChange = this.onChange.bind(this)
    this.onBlur = this.onBlur.bind(this)
  }

  onChange (e) {
    const { model, dispatch } = this.props;
    this.setState({
      value: e.target.value,
      dirty: true,
    })
    this.throttleChange(dispatch, model, e.target.value)
  }

  onBlur (e) {
    //XXX: dispatch is not explicitly defined as a prop (with redux connect). So we don't know why this works...
    const { model, dispatch } = this.props
    const value = e.target.value
    dispatch(actions.change(model, value))
  }

  throttleChange = throttle((dispatch, model, value) => {
    dispatch(actions.change(model, value))
  }, 5000)

  render () {

    const { model, label, type = 'text', children, valid, helper = '', ...props } = this.props

    const value = (this.state && this.state.dirty)
      ? this.state.value
      : get(props, model)

    const toolTip = helper !== ''
      ?
        <span id="noborders" className="input-group-addon borderless" data-tip data-for={ model }><i className={ classnames('fa fa-info-circle', { ' text-muted': valid !== false }) }></i>
          <ReactTooltip id={ model } type="warning" effect="solid" >
            { helper }
          </ReactTooltip>
        </span>
      : ''

    return (
      <div className={ classnames('form-group', { 'has-error': valid === false }) }>
        <Field
          model={ model }
          onChange={ this.onChange }
          onBlur={ this.onBlur }
          >
          <label>{ label }</label>
          <div className={ classnames({ 'input-group': helper !== '' }) }>
            <input type={ type } value={ value } { ...props } />
            { toolTip }
          </div>
          { children }
        </Field>
      </div>
    )
  }
}

class TextFieldComponent extends Component {

  static propTypes = {
    model: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    children: PropTypes.node,
    valid: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
  }

  constructor (props) {
    super(props)
    this.onChange = this.onChange.bind(this)
    this.onBlur = this.onBlur.bind(this)

    this.state = {
      value: null,
    }
  }

  onChange (e) {
    const { model, dispatch } = this.props;
    this.setState({
      value: e.target.value,
      dirty: true,
    })
    this.throttleChange(dispatch, model, e.target.value)
  }

  onBlur (e) {
    //XXX: dispatch is not explicitly defined as a prop (with redux connect). So we don't know why this works...
    const { model, dispatch } = this.props
    const value = e.target.value
    dispatch(actions.change(model, value))
  }

  throttleChange = throttle((dispatch, model, value) => {
    dispatch(actions.change(model, value))
  }, 5000)

  render () {
    const { model, label, children, valid, ...props } = this.props

    const value = (this.state && this.state.dirty)
      ? this.state.value
      : get(props, model)

    return (
      <div className={ classnames('form-group', { 'has-error': valid === false }) }>
        <Field
          model={ model }
          onChange={ this.onChange }
          onBlur={ this.onBlur }
          >
          <label>{ label }</label>
          <textarea {...props } value={ value } ></textarea>
          { children }
        </Field>
      </div>
    )
  }
}

const SelectFieldComponent = ({
  model,
  label,
  children,
  valid,
  ...props
}) => {

  const value = get(props, model)

  return (
    <div className={ classnames('form-group', { 'has-error': valid === false }) }>
      <Field model={ model }>
        <label>{ label }</label>
        <select { ...props } selected={ value }>
          { children }
        </select>
      </Field>
    </div>
  )
}
SelectFieldComponent.propTypes = {
  model: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  children: PropTypes.node,
  valid: PropTypes.bool,
}

export const InputField = connect(s => s)(InputFieldComponent);
export const TextField = connect(s => s)(TextFieldComponent);
export const SelectField = connect(s => s)(SelectFieldComponent);
