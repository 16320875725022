import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Link } from 'react-router'
import { connect } from 'react-redux'
import moment from 'moment'
import numeral from 'common/lib/numeral'
import classNames from 'classnames'
import { find, findIndex, toInteger, map } from 'lodash'
import { Condition, ConditionLegend } from 'client/components/Condition'
import actions from 'client/actions'
import Dropzone from 'react-dropzone'
import S3FileUploader from 'client/components/S3FileUploader'
import Promise from 'bluebird';
import _ from 'lodash'
import { API_URI, S3_BUCKET, S3_REGION } from 'client/constants'
import { notifyDanger, notifySuccess, clearNotifications } from 'client/components/Notifications/actions'
import { Line } from 'rc-progress';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {styles} from 'common/lib/styles';
import noImage from 'assets/images/no-image.png'
import { withStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import Avatar from '@material-ui/core/Avatar';
import ErrorIcon from '@material-ui/icons/Error';
import Tooltip from '@material-ui/core/Tooltip';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from 'client/components/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

class Part extends Component {

  static propTypes = {
    plan: PropTypes.object.isRequired,
    part: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    saveNote: PropTypes.func.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    deleteNote: PropTypes.func.isRequired,
  }

  constructor (props) {
    super(props)
    this.handleNoteContent = this.handleNoteContent.bind(this)
    this.handleNoteName = this.handleNoteName.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.handleNoteDelete = this.handleNoteDelete.bind(this)
    this.onDrop = this.onDrop.bind(this)
    this.onDropRejected = this.onDropRejected.bind(this)
    this.toggleModal = this.toggleModal.bind(this)

    this.state = {
      note: {
        name: '',
        content: '',
        files: [],
      },
      hasError: {
        name: false,
        content: false,
      },
      uploaders: [],
      uploading: false,
      filesCount: null,
      filesDone: 0,
      totalPercent: null,
      modalVisible: false,
      imageDialogUrl: null,
    }
  }

  componentDidMount() {
      const { dispatch, setBreadcrumb } = this.props
      let breadcrumb = 'Bygningsdele - ';
      if (this.props.part) {
        breadcrumb += this.props.part.title;
      }
      dispatch(setBreadcrumb(breadcrumb));
  }

  calculateTotalPercent (percent) {
    const filesCount = this.state.filesCount
    const filesDone = this.state.filesDone
    const totalPercent = (((filesDone * 100) + percent) / (filesCount * 100)) * 100
    this.setState({
      ...this.state,
      totalPercent,
    })
  }

  onDropRejected (file) {
    const { dispatch } = this.props
    dispatch(notifyDanger('Fil må kun fylde maks 20MB', ''))
  }

  onDrop(files) {
    const { dispatch, current } = this.props
    dispatch(clearNotifications())
    if (this.state.uploading) {
      return
    }
    let existingUploaders = this.state.uploaders;
    let existingUploadersByName = _.keyBy(existingUploaders, uploader => uploader.getFilename());
    let uploaders = _.map(files, (file) => {
        let filename = file.name;
        if (existingUploadersByName[filename] || file.size > 20971520) {
            return null;
        }

        let uploader = new S3FileUploader(file, {
          signingUrl: "/s3/sign",
          server: API_URI,
        });

        uploader.on('progress', (percent) => {
          this.calculateTotalPercent(percent)
        });

        uploader.on('complete', (result, file_name, mimetype) => {
          const files = this.state.note.files.concat({
            filename: file_name,
            s3_file_id: result.filename,
            mimetype,
          })
          this.setState({
            ...this.state,
            filesDone: this.state.filesDone + 1,
            note: {
              ...this.state.note,
              files,
            }
          });
          this.calculateTotalPercent(0)
        });

        uploader.on('error', (status) => {
          this.setState({
            hasFailedUpload: true,
            uploading: false,
          });
        });

        return uploader;
    });

    uploaders = _.compact(uploaders);

    this.setState({
        ...this.state,
        uploaders: existingUploaders.concat(uploaders),
        filesCount: files.length,
        filesDone: 0,
        totalPercent: 0,
        uploading: true,
        hasFailedUpload: false,
    });

    return Promise.resolve()
        .then(() => {
            return Promise.each(uploaders, uploader => uploader.start());
        })
        .finally(() => {
          this.setState({
              ...this.state,
              uploading: false,
          });
          if (uploaders.length > 0 && !this.state.hasFailedUpload) {
            dispatch(notifySuccess(`${files.length} ${files.length > 1 ? 'filer' : 'fil' } uploaded`))
          }
        })
        .catch(e => {
          dispatch(notifyDanger('Der skete en fejl', ''))
        })
  }

  handleNoteContent (e) {
    const content = e.target.value
    const state = this.state
    state.note.content = content
    this.setState(state)
  }

  handleNoteName (e) {
    const name = e.target.value
    const state = this.state
    state.note.name = name
    this.setState(state)
  }

  toggleModal (e, url) {
    e.preventDefault();
    this.setState({
      modalVisible: !this.state.modalVisible,
      imageDialogUrl: url
    })
  }

  handleSave () {
    const { part, dispatch, saveNote, isAdmin } = this.props
    const { name, content, files } = this.state.note
    if ((name !== '' || isAdmin) && content !== '') {
      const note = {
        part_id: part.id,
        content,
        name,
        files,
      }
      dispatch(saveNote(note, part.title))
      this.setState({
        note: {
          name: '',
          content: '',
          files: [],
        },
        hasError: {
          name: false,
          content: false,
        },
        uploaders: [],
        uploading: false,
        filesCount: null,
        filesDone: 0,
        totalPercent: null,
      })
    } else {
      const state = this.state
      state.hasError.name = name === '' || false
      state.hasError.content = content === '' || false
      this.setState(state)
    }
  }

  handleNoteDelete (note_id, part_id) {
    const { dispatch, deleteNote } = this.props
    return ev => {
      ev.preventDefault()
      dispatch(deleteNote(note_id, part_id))
    }
  }

  render () {
    const { plan, part = {}, classes, params: { customer_id }, isAdmin, readonly } = this.props
    const { images = [] } = part
    const { uploading, totalPercent, filesDone, filesCount, modalVisible, imageDialogUrl, note: { files } } = this.state

    const notes = map(part.notes, (note, idx) => {
      const deleteNote = isAdmin
        ? <button onClick={ this.handleNoteDelete(note.id, note.part_id) } className="btn btn-danger btn-sm pull-right">Slet</button>
        : !note.admin && !readonly
          ? <button onClick={ this.handleNoteDelete(note.id, note.part_id) } className="btn btn-danger btn-sm pull-right">Slet</button>
          : null

      return (
        <div key={idx} className="well well-sm" style={ { background: 'white' } }>
          <p>
            {
              note.content.split('\n').map((line, idx) => {
                return (
                  <span key={ idx }>
                    { line }
                    <br />
                  </span>
                )
              })
            }
          </p>
          {
            note.attachments && note.attachments.length > 0
              ? (
                <div>
                  <i className="fa fa-paperclip"></i> { map(note.attachments, (file, idx) =><span key={idx}><a target="_blank" href={ `https://s3.${S3_REGION}.amazonaws.com/${S3_BUCKET}/${file.s3_file_id}` }>{ file.filename }</a>, </span>) }
                </div>
              )
              : null
          }
          <p style={ { borderTop: '1px solid #999', paddingTop: '5px' } }>
            {
              note.admin
              ? <span><span className="date">plan1</span> - { note.name }</span>
              : <span>{ note.name }</span>
            }
            <small className="text-muted"> ({ moment(note.created_at).format('DD.MM.YYYY, H:mm:ss') })</small>
            { deleteNote }
          </p>
          <hr />
        </div>
      )
    })

    const totalUploadProgress = uploading
      ? (
        <div>
          <Line percent={totalPercent} strokeWidth={ 2 } trailWidth={ 2 }/>
          <p>Uploader { filesDone + 1 } / { filesCount } { filesCount > 1 ? 'filer' : 'fil'}</p>
        </div>
      )
      : null

  const imageUrl = images.length > 0
    ? `https://ik.imagekit.io/aw3ddey5g/${images[0].s3_image_id}?tr=w-223,h-167`
    : noImage

    return (<>
        <Grid className={`${classes.partGrid} ${classes.noPaddingTop}`}>
            <Badge
                overlap="circular"
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
                }}
                badgeContent={part.prioritized && <Tooltip title="Skal prioriteres"><ErrorIcon className={classes.iconWarning}/></Tooltip>}
            >
                <Avatar className={classes.partAvatar} src={imageUrl} />
            </Badge>
            <div className={classes.partItemTitle}><Typography>{ part.title }</Typography> <Condition condition={ part.condition } hasText={ false } /></div>
        </Grid>
        <Grid container alignItems="stretch" spacing={3}>
            <Grid item sm={12} md={7}>
                <Paper className={classes.paper}>
                    <Typography className={classes.header} variant="h5">Tilstandsvurdering</Typography>
                    <Typography paragraph>
                        { part.condition_text
                            ? part.condition_text.split('\n').map((line, idx) => {
                                return (
                                    <span key={ idx }>
                                    { line }
                                    <br />
                                    </span>
                                )
                            }): null }
                   <h4 className="text-muted">Restlevetid</h4>
                   <h4>{ part.remaining_lifetime } år</h4>
                   <h4 className="text-muted">Tilstand</h4>
                   <Condition condition={ part.condition } hasText />
                    </Typography>
                </Paper>
                <Paper className={`${classes.paper} ${classes.paperMarginTop}`}>
                    <Typography className={classes.header} variant="h5">Opgaver</Typography>
                   <div className="well well-sm well-dark">
                     <p>Frekvens: <b>{ part.maintenance_interval }. år</b><br />
                       Første gang: <b>{ part.maintenance_start }</b><br />
                       Omkostning pr vedligeholdelsesinterval: <b>{ numeral(part.maintenance_cost).format('0,0') }</b>
                     </p>
                   </div>
                    <Typography paragraph>
                     { part.maintenance
                       ? part.maintenance.split('\n').map((line, idx) => {
                         return (
                           <span key={ idx }>
                             { line }
                             <br />
                           </span>
                         )
                       })
                       : null
                     }
                    </Typography>
                </Paper>
            </Grid>
            <Grid item sm={12} md={5}>
                <Paper className={classes.paper}>
                    <Typography className={classes.header} variant="h5">Specifikationer</Typography>
                    <Typography paragraph>
                      { part.specification
                         ? part.specification.split('\n').map((line, idx) => {
                           return (
                             <span key={ idx }>
                               { line }
                               <br />
                             </span>
                           )
                         })
                         : null
                      }
                    </Typography>
                    <h4 className="text-muted">Antal</h4>
                    <h4>{ part.amount }</h4>
                   <h4 className="text-muted">Indbygget år</h4>
                   <h4>{ part.integrated_year }</h4>
                </Paper>
                <div className={`${classes.paperMarginTop}`}>
                    <Typography className={`${classes.textCenter} ${classes.header}`} variant="h5">Billeder</Typography>
                    <Grid container spacing={3}>
                        {
                        images.map((image, idx) =>
                        <Grid key={ idx } item sm={6} md={4}>
                            <a href="#" onClick={(e) => this.toggleModal(e, `https://ik.imagekit.io/aw3ddey5g/${image.s3_image_id}`)}>
                                <img src={ `https://ik.imagekit.io/aw3ddey5g/${image.s3_image_id}?tr=w-400,h-233` } className="img-responsive" />
                            </a>
                        </Grid>
                        )
                    }
                   </Grid>
                </div>
                <Paper className={`${classes.paper} ${classes.paperMarginTop}`}>
                  <Typography className={classes.header} variant="h5">Eftersyn</Typography>
                   <div className="well well-sm well-dark">
                     <p>Frekvens: { part.inspection_interval }. år<br />
                       Første gang: { part.inspection_start }
                     </p>
                   </div>
                    <Typography paragraph>
                     { part.inspection
                       ? part.inspection.split('\n').map((line, idx) => {
                         return (
                           <span key={ idx }>
                             { line }
                             <br />
                           </span>
                         )
                       })
                       : null
                     }
                   </Typography>
                </Paper>
                <Paper className={`${classes.paper} ${classes.paperMarginTop}`}>
                  <Typography className={classes.header} variant="h5">Brugerens noter</Typography>
                  <Typography paragraph>
                    Brug noter til at gemme overvejelser som er relevante for den enkelte bygningsdel
                  </Typography>
                  { notes }
                  {!readonly &&
                  <div>
                    <form className="text-center">
                      <div className={ classNames('form-group', { 'has-error': this.state.hasError.content }) }>
                        <textarea className="form-control" rows="3" placeholder="Opret en note på denne bygningsdel her" onChange={ this.handleNoteContent } value={ this.state.note.content }></textarea>
                        <div className={ classNames('help-block', { hidden: !this.state.hasError.content }) }>Dette felt skal udfyldes</div>
                      </div>
                      <div className={ classNames('form-group', { 'has-error': this.state.hasError.name }, { hidden: isAdmin }) }>
                        <label htmlFor="">Navn:</label>
                        <input className="form-control" placeholder="Dit navn" onChange={ this.handleNoteName } value={ this.state.note.name } />
                        <div className={ classNames('help-block', { hidden: !this.state.hasError.name }) }>Dette felt skal udfyldes</div>
                      </div>
                      <Dropzone
                        className="dropzone part-note"
                        activeClassName="dropzoneActive"
                        rejectClassName="label-danger dropzoneReject"
                        onDrop={ this.onDrop }
                        maxSize={ 20971520 }
                        onDropRejected={ this.onDropRejected }
                        // disableClick={ current.published || !hasEditRights }
                      >
                        Klik her for at uploade en fil. Det er også muligt at trække og slippe filer. <br/>
                        Filerne kan være max. 20 mb pr stk.
                        <div className="row">
                          <div className="col-md-8 col-md-offset-2">
                            { totalUploadProgress }
                          </div>
                        </div>
                      </Dropzone>
                      <div>
                        { files.length>0 ? <i className="fa fa-paperclip"></i> : null }
                        { map(files, (file, idx) => {
                          return <span key={idx}> <a target="_blank" href={ `https://s3.${S3_REGION}.amazonaws.com/${S3_BUCKET}/${file.s3_file_id}` }>{ file.filename }</a>, </span>
                        }) }
                      </div>
                      <p className="text-center"><button type="button" onClick={ this.handleSave } className="btn btn-primary">Opret note</button></p>
                    </form>
                  </div>}
                </Paper>
            </Grid>
        </Grid>
        <Dialog maxWidth="md" fullWidth onClose={this.toggleModal} open={modalVisible}>
            <DialogTitle onClose={this.toggleModal}>
                {part.title}
            </DialogTitle>
            <DialogContent dividers>
                {imageDialogUrl && <img className={classes.dialogImage} src={imageDialogUrl}/>}
            </DialogContent>
        </Dialog>
        </>
    //   <div className="page-break">
    //     <section id="headerDock" className="wrapper">
    //       <div id="stickyNav_container">
    //         <div id="stickyNav">
    //           <div id="masthead">
    //             <div className="container">
    //               <div className="row">
    //                 <div className="col-md-12">
    //                   <div className="pull-right header-actions">
    //                     <p>
    //                       <Link to={ `/c/${customer_id}/parts` }>Alle bygningsdele</Link> | <Link to={ `/c/${customer_id}/activity-plan` }>Aktivitetsplan</Link>
    //                     </p>
    //                   </div>
    //                   <h2>{ part.title }</h2>
    //                   <p className="text-muted">Drifts- og vedligeholdelsesplan: <span className="plan">{ plan.name }</span></p>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </section>


    //     <section>
    //       <div className="container">
    //         <div className="row">
    //           <div className="col-md-3" id="leftCol" style={ { marginTop: '30px' } }>
    //             <div className="well">
    //               <div className="text-center">
    //                 <h4 className="text-center">Stamkort</h4>
    //               </div>
    //               <div className="row text-center">
    //                 <div className="col-md-12">
    //                   <h4 className="text-muted">Indbygget år</h4>
    //                   <h4>{ part.integrated_year }</h4>
    //                 </div>
    //                 <div className="col-md-12">
    //                   <h4 className="text-muted">Restlevetid</h4>
    //                   <h4>{ part.remaining_lifetime } år</h4>
    //                   <h4 className="text-muted">Tilstand</h4>
    //                   <Condition condition={ part.condition } hasText />
    //                 </div>
    //               </div>
    //               <div className="row text-center">
    //                 <div className="col-md-12">
    //                   <h4 className="text-muted">Antal</h4>
    //                   <h4>{ part.amount }</h4>
    //                 </div>
    //                 <div className="col-md-12">
    //                   <h4 className="text-muted">Tilstand</h4>
    //                   <Condition condition={ part.condition } hasText />
    //                 </div>
    //               </div>
    //               <div className="row text-center">
    //                 <div className="col-md-12">
    //                   <h4 className="text-muted">BIPS ID</h4>
    //                   <h4>{ part.bips_id }</h4>
    //                 </div>
    //               </div>
    //             </div>
    //             <ConditionLegend />
    //           </div>


    //           <div className="col-md-9">
    //             <h2 id="sec0" className="text-center">Specifikation</h2>
    //             <p className="text-center text-muted"><small>Beskrivelse af bygningsdelen.</small></p>
    //             <p>
    //               { part.specification
    //                 ? part.specification.split('\n').map((line, idx) => {
    //                   return (
    //                     <span key={ idx }>
    //                       { line }
    //                       <br />
    //                     </span>
    //                   )
    //                 })
    //                 : null
    //               }
    //             </p>
    //             <hr />
    //             <h3 className="text-center"><i className="fa fa-camera-retro"></i> Billeder</h3>
    //             <p className="text-center"><small>Billeder af bygningsdelen</small></p>
    //             <div className="row image-gallery">
    //               {
    //                 images.map((image, idx) =>
    //                   <div key={ idx } className="col-md-4">
    //                     <a href={ `https://ik.imagekit.io/aw3ddey5g/${image.s3_image_id}` } target="_blank">
    //                       <img src={ `https://ik.imagekit.io/aw3ddey5g/${image.s3_image_id}?tr=w-400,h-233` } className="img-responsive" />
    //                     </a>
    //                   </div>
    //                 )
    //               }
    //             </div>

    //             <hr />

    //             <div className="well">

    //               <h3 className="text-center"><i className="fa fa-exclamation-triangle"></i> Ekstraordinær omkostning</h3>
    //               <div className="well well-sm well-dark">
    //                 <p>
    //                   Udføres: { part.establishment_year }
    //                   <br />
    //                   Omkostning: { numeral(part.establishment_cost).format('0,0') } kr.
    //                 </p>
    //               </div>
    //               <p>
    //                 { part.establishment
    //                   ? part.establishment.split('\n').map((line, idx) => {
    //                     return (
    //                       <span key={ idx }>
    //                         { line }
    //                         <br />
    //                       </span>
    //                     )
    //                   })
    //                   : null
    //                 }
    //               </p>
    //               <hr />

    //               <h3 className="text-center"><i className="fa fa-gavel"></i> Vedligehold</h3>
    //               <div className="well well-sm well-dark">
    //                 <p>Frekvens: { part.maintenance_interval }. år<br />
    //                   Første gang: { part.maintenance_start }<br />
    //                   Omkostning pr vedligeholdelsesinterval: { numeral(part.maintenance_cost).format('0,0') }
    //                 </p>
    //               </div>
    //               <p>
    //                 { part.maintenance
    //                   ? part.maintenance.split('\n').map((line, idx) => {
    //                     return (
    //                       <span key={ idx }>
    //                         { line }
    //                         <br />
    //                       </span>
    //                     )
    //                   })
    //                   : null
    //                 }
    //               </p>

    //               <hr />

    //               <h3 className="text-center"><i className="fa fa-bell"></i> Eftersyn</h3>
    //               <div className="well well-sm well-dark">
    //                 <p>Frekvens: { part.inspection_interval }. år<br />
    //                   Første gang: { part.inspection_start }
    //                 </p>
    //               </div>
    //               <p>
    //                 { part.inspection
    //                   ? part.inspection.split('\n').map((line, idx) => {
    //                     return (
    //                       <span key={ idx }>
    //                         { line }
    //                         <br />
    //                       </span>
    //                     )
    //                   })
    //                   : null
    //                 }
    //               </p>

    //             </div>

    //             <hr />

    //             <div>
    //               <h3 id="sec4" className="text-center"><i className="fa fa-pencil-square-o"></i> Brugerens noter</h3>
    //               <p className="text-center">Brug noter til at gemme overvejelser som er relevante for den enkelte bygningsdel</p>

    //               {!readonly &&
    //               <div>
    //                 <form className="text-center">
    //                   <div className={ classNames('form-group', { 'has-error': this.state.hasError.content }) }>
    //                     <textarea className="form-control" rows="3" placeholder="Opret en note på denne bygningsdel her" onChange={ this.handleNoteContent } value={ this.state.note.content }></textarea>
    //                     <div className={ classNames('help-block', { hidden: !this.state.hasError.content }) }>Dette felt skal udfyldes</div>
    //                   </div>
    //                   <div className={ classNames('form-group', { 'has-error': this.state.hasError.name }, { hidden: isAdmin }) }>
    //                     <label htmlFor="">Navn:</label>
    //                     <input className="form-control" placeholder="Dit navn" onChange={ this.handleNoteName } value={ this.state.note.name } />
    //                     <div className={ classNames('help-block', { hidden: !this.state.hasError.name }) }>Dette felt skal udfyldes</div>
    //                   </div>
    //                   <Dropzone
    //                     className="dropzone part-note"
    //                     activeClassName="dropzoneActive"
    //                     rejectClassName="label-danger dropzoneReject"
    //                     onDrop={ this.onDrop }
    //                     maxSize={ 20971520 }
    //                     onDropRejected={ this.onDropRejected }
    //                     // disableClick={ current.published || !hasEditRights }
    //                   >
    //                     Klik her for at uploade en fil. Det er også muligt at trække og slippe filer. <br/>
    //                     Filerne kan være max. 20 mb pr stk.
    //                     <div className="row">
    //                       <div className="col-md-8 col-md-offset-2">
    //                         { totalUploadProgress }
    //                       </div>
    //                     </div>
    //                   </Dropzone>
    //                   <div>
    //                     { files.length>0 ? <i className="fa fa-paperclip"></i> : null }
    //                     { map(files, (file, idx) => {
    //                       return <span key={idx}> <a target="_blank" href={ `https://s3.${S3_REGION}.amazonaws.com/${S3_BUCKET}/${file.s3_file_id}` }>{ file.filename }</a>, </span>
    //                     }) }
    //                   </div>
    //                   <p className="text-center"><button type="button" onClick={ this.handleSave } className="btn btn-primary">Opret note</button></p>
    //                 </form>
    //                 <hr />
    //               </div>}
    //             </div>
    //             { notes }
    //           </div>
    //         </div>
    //         <hr />
    //       </div>
    //     </section>

    //   </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  const part_id = toInteger(props.params.part_id) || props.part_id
  const part = find(state.data.customer.parts, { id: part_id })
  return {
    plan: state.data.customer.plan,
    part,
    isAdmin: state.auth.isAdmin,
    readonly: state.data.customer.readonly,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    setBreadcrumb: actions.setBreadcrumb,
    saveNote: actions.saveNote,
    deleteNote: actions.deleteNote,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Part))
