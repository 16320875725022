import React from 'react'
import { Route, IndexRoute, Link } from 'react-router'
import requireAuth from 'client/components/AuthenticatedComponent'

import CustomerWrapper from 'client/routes/Customer/index'
import Dashboard from 'client/routes/Customer/Dashboard'
import Overview from 'client/routes/Customer/Overview'
import Property from 'client/routes/Customer/Property'
import Energy from 'client/routes/Customer/Energy'
import PartsWrapper from 'client/routes/Customer/PartsWrapper'
import PartsList from 'client/routes/Customer/PartsList'
import Part from 'client/routes/Customer/Part'
import ActivityWrapper from 'client/routes/Customer/ActivityWrapper'
import ActivityPlan from 'client/routes/Customer/ActivityPlan'
import Files from 'client/routes/Customer/Files'

import FAQ from 'client/routes/Customer/FAQ'

const CustomerRoutes = (
  <Route path="c/:customer_id" state="Start" component={ requireAuth(CustomerWrapper) }>
    <IndexRoute component={ Dashboard } state="Start" title="Start - Plan1" />
    <Route path="overview"     handle={{
      // you can put whatever you want on a route handle
      // here we use "crumb" and return some elements,
      // this is what we'll render in the breadcrumbs
      // for this route
      crumb: () => <Link to="/messages">Messages</Link>,
    }} component={ Overview } state="Overblik" title="Overblik - Plan1" />
    <Route path="property" component={ Property } state="Ejendommen" title="Ejendommen - Plan1" />
    <Route path="energy" component={ Energy } state="Energi" title="Energi - Plan1" />
    <Route path="parts" component={ PartsWrapper } state="Bygningsdele" title="Bygningsdele - Plan1">
      <IndexRoute state="Bygningsdele" component={ PartsList } />
      <Route path=":part_id" component={ Part } />
    </Route>
    <Route path="activity-plan" component={ ActivityWrapper } title="Projektoversigt - Plan1">
      <IndexRoute component={ ActivityPlan } />
      <Route path=":activity_year" component={ ActivityPlan } />
    </Route>
    <Route path="files" component={ Files } state="Filarkiv" title="Filarkiv - Plan1" />
    <Route path="faq" component={ FAQ } state="FAQ" title="FAQ - Plan1" />
  </Route>
)

export default CustomerRoutes
