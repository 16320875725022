import { push } from 'react-router-redux'
import { notifyDanger, clearNotifications, notifySuccess } from 'client/components/Notifications/actions'
import api, { ApiError } from 'client/lib/apiHandler'
import { ActionTypes } from 'client/constants'
import { find, forEach, concat, reduce, inRange } from 'lodash'
import {
  addPriceIndexAndTaxToEstimate,
  addPriceIndexAndTaxToParts,
  calculateOrdinaryTotal,
  calculateExtraordinaryTotalsByYear,
  calculateExtraordinaryTotal,
  calculateTotalForSCAB,
  calculateOrdinaryTotalsByYear,
  calculateTotalsByYearForSC,
  calculateTotalsByYearForAB,
  calculateTotalsByYearForSCAB,
  calculateTotalsById,
  mergeTotals} from 'client/lib/keyNumbers'

export const fetchPortfolioFrontend = (id) => {

  const calculateTotalResidentialArea= (plans) => {
    return (
      reduce(plans, (totalArea, plan) => {
        return totalArea += plan.residential_area
      }, null)
    )
  }
  const calculateTotalCommercialArea= (plans) => {
    return (
      reduce(plans, (totalArea, plan) => {
        return totalArea += plan.commercial_area
      }, null)
    )
  }
  const calculateTotalResidentialUnits= (plans) => {
    return (
      reduce(plans, (totalUnits, plan) => {
        return totalUnits += plan.residential_units
      }, null)
    )
  }
  const calculateTotalCommercialUnits= (plans) => {
    return (
      reduce(plans, (totalUnits, plan) => {
        return totalUnits += plan.commercial_units
      }, null)
    )
  }

  return dispatch => {

    api.get(`/portfolio_customers/${id}`)
      .then(({ body }) => {
        const portfolio = body.portfolio
        const bipsList = body.bips
        let partList = []
        let estimateList = []

        /*let startYear = portfolio.plans.length > 0 ? portfolio.plans[0].start_year : 0
        forEach(portfolio.plans, (plan) => {
          if (plan.start_year < startYear) {
            startYear = plan.start_year
          }
        })*/
        const startYear = portfolio.start_year
        const activePlans = []
        const inactivePlans = []

        forEach(portfolio.plans, (plan) => {
          if (plan.published && plan.start_year == startYear) {
            activePlans.push(plan)
          } else {
            inactivePlans.push(plan)
            return
          }
          const parsed_price_index = 1

          const parts = addPriceIndexAndTaxToParts(plan.parts, parsed_price_index)
          partList = concat(partList, parts)

          const ranged_estimates = plan.estimates.filter(estimate => {
            return inRange(estimate.year, plan.start_year, plan.start_year + 15) || estimate.year === null
          })
          const estimates = addPriceIndexAndTaxToEstimate(ranged_estimates, parsed_price_index)
          estimateList = concat(estimateList, estimates)
        })

        //Now calculate estimates based on all plans and parts indexed by bips
        let estimates_by_bips = {
          estimates: {},
          total: 0,
          labels: {}
        }


        for (let i = 0;i < estimateList.length; i++) {
          const estimate = estimateList[i]
          const part = find(partList, {'id' : estimate.part_id})
          if (part && part.bips_id) {
            const bips_id = part.bips_id
            const bips = find(bipsList, {'id' : part.bips_id})
            if (!estimates_by_bips.estimates[bips_id]) {
              estimates_by_bips.estimates[bips_id] = {
                estimate: 0,
                label: bips.name,
              }
              //estimates_by_bips.labels[bips_id] = bips.name //TODO: Include BIPS
            }
            estimates_by_bips.estimates[bips_id].estimate += parseFloat(estimate.estimate)
            estimates_by_bips.total += parseFloat(estimate.estimate)
          }
        }

        const ordinaryMaintenance = calculateOrdinaryTotal(estimateList)
        const extraOrdinaryMaintenance = calculateExtraordinaryTotal(partList)
        const totalMaintenance = (ordinaryMaintenance + extraOrdinaryMaintenance)
        const ordinary_total_by_year = calculateOrdinaryTotalsByYear(estimateList)
        let extraordinary_by_year = calculateExtraordinaryTotalsByYear(partList)
        const scab_by_year = calculateTotalsByYearForSCAB(estimateList)
        extraordinary_by_year = mergeTotals(extraordinary_by_year, scab_by_year)

        const residentialArea = calculateTotalResidentialArea(activePlans)
        const commercialArea = calculateTotalCommercialArea(activePlans)
        const totalArea = residentialArea + commercialArea

        const residentialUnits = calculateTotalResidentialUnits(activePlans)
        const commercialUnits = calculateTotalCommercialUnits(activePlans)
        let averageMaintenancePerSquareMeter = 0
        //{ numeral(estimates.totals.ordinary_total * 1000 / plan.total_area / 15).format('0,0') }
        if (totalArea && totalMaintenance) {
          averageMaintenancePerSquareMeter = ordinaryMaintenance * 1000 / totalArea / 15
        }

        const totals = {
          //Count number of plans
          startYear,
          properties : activePlans.length,
          activePlans: activePlans,
          inActivePlans: inactivePlans,
          residentialArea : residentialArea ? residentialArea : 0,
          commercialArea : commercialArea ? commercialArea: 0,
          totalArea: residentialArea + commercialArea,
          residentialUnits : residentialUnits ? residentialUnits : 0,
          commercialUnits : commercialUnits ? commercialUnits : 0,
          totalUnits: residentialUnits + commercialUnits,
          averageMaintenancePerSquareMeter,
          ordinaryMaintenance,
          extraOrdinaryMaintenance,
          totalMaintenance,
          ordinary_total_by_year,
          extraordinary_by_year,
          estimates_by_bips,
        }

        dispatch({
          type: ActionTypes.RECEIVE_PORTFOLIO, payload: {
            portfolio,
            totals,
          }
        })
      })
  }
}

export default {
  fetchPortfolioFrontend
}
