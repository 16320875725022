import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Field, actions as formActions } from 'react-redux-form'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import AutoSave from 'client/components/AutoSave'
import { AUTO_SAVE_INTERVAL } from 'client/constants'
import moment from 'moment'
import classnames from 'classnames'
import { has, throttle } from 'lodash'
import { StickyContainer, Sticky } from 'react-sticky'
import { parseIntOrNull } from 'common/lib/validator'

import {
  InputField,
  TextField,
  SelectField,
} from 'client/components/Field'

import actions from 'client/actions'

let years = []
let year = moment().subtract(1, 'y')
for (let i = 0; i < 16; i++) {
  year = year.add(1, 'y')
  years = years.concat(year.year())
}

let intervals = []
let interval = ''
for (let i = 1; i <= 17; i++) {
  interval = <option value={ i } key={ i }>{ i } år</option>
  intervals = intervals.concat(interval)
}


class PartTemplate extends Component {

  static propTypes = {
    current: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    form_data: PropTypes.object.isRequired,
    form_model: PropTypes.string.isRequired,
    should_save: PropTypes.bool.isRequired,
    is_saving: PropTypes.bool.isRequired,
    last_saved: PropTypes.number.isRequired,
    auto_save_interval: PropTypes.number.isRequired,
    params: PropTypes.object,
    save: PropTypes.func.isRequired,
    deleteTemplate: PropTypes.func.isRequired,
    fetch: PropTypes.func.isRequired,
    fetchBips: PropTypes.func.isRequired,
    resetForm: PropTypes.func.isRequired,
    clearAutosave: PropTypes.func.isRequired,
    invalidateData: PropTypes.func.isRequired,
    forceUpdateAutosave: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
  }

  constructor (props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
  }

  componentWillMount () {
    const { dispatch, fetchBips, fetch, save, params, forceUpdateAutosave } = this.props

    if (params.id) {
      dispatch(fetch(params.id))
    } else {
      dispatch(fetchBips())
    }

    const throttled = throttle(() => dispatch(forceUpdateAutosave()), 30000)
    // handle autosave
    const funcId = setInterval(() => {
      throttled()
      //HACK: validating required field so it doesn't autosave
      if (this.props.should_save && this.props.current.title !== null && this.props.current.bips_id !== null) {
        if (has(this.props.current, 'id')) {
          dispatch(save('update', this.props.current))
        } else {
          dispatch(save('create', this.props.current))
        }
      }
    }, this.props.auto_save_interval)
    this.setState({
      auto_save_handler: funcId,
    })
  }

  componentWillUnmount () {
    const { form_model, dispatch, resetForm, clearAutosave, invalidateData } = this.props
    dispatch(clearAutosave())
    dispatch(resetForm(form_model))
    dispatch(invalidateData())

    // clear auto save interval handler
    clearInterval(this.state.auto_save_handler)
  }

  handleSubmit (item) {
    return ev => {
      const { dispatch, save } = this.props
      if (has(item, 'id')) {
        dispatch(save('update', this.props.current))
      } else {
        dispatch(save('create', this.props.current))
      }
    }
  }

  handleDelete (ev) {
    const { dispatch, deleteTemplate, current } = this.props
    if (window.confirm('Er du sikker?')) {
      dispatch(deleteTemplate(current.id))
    }
  }

  render () {
    const {
      current, form, form_data,
      form_model, is_saving, last_saved, role
    } = this.props
    const readOnly = role !== 'admin'
    return (
      <StickyContainer>

          <Sticky>
            {() => (
            <div className="row sub-header">
                <div className="pull-right action-bar">
                <AutoSave is_saving={ is_saving } last_saved={ last_saved } />
                <Link to="/admin/part-templates" className="btn btn-primary">Tilbage</Link>&nbsp;
                {
                    readOnly
                    ? null
                    : <button className="btn btn-primary" onClick={ this.handleDelete } disabled={ !current.id }><i className="fa fa-trash"></i> Slet</button>
                }
                </div>
                <h3>
                Tilføj Standard
                </h3>
            </div>
            )}
          </Sticky>


        <div className="row">
          <div className="col-md-6">
            <div className="well">

              <InputField
                model={ `${form_model}.title` }
                label="Title"
                className="form-control input-lg"
                disabled={ readOnly }
                />

              <SelectField
                model={ `${form_model}.bips_id` }
                label="Vælg kategori (BIPS listen)"
                className="form-control"
                disabled={ readOnly }
                >
                <option value={ null }>---</option>
                { form_data.bips.map(bip => <option key={ bip.id } value={ bip.id }>{ bip.id }. { bip.name }</option>) }
              </SelectField>

              <TextField
                model={ `${form_model}.internal_note` }
                label="Intern arbejdsbeskrivelse (Vises ikke i driftsplan)"
                className="form-control"
                rows="8"
                cols="40"
                disabled={ readOnly }
                />

              <TextField
                model={ `${form_model}.specification` }
                label="Specifikation"
                className="form-control"
                rows="8"
                cols="40"
                disabled={ readOnly }
                />

              <div className="row">

                <div className="col-xs-6">
                  <InputField
                    model={ `${form_model}.integrated_year` }
                    label="Indbygget år"
                    type="number"
                    className="form-control"
                    placeholder="F.eks 1903"
                    disabled
                    >
                    <span className="help-block">Bygning opført i [ÅRSTAL]</span>
                  </InputField>
                </div>

                <div className="col-xs-6">
                  <InputField
                    model={ `${form_model}.remaining_lifetime` }
                    label="Rest levetid"
                    type="number"
                    className="form-control"
                    placeholder="Levetid minus indbygningsår"
                    disabled
                    />
                </div>

              </div>

              <div className="row">

                <div className="col-xs-6">
                  <InputField
                    model={ `${form_model}.amount` }
                    label="Antal"
                    type="text"
                    className="form-control"
                    placeholder="Mængde i en passende enhed"
                    disabled
                    />
                </div>

                <div className="col-xs-6">
                  <SelectField
                    model={ `${form_model}.condition` }
                    label="Tilstand"
                    className="form-control"
                    disabled={ readOnly }
                    >
                    <option value="">Vælg tilstand</option>
                    <option value="1">Rød (under middel)</option>
                    <option value="2">Gul (middel)</option>
                    <option value="3">Grøn (god)</option>
                  </SelectField>
                </div>

              </div>
            </div> {/* END well */}
          </div> {/* END col-md-6 */}

          <div className="col-md-6">
            <div className="well">

              <TextField
                model={ `${form_model}.establishment` }
                label="Opretning (udføres i år [startår])"
                className="form-control"
                rows="8"
                cols="40"
                disabled={ readOnly }
                />

              <InputField
                model={ `${form_model}.establishment_cost` }
                label="Omkostninger ekskl. moms og prisindeks"
                type="number"
                parser={ parseIntOrNull }
                className="form-control"
                placeholder="Eks. 8000"
                disabled={ readOnly }
                />

              <TextField
                model={ `${form_model}.establishment_cost_note` }
                label="Interne noter til udregning"
                className="form-control"
                rows="4"
                cols="40"
                disabled={ readOnly }
                >
                <span className="help-block">Vises ikke i driftsplan</span>
              </TextField>

            </div> {/* END well */}

            <div className="well">

              <TextField
                model={ `${form_model}.maintenance` }
                label="Vedligehold"
                className="form-control"
                rows="8"
                cols="40"
                disabled={ readOnly }
                />

              <div className="row">

                <div className="col-xs-6">
                  <SelectField
                    model={ `${form_model}.maintenance_interval` }
                    label="Vedligeholdelsesinterval"
                    className="form-control"
                    disabled={ readOnly }
                    >
                    <option value="">Vælg interval</option>
                    { intervals }
                  </SelectField>
                </div>

                <div className="col-xs-6">
                  <SelectField
                    model={ `${form_model}.maintenance_start` }
                    label="Første gang"
                    className="form-control"
                    disabled
                    >
                    <option value="">Første gang (årstal)</option>
                    { years.map(y => <option key={ y } value={ y }>{ y }</option>) }
                  </SelectField>
                </div>

              </div> {/* END row */}

              <div className="row">

                <div className="col-xs-6">
                  <InputField
                    model={ `${form_model}.maintenance_cost` }
                    label="Omkostninger ekskl. moms og prisindeks"
                    parser={ parseIntOrNull }
                    type="number"
                    className="form-control"
                    placeholder="Eks. 8000"
                    disabled={ readOnly }
                    />
                </div>

                <div className="col-xs-6">
                  <div className="form-group">
                    <label htmlFor="">Kræver:</label>
                    <br />
                    <Field model={ `${form_model}.scaffolding` }>
                      <label className="checkbox-inline">
                        <input type="checkbox" value disabled={ readOnly } /> Stillads/lift
                      </label>
                    </Field>
                    <Field model={ `${form_model}.construction_site` }>
                      <label className="checkbox-inline">
                        <input type="checkbox" value disabled={ readOnly } /> Byggeplads
                      </label>
                    </Field>
                    <Field model={ `${form_model}.adviser` }>
                      <label className="checkbox-inline">
                        <input type="checkbox" value disabled={ readOnly } /> Teknisk rådgiver
                      </label>
                    </Field>
                    <Field model={ `${form_model}.building_management` }>
                      <label className="checkbox-inline">
                        <input type="checkbox" value disabled={ readOnly } /> Byggesagsadministration
                      </label>
                    </Field>
                  </div>
                </div>
              </div>

              <TextField
                model={ `${form_model}.maintenance_cost_note` }
                label="Interne noter til udregning"
                className="form-control"
                rows="4"
                cols="40"
                disabled={ readOnly }
                >
                <span className="help-block">Vises ikke i driftsplan</span>
              </TextField>

            </div> {/* END well */}

            <div className="well">

              <TextField
                model={ `${form_model}.inspection` }
                label="Eftersyn"
                className="form-control"
                rows="8"
                cols="40"
                disabled={ readOnly }
                />

              <div className="row">

                <div className="col-xs-6">
                  <SelectField
                    model={ `${form_model}.inspection_interval` }
                    label="Eftersynsinterval"
                    className="form-control"
                    disabled={ readOnly }
                    >
                    <option value="">Vælg interval</option>
                    { intervals }
                  </SelectField>
                </div>

                <div className="col-xs-6">
                  <SelectField
                    model={ `${form_model}.inspection_start` }
                    label="Første gang"
                    className="form-control"
                    disabled
                    >
                    <option value="">Første gang (årstal)</option>
                    { years.map(y => <option key={ y } value={ y }>{ y }</option>) }
                  </SelectField>
                </div>

              </div> {/* END row */}

            </div> {/* END well */}
          </div> {/* END col-md-6 */}
        </div> {/* END row */}

        <hr />

      </StickyContainer>
    )
  }
}

const mapStateToProps = state => {
  return {
    current: state.data.part_template.current,
    form: state.data.part_template.form,
    form_data: state.data.part_template.form_data,
    should_save: state.data.part_template.auto_save.should_save,
    is_saving: state.data.part_template.auto_save.is_saving,
    last_saved: state.data.part_template.auto_save.last_saved,
    auto_save_interval: AUTO_SAVE_INTERVAL,
    form_model: 'data.part_template.current',
    role: state.auth.role,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    save: actions.savePartTemplate,
    deleteTemplate: actions.deletePartTemplate,
    clearAutosave: actions.clearAutosave,
    fetch: actions.fetchPartTemplate,
    fetchBips: actions.fetchBips,
    resetForm: formActions.reset,
    invalidateData: actions.invalidateData,
    forceUpdateAutosave: actions.forceUpdateAutosave,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PartTemplate)
