import React from 'react'
import PropTypes from 'prop-types';
import { Link } from 'react-router'

const { toString } = Object.prototype
const typeOf = o => toString.call(o).slice(8, -1).toLowerCase()

function createLocationDescriptor ({ to, query, hash, state }) {
  if (typeOf(to) === 'string') {
    return { pathname: to, query, hash, state }
  }

  return { query, hash, state, ...to }
}

function wrapLink (WrappedComponent, options) {
  if (!WrappedComponent) {
    throw new Error('wrapLink() must be given a tag name or React component')
  }

  const opts = {
    link: false,
    linkClassName: undefined,
    ...options,
  }

  const WrapLink = ({
    link = opts.link,
    onlyActiveOnIndex = true,
    activeClassName = 'active',
    linkProps, to, query, hash, state,
    activeStyle,
    children, ...props
  }, context) => {
    const location = createLocationDescriptor({ to, query, hash, state })
    const { router } = context

    if (router) {
      props.active = router.isActive(location, onlyActiveOnIndex)
      if (props.active) {
        props.className = `${props.className || ''}${props.className ? ' ' : ''}${activeClassName}`

        if (activeStyle) {
          props.style = { ...props.style, activeStyle }
        }
      }
    }

    if (!link) {
      return <WrappedComponent { ...props }>{ children }</WrappedComponent>
    }

    return (
      <WrappedComponent {...props}>
        <Link className={opts.linkClassName} {...linkProps} to={location}>
          { children }
        </Link>
      </WrappedComponent>
    )
  }
  WrapLink.contextTypes = {
    router: PropTypes.object,
  }
  WrapLink.propTypes = {
    activeClassName: PropTypes.string,
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    activeStyle: PropTypes.object,
    className: PropTypes.string,
    hash: PropTypes.string,
    link: PropTypes.bool,
    linkProps: PropTypes.object,
    onlyActiveOnIndex: PropTypes.bool,
    query: PropTypes.object,
    state: PropTypes.object,
    children: PropTypes.node,
    style: PropTypes.object,
    active: PropTypes.bool,
  }

  return WrapLink
}

export default wrapLink
