import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Field, actions as formActions } from 'react-redux-form'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import AutoSave from 'client/components/AutoSave'
import { AUTO_SAVE_INTERVAL } from 'client/constants'
import moment from 'moment'
import classNames from 'classnames'
import { push } from 'react-router-redux'
import Select from "react-select";
import fetch from 'isomorphic-fetch'
import { API_URI } from 'client/constants'
import { uniqBy } from 'lodash'
import actions from 'client/actions'

class NewPart extends Component {

  static propTypes = {
    plan_id: PropTypes.string.isRequired,
    plan_published: PropTypes.bool.isRequired,
    rendered_component: PropTypes.string.isRequired,
    dispatch: PropTypes.func.isRequired,
    createPart: PropTypes.func.isRequired,
    setRenderedComponent: PropTypes.func.isRequired,
    hasEditRights: PropTypes.bool.isRequired,
  }

  constructor (props) {
    super(props)
    this.handleBipsChange = this.handleBipsChange.bind(this)
    this.handleTemplateChange = this.handleTemplateChange.bind(this)
    this.handleCreate = this.handleCreate.bind(this)
    this.state = {
      bips: {
        available: [],
        selected: '',
      },
      templates: {
        available: [],
        filtered: [],
        selected: [],
      },
    }
  }

  componentDidMount () {
    const { dispatch, plan_published, hasEditRights, setRenderedComponent, rendered_component, plan_id } = this.props
    if (plan_published || !hasEditRights) {
      dispatch(push(`/admin/plans/edit/${plan_id}/parts`))
    }
    if (rendered_component !== 'plan') {
      dispatch(setRenderedComponent('plan'))
    } else {
      const token = localStorage.getItem('token')
      const options = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }

      fetch(`${API_URI}/parts/available-templates`, options)
        .then(res => res.json())
        .then(templates => {
          const available_bips = templates.map(template => {
            return {
              name: template.bips.name,
              id: template.bips.id,
            }
          })
          const uniq_bips = uniqBy(available_bips, 'id')
          const state = this.state
          state.bips.available = uniq_bips
          state.templates.available = templates
          this.setState(state)
        })
    }

  }

  handleBipsChange (e) {
    const selected = e.target.value
    const state = this.state
    const filtered = state.templates.available.filter(template => template.bips.id == selected) // eslint-disable-line eqeqeq
    state.templates.filtered = filtered
    state.templates.selected = []
    state.bips.selected = selected
    this.setState(state)
  }

  handleTemplateChange (selected) {
    const state = this.state
    const template = state.templates.available.find(template => template.id == selected.value) // eslint-disable-line eqeqeq
    state.bips.selected = template ? template.bips.id : state.bips.selected
    state.templates.selected = selected
    this.setState(state)
  }

  handleCreate () {
    const state = this.state
    const { dispatch, createPart, plan_id } = this.props
    dispatch(createPart(state.templates.selected.map(s => s.value), plan_id))
  }

  render () {
    const {
      bips,
      templates,
    } = this.state
    const { plan_published, hasEditRights } = this.props

    const bipsOptions = bips.available.map(bip => <option key={ bip.id } value={ bip.id }>{ bip.id }. { bip.name }</option>)
    const templateSelection = templates.filtered.length !== 0 ? templates.filtered : templates.available

    return (
      <div className="row">
        <div className="col-md-8 col-md-offset-2">
          <div className="well">
            <div className="form-group">
              <label>Vælg kategori (BIPS listen)</label>
              <select className="form-control" value={ bips.selected } onChange={ this.handleBipsChange }>
                <option value="">Vælg en kategori</option>
                { bipsOptions }
              </select>
            </div>
            <div className="form-group">
              <label>Vælg standard</label>
              <Select
                  isMulti={true}
                  value={templates.selected}
                  onChange={this.handleTemplateChange}
                  noOptionsMessage={() =>"Ingen resultat"}
                  placeholder="Vælg en standard"
                  options={ templateSelection.map(template => ({ value: template.id, label: template.title}))}
              />
            </div>
            <p><button onClick={ this.handleCreate } className={ classNames('btn btn-primary', { disabled: templates.selected === '' || plan_published || !hasEditRights }) }>Opret bygningsdel</button></p>
          </div>


        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const hasEditRights =  state.auth.role === 'admin' || (state.auth.role === 'editor' && state.data.plan.current.internal_contact_id === state.auth.userId) || !state.data.plan.current.internal_contact_id 
  return {
    plan_id: ownProps.params.id,
    plan_published: state.data.plan.current.published,
    rendered_component: state.ui.rendered_component,
    hasEditRights,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    createPart: actions.createPart,
    setRenderedComponent: actions.setRenderedComponent,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewPart)
