import React, { Component } from 'react'
import PropTypes from 'prop-types';
import moment from 'moment'
import isFinite from 'lodash/isFinite'

class DateTime extends Component {

  static propTypes = {
    format: PropTypes.string.isRequired,
    addYear: PropTypes.bool,
    source: PropTypes.oneOfType([
      PropTypes.number, PropTypes.string,
    ]).isRequired,
  }

  render () {
    const { format, source, addYear } = this.props
    let date = isFinite(source) ? moment.unix(source) : moment(source);
    if (addYear) {
        date.add(1, 'year');
    }

    return (
      <span>
        { date.format(format) }
      </span>
    )
  }
}

export default DateTime
