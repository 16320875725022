import fetch from 'isomorphic-fetch'
import { ActionTypes, API_URI } from 'client/constants'
import { reduce } from 'lodash'
import { notifyDanger, notifySuccess, clearNotifications } from 'client/components/Notifications/actions'
import api, { ApiError } from 'client/lib/apiHandler'

const systemTextReceive = (texts) => {
  return {
    type: ActionTypes.SYSTEM_TEXT_RECEIVE,
    payload: texts,
  }
}

const systemTextChange = (label, content) => {
  return {
    type: ActionTypes.SYSTEM_TEXT_CHANGE,
    payload: {
      label,
      content,
    },
  }
}

const systemTextFetch = (endpoint = '/system-texts') => {

  return (dispatch) => {

    api.get(endpoint)
    .then(({ body }) => {
      const texts = reduce(body, (acc, text) => {
        acc[text.label] = text.content
        return acc
      }, {})
      dispatch(systemTextReceive(texts))
    })
    .catch(error => {
      dispatch({ type: ActionTypes.RECEIVE_REQUEST, subtype: 'error' })
    })
  }
}

const systemTextSave = () => {

  return (dispatch, getState) => {
    const { data: { systemTexts } } = getState()

    api.put('/system-texts', systemTexts)
    .then(({ body }) => {
      dispatch(notifySuccess('Dine ændringer er gemt'))
    })
    .catch(error => {
      if (error instanceof ApiError) {
        dispatch(notifyDanger('Der skete en fejl', 'Error'))
      }
    })
  }
}

export default {
  systemTextFetch,
  systemTextSave,
  systemTextChange,
}
