import { ActionTypes } from 'client/constants'
import { jwtDecode } from "jwt-decode"

let id = null
let role = ''
let isAdmin = false
const token = localStorage.getItem('token')
if (token) {
  const decode = jwtDecode(token)
  if (decode.id) {
    id = decode.id
  } else if (decode.plan_id) {
    id = decode.plan_id
  } else if (decode.portfolio_id) {
    id = decode.portfolio_id
  }

  role = decode.role || ''
  isAdmin = decode.admin || false
}

// TODO check if localstorage token is expired
const authReducer = (state = {
  isFetching: false,
  isAuthenticated: !!localStorage.getItem('token'),
  hasError: false,
  errorType: null,
  errorMessage: '',
  isAdmin,
  userId: id,
  plans: [],
  role,
}, action) => {
  switch (action.type) {
    case ActionTypes.LOGIN_REQUEST:
      return {
        ...state,
        isFetching: true,
        hasError: false,
        errorType: null,
        errorMessage: '',
      }
    case ActionTypes.LOGIN_SUCCESS:
      let userId = null
      if (action.token.id) {
        userId = action.token.id
      } else if (action.token.plan_id) {
        userId = action.token.plan_id
      } else if (action.portfolio_id) {
        userId = action.token.portfolio_id
      }

      return {
        ...state,
        isAuthenticated: true,
        userId,
        role: action.token.role || ''
      }
    case ActionTypes.CHECK_ADMIN_AUTH:
      return {
        ...state,
        isAdmin: action.isAdmin,
      }
    case ActionTypes.SET_PLANS:
      return {
        ...state,
        plans: action.plans,
      }
    case ActionTypes.LOGIN_FAILURE:
      return {
        ...state,
        isAuthenticated: false,
        hasError: true,
        errorType: action.errorType,
        errorMessage: action.errorMessage,
      }
    case ActionTypes.LOGOUT:
      return {
        ...state,
        hasError: false,
        errorType: null,
        errorMessage: '',
        isAuthenticated: false,
        isAdmin: false,
        userId: null,
        role: '',
      }
    default:
      return state
  }
}

export default authReducer
