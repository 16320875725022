import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import NavBar from 'client/components/NavBar'
import Spinner from 'client/components/Spinner'
import { ListLink } from 'client/components/NavLink'
import Notifications from 'client/components/Notifications'
import actions from 'client/actions'
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import WbIncandescentIcon from '@material-ui/icons/WbIncandescent';
import ListAltIcon from '@material-ui/icons/ListAlt';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DescriptionIcon from '@material-ui/icons/Description';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import { withStyles } from '@material-ui/core/styles';
import {styles} from 'common/lib/styles';
class CustomerWrapper extends Component {

  static propTypes = {
    children: PropTypes.node.isRequired,
    params: PropTypes.object.isRequired,
    plan: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    fetchPlanForCustomer: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
  }

  componentDidMount () {
    const { dispatch, fetchPlanForCustomer, params: { customer_id } } = this.props

    if (customer_id) {
      dispatch(fetchPlanForCustomer(customer_id))
    }
  }

  render () {
    const { children, params, classes, plan } = this.props
    const customer_url = `/c/${params.customer_id}`

    return (
      <div className={classes.root}>

          <Spinner />

          <NavBar root={ `${customer_url}` } fixed>
            <ListLink to={ `${customer_url}/overview` } state="Overblik">
                <ListItem button>
                <ListItemIcon>
                    <EqualizerIcon color="secondary" />
                </ListItemIcon>
                <ListItemText primary="Overblik" />
                </ListItem>
            </ListLink>
            {/* <ListLink to={ `${customer_url}/property` }>
                <ListItem button>
                <ListItemIcon>
                    <BusinessIcon fontSize="large" color="secondary" />
                </ListItemIcon>
                <ListItemText primary="Ejendommen" />
                </ListItem>
            </ListLink> */}
            <ListLink to={ `${customer_url}/activity-plan` }>
                <ListItem button>
                <ListItemIcon>
                    <AssignmentIcon color="secondary" />
                </ListItemIcon>
                <ListItemText primary="Projektoversigt" />
                </ListItem>
            </ListLink>
            {plan.energy_enable &&
            <ListLink to={ `${customer_url}/energy` } state="Energi">
                <ListItem button>
                <ListItemIcon>
                    <WbIncandescentIcon color="secondary" />
                </ListItemIcon>
                <ListItemText primary="Energi" />
                </ListItem>
            </ListLink>}
            <ListLink to={ `${customer_url}/parts` } state="Bygningsdele">
                <ListItem button>
                <ListItemIcon>
                    <ListAltIcon color="secondary" />
                </ListItemIcon>
                <ListItemText primary="Bygningsdele" />
                </ListItem>
            </ListLink>
            <ListLink to={ `${customer_url}/files` } state="Filarkiv">
                <ListItem button>
                <ListItemIcon>
                    <DescriptionIcon color="secondary" />
                </ListItemIcon>
                <ListItemText primary="Filarkiv" />
                </ListItem>
            </ListLink>
            <ListLink to={ `${customer_url}/faq` } state="FAQ">
                <ListItem button>
                <ListItemIcon>
                    <LiveHelpIcon color="secondary" />
                </ListItemIcon>
                <ListItemText primary="FAQ"/>
                </ListItem>
            </ListLink>
          </NavBar>
          <Notifications />
          <div className={classes.container}>
            { children }
          </div>
        </div>
    )

  }
}

const mapStateToProps = state => {
  return {
    plan: state.data.customer.plan,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    fetchPlanForCustomer: actions.fetchPlanForCustomer,
    logout: actions.logout,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(CustomerWrapper))
