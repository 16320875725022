import { template, get } from 'lodash'

import { da_DK as locale } from '../translations'

// TODO for now we don't load locale files dynamically
// const supportedLocales = ['da']
// const currentLocale = 'da'

// TODO compile all templates up front

export function translate (path, bindings) {
  const string = get(locale, path)
  if (!string) {
    console.error(`Error: could not find translation path: ${path}`)
    return ''
  }
  const compiled = template(string)
  return compiled(bindings)
}
