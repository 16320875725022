import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { reduce, forEach, find, includes } from 'lodash'
import { Link } from 'react-router'
import classNames from 'classnames'
import moment from 'moment'
import actions from 'client/actions'
import EstimateCell from './index'
import numeral from 'common/lib/numeral'
import ReactTooltip from 'react-tooltip'
import TitleInput from "./TitleInput";
import { CSVLink } from "react-csv";

function calculateYearInterval (maintenance_start, interval) {
    let years = [maintenance_start] // eslint-disable-line prefer-const
    for (let i = 1; i < 15; i++) {
        if (i % interval === 0) {
            years.push(maintenance_start + i)
        }
    }
    return years
}

class PartRow extends Component {

    static propTypes = {
        part: PropTypes.object.isRequired,
        by_id: PropTypes.object.isRequired,
        by_year: PropTypes.object.isRequired,
        totals: PropTypes.object.isRequired,
        years: PropTypes.array.isRequired,
        start_year: PropTypes.number.isRequired,
        saveEstimate: PropTypes.func.isRequired,
        dispatch: PropTypes.func.isRequired,
        autoFillEstimates: PropTypes.func.isRequired,
        plan_published: PropTypes.bool.isRequired,
        hasEditRights: PropTypes.bool.isRequired,
    }

    constructor (props) {
        super(props)
        this.toggleOverlay = this.toggleOverlay.bind(this)
        this.handleSave = this.handleSave.bind(this)
        this.autoFill = this.autoFill.bind(this)
        this.state = {
            showIntervals: false,
            estimateComponents: [],
        }
    }

    componentWillMount () {
        const { part, by_id, by_year, totals, years, start_year } = this.props
        const maintenance_start = part.maintenance_start || start_year
        const interval_years = calculateYearInterval(maintenance_start, part.maintenance_interval)
        const estimateComponents = this.state.estimateComponents
        forEach(years, year => {
            const interval = includes(interval_years, year)
            estimateComponents.push({
                year,
                interval,
            })
        })
        this.setState({ estimateComponents })
    }

    toggleOverlay () {
        const showIntervals = !this.state.showIntervals
        this.setState({
            ...this.state,
            showIntervals,
        })
    }

    handleSave (type, data) {
        const { saveEstimate, dispatch, plan_published, hasEditRights } = this.props
        if (!plan_published && hasEditRights) {
            dispatch(saveEstimate(type, data))
        }
    }

    autoFill () {
        const { part: { id, plan_id }, autoFillEstimates, dispatch, plan_published, hasEditRights } = this.props
        if (!plan_published && hasEditRights) {
            dispatch(autoFillEstimates(plan_id, id))
        }
    }

    render () {
        const { part, by_id, by_year, totals, years, plan_published, hasEditRights } = this.props
        const { id, title, maintenance_start, maintenance_interval, plan_id, bips_id } = part
        const estimateCells = years.map(year => {
            const isInterval = find(this.state.estimateComponents, { year }).interval
            const extraordinary = part.establishment_year == year && part.establishment_cost ? numeral(part.establishment_cost/1000).format('0,0.0') : null
            if (by_year[year] && by_year[year][id]) {
                const currentYear = by_year[year]
                const currentEstimate = currentYear[id]
                return <EstimateCell data={ by_id[currentEstimate] } year={ year } key={ year } plan_id={ plan_id } part_id={ id } isInterval={ isInterval } active={ this.state.showIntervals } handleSave={ this.handleSave } plan_published={ plan_published } hasEditRights={ hasEditRights } extraordinary={ extraordinary } />
            }
            return <EstimateCell year={ year } key={ year } plan_id={ plan_id } part_id={ id } isInterval={ isInterval } active={ this.state.showIntervals } handleSave={ this.handleSave } plan_published={ plan_published } hasEditRights={ hasEditRights } extraordinary={ extraordinary } />
        })

        const scaffolding = part.scaffolding
            ? <span className="label label-primary">S</span>
            : null
        const construction = part.construction_site
            ? <span className="label label-primary">B</span>
            : null
        const adviser = part.adviser
            ? <span className="label label-primary">R</span>
            : null
        const building_management = part.building_management
            ? <span className="label label-primary">BA</span>
            : null

        return (
            <tr>
                <td key="Title" data-tip data-for={ id } onMouseEnter={ this.toggleOverlay } onMouseLeave={ this.toggleOverlay }>
                    <a href={ `/admin/plans/edit/${plan_id}/parts/edit/${id}` }>{ title }</a>&nbsp;
                    { scaffolding }
                    { construction }
                    { adviser }
                    { building_management }
                    <br />
                    <span className="text-muted small">Frekv.: { maintenance_interval } år | Start: { maintenance_start } | BIPS: { bips_id }</span>
                    <button className="btn btn-link btn-sm" onClick={ this.autoFill }>Autofill</button>
                    <ReactTooltip id={ id.toString() } place="right" type="info" effect="solid" >
                        Omkostninger: <br />
                        { part.maintenance_cost
                            ? numeral(part.maintenance_cost).format('0,0')
                            : 'Ikke angivet'
                        }
                    </ReactTooltip>
                </td>
                { estimateCells }
                {/* <td className="text-muted">{ numeral(part.establishment_cost/1000).format('0.00') }</td> */}
                <td key="Samlet" className="table-cell-highlight text-right">{ numeral(totals.by_id[id]).format('0,0.0') }</td>
            </tr>
        )
    }
}

class OptionsRow extends Component {

    static propTypes = {
        by_id: PropTypes.object.isRequired,
        by_year: PropTypes.object.isRequired,
        totals: PropTypes.object.isRequired,
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        params: PropTypes.object.isRequired,
        years: PropTypes.array.isRequired,
        saveEstimate: PropTypes.func.isRequired,
        dispatch: PropTypes.func.isRequired,
        plan_published: PropTypes.bool.isRequired,
        hasEditRights: PropTypes.bool.isRequired,
        titleEditable: PropTypes.bool.isRequired,
        estimate: PropTypes.shape({
            id: PropTypes.number.isRequired,
            plan_id: PropTypes.number.isRequired,
            year: PropTypes.number.isRequired,
            estimate: PropTypes.number.isRequired,
        }),
        textNormal: PropTypes.bool,
        fieldToUpdate: PropTypes.string,
    }

    static defaultProps = {
        titleEditable: false,
    }

    constructor (props) {
        super(props)
        this.handleSave = this.handleSave.bind(this)
    }

    handleSave (type, data) {
        const { saveEstimate, dispatch } = this.props
        dispatch(saveEstimate(type, data))
    }

    render () {
        const { by_id, by_year, title, description, type, params, totals, years, plan_published, fieldToUpdate, hasEditRights, titleEditable, estimate, dispatch, start_year, textNormal } = this.props;

        const optionCells = years.map(year => {
            if (by_year[year] && by_year[year][type]) {
                const currentYear = by_year[year];
                const currentEstimate = currentYear[type];
                
                return <EstimateCell data={ by_id[currentEstimate] } year={ year } key={ year } plan_id={ params.id } textNormal={textNormal} optionType={ type } handleSave={ this.handleSave } plan_published={ plan_published } hasEditRights={ hasEditRights } />
            }
            return <EstimateCell year={ year } key={ year } plan_id={ params.id } optionType={ type } handleSave={ this.handleSave } textNormal={textNormal} plan_published={ plan_published } hasEditRights={ hasEditRights } />
        })

        const textColorClass = textNormal ? "" : "text-warning";

        const titleElement = titleEditable && typeof fieldToUpdate === "string" ?
            (
                <div>
                    <TitleInput
                        title={title}
                        canEdit={hasEditRights}
                        estimate={estimate}
                        dispatch={dispatch}
                        planId={parseInt(params.id)}
                        startYear={start_year}
                        fieldToUpdate={fieldToUpdate}
                    />
                </div>
            )
            :
            (
                <div>
                    { title }<br />
                    <span className="text-muted small">{ description }</span>
                </div>
            );

        return (
            <tr className={type === "free_form_total" ? "free-form-total" : ""}>
                <td key="Title">
                    {titleElement}
                </td>
                { optionCells }
                <td key="Samlet" className={`${type === "free_form_total" ? "free-form-total" : "table-cell-highlight"} ${textColorClass}`}>{ numeral(totals.by_id[type]).format('0,0.0') }</td>
            </tr>
        )
    }
}

const YearOrdinarySubtotalsRow = ({ totals, years }) => {
    const totalCells = years.map(year => {
        if (totals.by_year[year]) {
            const currentSum = numeral(totals.by_year[year]).format('0,0.0')
            return <td key={ year } className="table-cell-highlight">{ currentSum }</td>
        }
        return <td key={ year } className="table-cell-highlight">0</td>
    })

    return (
        <tr>
            <td key="Title">Ordinær omkostning <br/><span className="text-muted">Subtotal</span>
            </td>
            { totalCells }
            <td>{ numeral(totals.ordinary_total).format('0,0.0') }</td>
        </tr>
    )
}
YearOrdinarySubtotalsRow.propTypes = {
    totals: PropTypes.object.isRequired,
    years: PropTypes.array.isRequired,
}

const YearExtraordinarySubtotalsRow = ({ totals, years }) => {
    const totalCells = years.map(year => {
        return totals.extraordinary_by_year[year] ? <td key={ year } className="table-cell-highlight"><span className="text-warning">{ numeral(totals.extraordinary_by_year[year]).format('0,0.0') }</span></td> : <td key={ year } className="table-cell-highlight"><span className="text-warning">0</span></td>
    })

    return (
        <tr>
            <td key="Title">Ekstraordinær omkostning <br/><span className="text-muted">Subtotal</span>
            </td>
            { totalCells }
            <td className="text-warning">{ numeral(totals.extraordinary_total).format('0,0.0') }</td>
        </tr>
    )
}
YearExtraordinarySubtotalsRow.propTypes = {
    totals: PropTypes.object.isRequired,
    years: PropTypes.array.isRequired,
}

const YearTotalsRow = ({ totals, years, plan_area, price_index }) => {
    const optionCells = years.map(year => {
        if (totals.totals_by_year[year]) {
            const currentSum = numeral(totals.totals_by_year[year]).format('0,0.0')
            return <td key={ year } className="table-cell-highlight">{ currentSum }</td>
        }
        return <td key={ year } className="table-cell-highlight">0</td>
    })

    let totalMY_adjusted = ''
    if (!plan_area || !price_index) {
        totalMY_adjusted = 'Plan mangler bruttoareal eller prisindeks'
    } else {
        const parsed_price_index = 1
        const index_adjusted_total = ( totals.ordinary_total ) * parsed_price_index * 1.25
        totalMY_adjusted = (index_adjusted_total * 1000 / plan_area) / 15
        totalMY_adjusted = isNaN(totalMY_adjusted)
            ? ''
            : numeral(totalMY_adjusted).format('0,0.0')
    }
    return (
        <tr>
            <td key="Title">Total<br /> <span className="text-muted small"></span>
            </td>
            { optionCells }
            <td className={ classNames({ 'has-error': !plan_area }) }>
                { totalMY_adjusted } <br />
                <small>kr./m2/år</small>
            </td>
        </tr>
    )
}
YearTotalsRow.propTypes = {
    totals: PropTypes.object.isRequired,
    years: PropTypes.array.isRequired,
    plan_area: PropTypes.number,
    price_index: PropTypes.string,
}

class DistributionTable extends Component {

    static propTypes = {
        parts: PropTypes.array.isRequired,
        by_id: PropTypes.object.isRequired,
        by_year: PropTypes.object.isRequired,
        totals: PropTypes.object.isRequired,
        fetchEstimates: PropTypes.func.isRequired,
        dispatch: PropTypes.func.isRequired,
        params: PropTypes.object.isRequired,
        is_valid: PropTypes.bool.isRequired,
        start_year: PropTypes.any,
        years: PropTypes.array,
        plan_area: PropTypes.number,
        price_index: PropTypes.string,
        plan: PropTypes.object.isRequired,
    }

    componentDidMount () {
        const { dispatch, fetchEstimates, params, is_valid } = this.props
        if (!is_valid && params.id) {
            dispatch(fetchEstimates(params.id))
        }
    }

    /**
     * https://www.npmjs.com/package/react-csv
     * @returns {Array}
     */
    getCSVData() {
        const { parts, totals, plan } = this.props;
        const csvData = [];

        for (let i = 0; i < parts.length; i++) {
            const part = parts[i];

            csvData.push(this.getPartCSVData(part, true));

            if (part.establishment_cost > 0) {
                csvData.push(this.getPartCSVData(part, false));
            }
        }

        [
            ["Stillads/lift", "scaffolding", false],
            ["Byggeplads", "construction_site", false],
            ["Teknisk rådgiver", "adviser", false],
            ["Byggesagsadministration", "building_management", false],
            [plan.estimate_free_form_title, "free_form", true],
            [plan.estimate_free_form_total_title, "free_form_total", true],
        ].forEach(info => {
            const danishName = info[0];
            const englishName = info[1];

            csvData.push(this.getCSVDataForExtraordinary(englishName, danishName, info[2]));
        });

        csvData.push(this.getCSVTotals("Ordinær omkostning", totals.by_year, true));
        csvData.push(this.getCSVTotals("Ekstraordinær omkostning", totals.extraordinary_by_year, false));
        csvData.push(this.getCSVTotals("Total", totals.totals_by_year, true));

        return csvData;
    }

    getCSVTotals(name, yearEntries, ordinary) {
        const csvData = {
            name: name,
        };

        let total = 0;

        this.populateObjectYears(csvData);

        for (let year in yearEntries) {
            if (yearEntries.hasOwnProperty(year)) {
                // String must contain only numbers
                if (/^\d+$/.test(year)) {

                    csvData[year] = DistributionTable.formatCSVNumber(yearEntries[year]);
                    total += yearEntries[year];
                }
            }
        }

        csvData.total = DistributionTable.formatCSVNumber(total);
        csvData.state = ordinary ? "ordinær" : "ekstraordinær";

        return csvData;
    }

    getCSVDataForExtraordinary(identifier, propName, ordinary) {
        const { by_id } = this.props;

        const csvData = {
            name: propName,
        };

        this.populateObjectYears(csvData);

        let totalEstimate = 0;

        for (let entryId in by_id) {
            if (by_id.hasOwnProperty(entryId)) {
                const entry = by_id[entryId];

                if (entry[identifier] === true) {
                    csvData[entry.year] = DistributionTable.formatCSVNumber(entry.estimate);
                    totalEstimate += entry.estimate;
                }
            }
        }

        csvData.total = DistributionTable.formatCSVNumber(totalEstimate);
        csvData.state = ordinary ? "ordinær" : "ekstraordinær";

        return csvData;
    }

    getPartCSVData(part, ordinary) {
        const { by_id } = this.props;

        const csvData = {
            name: part.title,
        };

        this.populateObjectYears(csvData);

        let totalEstimate = 0;

        for (let entryId in by_id) {
            if (by_id.hasOwnProperty(entryId) && ordinary) {
                const entry = by_id[entryId];

                if (entry.part_id === part.id) {
                    csvData[entry.year] = DistributionTable.formatCSVNumber(entry.estimate);
                    totalEstimate += entry.estimate;
                }
            }
        }

        if (part.establishment_cost > 0 && !ordinary) {
            csvData[part.establishment_year] = DistributionTable.formatCSVNumber(part.establishment_cost / 1000);
            totalEstimate += part.establishment_cost / 1000;
        }

        csvData.total = DistributionTable.formatCSVNumber(totalEstimate);
        csvData.state = ordinary ? "ordinær" : "ekstraordinær";

        return csvData;
    }

    /**
     * @returns {object[]}
     */
    getCSVHeaders() {
        const { years } = this.props;

        const headers = ["name", "state"];

        for (let i = 0; i < years.length; i++) {
            const year = years[i].toString();

            headers.push(year);
        }

        headers.push("total");

        return headers;
    }

    /**
     * @param {number|string} csvNumber
     * @returns {string}
     */
    static formatCSVNumber(csvNumber) {
        csvNumber = csvNumber.toString();

        csvNumber = csvNumber.replace(".", ",");

        return csvNumber;
    }

    populateObjectYears(object) {
        const { years } = this.props;

        for (let i = 0; i < years.length; i++) {
            const year = years[i];

            object[year] = 0;
        }
    }

    render () {
        const { parts, totals, plan_area, price_index, start_year = null, years = [], params, plan } = this.props
        const partRows = parts.map((part, idx) => <PartRow key={ idx } part={ part } years={ years } { ...this.props } />)

        let tableHeaders = years.concat() //eslint-disable-line prefer-const
        tableHeaders.unshift('Aktiviteter')
        // tableHeaders.push('Ekstr. omkosting', 'Samlet')
        tableHeaders.push('Samlet')

        const table = years.length > 0
            ? (
                <div>
                    <h2 className="text-center">
                        Fordelingsplan
                    </h2>
                    <p className="text-center text-muted">Priser skal indtastes ekskl. moms og prisindeks</p>
                    <div className="text-center">
                        <CSVLink
                            data={this.getCSVData()}
                            filename={"fordelingsplan.csv"}
                            headers={this.getCSVHeaders()}
                            separator={";"}
                        >
                            Download som CSV
                        </CSVLink>
                    </div>
                    <hr />
                    <div className="table-responsive">
                        <table className="table table-bordered distribution-table">
                            <thead>
                            <tr>
                                { tableHeaders.map((header, idx) => <th key={ idx }>{ header }</th>) }
                            </tr>
                            </thead>
                            <tbody>
                            { partRows }
                            <OptionsRow title="Stillads/lift" description="" type="scaffolding" years={ years } { ...this.props } />
                            <OptionsRow title="Byggeplads" description="" type="construction_site" years={ years } { ...this.props } />
                            <OptionsRow title="Teknisk rådgiver" description="" type="adviser" years={ years } { ...this.props } />
                            <OptionsRow title="Byggesagsadministration" description="" type="building_management" years={ years } { ...this.props } />
                            <OptionsRow
                                title={plan.estimate_free_form_title}
                                titleEditable={true}
                                description=""
                                type="free_form"
                                years={years}
                                textNormal={true}
                                fieldToUpdate={"estimate_free_form_title"}
                                { ...this.props }
                            />
                            </tbody>
                            <tfoot>
                            <YearOrdinarySubtotalsRow years={ years } totals= { totals } />
                            <YearExtraordinarySubtotalsRow years={ years } totals= { totals } />
                            <YearTotalsRow years={ years } totals= { totals } plan_area={ plan_area } price_index={ price_index } />
                            <OptionsRow
                                title={plan.estimate_free_form_total_title}
                                titleEditable={true}
                                description=""
                                type="free_form_total"
                                years={years}
                                textNormal={true}
                                fieldToUpdate={"estimate_free_form_total_title"}
                                { ...this.props }
                            />
                            </tfoot>
                        </table>
                        <p className="text-muted text-right">Nøgletal er inkl. <br /> moms og prisindeks</p>
                    </div>
                </div>
            )
            : null

        const view = params.id
            ? table
            : (
                <div className="row text-center text-warning">
                    <h2>Driftsplanen er ikke blevet gemt endnu</h2>
                </div>
            )

        return view
    }
}


const mapStateToProps = (state) => {
    const hasEditRights =  state.auth.role === 'admin' || (state.auth.role === 'editor' && state.data.plan.current.internal_contact_id === state.auth.userId) || !state.data.plan.current.internal_contact_id
    return {
        is_valid: state.data.estimates.is_valid,
        by_year: state.data.estimates.by_year,
        by_id: state.data.estimates.by_id,
        totals: state.data.estimates.totals,
        parts: state.data.part.list.content,
        start_year: state.data.years.start_year,
        years: state.data.years.years,
        plan_area: state.data.plan.current.total_area,
        price_index: state.data.plan.current.price_index,
        plan_published: state.data.plan.current.published,
        plan: state.data.plan.current,
        hasEditRights,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
        fetchEstimates: actions.fetchEstimates,
        saveEstimate: actions.saveEstimate,
        autoFillEstimates: actions.autoFillEstimates,
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DistributionTable)
