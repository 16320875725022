import { combineReducers } from 'redux'
import { ActionTypes } from 'client/constants'
import { findIndex } from 'lodash'

const initialCurrentState = {
  portfolio: {},
  totals: {
    properties : 0,
    activePlans: [],
    inActivePlans: [],
    residentialArea : 0,
    commercialArea : 0,
    totalArea: 0,
    residentialUnits : 0,
    commercialUnits : 0,
    totalUnits: 0,
    averageMaintenancePerSquareMeter : 0,
    ordinaryMaintenance : 0,
    extraOrdinaryMaintenance : 0,
    totalMaintenance : 0,
  }
}
const portfolioReducer = (state = initialCurrentState, action) => {
  switch (action.type) {
    case ActionTypes.RECEIVE_PORTFOLIO:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

export default combineReducers({
  portfolio: portfolioReducer,
})
