import { push } from 'react-router-redux'
import fetch from 'isomorphic-fetch'
import { has } from 'lodash'
import { ActionTypes, API_URI } from 'client/constants'
import { actions as spinner } from 'client/components/Spinner'
import { notifyDanger, clearNotifications, notifySuccess } from 'client/components/Notifications/actions'
import validate, { ValidationError } from 'common/lib/validator'
import { UserSchema, UserImageSchema } from 'common/schemas'
import api, { ApiError } from 'client/lib/apiHandler'

const receiveRequest = (data) => ({
  type: ActionTypes.RECEIVE_USER_REQUEST,
  payload: data,
})

const receiveUpdateRequest = (data) => ({
  type: ActionTypes.RECEIVE_USER_UPDATE_REQUEST,
  payload: data,
})

const receiveCreateRequest = (data) => ({
  type: ActionTypes.RECEIVE_USER_CREATE_REQUEST,
  payload: data,
})

const receiveDeactivateRequest = (user_id) => ({
  type: ActionTypes.RECEIVE_USER_DEACTIVATE_REQUEST,
  payload: user_id,
})

const receiveActivateRequest = (user_id) => ({
  type: ActionTypes.RECEIVE_USER_ACTIVATE_REQUEST,
  payload: user_id,
})

const receiveUploadRequest = (user_id) => ({
  type: ActionTypes.RECEIVE_USER_UPLOAD_REQUEST,
  payload: user_id,
})

const fetchUsers = () => {
  return dispatch => {
    dispatch(spinner.show())

    api.get('/users')
      .then(({ body }) => {
        dispatch(receiveRequest(body))
      })
      .catch(error => {
        // TODO handle errors
        console.log(error)
        if (error instanceof ApiError) {
          dispatch(push('/admin/plans'))
        }
      })
      .then(_ => {
        dispatch(spinner.hide())
      })
  }
}

const saveUser = user => {
  return dispatch => {
    dispatch(spinner.show())

    validate(UserSchema, user, 'update')
      .then(result => {
        return api.put(`/users/${user.id}`, result.data)
      })
      .then(({ body }) => {
        dispatch(receiveUpdateRequest(body))
        dispatch(clearNotifications('danger'))
        dispatch(push('/admin/users'))
      })
      .catch(error => {
        if (error instanceof ValidationError) {  //TODO: Better handling of validation errors
          if (has(error.errors, 'email')) {
            dispatch(notifyDanger('Email skal være valid', 'Fejl'))
          }
        }
        if (error instanceof ApiError) {
          if (error.body && error.body.errorCode === 2) {
            dispatch(notifyDanger('Email skal være unik', 'Fejl'))
          } else {
            dispatch(push('/admin/users'))
          }
        }
      })
      .then(_ => {
        dispatch(spinner.hide())
      })
  }
}

const createUser = user => {
  return dispatch => {
    dispatch(spinner.show())

    validate(UserSchema, user)
      .then(result => {
        return api.post('/users', result.data)
      })
      .then(({ body }) => {
        dispatch(receiveCreateRequest(body))
        dispatch(clearNotifications('danger'))
        dispatch(push('/admin/users'))
      })
      .catch(error => {
        if (error instanceof ValidationError) { //TODO: Better handling of validation errors
          console.log(error.errors)
          if (has(error.errors, 'email')) {
            dispatch(notifyDanger('Email skal være valid', 'Fejl'))
          } else {
            dispatch(notifyDanger('Et eller flere felter mangler at blive udfyldt', 'Fejl'))
          }
        }
        if (error instanceof ApiError) {
          if (error.body && error.body.errorCode === 2) {
            dispatch(notifyDanger('Email skal være unik', 'Fejl'))
          }
        }
      })
      .then(_ => {
        dispatch(spinner.hide())
      })
  }
}

const updatePassword = (user_id, password) => {
  return dispatch => {
    dispatch(spinner.show())
    api.put(`/users/${user_id}/set-password`, { password })
      .then(({ body }) => {
        dispatch(notifySuccess('Adgangskode ændret'))
        dispatch(clearNotifications('danger'))
      })
      .catch(error => {
        dispatch(notifyDanger('Der skete en fejl'))
      })
      .then(_ => {
        dispatch(spinner.hide())
      })
  }
}

const deactivateUser = (user_id) => {
  return dispatch => {
    dispatch(spinner.show())
    api.put(`/users/${user_id}/activate`, { activate: false })
      .then(({ body }) => {
        dispatch(receiveDeactivateRequest(user_id))
        dispatch(clearNotifications('danger'))
        dispatch(push('/admin/users'))
      })
      .catch(error => {
        console.log(error)
        dispatch(notifyDanger('Der skete en fejl'))
      })
      .then(_ => {
        dispatch(spinner.hide())
      })
  }
}

const activateUser = (user_id) => {
  return dispatch => {
    dispatch(spinner.show())
    api.put(`/users/${user_id}/activate`, { activate: true })
      .then(({ body }) => {
        dispatch(receiveActivateRequest(user_id))
        dispatch(clearNotifications('danger'))
        dispatch(push('/admin/users'))
      })
      .catch(error => {
        console.log(error)
        dispatch(notifyDanger('Der skete en fejl'))
      })
      .then(_ => {
        dispatch(spinner.hide())
      })
  }
}

const uploadImage = (id, s3_image_id) => {
  return dispatch => {

    validate(UserImageSchema, { id, s3_image_id })
      .then(result => {
        return api.put(`/users/${id}/image`, result.data)
      })
      .then(({ body }) => {
        dispatch(receiveUploadRequest(body))
        dispatch(clearNotifications('danger'))
      })
      .catch(error => {
        dispatch(notifyDanger('Der skete en fejl'))
      })
      .then(_ => {
        dispatch(spinner.hide())
      })

  }
}

export default {
  fetchUsers,
  saveUser,
  createUser,
  deactivateUser,
  activateUser,
  updatePassword,
  uploadImage,
}
